import React from "react";
import MediaViewer from "../Common/MediaViewer";
import hourIcon from "../../assets/icons/hourGlassIcon.svg";
import eyeIcon from "../../assets/icons/eyeIcon.svg";
import {
  VENDOR_VIEW_VENDOR_OFFER_MANAGEMENT_URL,
  VENDR_VIEW_VENDR_EVENT_MANAGEMENT_URL,
  calculateTimeLeft,
} from "../Shared/constant";
import defaultThumbnail from "../../assets/img/logoIcon.png";
import { useNavigate } from "react-router-dom";

const InfoCard = (props) => {
  const Navigate = useNavigate();
  console.log("props?.data?.logo", props?.data?.logo);
  const yesData = props?.data?.user_response?.filter(
    (item) => item.response_value === "Yes"
  );

  // Calculate the total count for response_value equal to "Yes"
  const totalCountYes = yesData?.reduce((total, item) => total + item.count, 0);
  const totalCount = props?.data?.user_response?.reduce(
    (total, item) => total + item.count,
    0
  );
  const percentage = (totalCountYes / totalCount) * 100;

  const timeLeft = calculateTimeLeft(
    props?.data?.offer_end_time || props?.data?.event_end_time
  );
  console.log("timeLeft", timeLeft);

  return (
    <div className="row border-bottom pb-3" id="vendor-dashboard-info-card">
      <div
        className="col-md-3"
        onClick={
          props?.data?.event_title
            ? () =>
                Navigate(
                  `${VENDR_VIEW_VENDR_EVENT_MANAGEMENT_URL}/${props?.data?.id}`
                )
            : () =>
                Navigate(
                  `${VENDOR_VIEW_VENDOR_OFFER_MANAGEMENT_URL}/${props?.data?.id}`
                )
        }
      >
        <MediaViewer
          media={ props?.data?.logo?.thumbnail_url || props?.data?.logo?.file_url || defaultThumbnail}
          maxHeight={"70px"}
          minHeight={"70px"}
          minWidth={"70px"}
          maxWidth={"70px"}
          thumbnail={ props?.data?.logo?.thumbnail_url || props?.data?.logo?.file_url || defaultThumbnail}
        />
      </div>

      <div className="col-md-7 d-center flex-wrap flex-column ps-5">
        <h6 className="text-truncate mb-4 col-md-12" title={props?.data?.offer_title || props?.data?.event_title}>
          {props?.data?.offer_title || props?.data?.event_title}
        </h6>
        <div className="progress-line-container col-md-12 d-flex align-items-center">
          <div
            className="progress-line"
            style={{ width: `${percentage || 0}%` }}
          >
            {" "}
          </div>
        </div>
      </div>
      <div className="col-md-2 d-flex  align-items-center">
        <span className="text-small  fw-200 fs-12px mt-5 mb-2">
          {totalCountYes} / {totalCount}
        </span>
      </div>
      <div className="col-md-12 mt-4">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-between align-items-center flex-wrap mb-3">
            <div className="d-flex gap-4 align-items-center">
              <img src={eyeIcon} alt="" />
              <span>{totalCount || 0} Response</span>
            </div>
          </div>
          <div className="col-md-12 d-flex flex-wrap justify-content-between">
            <div className="d-flex gap-4 align-items-center ms-1">
              <img src={hourIcon} alt="" className="me-1" />
              <span>Time Left: {timeLeft} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
