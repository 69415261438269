/* eslint-disable */
import React, { useEffect, useState, createContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../src/assets/css/style.css";
import "../src/assets/css/bootstrapClasses.css";
import "../src/assets/css/media.css";
import "../src/assets/css/classic/default.css";
import "../src/assets/css/material/base.css";
import "../src/assets/css/material/button.css";
import "../src/assets/css/material/default.css";
import "../src/assets/css/material/timezone.css";

import {
  LOGIN_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
  SETTINGS_MANAGEMENT_URL,
  ADMIN_APPROVAL_MANAGEMENT_URL,
  ADMIN_PAYMENT_MANAGEMENT_URL,
  ADMIN_EDIT_ADMIN_OFFER_MANAGEMENT_URL,
  EDIT_FAQ_MANAGEMENT_URL,
  ADD_FAQ_MANAGEMENT_URL,
  ADMIN_NOTIFICATION_MANAGEMENT_URL,
  ADMIN_VIEW_NOTIFICATION_MANAGEMENT_URL,
  VIEW_FAQ_MANAGEMENT_URL,
  FAQ_MANAGEMENT_URL,
  ADMIN_EVENT_MANAGEMENT_URL,
  ADMIN_VIEW_ADMIN_EVENT_MANAGEMENT_URL,
  ADMIN_VIEW_ADMIN_OFFER_MANAGEMENT_URL,
  EDIT_ADMIN_VENDOR_MANAGEMENT_URL,
  EDIT_SUPPLIER_MANAGEMENT_URL,
  VIEW_PROFILE_URL,
  VIEW_VENDOR_PROFILE_URL,
  ADD_USER_URL,
  EDIT_USER_URL,
  VIEW_SUPPLIER_MANAGEMENT_URL,
  VIEW_ADMIN_VENDOR_MANAGEMENT_URL,
  ADD_SUPPLIER_MANAGEMENT_URL,
  DASHBOARD_URL,
  ADMIN_VIEW_USER_MANAGEMENT_URL,
  USER_MANAGEMENT_URL,
  ADMIN_VENDOR_MANAGEMENT_URL,
  ADD_ADMIN_VENDOR_MANAGEMENT_URL,
  SUPPLIER_MANAGEMENT_URL,
  ADMIN_SETTING_MANAGEMENT_URL,
  MODEL_BRAND_MANAGEMENT_URL,
  VIEW_MODEL_BRANDS_MANAGEMENT_URL,
  ADMIN_SUPPORT_MANAGEMENT_URL,
  CATEGORY_MANAGEMENT_URL,
  ADMIN_OFFER_MANAGEMENT_URL,
  ADMIN_ADD_ADMIN_EVENT_MANAGEMENT_URL,
  ADMIN_EDIT_ADMIN_EVENT_MANAGEMENT_URL,
  ADMIN_LIVE_MAP_MANAGEMENT_URL,
  VENDOR_VIEW_VENDOR_OFFER_MANAGEMENT_URL,
  VENDR_EVENT_MANAGEMENT_URL,
  VENDR_EDIT_VENDR_EVENT_MANAGEMENT_URL,
  VENDOR_DASHBOARD_URL,
  VENDOR_OFFER_MANAGEMENT_URL,
  VENDR_ADD_VENDR_EVENT_MANAGEMENT_URL,
  VENDR_VIEW_VENDR_EVENT_MANAGEMENT_URL,
  VENDOR_PAYMENT_MANAGEMENT_URL,
  VENDOR_SUPPORT_MANAGEMENT_URL,
  VENDOR_REVIEW_MANAGEMENT_URL,
  VENDOR_COIN_TRANSACTION_URL,
  VENDOR_ADD_VENDOR_OFFER_MANAGEMENT_URL,
  VENDOR_GALLERY_MANAGEMENT_URL,
  VENDOR_MY_BUSINESS_MANAGEMENT_URL,
  VENDOR_EDIT_VENDOR_OFFER_MANAGEMENT_URL,
} from "./components/Shared/constant";
import GuestLayout from "./components/Shared/GuestLayout";
import AuthLayout from "./components/Shared/AuthLayout";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Dashboard from "./components/Dashboard";
import UserManagement from "./components/UserManagement";
import VendorManagement from "./components/VendorMangement";
import Settings from "./components/Settings";
import Supplier from "./components/Supplier";
import AddEditVendor from "./components/VendorMangement/AddEditVendor";
import AddEditSupplier from "./components/Supplier/AddEditSupplier";
import ViewVendor from "./components/VendorMangement/ViewVendor";
import ViewSupplier from "./components/Supplier/ViewSupplier";
import CategoryManagement from "./components/CategoryManagement";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./components/Profile";
import { ws_response } from "./Api/ws/ws_response";
import AddEditUser from "./components/UserManagement/AddEditUser";
import {
  getUserListIdWise,
  clearRedux,
  checkLogin,
} from "./redux/actions/adminActions";
import EditSupplier from "./components/Supplier/EditSupplier";
import SettingsManagement from "./components/SettingsManagement";
import FAQManagement from "./components/FAQManagement/Index";
import ViewFAQ from "./components/FAQManagement/ViewFAQ";
import AddEditFAQ from "./components/FAQManagement/AddEditFAQ";
export const WebSocketContext = createContext();
import useWebSocket from "./Api/UseWebsocket";
import NotificationManagement from "./components/NotificationManagement/Index";
import ViewNotification from "./components/NotificationManagement/ViewNotification";
import SupportManagement from "./components/SupportManagent";
import ApprovalManagement from "./components/ApprovalManagement/Index";
import PaymentManagement from "./components/PaymentManagement/Index";
import ViewUser from "./components/UserManagement/ViewUser";
import EventManagement from "./components/EventManagement/Index";
import ViewEvent from "./components/EventManagement/ViewEvent";
import OfferManagement from "./components/OfferManagement/Index";
import ViewOffer from "./components/OfferManagement/ViewOffer";
import AddEditEvent from "./components/EventManagement/AddEditEvent";
import LiveMapFilter from "./components/LiveMap/Index";
import VendorOfferManagement from "./components/VendorOfferManagement/Index";
import VendorViewOffer from "./components/VendorOfferManagement/VendorViewOffer";
import VendorAddEditEvent from "./components/VendorEventManagement/AddEditEvent";
import VendorEventManagement from "./components/VendorEventManagement/Index";
import VendorViewEvent from "./components/VendorEventManagement/ViewEvent";
import VendorPaymentManagement from "./components/VendorPayment/VendorPaymentManagement";
import VendorSupportManagement from "./components/VendorSupport/VendorSupportManagement";
import VendorCTManagement from "./components/Common/VendorCoinTran/VendorCoinTransaction";
import VendorDashboard from "./components/VendorDashboard/Index";
import VendorAddEditOffer from "./components/VendorOfferManagement/VendorAddEditOffer";
import VendorProfile from "./components/VendorProfile/Index";
import VendorReviewManagement from "./components/VendorReviewManagement/Index";
import VendorGallery from "./components/VendorGalleryManagement/VendorGallery";
import VendorMyBusiness from "./components/VendorDashboard/MyBusiness";
import PrivacyPolicy from "./components/Shared/PrivacyPolicy";
import PageLoad from "./components/firebase/PageLoad";
import DeleteAccount from "./components/Shared/DeleteAccount";
window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

function App(props) {
  window.dispatchEvent(new Event("resize"));
  const Dispatch = useDispatch();
  const { accessToken, is_login, ClearCaches_setww, selfInfo } = useSelector(
    (state) => state.adminReducers
  );
  const [GetWEBSocket, setGetWEBSocket] = useState("");

  useWebSocket(
    `${process.env.REACT_APP_SERVICE_URL}/${accessToken}/`,
    setGetWEBSocket,
    Dispatch,
    accessToken
  );
  const isDashboardUrl = window.location.pathname === "/dashboard";

  useEffect(() => {
    // Get the panelContentwrapper element
    const panelContentwrapper = document.querySelector(".panelContentwrapper");

    // Check if the panelContentwrapper element exists
    if (panelContentwrapper) {
      // If the URL is '/dashboard', add the 'dashboard-grey' class; otherwise, remove it
      if (isDashboardUrl) {
        panelContentwrapper.classList.add("dashboard-grey");
      } else {
        panelContentwrapper.classList.remove("dashboard-grey");
      }
    }
  }, [isDashboardUrl]);

  const remember = localStorage.setItem("remember", true);
  useEffect(() => {
    if (ClearCaches_setww === null || ClearCaches_setww === undefined) {
      Dispatch(clearRedux());
      localStorage.clear();
      Dispatch(checkLogin("no"));
    }
  }, [ClearCaches_setww, remember]);

  const RoutUrlSet = () => {
    if (accessToken === "" && is_login === "no") {
      return LOGIN_URL;
    } else {
      if (
        selfInfo?.user_type === "admin" ||
        selfInfo?.permissions?.includes("dashboard")
      ) {
        return DASHBOARD_URL;
      } else {
        if (selfInfo?.user_type === "vendor") {
          return VENDOR_DASHBOARD_URL;
        }
        if (selfInfo?.permissions !== null) {
          if (
            selfInfo?.permissions?.split(",")[0] === "user-list" ||
            selfInfo?.permissions?.split(",")[0] === "add-user" ||
            selfInfo?.permissions?.split(",")[0] === "update-user" ||
            selfInfo?.permissions?.split(",")[0] === "delete-user"
          ) {
            return USER_MANAGEMENT_URL;
          } else if (
            selfInfo?.permissions?.split(",")[0] === "product-list" ||
            selfInfo?.permissions?.split(",")[0] === "add-product" ||
            selfInfo?.permissions?.split(",")[0] === "update-product" ||
            selfInfo?.permissions?.split(",")[0] === "delete-product"
          ) {
            return ADMIN_VENDOR_MANAGEMENT_URL;
          } else if (
            selfInfo?.permissions?.split(",")[0] === "add-Settings" ||
            selfInfo?.permissions?.split(",")[0] === "export-Settings" ||
            selfInfo?.permissions?.split(",")[0] === "Settings-list"
          ) {
            return ADMIN_SETTING_MANAGEMENT_URL;
          } else if (
            selfInfo?.permissions?.split(",")[0] === "add-settings" ||
            selfInfo?.permissions?.split(",")[0] === "update-settings" ||
            selfInfo?.permissions?.split(",")[0] === "delete-settings" ||
            selfInfo?.permissions?.split(",")[0] === "settings-list"
          ) {
            return SUPPLIER_MANAGEMENT_URL;
          } else if (
            selfInfo?.permissions?.split(",")[0] === "model-list" ||
            selfInfo?.permissions?.split(",")[0] === "add-model" ||
            selfInfo?.permissions?.split(",")[0] === "update-model" ||
            selfInfo?.permissions?.split(",")[0] === "delete-model" ||
            selfInfo?.permissions?.split(",")[0] === "brand-list" ||
            selfInfo?.permissions?.split(",")[0] === "add-brand" ||
            selfInfo?.permissions?.split(",")[0] === "update-brand" ||
            selfInfo?.permissions?.split(",")[0] === "delete-brand"
          ) {
            return MODEL_BRAND_MANAGEMENT_URL;
          } else if (
            selfInfo?.permissions?.split(",")[0] === "product-setting-list" ||
            selfInfo?.permissions?.split(",")[0] === "add-product-setting" ||
            selfInfo?.permissions?.split(",")[0] === "update-product-setting" ||
            selfInfo?.permissions?.split(",")[0] === "delete-product-setting"
          ) {
            return SETTINGS_MANAGEMENT_URL;
          }
        }
      }
    }
  };

  return (
    <WebSocketContext.Provider value={{ websocket: GetWEBSocket }}>
      <div className="wrapper">
      <PageLoad />
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Navigate replace to={RoutUrlSet()} />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/delete-account" element={<DeleteAccount />} />
            <Route
              path="/"
              element={
                accessToken === "" && is_login === "no" ? (
                  <GuestLayout>
                    <Login />
                  </GuestLayout>
                ) : (
                  <Navigate replace to={RoutUrlSet()} />
                )
              }
            >
              <Route
                index
                element={
                  accessToken !== "" ? (
                    <Navigate replace to={RoutUrlSet()} />
                  ) : (
                    <GuestLayout>
                      <Login />
                    </GuestLayout>
                  )
                }
              />
              <Route
                path={LOGIN_URL}
                element={
                  accessToken === "" && is_login === "no" ? (
                    <GuestLayout>
                      <Login />
                    </GuestLayout>
                  ) : (
                    <Navigate replace to={RoutUrlSet()} />
                  )
                }
              />
            </Route>
            <Route
              path={FORGOT_PASSWORD_URL}
              element={
                <GuestLayout>
                  <ForgotPassword />
                </GuestLayout>
              }
            />
            <Route
              path={`${RESET_PASSWORD_URL}/:roomId`}
              element={
                <GuestLayout>
                  <ResetPassword />
                </GuestLayout>
              }
            />
            <Route
              path={DASHBOARD_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <Dashboard />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${ADMIN_VIEW_ADMIN_EVENT_MANAGEMENT_URL}/:roomId`}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <ViewEvent />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={ADMIN_EVENT_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <EventManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={FAQ_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <FAQManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${VIEW_FAQ_MANAGEMENT_URL}/:roomId`}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <ViewFAQ />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={ADD_FAQ_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <AddEditFAQ />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${EDIT_FAQ_MANAGEMENT_URL}/:roomId`}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <AddEditFAQ />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${ADMIN_EDIT_ADMIN_OFFER_MANAGEMENT_URL}/:roomId`}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <LiveMapFilter />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={ADMIN_OFFER_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <OfferManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={ADMIN_LIVE_MAP_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <LiveMapFilter />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${ADMIN_VIEW_ADMIN_OFFER_MANAGEMENT_URL}/:roomId`}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <ViewOffer />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={USER_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <UserManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${ADMIN_VIEW_USER_MANAGEMENT_URL}/:roomId`}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <ViewUser />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={ADD_USER_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <AddEditUser />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${EDIT_USER_URL}/:roomId`}
              element={
                <AuthLayout>
                  <AddEditUser />
                </AuthLayout>
              }
            />
            <Route
              path={VIEW_PROFILE_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <Profile />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={VIEW_VENDOR_PROFILE_URL}
              element={
                selfInfo?.user_type === "vendor" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <VendorProfile />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />

            <Route
              path={CATEGORY_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <CategoryManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={SETTINGS_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <SettingsManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={ADMIN_VIEW_NOTIFICATION_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <ViewNotification />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={ADMIN_NOTIFICATION_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <NotificationManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={ADMIN_APPROVAL_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <ApprovalManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={ADMIN_PAYMENT_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <PaymentManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={ADMIN_VENDOR_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <VendorManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${VIEW_ADMIN_VENDOR_MANAGEMENT_URL}/:roomId`}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <ViewVendor />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={ADD_ADMIN_VENDOR_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <AddEditVendor />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${EDIT_ADMIN_VENDOR_MANAGEMENT_URL}/:roomId`}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <AddEditVendor />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${ADMIN_EDIT_ADMIN_EVENT_MANAGEMENT_URL}/:roomId`}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <AddEditEvent />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={ADMIN_ADD_ADMIN_EVENT_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <AddEditEvent />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />

            <Route
              path={`${ADMIN_EDIT_ADMIN_EVENT_MANAGEMENT_URL}/:roomId`}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <AddEditEvent />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />

            <Route
              path={ADMIN_SETTING_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <Settings />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={SUPPLIER_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <Supplier />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={ADD_SUPPLIER_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <AddEditSupplier />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${VIEW_SUPPLIER_MANAGEMENT_URL}/:roomId`}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <ViewSupplier />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${EDIT_SUPPLIER_MANAGEMENT_URL}/:roomId`}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <EditSupplier />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={ADMIN_SUPPORT_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "admin" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <SupportManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />

            {/* Vendor routes */}

            <Route
              path={VENDOR_SUPPORT_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "vendor" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <VendorSupportManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={VENDOR_REVIEW_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "vendor" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <VendorReviewManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={VENDOR_GALLERY_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "vendor" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <VendorGallery />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />

            <Route
              path={VENDOR_MY_BUSINESS_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "vendor" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <VendorMyBusiness />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={VENDOR_COIN_TRANSACTION_URL}
              element={
                selfInfo?.user_type === "vendor" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <VendorCTManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />

            <Route
              path={VENDOR_PAYMENT_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "vendor" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <VendorPaymentManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />

            <Route
              path={VENDR_EVENT_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "vendor" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <VendorEventManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />

            <Route
              path={`${VENDR_VIEW_VENDR_EVENT_MANAGEMENT_URL}/:roomId`}
              element={
                selfInfo?.user_type === "vendor" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <VendorViewEvent />
                  </AuthLayout>
                ) : (
                  <>
                    <Navigate replace to={LOGIN_URL} />
                  </>
                )
              }
            />
            <Route
              path={VENDOR_DASHBOARD_URL}
              element={
                selfInfo?.user_type === "vendor" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <VendorDashboard />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${VENDR_EDIT_VENDR_EVENT_MANAGEMENT_URL}/:roomId`}
              element={
                selfInfo?.user_type === "vendor" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <VendorAddEditEvent />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={VENDR_ADD_VENDR_EVENT_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "vendor" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <VendorAddEditEvent />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={VENDOR_OFFER_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "vendor" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <VendorOfferManagement />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={VENDOR_ADD_VENDOR_OFFER_MANAGEMENT_URL}
              element={
                selfInfo?.user_type === "vendor" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <VendorAddEditOffer />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
            <Route
              path={`${VENDOR_EDIT_VENDOR_OFFER_MANAGEMENT_URL}/:roomId`}
              element={
                selfInfo?.user_type === "vendor" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <VendorAddEditOffer />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />

            <Route
              path={`${VENDOR_VIEW_VENDOR_OFFER_MANAGEMENT_URL}/:roomId`}
              element={
                selfInfo?.user_type === "vendor" &&
                accessToken !== "" &&
                is_login !== "no" ? (
                  <AuthLayout>
                    <VendorViewOffer />
                  </AuthLayout>
                ) : (
                  <Navigate replace to={LOGIN_URL} />
                )
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
      <ToastContainer />
    </WebSocketContext.Provider>
  );
}

export default App;
