/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { API_URL, ADMIN_VENDOR_MANAGEMENT_URL } from "../Shared/constant";
import Scrollbars from "react-custom-scrollbars-2";
import DataTableFilter from "../Common/DataTableFilter";
import moment from "moment";
import CommonToggle from "../Common/CommonToggle";
import { WebSocketContext } from "../../App";
import CommonModel from "../Common/CommonModel";
import wsSend_request from "../../Api/ws/ws_request";
import { GetRequestCall } from "../Common/GetRequestCall";
import AvatarGet from "../Common/AvatarGet";
import { toast } from "react-toastify";

const Settings = () => {
  const Dispatch = useDispatch();
  const {
    selfInfo,
    settingBadgeList,
    settingCoinEarningList,
    vendorListAll,
    accessToken,
  } = useSelector((state) => state.adminReducers);
  console.log(
    "settingCoinEarningList",
    settingCoinEarningList,
    "settingBadgeList",
    settingBadgeList
  );
  const [key, setKey] = useState("redom");
  const { websocket } = useContext(WebSocketContext);
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState("all");
  const [userLimit, setUserLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  // add new category
  const AddNewEarning = () => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Earnings",
      subtitle: "Add Earnings",
      description: ``,
      modalType: "form",
      button: "Save",
    });
  };

  // edit category
  const EditCoinEarnings = (data_) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Edit Earning",
      subtitle: "Edit Earning",
      description: ``,
      modalType: "form",
      button: "Save",
      Data: data_,
    });
  };

  // delete category
  const DeleteCoinEarning = (data_) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Delete Category",
      subtitle: "Delete Category",
      description: `Do you really want to delete <h5>${data_?.name}</h5>`,
      modalType: "not form",
      button: "Save",
      Data: data_,
    });
  };

  const AddNewBadge = () => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Add Badge",
      subtitle: "Add Badge",
      description: ``,
      modalType: "form",
      button: "Save",
    });
  };

  // edit category
  const EditBadge = (data_) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Edit Badge",
      subtitle: "Edit Badge",
      description: ``,
      modalType: "form",
      button: "Save",
      Data: data_,
    });
  };

  // delete category
  const DeleteBadge = (data_) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Delete Badge",
      subtitle: "Delete Badge",
      description: `Do you really want to delete <h5>${data_?.level}</h5>`,
      modalType: "not form",
      button: "Delete",
      Data: data_,
    });
  };
  useEffect(() => {
    let param = "";
    if (key === "earning") {
      param = {
        transmit: "single",
        url: "coinearning_listing",
        request: {
          filter: userType,
          limit: userLimit,
          page: currentPage,
          search: userSearch.trim(),
          user_type: selfInfo?.user_type,
        },
      };
    } else {
      param = {
        transmit: "single",
        url: "badge_listing",
        request: {
          limit: userLimit,
          page: currentPage,
          user_type: selfInfo?.user_type || "admin",
        },
      };
    }
    wsSend_request(websocket, param);
  }, [userSearch, userType, userLimit, currentPage, key,websocket]);
  useEffect(() => {
    fetchInitialValues();
  }, []);
  const fetchInitialValues = async () => {
    try {
      const urlU2V = `${API_URL}admin/setting/percentage-redemption/null/U2V/view`;
      const responseU2V = await GetRequestCall(urlU2V, {}, accessToken);
      if (responseU2V) {
        setFormData((prev) => ({
          ...prev,
          u2v: responseU2V?.data?.percentage,
          u2vdma: responseU2V?.data?.ratio?.dma_coins,
          u2vcoin: responseU2V?.data?.ratio?.value,
        }));
      }

      // Do something with the response, for example, log it
      console.log("response", responseU2V, "url", urlU2V);

      // Handle the response as needed
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      // Handle the error as needed
    }

    try {
      const urlV2U = `${API_URL}admin/setting/percentage-redemption/null/V2U/view`;
      const responseV2U = await GetRequestCall(urlV2U, {}, accessToken);

      if (responseV2U) {
        setFormData((prev) => ({
          ...prev,
          v2u: responseV2U?.data?.percentage, // Assuming you want to set v2u here
          v2udma: responseV2U?.data?.ratio?.dma_coins,
          v2ucoin: responseV2U?.data?.ratio?.value,
        }));
      }

      // Do something with the response, for example, log it
      console.log("response", responseV2U, "url", urlV2U);

      // Handle the response as needed
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      // Handle the error as needed
    }
  };

  const percentageHandler = async (e, value, type, action) => {
    e.preventDefault();
    if(!value){
      toast.error("Please enter percentage value")
      return
    }
    try {
      const url = `${API_URL}admin/setting/percentage-redemption/${value.toString()}/${type}/${action}`;
      const response = await GetRequestCall(url, {}, accessToken);

      // Do something with the response, for example, log it
      console.log("response", response, "url", url);
      toast.success(response?.msg);

      // Handle the response as needed
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      toast.error(error);
      fetchInitialValues();
      // Handle the error as needed
    }
  };
  const ratioHandler = async (e, value1, value2, type, action) => {
    console.log(
      "e, value1, value2,type, action",
      e,
      value1,
      value2,
      type,
      action
    );
    if(!value1){
      toast.error("Please enter DMA coins")
      
    }
    if(!value2){
      toast.error("Please enter DMA coins value")
      
    }
    if(!value1 || !value2) return
    e.preventDefault();
    try {
      const url = `${API_URL}admin/setting/coin-ratio/update/${value1.toString()}:${value2.toString()}/${type}`;
      const response = await GetRequestCall(url, {}, accessToken);

      // Do something with the response, for example, log it
      console.log("response", response, "url", url);
      toast.success(response?.msg);

      // Handle the response as needed
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      toast.error(error);
      fetchInitialValues();

      // Handle the error as needed
    }
  };
  const [formData, setFormData] = useState({
    u2v: "",
    v2u: "",
    u2vdma: "",
    v2udma: "",
    u2vcoin: "",
    v2ucoin: "",
  });
  const [erroru2v, setErroru2v] = useState("");
  const [errorv2u, setErrorv2u] = useState("");
  console.log("formData", formData);

  return (
    <section className="dashboard_wrapper p-0">
      {/* <div className="card-header-New card-header-New-header">
                <Link to={ADMIN_VENDOR_MANAGEMENT_URL} className="header_backbtn">
                    <i className="bi bi-arrow-left"></i> {window.screen.width > 541 && (<>&nbsp; Back</>)}
                </Link>
              
            </div> */}
      <div className="border ps-0 pt-0 p-3 h-100">
        <div>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3 mt-0"
          >
            <Tab eventKey="redom" title="Maximum Coins Redemption">
              <div className="ps-3">
                <div className="bg-blue">
                  <p className="info">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2187_37685)">
                        <path
                          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM9.6875 5.625C9.87292 5.625 10.0542 5.67998 10.2084 5.783C10.3625 5.88601 10.4827 6.03243 10.5536 6.20373C10.6246 6.37504 10.6432 6.56354 10.607 6.7454C10.5708 6.92725 10.4815 7.0943 10.3504 7.22541C10.2193 7.35652 10.0523 7.44581 9.8704 7.48199C9.68854 7.51816 9.50004 7.49959 9.32874 7.42864C9.15743 7.35768 9.01101 7.23752 8.908 7.08335C8.80499 6.92918 8.75 6.74792 8.75 6.5625C8.75 6.31386 8.84878 6.0754 9.02459 5.89959C9.20041 5.72377 9.43886 5.625 9.6875 5.625ZM10.625 14.375C10.2935 14.375 9.97554 14.2433 9.74112 14.0089C9.5067 13.7745 9.375 13.4565 9.375 13.125V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75C9.70652 8.75 10.0245 8.8817 10.2589 9.11612C10.4933 9.35054 10.625 9.66848 10.625 10V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z"
                          fill="#407BFF"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2187_37685">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="common-blue">
                      {" "}
                      This maximum coin percentage redemption value is applied
                      at the time of payment on the total bill amount.
                    </span>
                  </p>
                </div>
                <div className="u2v row mb-5">
                  <div className="col-md-12 mb-2 common-blue">
                    User To Vendor
                  </div>
                  <div className="col-md-3 mb-3">
                    <label
                      htmlFor="userToVendorPercentage"
                      className="mb-1 fs-14px"
                    >
                      Percentage
                    </label>
                    <input
                      type="text"
                      id="userToVendorPercentage"
                      className="form-control mb-3"
                      placeholder="User to vendor percentage"
                      title="User to vendor percentage"
                      value={formData.u2v}
                      pattern="[0-9]+(\.[0-9]*)?" // Accepts numbers with optional decimal point
                      onChange={(e) => {
                        let inputValue = e.target.value.replace(/[^\d.]/g, ""); // Remove non-numeric characters except decimal point
                        // Limit the value to a maximum of 100
                        if (parseFloat(inputValue) > 100) {
                          inputValue = "100";
                        }
                        setFormData((prev) => ({
                          ...prev,
                          u2v: inputValue,
                        }));
                      }}
                    />

                    <div className="d-flex align-items-end justify-content-end">
                      <button
                        className="btn common-blue-bg"
                        onClick={(e) => {
                          percentageHandler(e, formData?.u2v, "U2V", "update");
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </div>

                  <div className="col-md-9 gap-3">
                    <div className="d-flex gap-4 w-100">
                      <div className="col-md-5">
                        <label
                          htmlFor="userToVendorDMA"
                          className="mb-1 fs-14px"
                        >
                          DMA Coins
                        </label>
                        <input
                          type="text"
                          id="userToVendorDMA"
                          className="form-control mb-3"
                          placeholder="User to vendor DMA coins"
                          title="User to vendor DMA coins"
                          value={formData.u2vdma}
                          pattern="[0-9]+(\.[0-9]*)?" // Accepts numbers with optional decimal point
                          onChange={(e) => {
                            const inputValue = e.target.value.replace(
                              /[^\d.]/g,
                              ""
                            ); // Remove non-numeric characters except decimal point
                            setFormData((prev) => ({
                              ...prev,
                              u2vdma: inputValue,
                            }));
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column col-md-5">
                        <div>
                          <label
                            htmlFor="userToVendorValue"
                            className="mb-1 fs-14px"
                          >
                            Value
                          </label>
                          <input
                            type="text"
                            id="userToVendorValue"
                            className="form-control mb-3"
                            placeholder="User to vendor DMA coin value"
                            title="User to vendor DMA coin value"
                            value={formData.u2vcoin}
                            pattern="[0-9]+(\.[0-9]*)?" // Accepts numbers with optional decimal point
                            onChange={(e) => {
                              const inputValue = e.target.value.replace(
                                /[^\d.]/g,
                                ""
                              ); // Remove non-numeric characters except decimal point
                              setFormData((prev) => ({
                                ...prev,
                                u2vcoin: inputValue,
                              }));
                            }}
                          />
                        </div>
                        <div className="d-flex align-items-end justify-content-end">
                          <button
                            className="btn common-blue-bg"
                            onClick={(e) => {
                              ratioHandler(
                                e,
                                formData?.u2vdma,
                                formData?.u2vcoin,
                                "U2V",
                                "update"
                              );
                            }}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="v2a row mt-2 border-top pt-4 ">
                  <div className="col-md-12 mb-2 common-blue">
                    Vendor To Admin
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="vendorToAdmin" className="mb-1 fs-14px">
                      Percentage
                    </label>
                    <input
                      type="text"
                      id="vendorToAdmin"
                      className="form-control mb-3"
                      placeholder="Vendor to admin percentage"
                      title="Vendor to admin percentage"
                      value={formData.v2u}
                      pattern="[0-9]*\.?[0-9]*" // Accepts numbers with optional decimal point
                      onChange={(e) => {
                        let inputValue = e.target.value.replace(/[^\d.]/g, ""); // Remove non-numeric characters except decimal point
                        // Limit the value to a maximum of 100
                        if (parseFloat(inputValue) > 100) {
                          inputValue = "100";
                        }
                        setFormData((prev) => ({
                          ...prev,
                          v2u: inputValue,
                        }));
                      }}
                    />

                    <div className="d-flex align-items-end justify-content-end">
                      <button
                        className="btn common-blue-bg"
                        onClick={(e) => {
                          percentageHandler(e, formData?.v2u, "V2U", "update");
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                  <div className="col-md-9 gap-3">
                    <div className="d-flex gap-4">
                      <div className="col-md-5">
                        <label
                          htmlFor="vendorToAdminDMA"
                          className="mb-1 fs-14px"
                        >
                          DMA Coins
                        </label>
                        <input
                          type="text"
                          id="vendorToAdminDMA"
                          className="form-control mb-3"
                          placeholder="Vendor to admin DMA coins"
                          title="Vendor to admin DMA coins"
                          value={formData.v2udma}
                          pattern="[0-9]*\.?[0-9]*" // Accepts numbers with optional decimal point
                          onChange={(e) => {
                            const inputValue = e.target.value.replace(
                              /[^\d.]/g,
                              ""
                            ); // Remove non-numeric characters except decimal point
                            setFormData((prev) => ({
                              ...prev,
                              v2udma: inputValue,
                            }));
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column col-md-5">
                        <div>
                          <label
                            htmlFor="vendorToAdminValue"
                            className="mb-1 fs-14px"
                          >
                            Value
                          </label>
                          <input
                            type="text"
                            id="vendorToAdminValue"
                            className="form-control mb-3"
                            placeholder="Vendor to admin DMA coin value"
                            title="Vendor to admin DMA coin value"
                            value={formData.v2ucoin}
                            pattern="[0-9]*\.?[0-9]*" // Accepts numbers with optional decimal point
                            onChange={(e) => {
                              const inputValue = e.target.value.replace(
                                /[^\d.]/g,
                                ""
                              ); // Remove non-numeric characters except decimal point
                              setFormData((prev) => ({
                                ...prev,
                                v2ucoin: inputValue,
                              }));
                            }}
                          />
                        </div>
                        <div className="d-flex align-items-end justify-content-end">
                          <button
                            className="btn common-blue-bg"
                            onClick={(e) => {
                              ratioHandler(
                                e,
                                formData?.v2udma,
                                formData?.v2ucoin,
                                "V2A",
                                "update"
                              );
                            }}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="earning" title="Coin Earnings">
              {/* <DataTableFilter
            
            buttonvisible={(selfInfo?.user_type === "admin" || (selfInfo?.permissions?.includes("add-category")))}
            ButtonSet={{
                buttontitle: "Add New Earnings",
                function: AddNewEarning
            }}
        /> */}
              {/* <div className="d-flex justify-content-end mb-3" hidden>
                <button className="btn common-blue-bg" onClick={AddNewEarning}>
                  {" "}
                  <span></span>+ Add New Earning
                </button>
              </div> */}
              <div
                className="tableContent"
                style={{
                  height:
                    window.screen.width > 540
                      ? "calc(100vh - 155px)"
                      : "calc(100vh - 143px)",
                }}
              >
                <Scrollbars
                  style={{
                    height:
                      settingCoinEarningList?.pagination?.total_records > 10
                        ? window.screen.width < 767
                          ? "calc(100% - 10px)"
                          : "calc(100% - 38px)"
                        : "calc(100% - 0px)",
                  }}
                  className="ScrollbarsWrapper"
                  renderView={(props) => <div {...props} className="view" />}
                >
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Reason</th>
                          <th>Coin</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {settingCoinEarningList &&
                          settingCoinEarningList?.list?.map((user, index) => {
                            return (
                              <tr key={index}>
                                <td>{user?.reason}</td>

                                <td>{user?.value}</td>
                                <td className="respoactionSupplier">
                                  <button
                                    type="button"
                                    className="btn actiontblebtn edit-btn"
                                    onClick={() => EditCoinEarnings(user)}
                                  >
                                    <i className="bi bi-pencil-square"></i>
                                  </button>
                                  <button
                                    hidden
                                    type="button"
                                    className="btn actiontblebtn delete-btn"
                                    onClick={() => DeleteCoinEarning(user)}
                                  >
                                    <i className="bi bi-trash3"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}

                        {settingCoinEarningList &&
                          settingCoinEarningList?.list.length === 0 && (
                            <tr>
                              <td style={{ textAlign: "center" }} colSpan={8}>
                                Coin Earnings Not Found !
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </Scrollbars>
              </div>
            </Tab>
            <Tab eventKey="badge" title="Badges">
              <div className="d-flex justify-content-end mb-3">
                <button className="btn common-blue-bg" onClick={AddNewBadge}>
                  {" "}
                  <span></span>+ Add New Badge
                </button>
              </div>
              <div
                className="tableContent"
                style={{
                  height:
                    window.screen.width > 540
                      ? "calc(100vh - 155px)"
                      : "calc(100vh - 143px)",
                }}
              >
                <Scrollbars
                  style={{
                    height:
                      settingBadgeList?.pagination?.total_records > 10
                        ? window.screen.width < 767
                          ? "calc(100% - 10px)"
                          : "calc(100% - 38px)"
                        : "calc(100% - 0px)",
                  }}
                  className="ScrollbarsWrapper"
                  renderView={(props) => <div {...props} className="view" />}
                >
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Level</th>
                          <th>Points</th>
                          <th>Badge</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {settingBadgeList &&
                          settingBadgeList?.list?.map((user, index) => {
                            return (
                              <tr key={index}>
                                <td>{user?.level}</td>
                                <td>{user?.points}</td>

                                <td id="list-avatar">
                                  {" "}
                                  <AvatarGet
                                    type="product"
                                    url={
                                      user?.logo?.thumbnail_url
                                        ? user?.logo?.thumbnail_url
                                        : null
                                    }
                                  />{" "}
                                </td>
                                <td className="respoactionSupplier">
                                  <button
                                    type="button"
                                    className="btn actiontblebtn edit-btn"
                                    onClick={() => EditBadge(user)}
                                  >
                                    <i className="bi bi-pencil-square"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn actiontblebtn delete-btn"
                                    onClick={() => DeleteBadge(user)}
                                  >
                                    <i className="bi bi-trash3"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}

                        {settingBadgeList &&
                          settingBadgeList?.list.length === 0 && (
                            <tr>
                              <td style={{ textAlign: "center" }} colSpan={8}>
                                Badges Not Found !
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </Scrollbars>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      {showModal?.open && (
        <CommonModel
          setUserSearch={setUserSearch}
          setCurrentPage={setCurrentPage}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </section>
  );
};

export default Settings;
