import { DELETE_FILE_API_URL, THUMBNAIL_FILE_API_URL, UPLOAD_FILE_API_URL } from "../components/Shared/constant";

const UploadFileAPI = async (accessToken, payload) => {
    const responce = window.axios.post(UPLOAD_FILE_API_URL, payload, {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
    }}).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default UploadFileAPI;

export const DeleteFileAPI = async (accessToken, id) => {
  const URL = `${DELETE_FILE_API_URL}${id}`;

  try {
    const response = await window.axios.get(URL, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      },
    });

    return {
      ...response.data,
      status: 200,
    };
  } catch (error) {
    return {
      ...error?.response?.data,
      status: error?.response?.status,
    };
  }
};
export const ThumbnailFileAPI = async (accessToken, id) => {
  const URL = `${THUMBNAIL_FILE_API_URL}${id}`;

  try {
    const response = await window.axios.get(URL, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      },
    });

    return {
      ...response.data,
      status: 200,
    };
  } catch (error) {
    return {
      ...error?.response?.data,
      status: error?.response?.status,
    };
  }
};
  