/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { FAQ_MANAGEMENT_URL, countPlainTextOccurrences, maxDescLength, minDescLength } from "../Shared/constant";
import BackButton from "../Common/BackButton";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { Spinner } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonModel from "../Common/CommonModel";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import CommonLoader from "../Common/CommonLoader";
import "react-toastify/dist/ReactToastify.css";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import InputField from "../Common/InputField";
const AddEditFAQ = () => {
  const Navigate = useNavigate();
  const params = useParams();
  const Location = useLocation()?.pathname?.split("/")[1];
  const { websocket } = useContext(WebSocketContext);
  const [inputValue, setInputValue] = useState({
    question: "",
    answer: "",
    faq_id: "",
  });
  const { question, answer } = inputValue;
  const [ErrorQuestion, setErrorQuestion] = useState("");
  const [ErrorAnswer, setErrorAnswer] = useState("");
  console.log("ErrorQuestion", ErrorQuestion, "ErrorAnswer", ErrorAnswer);
  const [editorState, setQueEditorState] = useState(EditorState.createEmpty());
  const [questionEditorState, setAnsEditorState] = useState(
    EditorState.createEmpty()
  );
  const [charCount, setCharCount] = useState(0);
  const { viewProduct, faqList, selfInfo, loaderMain, ClearFormSet } =
    useSelector((state) => state.adminReducers);
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  const [faqQuestion, setFaqQuestion] = useState("");
  const [faqAnswer, setFaqAnswer] = useState("");
  useEffect(() => {
    let param = {
      transmit: "single",
      url: "faq_listing",
      request: {
        limit: 5000,
        page: 1,
        search: "",
        user_type: selfInfo?.user_type || "admin",
      },
    };
    wsSend_request(websocket, param);
  }, [websocket]);
  // get product details
  useEffect(() => {
    if (Location === "edit-faq") {
      if (params?.roomId) {
        let param = {
          transmit: "single",
          url: "faq_detail",
          request: {
            faq_id: params?.roomId,
          },
        };
        wsSend_request(websocket, param);
      }
    }
  }, [params?.roomId, Location,websocket]);

  useEffect(() => {
    if (Location === "edit-faq") {
     
      if (viewProduct?.answer) {
        const characterCount = countPlainTextOccurrences(
          viewProduct?.answer
        );
        setCharCount(characterCount)
        const defaultanswerContent = viewProduct?.answer;
        const answerContentState = ContentState.createFromBlockArray(
          convertFromHTML(defaultanswerContent)
        );
        const answerEditorState =
          EditorState.createWithContent(answerContentState);
        setAnsEditorState(answerEditorState);
      }

      if (viewProduct?.question) {
        setInputValue({
          ...inputValue,
          answer: viewProduct?.answer,
          question: viewProduct?.question,
          faq_id: viewProduct?.faq_id || params?.roomId,
        });
      }
    }
  }, [viewProduct,websocket]);
  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const onAnswerEditorChange = (newEditorState) => {
    // if (charCount < maxDescLength) {
      setErrorAnswer("");
    setAnsEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const plainText = contentState.getPlainText("");
    const characterCount = plainText.length;
    setCharCount(characterCount);
    const contentAsRaw = convertToRaw(contentState);
    const htmlContent = draftToHtml(contentAsRaw);
    const styledHtmlContent = `<div style="font-family: sans-serif;">${htmlContent}</div>`;
    setFaqAnswer(styledHtmlContent);
    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      answer: styledHtmlContent,
    }));
  // }
  };



  const checkIsValid = () => {
    console.log("inside else",inputValue);
    let isValid = true;
    if (inputValue?.question === "") {
      setErrorQuestion("Please enter FAQ's Question");
      isValid = false;
    } else {
      setErrorQuestion("");
    }
    if (!faqAnswer && Location === "add-faq") {
      setErrorAnswer("Please enter FAQ's Answer");
      isValid = false;
    } else {
      setErrorAnswer("");
    }
    if (charCount < minDescLength) {
      setErrorAnswer(
        `Please enter at least ${minDescLength} character answer`
      );
      isValid = false;
    } else {
      setErrorAnswer("");
    }
    if (charCount > maxDescLength && charCount > minDescLength) {
      setErrorAnswer(
        `Please enter maximum ${maxDescLength} character answer`
      );
    }

    return isValid;
  };
  console.log("inputValue?.question", inputValue?.question, faqAnswer);

  const CreateDocument = () => {
    if (checkIsValid()) {
      if (Location === "edit-faq") {
        let param = {
          transmit: "single",
          url: "faq_update",
          request: {
            answer: inputValue?.answer || faqAnswer,
            question: inputValue?.question || faqQuestion,
            faq_id: inputValue?.faq_id || params?.roomId, // Check if params is defined
          },
        };
        console.log("param", param);
        wsSend_request(websocket, param);
        Navigate(FAQ_MANAGEMENT_URL);
      } else {
        let paramRequest = {
          answer: faqAnswer,
          question: inputValue?.question || faqQuestion,
        };

        const paramfilter = Object.entries(paramRequest).filter(
          (elm) => elm[1] !== ""
        );
        let param = {
          transmit: "single",
          url: "faq_add",
          request: Object.fromEntries(paramfilter),
        };
        wsSend_request(websocket, param);
        Navigate(FAQ_MANAGEMENT_URL);
      }
    }
  };

  useEffect(() => {
    if (ClearFormSet.url === "faq_update" && ClearFormSet.action === true) {
      Navigate(FAQ_MANAGEMENT_URL);
      ClearForm();
    } else if (ClearFormSet.url === "faq_add" && ClearFormSet.action === true) {
      Navigate(FAQ_MANAGEMENT_URL);
      ClearForm();
    }
  }, [ClearFormSet]);

  // clear form
  const ClearForm = () => {
    setInputValue({
      ...inputValue,
      question: "",
      answer: "",
    });
  };


  return (
    <React.Fragment>
      <div
        hidden
        className="header_back header_back_with_btn"
        style={{
          justifyContent: Location !== "edit-product" ? "end" : "space-between",
        }}
      >
        <BackButton targeturl={FAQ_MANAGEMENT_URL} title={"Back"} />
      </div>

      <section className="dashboard_wrapper_form">
        <div className="breadcrumb mb-3">
          <Link to={FAQ_MANAGEMENT_URL}>FAQ</Link>
          <span> > </span>
          <span>{Location === "edit-faq" ? "Edit FAQ" : "Add FAQ"}</span>
        </div>
        <div className="scrollform" style={{ border: "1px solid #b7b6b6" }}>
          <Scrollbars
            style={{ height: "calc(100vh - 150px)" }}
            className="ScrollbarsWrapper"
            renderView={(props) => <div {...props} className="view" />}
          >
            <div className="scrollformCustom faq-bg h-100 p-3">
              <div className="row m-0" style={{ margin: "0 auto" }}>
                <div className="col-md-12">
                  <div className="row m-0">
                  <div className="col-md-10">
                <InputField
                  type={"text"}
                 value={inputValue?.question || question}
                  placeholder="Enter your question"
                  label="Question <span class='redmednstar'>*</span>"
                  name="question"
                  onChange={handleChange}
                  onKeyUp={(e) =>
                    CheckValid(e.target.value, {
                      type: "Question",
                      error: setErrorQuestion,
                    })
                  }
                  onKeyDown={EmptySpaceFieldValid}
                  errormessage={ErrorQuestion}
                  required={true}
                  maxLength={255}
                  minLength={1}
                  className={
                    ErrorQuestion !== ""
                      ? "form-control error-form"
                      : "form-control"
                  }
                />
                </div>
                </div>
                </div>

                <div className="col-md-12 mt-3">
                  <div className="row m-0">
                    <div className="col-md-12">
                  <label htmlFor="toolbarClassName" className="mb-2 fs-14px">
                    Answer <span className="common-blue">*</span>
                  </label>
                  <Editor
                    editorState={questionEditorState}
                    onEditorStateChange={onAnswerEditorChange}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    wrapperStyle={{
                      width: "81%",
                      border: "1px solid #ccc",
                      backgroundColor: "white",
                      padding: "10px",
                      borderRadius: "4px",
                    }}
                    editorStyle={{
                      minHeight: "150px",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "4px",
                      fontFamily: "sans-serif",
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "link",
                        "emoji",
                        "history",
                      ],
                      inline: {
                        inDropdown: false,
                        options: [
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                          "superscript",
                          "subscript",
                        ],
                      },
                      list: {
                        inDropdown: false,
                        options: ["unordered", "ordered"],
                      },
                      textAlign: {
                        inDropdown: true,
                      },
                      link: { inDropdown: false },
                      history: { inDropdown: false },
                    }}
                  />
                  {ErrorAnswer !== "" && (
                    <div className="errormendatorr">
                      <i className="bi bi-x-circle-fill"></i>
                      {ErrorAnswer}
                    </div>
                  )}
                  </div></div>

                </div>
                <div className="col-md-6"></div>
                <div className="col-md-4 mt-3">
                  <div className="row m-0">
                    <div className="col-md-12">
                  <div
                    className="form-group-btn"
                    style={{ textAlign: "right" }}
                  >
                    <button
                      onClick={() => CreateDocument()}
                      className="btn addbtncmn"
                    >
                      {Location === "edit-faq" ? "Save" : "Create"}
                    </button>
                  </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
        {/* loader start here */}
        {loaderMain && <CommonLoader />}
      </section>
      {showModal?.open && (
        <CommonModel
          fromUrl={"product"}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </React.Fragment>
  );
};

export default AddEditFAQ;
