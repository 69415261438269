import LogoutAPI from "../../Api/LogoutAPI";
import {
  clearRedux,
  checkLogin,
  getDeviceId,
} from "../../redux/actions/adminActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CommonLogout = async (accessToken, dispatch) => {
  const responce = await LogoutAPI(accessToken);
  if (responce?.status === 200) {
    setTimeout(() => {
      toast.success(responce.msg);
      dispatch(clearRedux());
      localStorage.clear();
      dispatch(checkLogin("no"));
      dispatch(getDeviceId(""));
    }, 200);
  } else if (responce?.status === 403) {
    setTimeout(() => {
      toast.success(responce.msg);
      dispatch(clearRedux());
      localStorage.clear();
      dispatch(checkLogin("no"));
      dispatch(getDeviceId(""));
    }, 200);
  }
};

export default CommonLogout;
