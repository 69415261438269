/* eslint-disable */
import React, { useState, useContext, useEffect, useRef } from "react";
import {
  GOOGLE_MAP_API_KEY,
  ADMIN_VENDOR_MANAGEMENT_URL,
  ageOptions,
  dubaiStatesOptions,
  imagesArray,
  isVideoUrl,
  parseDateTime,
  vendorCountryOptions,
  ADMIN_OFFER_MANAGEMENT_URL,
  extractIdFromUrl,
  maxSize,
  formatDates,
  minDescLength,
  maxDescLength,
  isEndDateTimeGreaterThanStartDateTime,
  countPlainTextOccurrences,
} from "../Shared/constant";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import UploadFileAPI, { DeleteFileAPI } from "../../Api/UploadFileAPI";
import CommonModel from "../Common/CommonModel";
import AvatarGet from "../Common/AvatarGet";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import CommonLoader from "../Common/CommonLoader";
import {
  LoaderStartMain,
  getBrandsWiseModelList,
  setConfirmModal,
} from "../../redux/actions/adminActions";
import { Tab, Tabs } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import Select from "react-select";
import menIcon from "../../assets/img/menIcon.png";
import womenIcon from "../../assets/img/womenIcon.png";
import "react-datepicker/dist/react-datepicker.css";
import "react-clock/dist/Clock.css";
import DatePicker from "react-datepicker";

import uploadIcon from "../../assets/icons/uploadIcon.svg";
import MediaViewer from "../Common/MediaViewer";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { FilteredMap, InitialMap } from "../Common/Map";
import categoryIcon from "../../assets/icons/categoryIconSVG.svg";
import stateIcon from "../../assets/icons/stateIconSVG.svg";
import usersIcon from "../../assets/icons/peopleIconSVG.svg";
import genderIcon from "../../assets/icons/userIconSVG.svg";
import closeIcon from "../../assets/img/closeIcon.png";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TimePicker from "../Common/TimePicker/TimePicker.jsx";

const LiveMapFilter = () => {
  const location = useLocation();
  const state = location.state;

  // Now you can access the state object
  console.log("state", state);
  const Navigate = useNavigate();
  const Dispatch = useDispatch();
  const [key, setKey] = useState("info");
  const [infoFormFill, setinfoFormFill] = useState(false);
  const params = useParams();
  const Location = useLocation()?.pathname?.split("/")[1];
  const { websocket } = useContext(WebSocketContext);
  const [inputValue, setInputValue] = useState({
    VendorName: "",
    address: "",
    OfferAddress: "",
    Email: "",
    ContactNumber: "",
    BussinessContactNumber: "",
    OfferTitle: "",
    DetailAddress: "",
    VendorLocation: "",
  });
  const {
    VendorName,
    address,
    OfferTitle,
    ContactNumber,
    OfferAddress,
    Email,
    BussinessContactNumber,

    DetailAddress,
    VendorLocation,
  } = inputValue;

  const [errorVendorName, setErrorVendorName] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  const [errorCategory, seterrorCategory] = useState("");
  const [errorMedia, setErrorMedia] = useState("");
  const [errorOfferAddress, setErrorOfferAddress] = useState("");
  const [errorOfferTitle, setErrorOfferTitle] = useState("");
  const [errorDesc, setErrorDesc] = useState("");
  const [errorOfferStart, setErrorOfferStart] = useState("");
  const [errorOfferEnd, setErrorOfferEnd] = useState("");
  const [errorAge, setErrorAge] = useState("");
  const [errorGender, setErrorGender] = useState("");
  const [errorSelectedVendor, setErrorSelectedVendor] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [editorState, setQueEditorState] = useState(EditorState.createEmpty());
  const [StoreDesc, setStoreDesc] = useState("");
  const {
    viewProduct,
    accessToken,
    liveMapUserList,
    loaderMain,
    selfInfo,
    categoryList,
    subcategoryList,
    vendorList,
  } = useSelector((state) => state.adminReducers);
  console.log("liveMapUserList", liveMapUserList);
  const [UploadFileLoader, setUploadFileLoader] = useState(false);
  const [AvatarImage, setAvatarImage] = useState("");
  const [AvatarImageId, setAvatarImageId] = useState("");
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  const [fileData, setFileData] = useState([]);
  console.log("fileData", fileData);
  const [showVideoModal, setShowVideoModal] = useState({
    show: false,
    to: "",
    id: "",
    parent_id: "",
  });
  const [submitClick, setSubmitClick] = useState(false);
  const [selectedVendor, setSelectedVednor] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const handleVednorChange = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    setSelectedVednor(selectedOption);
    setSelectedDubaiState(null);
    if (selectedOption && selectedOption?.interest) {
      // Split the categories string into an array
      const productCategories = selectedOption?.interest.split(",");

      // Filter categoryList to find objects whose name matches the product categories
      const filteredCategories = categoryList?.list?.filter((category) =>
        productCategories.includes(category.id)
      );

      // Map the filtered categories to the required structure and update the state
      const mappedCategories = filteredCategories.map((category) => ({
        value: category.id,
        label: category.name,
      }));

      setSelectedCategories(mappedCategories);
    }
  };
  console.log("selectedVendor", selectedVendor);
  const categoryOptions = categoryList?.list
    ?.filter((elm) => elm?.is_active === true)
    ?.map((elm) => ({ value: elm?.id, label: elm?.name }));
  const vendorListOption = vendorList?.list
    ?.filter((elm) => elm?.is_active === true)
    ?.map((elm) => ({
      value: elm?.id ? elm?.id : elm?.owner_email_id,
      label: elm?.business_name ? elm?.business_name : "-",
      code: elm?.vendor_code ? elm?.vendor_code : "-",
      address: elm?.address ? elm?.address : "-",
      name: elm?.business_name ? elm?.business_name : "-",
      state: elm?.state ? elm?.state : "-",
      country: elm?.country ? elm?.country : "-",
      email: elm?.owner_email_id ? elm?.owner_email_id : "-",
      interest: elm?.interest,
    }));
  useEffect(() => {
    if (state?.userId) {
      const prevSelectedVendor = vendorList?.list?.find(
        (elm) => elm?.id === state?.userId
      );

      const selectedVendorData = prevSelectedVendor
        ? {
            value: prevSelectedVendor.id,
            id: prevSelectedVendor.id,
            label: prevSelectedVendor.business_name,
            code: prevSelectedVendor.vendor_code,
            address: prevSelectedVendor.address,
            name: prevSelectedVendor.business_name,
            state: prevSelectedVendor.state,
            country: prevSelectedVendor.country,
            email: prevSelectedVendor.owner_email_id,
            interest: prevSelectedVendor?.interest,
          }
        : null;

      setSelectedVednor(selectedVendorData);
      if (selectedVendorData && selectedVendorData?.interest) {
        // Split the categories string into an array
        const productCategories = selectedVendorData?.interest.split(",");

        // Filter categoryList to find objects whose name matches the product categories
        const filteredCategories = categoryList?.list?.filter((category) =>
          productCategories.includes(category.id)
        );

        // Map the filtered categories to the required structure and update the state
        const mappedCategories = filteredCategories.map((category) => ({
          value: category.id,
          label: category.name,
        }));

        setSelectedCategories(mappedCategories);
      }
    }
  }, [location]);

  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
  };
  const commaSeparatedCategories = selectedCategories
    .map((category) => category.value)
    .join(",");
  const [menSelected, setMenSelected] = useState(false);
  const [womenSelected, setWomenSelected] = useState(false);

  const [selectedDubaiState, setSelectedDubaiState] = useState(null);

  const ApplyFilterHandler = () => {
    if ((startAge && !endAge) || (!startAge && endAge)) {
      toast.error("Please select valid ages");
      return;
    }
    if (
      startAge?.value !== undefined &&
      endAge?.value !== undefined &&
      (endAge.value <= 0 || startAge.value > endAge.value)
    ) {
      toast.error(
        "Start age cannot be greater than end age, and end age must be greater than 0"
      );
      return;
    }

    if (selectedVendor?.value || selectedDubaiState?.value) {
      setShowSubmit(true);
      let paramRequest = {
        limit: 5000,
        page: 1,
        distance: selectedVendor ? (rangeValue == "0" ? "" : rangeValue) : "",
        gender:
          menSelected && womenSelected
            ? ""
            : menSelected
            ? "Male"
            : womenSelected
            ? "Female"
            : "",
        interest: selectedCategories?.map((item) => item.value).join(","),
        age_range:
          startAge?.value >= 0 && endAge?.value >= 0
            ? `${startAge?.value}-${endAge?.value}`
            : "",
        state: selectedDubaiState?.value,
        vendor_id: selectedVendor?.value,
      };
      const paramfilter = Object.entries(paramRequest).filter((elm) => {
        if (elm[1] !== "") {
          return elm;
        }
      });
      let param = {
        transmit: "single",
        url: "user_live_map",
        request: Object.fromEntries(paramfilter),
      };
      console.log("param", param);
      wsSend_request(websocket, param);
      toast.success("Filter applied");
    } else {
      toast.error("Please select specified vendor or state");
    }
  };
  // get product details
  useEffect(() => {
    if (Location === "edit-offer") {
      if (params?.roomId) {
        let param = {
          transmit: "single",
          url: "offer_get",
          request: {
            offer_id: params?.roomId,
            user_type: selfInfo?.user_type,
          },
        };
        wsSend_request(websocket, param);
      }
    }
  }, [params?.roomId, Location, websocket]);

  useEffect(() => {
    let param = {
      transmit: "single",
      url: "category_listing",
      request: {
        filter: "all",
        limit: 5000,
        page: 1,
        search: "",
        user_type: selfInfo?.user_type,
        category_by: selfInfo?.user_type,
      },
    };
    wsSend_request(websocket, param);
  }, [websocket]);
  useEffect(() => {
    let param = {
      transmit: "single",
      url: "vendor_admin_listing",
      request: {
        filter: "",
        limit: 5000,
        page: 1,
        search: "",
        user_type: selfInfo?.user_type,
        state_filter: selectedDubaiState?.value,
      },
    };
    wsSend_request(websocket, param);
  }, [selectedDubaiState, websocket]);
  // add new category
  const AddCategoryFnct = () => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Add Category",
      subtitle: "Add Category",
      description: ``,
      modalType: "form",
      button: "Yes",
    });
  };
  useEffect(() => {
    if (Location === "edit-offer" && viewProduct) {
      const prevSelectedVendor = vendorList?.list?.find(
        (elm) => elm?.vendor_code === viewProduct?.vendor_code
      );

      const selectedVendorData = prevSelectedVendor
        ? {
            value: prevSelectedVendor.id,
            id: prevSelectedVendor.id,
            label: prevSelectedVendor.business_name,
            code: prevSelectedVendor.vendor_code,
            address: prevSelectedVendor.address,
            name: prevSelectedVendor.business_name,
            state: prevSelectedVendor.state,
            country: prevSelectedVendor.country,
            email: prevSelectedVendor.owner_email_id,
          }
        : null;

      setSelectedVednor(selectedVendorData);
      setSelectedDubaiState({
        value: viewProduct?.state,
        label: viewProduct?.state,
      });

      setInputValue({
        ...inputValue,
        OfferTitle: viewProduct?.offer_title,
        country: viewProduct?.country,
        city: viewProduct?.city,

        address: viewProduct?.address,
        location: viewProduct?.location,
        state_code: viewProduct?.state,
      });
      if (viewProduct?.offer_users) {
        const [startAge, endAge] = viewProduct?.offer_users?.age_range
          .split("-")
          .map((age) => parseInt(age));
        setStartAge({
          value: startAge,
          label: `${startAge} - Age`,
        });
        setEndAge({
          value: endAge,
          label: `${endAge} - Age`,
        });
      }
      if (viewProduct?.offer_users?.gender?.includes("Male")) {
        setMenSelected(true);
      }
      if (viewProduct?.offer_users?.gender?.includes("Female")) {
        setWomenSelected(true);
      }
      if (viewProduct?.offer_start_time) {
        const { date, time } = parseDateTime(viewProduct?.offer_start_time);
        setStartDate(date);
        setStartTime(time);
      }
      if (viewProduct?.offer_end_time) {
        const { date, time } = parseDateTime(viewProduct?.offer_end_time);
        setEndDate(date);
        setEndTime(time);
      }
      if (viewProduct?.offer_description) {
        setErrorDesc("");
        console.log("setErrorDesc", viewProduct?.offer_description);
        const characterCount = countPlainTextOccurrences(
          viewProduct?.offer_description
        );
        setCharCount(characterCount);
        const defaultStoreDescriptionContent = viewProduct.offer_description;
        const storeDescriptionContentState = ContentState.createFromBlockArray(
          convertFromHTML(defaultStoreDescriptionContent)
        );
        const storeDescriptionEditorState = EditorState.createWithContent(
          storeDescriptionContentState
        );
        setQueEditorState(storeDescriptionEditorState);
      }

      if (viewProduct && viewProduct?.interest) {
        // Split the categories string into an array
        const productCategories = viewProduct?.interest.split(",");

        // Filter categoryList to find objects whose name matches the product categories
        const filteredCategories = categoryList?.list?.filter((category) =>
          productCategories.includes(category.name)
        );

        // Map the filtered categories to the required structure and update the state
        const mappedCategories = filteredCategories.map((category) => ({
          value: category.id,
          label: category.name,
        }));

        setSelectedCategories(mappedCategories);
      }
      if (viewProduct && viewProduct?.sub_interest) {
        // Split the categories string into an array
        const productCategories = viewProduct?.sub_interest.split(",");

        // Filter categoryList to find objects whose name matches the product categories
        const filteredCategories = subcategoryList?.list?.filter((category) =>
          productCategories.includes(category.name)
        );

        // Map the filtered categories to the required structure and update the state
        const mappedCategories = filteredCategories.map((category) => ({
          value: category.id,
          label: category.name,
        }));

        setSelectedTags(mappedCategories);
      }
      setAvatarImage({
        url: {
          file_url: viewProduct?.logo?.file_url,
        },
      });
      setFileData([]);
      if (viewProduct?.logo?.original_name) {
        setFileData((prevFileData) => {
          prevFileData.push(viewProduct?.logo);
          return prevFileData;
        });
      }
      console.log("fileData", fileData);
    }
  }, [viewProduct, websocket]);
  const onEditorChange = (newEditorState) => {
    // if (charCount < maxDescLength) {
      setQueEditorState(newEditorState);
      const contentState = newEditorState.getCurrentContent();
      const plainText = contentState.getPlainText("");
      const characterCount = plainText.length;
      setCharCount(characterCount);
      const contentAsRaw = convertToRaw(contentState);
      const htmlContent = draftToHtml(contentAsRaw);
      const styledHtmlContent = `<div style="font-family: Manrope;">${htmlContent}</div>`;

      setStoreDesc(styledHtmlContent);

      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        question: styledHtmlContent,
      }));
    // }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const UploadImage = async (e) => {
    Dispatch(LoaderStartMain(true));

    setUploadFileLoader(true);
    const files = e.target.files[0];
    if (files && files.size > maxSize) {
      toast.error("File size exceeds 30MB limit.");
      e.target.value = null; // Clear the input field
      Dispatch(LoaderStartMain(false));
      return;
    }

    const formData = new FormData();
    formData.append("file", files, files.name);

    formData?.append("to", selectedVendor?.value);

    formData.append("is_admin", selfInfo?.user_type === "admin" ? "1" : "0");

    const fileresponse = await UploadFileAPI(accessToken, formData);

    if (fileresponse) {
      setFileData((prevFileData) => [...prevFileData, fileresponse[0]]);
      setFileData((prevFileData) =>
        prevFileData.map((file, index) => ({ ...file, sequence: index + 1 }))
      );
      console.log("fileData", fileData);

      if (files.type.startsWith("image")) {
        // Handle image upload
        console.log("Selected file is an image:", files);
        // Your image upload logic here
      }
      // Check if the selected file is a video
      else if (files.type.startsWith("video")) {
        // Handle video upload
        console.log("Selected file is a video:", files);
        setShowVideoModal((prev) => ({
          ...prev,
          show: true,
          to: formData?.to,
          id: params?.roomId || inputValue?.owner_email_id,
          parent_id: fileresponse[0]?.id,
        }));
      }
    }
    Dispatch(LoaderStartMain(false));
  };

  const checkIsValid = () => {
    if (
      OfferTitle &&
      fileData?.length === 1 &&
      selectedVendor &&
      startDate !== null &&
      startTime !== null &&
      endTime !== null &&
      endDate !== null &&
      errorOfferEnd === "" &&
      isEndDateTimeGreaterThanStartDateTime(
        startDate,
        startTime,
        endDate,
        endTime
      ) &&
      charCount > minDescLength &&
      charCount < maxDescLength
    ) {
      return true;
    } else {
      if (!startDate || !startTime) {
        setErrorOfferStart("Please select offer start date and time");
      } else {
        setErrorOfferStart("");
      }
      if (!endDate || !endTime) {
        setErrorOfferEnd("Please select offer end date and time");
      } else {
        setErrorOfferEnd("");
      }
      if (!startAge || !endAge) {
        setErrorAge("Please select age");
      } else {
        setErrorAge("");
      }
      if (!menSelected && !womenSelected) {
        setErrorGender("Please select gender");
      } else {
        setErrorGender("");
      }
      CheckValid(OfferTitle, {
        type: "Title",
        error: setErrorOfferTitle,
      });
      if (OfferTitle === undefined || OfferTitle === "") {
        setErrorOfferTitle("Please enter offer title");
      }
      if (charCount < minDescLength) {
        setErrorDesc(
          `Please enter at least ${minDescLength} character description`
        );
      } else {
        setErrorDesc("");
      }
      if (charCount > maxDescLength && charCount > minDescLength) {
        setErrorDesc(
          `Please enter maximum ${maxDescLength} character description`
        );
      }
      CheckValid(inputValue?.address, {
        type: "Address",
        error: setErrorOfferAddress,
      });
      CheckValid(inputValue?.OfferTitle, {
        type: "Title",
        error: setErrorOfferTitle,
      });

      if (selectedCategories?.length == 0) {
        seterrorCategory("Please select category");
      } else {
        seterrorCategory("");
      }

      if (fileData?.length == 0) {
        setErrorMedia("Please Upload Offer Image");
      } else {
        setErrorMedia("");
      }

      if (!selectedVendor) {
        setErrorSelectedVendor("Please select vendor");
      } else {
        setErrorSelectedVendor("");
      }
      if (startDate?.getTime() === endDate?.getTime() && startTime >= endTime) {
        setErrorOfferEnd(
          "Please select offer end time greater than start time"
        );
      } else if (!startDate || !endDate || !startTime || !endTime) {
        // Handle the case where any of the time values are missing
        setErrorOfferEnd("Please enter all required time values for the offer");
      }
      if (
        !isEndDateTimeGreaterThanStartDateTime(
          startDate,
          startTime,
          endDate,
          endTime
        )
      ) {
        setErrorOfferEnd(
          "Please select an offer end time greater than the start time"
        );
      }

      return false;
    }
  };
  const setOfferMode = () => {
    setSubmitClick(true);
    const isValid = checkIsValid();
    console.log("isValid", isValid);
    if (isValid) {
      let paramRequest;

      paramRequest = {
        created_by: selfInfo?.user_type,
        vendor_code: selectedVendor?.code || selectedVendor?.value,
        vendor_id: selectedVendor?.value,
        offer_title: inputValue?.OfferTitle,
        offer_start_time: formatDates(startDate, startTime),
        offer_end_time: formatDates(endDate, endTime),
        offer_interests: selectedCategories
          ?.map((item) => item.value)
          .join(","),
        notification_ids: liveMapUserList?.list?.list?.length
          ? liveMapUserList?.list?.list?.map((item) => item?.id).join(",")
          : "",
        offer_description: StoreDesc || viewProduct?.offer_description,
        logo: fileData[0]?.name || AvatarImage?.name,
        offer_mode: "payment",
        user_count: liveMapUserList?.list?.counts?.total_users,
        user_type: selfInfo?.user_type,
      };

      const paramfilter = Object.entries(paramRequest).filter((elm) => {
        if (elm[1] !== "") {
          return elm;
        }
      });
      console.log("paramfilter", paramfilter, "paramRequest", paramRequest);
      let param = {
        transmit: "single",
        url: "offer_add",
        request: Object.fromEntries(paramfilter),
      };
      wsSend_request(websocket, param);
      Navigate(ADMIN_OFFER_MANAGEMENT_URL);
    }
  };
  console.log(
    "liveofferuserid",
    liveMapUserList?.list?.list?.length
      ? liveMapUserList?.list?.list?.map((item) => item?.id).join(",")
      : ""
  );
  const CreateOffer = () => {
    setSubmitClick(true);
    const isValid = checkIsValid();
    console.log("isValid", isValid);
    if (isValid) {
      if (Location === "edit-offer") {
        let param;

        param = {
          transmit: "single",
          url: "offer_update",
          request: {
            offer_title: inputValue?.OfferTitle,
            offer_start_time: formatDates(startDate, startTime),
            offer_end_time: formatDates(endDate, endTime),
            offer_interests: selectedCategories
              ?.map((item) => item.value)
              .join(","),

            offer_description: StoreDesc || viewProduct?.offer_description,
            logo: fileData[0]?.name || AvatarImage?.name,
            offer_id: params?.roomId,
            user_type: selfInfo?.user_type,
          },
        };
        console.log("param", param);
        wsSend_request(websocket, param);
        Navigate(ADMIN_OFFER_MANAGEMENT_URL);
      } else {
        let paramRequest;

        paramRequest = {
          created_by: selfInfo?.user_type,
          vendor_code: selectedVendor?.code || selectedVendor?.value,
          vendor_id: selectedVendor?.value,
          offer_title: inputValue?.OfferTitle,
          offer_start_time: formatDates(startDate, startTime),
          offer_end_time: formatDates(endDate, endTime),
          offer_interests: selectedCategories
            ?.map((item) => item.value)
            .join(","),
          notification_ids: liveMapUserList?.list?.list?.length
            ? liveMapUserList?.list?.list?.map((item) => item?.id).join(",")
            : "",
          offer_description: StoreDesc || viewProduct?.offer_description,
          logo: fileData[0]?.name || AvatarImage?.name,
          offer_mode: "free",
          user_count: liveMapUserList?.list?.counts?.total_users,
          user_type: selfInfo?.user_type,
        };

        const paramfilter = Object.entries(paramRequest).filter((elm) => {
          if (elm[1] !== "") {
            return elm;
          }
        });
        console.log("paramfilter", paramfilter, "paramRequest", paramRequest);
        let param = {
          transmit: "single",
          url: "offer_add",
          request: Object.fromEntries(paramfilter),
        };
        wsSend_request(websocket, param);
        Navigate(ADMIN_OFFER_MANAGEMENT_URL);
      }
    }
  };

  const removeCategory = (categoryToRemove) => {
    const updatedCategories = selectedCategories.filter(
      (category) => category.value !== categoryToRemove.value
    );
    setSelectedCategories(updatedCategories);
  };

  const [startAge, setStartAge] = useState(null);
  const [endAge, setEndAge] = useState(null);
  const handleStartAgeChange = (selectedOption) => {
    setStartAge(selectedOption);
  };

  const handleEndAgeChange = (selectedOption) => {
    setEndAge(selectedOption);
  };

  const handleDubaiStateChange = (selectedOption) => {
    setSelectedDubaiState(selectedOption);
    setSelectedVednor(null);
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  console.log("startDate", startDate, "startTime", startTime);
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    console.log("handleStartTimeChange", time);
    if (time && time.hour && time.minute) {
      // Construct the time string from hour and minute
      const timeString = `${time.hour}:${time.minute}`;
      setStartTime(timeString);
    }
  };

  const handleEndTimeChange = (time) => {
    if (time && time.hour && time.minute) {
      // Construct the time string from hour and minute
      const timeString = `${time.hour}:${time.minute}`;
      setEndTime(timeString);
    }
  };
  let inputRef = useRef();
  const libraries = ["places"];
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: libraries,
  });

  useEffect(() => {
    if (selectedCategories?.length == 0 && selectedVendor && submitClick) {
      seterrorCategory("Please select category");
    } else {
      seterrorCategory("");
    }
    if (startAge && endAge && submitClick) {
      setErrorAge("");
    }
    if (menSelected || (womenSelected && submitClick)) {
      setErrorGender("");
    }
    if (inputValue?.OfferTitle?.length && submitClick) {
      setErrorOfferTitle("");
    }
    if (inputValue?.OfferTitle?.length && submitClick) {
      CheckValid(inputValue?.OfferTitle, {
        type: "Title",
        error: setErrorOfferTitle,
      });
    }
    if (inputValue?.address && submitClick) {
      setErrorOfferAddress("");
    }
    if (startDate && startTime && submitClick) {
      setErrorOfferStart("");
    }
    if (endDate && endTime && submitClick) {
      setErrorOfferEnd("");
    }

    if (
      (StoreDesc?.length == 0 ||
        StoreDesc?.length == 49 ||
        (Location === "edit-offer" && !viewProduct?.offer_description)) &&
      selectedVendor &&
      submitClick
    ) {
      console.log("setErrorDesc", viewProduct?.offer_description);
      setErrorDesc("Please enter description");
    } else {
      setErrorDesc("");
    }
    if (fileData?.length == 0 && selectedVendor && submitClick) {
      setErrorMedia("Please upload media");
    } else {
      setErrorMedia("");
    }
    if (selectedVendor && inputValue?.address === "" && submitClick) {
      CheckValid(inputValue?.address, {
        type: "Address",
        error: setErrorOfferAddress,
      });
    }
  }, [
    selectedVendor,
    inputValue?.OfferTitle,
    selectedCategories,
    menSelected,
    womenSelected,
    startDate,
    startTime,
    endDate,
    endTime,
    inputValue?.address,
    StoreDesc?.length,
    startAge,
    endAge,
    viewProduct,
    fileData,
  ]);
  const removeMediaAPIHandler = async (nameToRemove) => {
    Dispatch(LoaderStartMain(true));
    const response = await DeleteFileAPI(
      accessToken,
      extractIdFromUrl(nameToRemove)
    );
    console.log("response", response);
    if (response?.status === 200) {
      console.log("fileData", fileData);

      setFileData([]);

      toast.success(response?.file);
    } else {
      toast.error(response?.file || response?.msg);
    }
    Dispatch(LoaderStartMain(false));
  };
  const handleRemoveImage = async (nameToRemove) => {
    Dispatch(
      setConfirmModal({
        show: "MEDIA",
        callBack: () => removeMediaAPIHandler(nameToRemove),
      })
    );
  };

  const [rangeValue, setRangeValue] = useState(10); // Initial value
  const formatLabel = (value) => `${value} km`;
  const ResetAllData = () => {
    setInputValue({
      VendorCode: "",
      Address: "",
      OfferAddress: "",
      Email: "",
      ContactNumber: "",
      BussinessContactNumber: "",
      OfferTitle: "",
      DetailAddress: "",
      VendorLocation: "",
    });
    setRangeValue(0);
    setSelectedDubaiState(null);
    setWomenSelected(false);
    setMenSelected(false);
    setSelectedCategories([]);
    setEndAge(null);
    setStartAge(null);
    setSelectedVednor(null);
  };
  const [showSubmit, setShowSubmit] = useState(false);
  const [showOfferForm, setShowOfferForm] = useState(Location === "edit-offer");
  console.log("showOfferForm", showOfferForm);

  const CreateOfferHandler = (e) => {
    console.log("CreateOfferHandler inside");
    setShowOfferForm(true);
  };
  const handleFileInputChange = (e) => {
    if (fileData.length > 0) {
      console.log("fileData handleFileInputChange", fileData);
      // If files have already been uploaded, show an error toast
      toast.error("Upload single file only");
    } else {
      // Otherwise, proceed with file upload
      UploadImage(e);
    }
  };
  return (
    <>
      <div className="breadcrumb ">
        <Link to={ADMIN_OFFER_MANAGEMENT_URL} className="text-black">
          Offer Generation
        </Link>
        <span> > </span>
        <Link>Live Map</Link>
      </div>
      {!showOfferForm ? (
        <div className="h-100 w-100">
          <div className="">
            <section className="dashboard_wrapper p-0">
              <div className="view_details">
                <Scrollbars
                  style={{ height: "calc(100vh - 153px)" }}
                  className="ScrollbarsWrapper"
                  renderView={(props) => <div {...props} className="view" />}
                >
                  <div
                    className="row d-flex flex-wrap m-0"
                    style={{ margin: "0 auto" }}
                  >
                    <div className="col-md-4 border overflow-hidden rounded p-0">
                      <div className="light-grey-bg col-md-12 d-flex justify-content-between aic px-3 pt-2">
                        <h5 className="fw-700 fs-18px">Live Map Filter</h5>
                        <button
                          className="text-danger btn fs-18px"
                          onClick={ResetAllData}
                        >
                          Reset All
                        </button>
                      </div>
                      <div className="col-md-12">
                        <div className="bg-blue br-0 w-100">
                          <p className="info">
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_2187_37685)">
                                <path
                                  d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM9.6875 5.625C9.87292 5.625 10.0542 5.67998 10.2084 5.783C10.3625 5.88601 10.4827 6.03243 10.5536 6.20373C10.6246 6.37504 10.6432 6.56354 10.607 6.7454C10.5708 6.92725 10.4815 7.0943 10.3504 7.22541C10.2193 7.35652 10.0523 7.44581 9.8704 7.48199C9.68854 7.51816 9.50004 7.49959 9.32874 7.42864C9.15743 7.35768 9.01101 7.23752 8.908 7.08335C8.80499 6.92918 8.75 6.74792 8.75 6.5625C8.75 6.31386 8.84878 6.0754 9.02459 5.89959C9.20041 5.72377 9.43886 5.625 9.6875 5.625ZM10.625 14.375C10.2935 14.375 9.97554 14.2433 9.74112 14.0089C9.5067 13.7745 9.375 13.4565 9.375 13.125V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75C9.70652 8.75 10.0245 8.8817 10.2589 9.11612C10.4933 9.35054 10.625 9.66848 10.625 10V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z"
                                  fill="#407BFF"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2187_37685">
                                  <rect width="20" height="20" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="common-blue fs-14px">
                              {" "}
                              The distance field becomes available upon
                              selecting the vendor.
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 px-2">
                        <div className="col-md-12 d-flex">
                          <div className="col-md-12 py-0 px-2">
                            <label className="fs-14px">State</label>
                            <Select
                              value={selectedDubaiState}
                              onChange={(e) => {
                                handleDubaiStateChange(e);
                                setInputValue((prev) => ({
                                  ...prev,
                                  VendorCode: "", // Set VendorCode to an empty string
                                }));
                              }}
                              options={dubaiStatesOptions}
                              isSearchable
                              placeholder="Select State"
                            />
                            <div className="col-md-12 d-flex justify-content-center align-items-center mt-1">
                              <div className="col-md-5 border-top"></div>
                              <div className="col-md-1 text-center">
                                {" "}
                                <small>OR</small>
                              </div>
                              <div className="col-md-5 border-top"></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 px-2">
                          <label
                            className="fs-14px mb-1"
                            htmlFor="selectedVendor"
                          >
                            Select Business
                          </label>
                          <Select
                            value={selectedVendor}
                            onChange={handleVednorChange}
                            options={vendorListOption}
                            isSearchable
                            placeholder="Select Business Name"
                            name="selectedVendor"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3 mt-2 px-3 p-2">
                        <label className="fs-14px">Categories</label>
                        <div className="filedaddwraps justify-content-start mb-2">
                          <Select
                            value={selectedCategories}
                            onChange={handleCategoryChange}
                            options={categoryOptions}
                            isSearchable
                            isMulti
                            placeholder="Select Categories"
                          />
                          <OverlayTrigger
                            placement="bottom"
                            trigger={["hover", "focus"]}
                            overlay={
                              <Tooltip
                                id="button-tooltip-2"
                                style={{ position: "fixed" }}
                              >
                                Add Category
                              </Tooltip>
                            }
                          >
                            <button
                              type="button"
                              className="btn addbtn"
                              onClick={(e) => AddCategoryFnct(e)}
                            >
                              <i className="bi bi-plus"></i>
                            </button>
                          </OverlayTrigger>
                        </div>

                        {selectedCategories.map((category, index) => (
                          <div key={index} className="selected-category ms-2">
                            <i
                              className="bi bi-dash-circle text-red"
                              onClick={() => removeCategory(category)}
                            ></i>
                            <span className="ms-2"> {category.label}</span>
                          </div>
                        ))}
                      </div>
                      <div className="col-md-12 px-2">
                        <div className="col-md-12 d-flex gap-5 p-2">
                          <div
                            className="d-flex align-items-center gap-2 border br-1rem px-2 py-1"
                            onClick={(e) => {
                              setMenSelected((prev) => !prev);
                            }}
                          >
                            <img
                              src={menIcon}
                              alt="menIcon"
                              className="h-1-5rem w-1-5rem"
                            />{" "}
                            <span
                              className={`fs-16px ${
                                menSelected ? "text-primary" : "text-black"
                              }`}
                            >
                              Men
                            </span>
                            <i
                              className={`bi bi-check-circle-fill fs-1-3rem ${
                                menSelected ? "text-primary" : "text-black"
                              }`}
                            ></i>
                          </div>
                          <div
                            className="d-flex align-items-center gap-2 border br-1rem px-2 py-1"
                            onClick={(e) => {
                              setWomenSelected((prev) => !prev);
                            }}
                          >
                            <img
                              src={womenIcon}
                              alt="womenIcon"
                              className="h-1-5rem w-1-5rem"
                            />{" "}
                            <span
                              className={`fs-16px ${
                                womenSelected ? "text-primary" : "text-black"
                              }`}
                            >
                              Women
                            </span>
                            <i
                              className={`bi bi-check-circle-fill fs-1-3rem ${
                                womenSelected ? "text-primary" : "text-black"
                              }`}
                            ></i>
                          </div>
                        </div>
                        {selectedVendor && (
                          <div className="col-md-12">
                            <div className="px-2">
                              <label htmlFor="rangeInput">Distance</label>
                              <div className="mb-2 mt-3 px-3 py-2">
                                <InputRange
                                  maxValue={20}
                                  minValue={0}
                                  value={rangeValue}
                                  onChange={(newValue) =>
                                    setRangeValue(newValue)
                                  }
                                  onChangeComplete={(newValue) =>
                                    console.log(newValue)
                                  }
                                  formatLabel={formatLabel}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-md-12 d-flex flex-column p-2">
                          <label className="fs-14px mb-1 mt-2">
                            Select Age Range
                          </label>
                          <div className="d-flex gap-3">
                            <div className="col-md-4">
                              <Select
                                value={startAge}
                                onChange={handleStartAgeChange}
                                options={ageOptions}
                                isSearchable
                                placeholder="Start"
                                menuPortalTarget={document.body} // Render the menu in the body to avoid overflow issues
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    maxHeight: "500px", // Adjust the max height as needed
                                  }),
                                }}
                              />
                            </div>
                            <label>To</label>
                            <div className="col-md-4">
                              <Select
                                value={endAge}
                                onChange={handleEndAgeChange}
                                options={ageOptions}
                                isSearchable
                                placeholder="End"
                                menuPortalTarget={document.body} // Render the menu in the body to avoid overflow issues
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    maxHeight: "500px", // Adjust the max height as needed
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 px-2 py-2">
                          <button
                            className="bg-primary px-3 py-3 btn w-100 text-white"
                            onClick={ApplyFilterHandler}
                          >
                            Apply Filter
                          </button>
                        </div>
                        {showSubmit &&
                          (selectedVendor?.value ||
                            selectedDubaiState?.value) && (
                            <div className="col-md-12 mb-5 px-2">
                              <div className="col-md-12 my-2">
                                <div className="d-flex align-items-center gap-3 p-2">
                                  <img
                                    src={stateIcon}
                                    alt="stateIcon"
                                    className="mb-4"
                                  />
                                  <div className="d-flex flex-column">
                                    <span className="secondary-text">
                                      State
                                    </span>
                                    <p className="mb-0">
                                      {selectedDubaiState?.value || "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 my-2">
                                <div className="d-flex align-items-center gap-3 p-2">
                                  <img
                                    src={genderIcon}
                                    alt="genderIcon"
                                    className="mb-4"
                                  />
                                  <div className="d-flex flex-column">
                                    <span className="secondary-text">
                                      Gender
                                    </span>
                                    <p className="mb-0">
                                      {menSelected && womenSelected
                                        ? "Male,Female"
                                        : menSelected
                                        ? "Male"
                                        : womenSelected
                                        ? "Female"
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 my-2">
                                <div className="d-flex align-items-center gap-3 p-2">
                                  <img
                                    src={categoryIcon}
                                    alt="categoryIcon"
                                    className="mb-4"
                                  />
                                  <div className="d-flex flex-column">
                                    <span className="secondary-text">
                                      Categories
                                    </span>
                                    <p className="mb-0 ml--12 d-flex flex-wrap">
                                      {selectedCategories?.map(
                                        (category, index) => (
                                          <span key={index} className="">
                                            {index > 0 && ","}{" "}
                                            {/* Add comma if it's not the first item */}
                                            {category?.label}
                                          </span>
                                        )
                                      )}
                                      {!selectedCategories?.length && (
                                        <>
                                          <span>-</span>
                                        </>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {selectedVendor && (
                                <div className="col-md-12 my-2">
                                  <div className="d-flex align-items-center gap-3 p-2">
                                    <i className="bi bi-geo-alt-fill text-primary mb-3"></i>{" "}
                                    <div className="d-flex flex-column">
                                      <span className="secondary-text">
                                        Distance
                                      </span>
                                      <p className="mb-0">{rangeValue} Km</p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="col-md-12 my-2">
                                <div className="d-flex align-items-center gap-3 p-2">
                                  <img
                                    src={usersIcon}
                                    alt="usersIcon"
                                    className="mb-4"
                                  />
                                  <div className="d-flex flex-column">
                                    <span className="secondary-text">
                                      Active Users
                                    </span>
                                    <p className="mb-0">
                                      {liveMapUserList?.list?.counts
                                        ?.active_users || "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 my-2">
                                <div className="d-flex align-items-center gap-3 p-2">
                                  <img
                                    src={usersIcon}
                                    alt="usersIcon"
                                    className="mb-4"
                                  />
                                  <div className="d-flex flex-column">
                                    <span className="secondary-text">
                                      Total Users
                                    </span>
                                    <p className="mb-0">
                                      {liveMapUserList?.list?.counts
                                        ?.total_users || "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {liveMapUserList?.list?.list?.length > 0 && (
                                <div className="col-md-12 px-1 py-2">
                                  <button
                                    className="bg-yellow px-3 py-3 btn w-100"
                                    onClick={CreateOfferHandler}
                                  >
                                    Create Offer
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-md-8 position-relative">
                      {showSubmit && (
                        <h6 className="position-absolute start-3 top-1 w-25 bg-none total-user-count-heading">
                          <span className="position-fixed z-1000">
                            {" "}
                            total users{" "}
                            {liveMapUserList?.list?.counts?.total_users}
                          </span>
                        </h6>
                      )}
                      {showSubmit && liveMapUserList?.list?.list?.length ? (
                        <>
                          <FilteredMap
                            data={
                              liveMapUserList?.list?.list
                                ? liveMapUserList?.list
                                : []
                            }
                          />
                        </>
                      ) : (
                        <>
                          <InitialMap />
                        </>
                      )}
                    </div>
                  </div>
                </Scrollbars>
              </div>
              {/* loader start here */}
              {loaderMain && <CommonLoader />}
            </section>
          </div>
          {showModal?.open && (
            <CommonModel
              fromUrl={"product"}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}
        </div>
      ) : (
        <>
          <div className="p-0 h-100 w-100">
            <div className="">
              <section className="dashboard_wrapper p-0">
                <div className="view_details">
                  <Scrollbars
                    style={{ height: "calc(100vh - 153px)" }}
                    className="ScrollbarsWrapper"
                    renderView={(props) => <div {...props} className="view" />}
                  >
                    <div className="row m-0">
                      <div className="col-md-12 m-0 p-0 row">
                        <div className="col-md-5">
                          <h6 className="mb-2 col-md-12">
                            Select Vendor Details
                          </h6>

                          <div className="bg-f8 col-md-12 ms-1 row rounded border mb-3">
                            <div className="col-md-12 d-flex  gap-3">
                              <div className="selected-vendor col-md-6  py-2 ">
                                <label
                                  className="fs-14px mb-1"
                                  htmlFor="selectedVendor"
                                >
                                  Select Vendor{" "}
                                  <span className="common-blue">*</span>
                                </label>
                                <Select
                                  value={selectedVendor}
                                  onChange={handleVednorChange}
                                  options={vendorListOption}
                                  isSearchable
                                  placeholder="Select Vendor"
                                  name="selectedVendor"
                                  isDisabled={
                                    Location === "edit-offer" ||
                                    selectedVendor?.code
                                  }
                                />
                              </div>
                              <div className="col-md-5 px-3 py-1 mb-1">
                                <InputField
                                  type={"text"}
                                  label={"Vendor Code"}
                                  placeholder={"Enter Vendor Code"}
                                  value={selectedVendor?.code}
                                  className={
                                    errorVendorName !== ""
                                      ? "form-control error-form"
                                      : "form-control"
                                  }
                                  name="VendorName"
                                  errormessage={errorVendorName}
                                  readOnly={true}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <InputField
                                type={"text"}
                                value={selectedVendor?.address}
                                label={"Vendor Address"}
                                placeholder={"Enter Vendor Address"}
                                className={
                                  errorAddress !== ""
                                    ? "form-control error-form"
                                    : "form-control"
                                }
                                name="Address"
                                onChange={handleChange}
                                onKeyUp={(e) =>
                                  CheckValid(e.target.value, {
                                    type: "Address",
                                    error: setErrorAddress,
                                  })
                                }
                                onKeyDown={EmptySpaceFieldValid}
                                errormessage={errorAddress}
                                readOnly={true}
                              />
                            </div>
                            {errorSelectedVendor && !selectedVendor && (
                              <div className="errormendatorr">
                                <i className="bi bi-x-circle-fill"></i>
                                {errorSelectedVendor}
                              </div>
                            )}
                          </div>

                          <h6>Description</h6>
                          <div className="col-md-12 border py-3">
                            <div className="col-md-12">
                              <div className="col-md-12 p-2">
                                <InputField
                                  type={"text"}
                                  label={"Offer Title"}
                                  placeholder={"Enter Offer Title"}
                                  value={OfferTitle}
                                  className={
                                    errorOfferTitle !== ""
                                      ? "form-control error-form"
                                      : "form-control"
                                  }
                                  name="OfferTitle"
                                  onChange={handleChange}
                                  onKeyUp={(e) =>
                                    CheckValid(e.target.value, {
                                      type: "Title",
                                      error: setErrorOfferTitle,
                                    })
                                  }
                                  onKeyDown={EmptySpaceFieldValid}
                                  errormessage={errorOfferTitle}
                                  required={true}
                                  maxLength={255}
                                  minLength={2}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 p-2">
                              <label className="col-md-12 fs-14px mb-2">
                                Offer Description{" "}
                                <span className="common-blue">*</span>
                              </label>
                              <div className="col-md-12">
                                <Editor
                                  editorState={editorState}
                                  onEditorStateChange={onEditorChange}
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                  wrapperStyle={{
                                    width: "100%",
                                    border: "1px solid #ccc",
                                    backgroundColor: "white",
                                    padding: "0px",
                                    borderRadius: "4px",
                                  }}
                                  editorStyle={{
                                    minHeight: "150px",
                                    border: "1px solid #ccc",
                                    padding: "10px",
                                    borderRadius: "4px",
                                    fontFamily: "sans-serif",
                                  }}
                                  toolbar={{
                                    options: [
                                      "inline",
                                      "blockType",
                                      "fontSize",
                                      "list",
                                      "textAlign",
                                      "colorPicker",
                                      "link",
                                      "emoji",
                                      "history",
                                    ],
                                    inline: {
                                      inDropdown: false,
                                      options: [
                                        "bold",
                                        "italic",
                                        "underline",
                                        "strikethrough",
                                        "superscript",
                                        "subscript",
                                      ],
                                    },
                                    list: {
                                      inDropdown: false,
                                      options: ["unordered", "ordered"],
                                    },
                                    textAlign: {
                                      inDropdown: true,
                                    },
                                    link: { inDropdown: false },
                                    history: { inDropdown: false },
                                  }}
                                />
                              </div>
                              {errorDesc !== "" && (
                                <div className="errormendatorr">
                                  <i className="bi bi-x-circle-fill"></i>
                                  {errorDesc}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-md-12 mt-3">
                            <h6 className="col-md-12 mb-2">Offer Period</h6>
                            <div className="col-md-12 row border p-2">
                              <div className="d-flex gap-3 flex-column col-md-12 p-3">
                                <label className="fs-14px">
                                  Starting On{" "}
                                  <span className="common-blue">*</span>
                                </label>
                                <div className="row flex-wrap gap-3 col-md-12 align-items-center">
                                  <div className="col-md-6">
                                    <DatePicker
                                      selected={startDate}
                                      onChange={handleStartDateChange}
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText="Select a date"
                                      minDate={new Date()}
                                    />
                                  </div>
                                  <div className="col-md-5" id="starting_on">
                                    <TimePicker
                                      name="startTime"
                                      onFocusChange={console.log}
                                      minuteStep={1}
                                      onTimeChange={(newTime) =>
                                        handleStartTimeChange(newTime)
                                      }
                                      placeholder={startTime}
                                      time={startTime}
                                      timeFormat="HH:MM"
                                      theme="material"
                                      timeMode="24"
                                    />
                                  </div>
                                  {errorOfferStart && (
                                    <div className="errormendatorr">
                                      <i className="bi bi-x-circle-fill"></i>
                                      {errorOfferStart}
                                    </div>
                                  )}
                                </div>
                                <label className="fs-14px">
                                  Expires On{" "}
                                  <span className="common-blue">*</span>
                                </label>
                                <div className="row gap-3 col-md-12 align-items-center">
                                  <div className="col-md-6">
                                    <DatePicker
                                      selected={endDate}
                                      onChange={handleEndDateChange}
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText="Select a date"
                                      minDate={
                                        startDate
                                          ? new Date(startDate)
                                          : new Date()
                                      }
                                    />
                                  </div>
                                  <div className="col-md-5" id="starting_on">
                                    <TimePicker
                                      name="endTime"
                                      minuteStep={1}
                                      onTimeChange={(newTime) =>
                                        handleEndTimeChange(newTime)
                                      }
                                      placeholder={endTime}
                                      time={endTime}
                                      timeFormat="HH:MM"
                                      theme="material"
                                      timeMode="24"
                                    />
                                  </div>
                                </div>
                                {errorOfferEnd && (
                                  <div className="errormendatorr">
                                    <i className="bi bi-x-circle-fill"></i>
                                    {errorOfferEnd}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-6">
                          <div className="col-md-12">
                            <h6 className="col-md-12 mb-2">
                              Upload Offer Image{" "}
                              <span className="common-blue">*</span>
                            </h6>
                            <div className="col-md-12 mt-1">
                              <form
                                className="file-upload-form"
                                id="add-edit-event"
                              >
                                <label
                                  htmlFor="file"
                                  className="file-upload-label"
                                >
                                  <div className="file-upload-design">
                                    <img src={uploadIcon} alt="" />
                                    <p>
                                      <a className="browse-button px-1 py-0 text-primary">
                                        Click to upload
                                      </a>
                                      or drag and drop
                                    </p>
                                    <p className="secondary-text">
                                      Max. File Size: 30MB
                                    </p>
                                  </div>
                                  <input
                                    id="file"
                                    type="file"
                                    // onChange={UploadImage}
                                    onChange={handleFileInputChange}
                                    accept=".jpg, .jpeg, .png"
                                    disabled={fileData?.length > 0}
                                  />
                                </label>
                              </form>
                              {errorMedia && (
                                <div className="errormendatorr">
                                  <i className="bi bi-x-circle-fill"></i>
                                  {errorMedia}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            {fileData?.length > 0 &&
                              fileData?.map((item, index) => (
                                <div
                                  key={index}
                                  className="col-md-12 my-2 position-relative"
                                  id="add-edit-event-media"
                                >
                                  <div
                                    className="d-flex  justify-content-end"
                                    style={{ width: "265px", height: "auto" }}
                                  >
                                    <img
                                      src={closeIcon}
                                      alt="Close"
                                      className="bi bi-x rounded-circle mt-3 mr-2 w-1-5rem h-1-5rem"
                                      style={{
                                        cursor: "pointer",
                                        zIndex: 12,
                                        marginBottom: "-10px",
                                      }}
                                      onClick={() =>
                                        handleRemoveImage(
                                          item?.name || item?.file_url
                                        )
                                      } // Assuming you have a function to remove the image
                                    />
                                    {console.log("item", item, fileData)}
                                  </div>

                                  <MediaViewer
                                    media={
                                      item?.url?.file_url || item?.thumbnail_url
                                    }
                                    minHeight={"150px"}
                                    maxHeight={"150px"}
                                    minWidth={"252px"}
                                    maxWidth={"252px"}
                                    // thumbnail={
                                    //   isVideoUrl(
                                    //     item?.url?.file_url
                                    //   )
                                    //     ? item?.thumbnail?.parent_url?.thumbnail_url
                                    //     : ""
                                    // }
                                  />
                                </div>
                              ))}
                          </div>
                          <div className="col-md-12 mt-4 d-flex gap-4">
                            {Location !== "edit-offer" && (
                              <button
                                className="btn w-50 py-2 px-3 bg-pink text-white"
                                onClick={(e) => {
                                  setOfferMode(e);
                                }}
                              >
                                Share with vendor
                              </button>
                            )}

                            <button
                              className="btn w-50 py-2 px-3 bg-primary text-white"
                              onClick={CreateOffer}
                            >
                              {Location == "edit-offer" ? "Save" : "Submit"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Scrollbars>
                </div>
                {/* loader start here */}
                {loaderMain && <CommonLoader />}
              </section>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LiveMapFilter;
