import "firebase/compat/messaging";
import firebase from "firebase/compat/app";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const messaging = firebase.messaging();

// Function to get FCM token
export const getToken = async () => {
    try {
        const currentToken = await messaging.getToken({
            vapidKey: process.env.REACT_APP_VAPID_KEY,
        });
        return currentToken || "";
    } catch (error) {
        console.error("Error getting FCM token:", error);
        return "";
    }
};

// Function to request notification permission and get FCM token
export const callGetFCMToken = async () => {
    if ("Notification" in window) {
        try {
            const permission = await Notification.requestPermission();
            if (permission === "granted" && "PushManager" in window) {
                const token = await getToken();
                return token;
            }
            return undefined;
        } catch (error) {
            console.error("Error requesting notification permission:", error);
            return null;
        }
    }
};

// Function to listen for messages
export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            console.log("'Notification' in window", "Notification" in window, "\n payload", payload);
            const bodyData = payload?.notification;
            const title = bodyData?.title;
            const description = bodyData?.body;
            const notiIcon = `https://geoa.appristine.in/icon.png?v=${Math.random()}`;
            toast.info(
                <div className="ms-4">
                    <strong className="mb-1">{title}</strong>
                    <p>{description}</p>
                </div>,
                {
                    onClick: () => {
                        window.location.href = "/notification";
                    },
                    icon: (
                        <img
                            src={notiIcon}
                            alt="Notification Icon"
                        />
                    ),
                    autoClose: 5000, // Adjust as needed
                    closeButton: true, // Show the close button
                    className: "custom-toast", // Add a custom class for styling
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                }
            );
        });
    });