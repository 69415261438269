/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from "react-custom-scrollbars-2";
import CommonToggle from "../Common/CommonToggle";
import moment from "moment";
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";
import CommonModel from "../Common/CommonModel";
import { RatingStars } from "../Shared/constant";

const VendorReviewManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const { selfInfo, vendorViewReviewList } = useSelector(
    (state) => state.adminReducers
  );
  console.log("vendorViewReviewList", vendorViewReviewList);
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState("all");
  const [userLimit, setUserLimit] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });

  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  // clear search box
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  // select user type
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
    setCurrentPage(1);
  };

  // get supplier list
  useEffect(() => {
    let param = {
      transmit: "single",
      url: "review_listing",
      request: {
        limit: userLimit || 10,
        user_type: selfInfo?.user_type,
        page: currentPage,
        search: userSearch.trim(),
        filter: userType,
        vendor_id: selfInfo?.id,
      },
    };
    wsSend_request(websocket, param);
  }, [userSearch, userType, userLimit, currentPage,websocket]);

  // add new category
  const AddCategoryFnct = () => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Add Category",
      subtitle: "Add Category",
      description: ``,
      modalType: "form",
      button: "Yes",
    });
  };

  // delete category
  const DeleteCategoryFnct = (data_) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Request to Delete",
      subtitle: "Delete Review",
      modalType: "form",
      button: "Submit",
      Data: data_,
    });
  };

  return (
    <section className="dashboard_wrapper p-0">
      <DataTableFilter
        // filterType={"by business or vendor code"}
        // userSearch={userSearch}
        // setUserSearch={setUserSearch}
        // userType={userType}
        // setUserType={setUserType}
        userLimit={userLimit}
        setUserLimit={setUserLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        ClearSearchFiled={ClearSearchFiled}
        SelectUserTypeFnct={SelectUserTypeFnct}
        // buttonvisible={(selfInfo?.user_type === "admin" || (selfInfo?.permissions?.includes("add-category")))}
        // ButtonSet={{
        //     buttontitle: "Add Category",
        //     function: AddCategoryFnct
        // }}
      />
      <div
        className="tableContent"
        style={{
          height:
            window.screen.width > 540
              ? "calc(100vh - 155px)"
              : "calc(100vh - 143px)",
        }}
      >
        <Scrollbars
          style={{
            height:
              vendorViewReviewList?.pagination?.total_records > 10
                ? window.screen.width < 767
                  ? "calc(100% - 10px)"
                  : "calc(100% - 38px)"
                : "calc(100% - 0px)",
          }}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>User Code</th>
                  <th>Created At</th>

                  <th>Comment</th>
                  <th>Rating</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {vendorViewReviewList &&
                  vendorViewReviewList?.list?.reviews?.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td>{user?.usercode}</td>
                        <td>{moment(user?.created_at).format("LL")}</td>
                        <td>{user?.review}</td>
                        <td className="d-flex" style={{ flexWrap: "nowrap" }}>
                          <span>
                            {user?.rating} <RatingStars rating={user?.rating} />
                          </span>
                        </td>

                        <td className="respoactionSupplier">
                          <button
                            type="button"
                            className="btn actiontblebtn delete-btn"
                            onClick={() => DeleteCategoryFnct(user)}
                          >
                            <i className="bi bi-trash3"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                {vendorViewReviewList &&
                  vendorViewReviewList?.list.length === 0 && (
                    <tr>
                      <td style={{ textAlign: "center" }} colSpan={8}>
                        Reviews Not Found !
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {/* pagination */}
        {vendorViewReviewList?.pagination?.total_records > 10 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={vendorViewReviewList?.pagination}
            currentFunction={currentFunction}
          />
        )}
      </div>
      {showModal?.open && (
        <CommonModel
          setUserSearch={setUserSearch}
          setCurrentPage={setCurrentPage}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </section>
  );
};

export default VendorReviewManagement;
