import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AvatarGet from "../Common/AvatarGet";
import moment from "moment";
import CommonPagination from "../Common/CommonPagination";
import Scrollbars from "react-custom-scrollbars-2";
import {
  MountainChart,
  RevenueLineChart,
  TotalCoinRedemptionChart,
  UserDonutChart,
  VendorDonutChart,
} from "../Common/PieChart";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";

const DashboardTables = () => {
  const { websocket } = useContext(WebSocketContext);

  const {
    selfInfo,
    DashboardMostSuccessOfferList,
    DashboardMostSuccessEventList,
    DashboardReferralList,
    DashboardMostLikedList,
    DashboardMostWhishList,
    vendorViewTCList,
    dashboardPaymentRevenue,
    dashboardTransactionRevenue,
    dashboardUserRevenue,
    dashboardCount,
  } = useSelector((state) => state.adminReducers);

  console.log("dashboardPaymentRevenue", dashboardPaymentRevenue);
  const [selectedPaymentInterval, setselectedPaymentInterval] = useState(""); // Initial selection is 'day'
  const [selectedUserOption, setselectedUserOption] = useState("");
  const [selectedUserTimeInterval, setselectedUserTimeInterval] = useState("");
  const [selectedTransactionTimeInterval, setselectedTransactionTimeInterval] =
    useState("");
  console.log("selectedPaymentInterval", selectedPaymentInterval);
  const handlePaymentInterval = (event) => {
    setselectedPaymentInterval(event.target.value);
    // You can perform additional actions based on the selected option if needed
  };
  const [userLimitReferral, setuserLimitReferral] = useState("");
  const [currentPageReferral, setCurrentPageReferral] = useState(1);
  const [userLimitCoinEarning, setuserLimitCoinEarning] = useState("");
  const [currentPageCoinEarning, setCurrentPageCoinEarning] = useState(1);
  console.log("setuserLimitReferral",setuserLimitReferral,"setuserLimitCoinEarning",setuserLimitCoinEarning)

  const currentFunctionCoinEarning = (page) => {
    setCurrentPageCoinEarning(page);
  };
  const currentFunctionReferral = (page) => {
    setCurrentPageReferral(page);
  };
 
  // get product list
  useEffect(() => {
    let paramRequest = {
      limit: userLimitReferral || 10,
      page: currentPageReferral || 1,
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => elm[1] !== "");
    let param = {
      transmit: "single",
      url: "dashboard_referral_listing",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, [userLimitReferral, currentPageReferral, websocket]);

  useEffect(() => {
    let paramRequest = {
      limit: userLimitCoinEarning || 10,
      page: currentPageCoinEarning || 1,
      user_type: selfInfo?.user_type || "admin",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => elm[1] !== "");

    let param = {
      transmit: "single",
      url: "vendor_coin_transaction",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, [userLimitCoinEarning, currentPageCoinEarning, websocket,selfInfo?.user_type]);

  useEffect(() => {
    let paramRequest = {
      limit: 10,
      page: 1,
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => elm[1] !== "");
    let param = {
      transmit: "single",
      url: "dashboard_most_liked",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, [websocket]);
  useEffect(() => {
    let paramRequest = {
      limit: 10,
      page: 1,
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => elm[1] !== "");
    let param = {
      transmit: "single",
      url: "dashboard_most_wishlist",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, [websocket]);
  useEffect(() => {
    let paramRequest = {
      type: "offer",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => elm[1] !== "");

    let param = {
      transmit: "single",
      url: "dashboard_most_successful",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, [websocket]);
  useEffect(() => {
    let paramRequest = {
      type: "event",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => elm[1] !== "");

    let param = {
      transmit: "single",
      url: "dashboard_most_successful",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, [websocket]);
  useEffect(() => {
    let paramRequest = {
      input_time: selectedUserTimeInterval,
      particular: selectedUserOption || "user",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => elm[1] !== "");

    let param = {
      transmit: "single",
      url: "dashboard_user_revenue",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, [selectedUserTimeInterval, selectedUserOption, websocket]);
  useEffect(() => {
    let paramRequest = {
      input_time: selectedPaymentInterval || "week",
      particular: "event",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => elm[1] !== "");

    let param = {
      transmit: "single",
      url: "dashboard_payment_revenue",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);

    let paramRequestOffer = {
      input_time: selectedPaymentInterval || "week",
      particular: "offer",
    };
    const paramfilterOffer = Object.entries(paramRequestOffer).filter((elm) => elm[1] !== "");

    let paramOffer = {
      transmit: "single",
      url: "dashboard_payment_revenue",
      request: Object.fromEntries(paramfilterOffer),
    };
    wsSend_request(websocket, paramOffer);
  }, [selectedPaymentInterval, websocket]);

  useEffect(() => {
    let paramRequest = {
      input_time: selectedTransactionTimeInterval || "week",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => elm[1] !== "");

    let param = {
      transmit: "single",
      url: "dashboard_transaction_revenue",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, [selectedTransactionTimeInterval, websocket]);
  return (
    <div className="dashboard-tables">
      <div className="ps-0 p-3">
        <div className="row">
          <div className="col-md-12">
            <div className="row h-100">
              <div className="col-md-6 d-flex">
                <RevenueLineChart
                  data={dashboardPaymentRevenue}
                  count={selectedPaymentInterval || "week"}
                />
                <div
                  className="position-relative bg-white border-none gap-0 p-0 m-0"
                  style={{
                    zIndex: "1000",
                    width: "5rem",
                    visibility: "hidden",
                  }}
                >
                  <select
                    id="timeRangeSelect"
                    className="form-control position-absolute me-5"
                    value={selectedPaymentInterval}
                    onChange={handlePaymentInterval}
                    style={{
                      right: "-850%",
                      top: "1%",
                      width: "80px",
                      visibility: "visible",
                    }}
                    title="Select Interval"
                  >
                    <option value="" selected disabled>
                      Select Interval
                    </option>
                    <option value="week">1 Week</option>
                    <option value="half_year">6 Months</option>
                    <option value="year">1 Year</option>
                  </select>
                  <div
                    className="info-meta mt-5 position-absolute end-0 px-2 secondary-bg me-3 rounded"
                    hidden
                    style={{ width: "10rem", top: "10%" }}
                  >
                    <div className="p-1 bb-dashed">
                      <div className="d-flex align-items-center gap-2">
                        <span
                          className="h-1rem w-1rem br-50 d-inline-block mr-2"
                          style={{
                            backgroundColor: "#285EF1",
                          }}
                        ></span>
                        <span className="fs-14px">Total Offers Earned</span>
                      </div>
                      <small className="fw-600 ms-4">130 AED</small>
                    </div>
                    <div className="p-1 bb-dashed">
                      <div className="d-flex align-items-center gap-2">
                        <span
                          className="h-1rem w-1rem br-50 d-inline-block mr-2"
                          style={{
                            backgroundColor: "#FF861A",
                          }}
                        ></span>
                        <span className="fs-14px">Total Event Earned</span>
                      </div>
                      <small className="fw-600 ms-4">130 AED</small>
                    </div>
                    <div className="p-1">
                      <div className="d-flex align-items-center gap-2">
                        <span
                          className="h-1rem w-1rem br-50 d-inline-block mr-2"
                          style={{
                            backgroundColor: "#1ABCFB",
                          }}
                        ></span>
                        <span className="fs-14px">Total Coin Redemption</span>
                      </div>
                      <small className="fw-600 ms-4">130 AED</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row h-100 flex-wrap">
                  {/* <div className="col-md-6">
                    <TotalAmountEarnedAreaChart />
                  </div> */}
                  <div className="col-md-12">
                    <div
                      className="position-relative bg-white border-none gap-0 p-0 m-0 visibility-hidden"
                      style={{
                        zIndex: "1000",
                        width: "5rem",
                        marginRight: "5rem",
                      }}
                    >
                      <select
                        id="timeRangeSelect"
                        className="form-control position-absolute visibility-visible"
                        value={selectedTransactionTimeInterval}
                        onChange={(e) => {
                          setselectedTransactionTimeInterval(e.target.value);
                        }}
                        style={{
                          right: window.screen.width > 1400 ? "-650%" : "-500%",
                          top: "10px",
                          width: "80px",
                        }}
                        title="Select Interval"
                      >
                        <option value="" selected disabled>
                          Select Interval
                        </option>
                        <option value="week">1 Week</option>
                        <option value="half_year">6 Months</option>
                        <option value="year">1 Year</option>
                      </select>
                    </div>
                    <TotalCoinRedemptionChart
                      data={dashboardTransactionRevenue?.data}
                      count={selectedTransactionTimeInterval || "week"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 d-flex mb-2 fw-600" id="manage-svg">
            <div className="col-md-custom mt-4" id="user-pie-chart">
              <UserDonutChart
                active={dashboardCount?.data?.total_active_users}
                inactive={dashboardCount?.data?.total_inactive_users}
                total={dashboardCount?.data?.total_users}
              />
            </div>
            <div className="col-md-11percent"></div>
            <div className="col-md-custom mt-4" id="vendor-pie-chart">
              <VendorDonutChart
                active={dashboardCount?.data?.total_active_vendors}
                inactive={dashboardCount?.data?.total_inactive_vendors}
                total={dashboardCount?.data?.total_vendors}
              />
            </div>
          </div>
          <div className="col-md-6 mt-4 mb-2 fw-600" id="new-reg">
            <div className="bg-white rounded" style={{ width: "100%" }}>
              <div
                className="position-relative bg-white border-none gap-0 p-0 m-0 visibility-hidden"
                style={{ zIndex: "1000", width: "5rem", marginRight: "1rem" }}
              >
                <select
                  id="userSelect"
                  className="form-control position-absolute visibility-visible"
                  value={selectedUserOption}
                  onChange={(e) => {
                    setselectedUserOption(e.target.value);
                  }}
                  style={{
                    top: "5px",
                    right: window.screen.width > 1400 ? "-530%" : "-390%",
                  }}
                  title="User Type"
                >
                  <option value="" disabled selected>
                    User Type
                  </option>
                  <option value="user">User</option>
                  <option value="vendor">Vendor</option>
                </select>
              </div>
              <div
                className="position-relative bg-white border-none gap-0 p-0 m-0 visibility-hidden"
                style={{ zIndex: "1000", width: "5rem", marginRight: "5rem" }}
              >
                <select
                  id="timeRangeSelect"
                  className="form-control position-absolute visibility-visible"
                  value={selectedUserTimeInterval}
                  onChange={(e) => {
                    setselectedUserTimeInterval(e.target.value);
                  }}
                  style={{
                    top: "5px",

                    right: window.screen.width > 1400 ? "-650%" : "-500%",
                  }}
                  title="Select Interval"
                >
                  <option value="" selected disabled>
                    Select Interval
                  </option>
                  <option value="week">1 Week</option>
                  <option value="half_year">6 Months</option>
                  <option value="year">1 Year</option>
                </select>
              </div>
              <MountainChart
                data={dashboardUserRevenue?.data}
                count={selectedUserTimeInterval || "week"}
              />
            </div>
          </div>

          {/* First Table */}
          <div className="col-md-6 my-3">
            <div className="d-flex justify-content-between dashboard-page-bg">
              <h6 className="mb-2 fw-600">Most Successful Offers</h6>
              <small className="secondary-text me-2">Top 5 Views Only</small>
            </div>
            <div className="table-responsive bg-white">
              <table className="table">
                <thead>
                  <tr>
                    <th>Offer Image </th>
                    <th>Offer Name</th>
                    <th>Total Visit </th>
                    <th>Vendor Code</th>
                    <th>Offer Code</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(DashboardMostSuccessOfferList?.list) && DashboardMostSuccessOfferList &&
                    DashboardMostSuccessOfferList?.list
                      ?.slice(0, 5)
                      ?.map((user, index) => (
                        <tr key={index}>
                          <td id="list-avatar">
                            <AvatarGet
                              type="product"
                              url={user?.logo?.file_url}
                            />
                          </td>
                          <td>{user?.offer_title || "-"}</td>
                          <td>{user?.count || "-"}</td>
                          <td>{user?.vendor_code || "-"}</td>
                          <td>{user?.offer_code || "-"}</td>
                        </tr>
                      ))}
                  {DashboardMostSuccessOfferList &&
                    DashboardMostSuccessOfferList?.list?.length === 0 && (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={10}>
                          Offers Not Found !
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>

          {/* Second Table */}
          <div className="col-md-6 my-3">
            <div className="d-flex justify-content-between dashboard-page-bg">
              <h6 className="mb-2 fw-600">Most Successful Events</h6>
              <small className="secondary-text me-2">Top 5 Views Only</small>
            </div>
            <div className="table-responsive bg-white">
              <table className="table">
                <thead>
                  <tr>
                    <th>Event Image </th>
                    <th>Event Name</th>
                    <th>Total Visit </th>
                    <th>Vendor Code</th>
                    <th>Event Code</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(DashboardMostSuccessEventList?.list) && DashboardMostSuccessEventList &&
                    DashboardMostSuccessEventList?.list
                      ?.slice(0, 5)
                      ?.map((user, index) => (
                        <tr key={index}>
                          <td id="list-avatar">
                            <AvatarGet
                              type="product"
                              url={user?.logo[0]?.url?.file_url}
                            />
                          </td>
                          <td>{user?.event_title || "-"}</td>
                          <td>{user?.count || "-"}</td>
                          <td>{user?.vendor_code || "-"}</td>
                          <td>{user?.event_code || "-"}</td>
                        </tr>
                      ))}
                  {DashboardMostSuccessEventList &&
                    !DashboardMostSuccessEventList?.list?.length && (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={10}>
                          Events Not Found !
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
          {/* Third Table */}
          <div className="col-md-6 my-3">
            <div className="d-flex justify-content-between dashboard-page-bg">
              <h6 className="mb-2 fw-600">Most Liked </h6>
              <small className="secondary-text me-2">Top 5 Views Only</small>
            </div>
            <div className="table-responsive bg-white">
              <table className="table">
                <thead>
                  <tr>
                    <th>File </th>
                    <th>Type</th>
                    <th>Upload By</th>
                    <th>Vendor Code</th>
                    <th>Like</th>
                  </tr>
                </thead>
                <tbody>
                  {console.log("DashboardMostLikedList",DashboardMostLikedList)}
                  {Array.isArray(DashboardMostLikedList?.list) &&  DashboardMostLikedList &&
                    DashboardMostLikedList?.list
                      ?.slice(0, 5)
                      ?.map((user, index) => (
                        <tr key={index}>
                          <td id="list-avatar">
                            <AvatarGet
                              type="product"
                              url={user?.url?.gif_url || user?.url?.file_url}
                            />
                          </td>
                          <td>{user?.type || "-"}</td>
                          <td>{user?.upload_by || "Self"}</td>
                          <td>{user?.vendor_code || "-"}</td>
                          <td>{user?.count || "-"}</td>
                        </tr>
                      ))}
                  {DashboardMostLikedList &&
                    !DashboardMostLikedList?.list?.length && (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={10}>
                          Likes Not Found !
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
          {/* Fourth Table */}
          <div className="col-md-6 my-3">
            <div className="d-flex justify-content-between dashboard-page-bg">
              <h6 className="mb-2 fw-600">Most Wishlist </h6>
              <small className="secondary-text me-2">Top 5 Views Only</small>
            </div>
            <div className="table-responsive bg-white">
              <table className="table">
                <thead>
                  <tr>
                    <th>File </th>
                    <th>Type</th>
                    <th>Vendor Code</th>
                    <th>Wishlist</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(DashboardMostWhishList?.list) && DashboardMostWhishList &&
                    DashboardMostWhishList?.list
                      ?.slice(0, 5)
                      ?.map((user, index) => (
                        <tr key={index}>
                          <td id="list-avatar">
                            <AvatarGet
                              type="product"
                              url={user?.url?.gif_url || user?.url?.file_url}
                            />
                          </td>
                          <td>{user?.type || "-"}</td>
                          <td>{user?.code || "-"}</td>
                          <td>{user?.count || "-"}</td>
                        </tr>
                      ))}
                  {DashboardMostWhishList &&
                    !DashboardMostWhishList?.list?.length && (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={10}>
                          WhishList Not Found !
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>

          {/* Fifth Table */}
          <div className="col-md-12 my-3">
            <div className="d-flex justify-content-between dashboard-page-bg">
              <h6 className="mb-0 fw-600">
                User Joined Through Referrals List{" "}
              </h6>
              <small className="secondary-text me-2"></small>
            </div>
            <div
              className="tableContent mt-2"
              style={{
                height:
                  window.screen.width > 540 ? "470px" : "calc(100vh - 143px)",
              }}
            >
              <Scrollbars
                style={{
                  height:
                    DashboardReferralList?.pagination?.total_records > 10
                      ? window.screen.width < 767
                        ? "calc(100% - 10px)"
                        : "calc(100%)"
                      : "calc(100% - 0px)",
                }}
                className="ScrollbarsWrapper"
                renderView={(props) => <div {...props} className="view" />}
              >
                <div className="table-responsive bg-white">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Referral User Name</th>
                        <th>Referral User Code</th>
                        <th>Referral Code</th>
                        <th>Gender</th>
                        <th>Country</th>
                        <th>State</th>
                        <th>Coins</th>
                        <th>Badge</th>
                      </tr>
                    </thead>
                    <tbody>
                      {DashboardReferralList &&
                        DashboardReferralList?.list?.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td id="list-avatar">
                                <AvatarGet
                                  type="product"
                                  url={user?.avatar?.file_url}
                                />{" "}
                                <span>{user?.username}</span>
                              </td>

                              <td>{user?.usercode || "-"}</td>
                              <td>{user?.referral_code || "-"}</td>
                              <td>{user?.gender || "-"}</td>
                              <td>{user?.country || "-"}</td>
                              <td>{user?.state || "-"}</td>
                              <td>{user?.coins || "-"}</td>
                              <td>{user?.badge?.level || "-"}</td>
                            </tr>
                          );
                        })}
                      {DashboardReferralList &&
                        DashboardReferralList?.list.length === 0 && (
                          <tr>
                            <td style={{ textAlign: "center" }} colSpan={8}>
                              Referrals Not Found !
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </Scrollbars>
              {/* pagination */}
              {DashboardReferralList?.pagination?.total_records > 10 && (
                <CommonPagination
                  currentPage={currentPageReferral}
                  paginationLength={DashboardReferralList?.pagination}
                  currentFunction={currentFunctionReferral}
                />
              )}
            </div>
          </div>
          {/* Sixth Table */}
          <div className="col-md-12 mt-5 mb-3">
            <div className="d-flex justify-content-between dashboard-page-bg">
              <h6 className="mb-2 fw-600">User Coin Earnings list</h6>
              <small className="secondary-text me-2"></small>
            </div>
            <div
              className="tableContent"
              style={{
                height:
                  window.screen.width > 540 ? "470px" : "calc(100vh - 143px)",
              }}
            >
              <Scrollbars
                style={{
                  height:
                    vendorViewTCList?.pagination?.total_records > 10
                      ? window.screen.width < 767
                        ? "calc(100% - 10px)"
                        : "calc(100% - 7%)"
                      : "calc(100% - 0px)",
                }}
                className="ScrollbarsWrapper"
                renderView={(props) => <div {...props} className="view" />}
              >
                <div className="table-responsive bg-white">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>User Name</th>
                        <th>Reason</th>
                        <th>Earned Coins</th>
                        <th>Available Coins</th>
                        <th>Code</th>
                        <th>Badge</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vendorViewTCList &&
                        vendorViewTCList?.list?.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td id="list-avatar">
                                <AvatarGet
                                  type="product"
                                  url={user?.user?.avatar?.file_url}
                                />{" "}
                                <span>{user?.user?.name}</span>
                              </td>

                              <td className="text-capitalize">{user?.reason || "-"}</td>
                              <td>{user?.user?.coins || "-"}</td>
                              <td>
                                {user?.user?.available_coins
                                  ? `${user?.user?.available_coins} Coins`
                                  : "-"}
                              </td>
                              <td>{user?.code || "-"}</td>
                              <td>{user?.user?.badge?.level || "-"}</td>
                              <td>
                                {moment(user?.created_at).format("LL") || "-"}
                              </td>
                            </tr>
                          );
                        })}
                      {vendorViewTCList &&
                        vendorViewTCList?.list.length === 0 && (
                          <tr>
                            <td style={{ textAlign: "center" }} colSpan={8}>
                              Earning List Not Found !
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </Scrollbars>
              {/* pagination */}
              {vendorViewTCList?.pagination?.total_records > 10 && (
                <CommonPagination
                  currentPage={currentPageCoinEarning}
                  paginationLength={vendorViewTCList?.pagination}
                  currentFunction={currentFunctionCoinEarning}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTables;
