import React, { useEffect, useState } from "react";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { Image } from "react-bootstrap";
import ForgotPasswordImage from "../../assets/img/LogINBG.png";
import KeyImage from "../../assets/img/key.png";
import EmailImage from "../../assets/img/email.png";

import { Link, useNavigate } from "react-router-dom";
import {
  FORGOT_PASSWORD_API_URL,
  LOGIN_URL,
  SET_NEW_PASSWORD_API_URL,
  VERIFY_OTP_API_URL,
} from "../Shared/constant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPasswordAPI, { SetPasswordAPI } from "../../Api/ForgotPasswordAPI";
import Otpinput from "./Otpinput";

const ForgotPassword = () => {
  const [inputValue, setInputValue] = useState({
    Email: "",
    otp: "",
    Password: "",
    Confirm_password: "",
  });
  const { Email, otp, Password, Confirm_password } = inputValue;
  const [showpassword, setshowpassword] = useState(false);
  const [showConfirmpassword, resetshowpassword] = useState(false);
console.log("showConfirmpassword",showConfirmpassword)
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showUpdatePasswordForm, setShowUpdatePasswordForm] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirm_password, setErrorConfirm_password] = useState("");

  const Navigate = useNavigate();
  const EmailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // show password
  const ShowHidePassword = () => {
    setshowpassword(!showpassword);
  };
  const ShowHideConformPassword = () =>{
    resetshowpassword(!showConfirmpassword)
  }

  const verifyOTP = async () => {
    if (Email !== "" && otp.length === 4) {
      if (EmailRegex.test(Email) === true) {
        const jsonData = JSON.stringify({ email: Email, otp: otp });
        const response = await ForgotPasswordAPI(jsonData, VERIFY_OTP_API_URL);
        console.log("response",response,"showUpdatePasswordForm",showUpdatePasswordForm)

        if (response?.status === 200) {
          setShowUpdatePasswordForm(true);
          setShowOtpForm(false);
          toast.success(response.msg);
        } else {
          clearForm();
          toast.error(response.error);
        }
      } else {
        CheckValid(Email, { type: "email", error: setErrorEmail });
      }
    }
  };
  useEffect(() => {
    if (otp?.length === 4) {
      setShowUpdatePasswordForm(true);
      setShowOtpForm(false);
    }
  }, [otp]);
  const forgotPasswordfunct = async (e) => {
    e.preventDefault()
    if (showUpdatePasswordForm) {
      if (inputValue?.Password === inputValue?.Confirm_password) {
        const jsonData = JSON.stringify({
          email: Email,
          otp: inputValue?.otp,
          password: inputValue?.Password,
        });
        const response = await SetPasswordAPI(
          jsonData,
          SET_NEW_PASSWORD_API_URL
        );
        console.log("response",response,"showUpdatePasswordForm",showUpdatePasswordForm)

        if (response?.status === 200) {
          toast.success(response.msg);
          setTimeout(() => Navigate(LOGIN_URL), 200);
        } else {
          clearForm();
          toast.error(response.error);
        }
      } else {
        toast.error("New password and confirm password does not match");
      }
    } else {
      if (Email !== "") {
        if (EmailRegex.test(Email) === true) {
          const jsonData = JSON.stringify({ email: Email });
          const response = await ForgotPasswordAPI(
            jsonData,
            FORGOT_PASSWORD_API_URL
          );
          console.log("response",response,"showUpdatePasswordForm",showUpdatePasswordForm)
          if (response?.status === 200) {
            toast.success(response.msg);
            setShowOtpForm(true);
          } else {
            clearForm();
            toast.error(response.error);
          }
        } else {
          CheckValid(Email, { type: "email", error: setErrorEmail });
        }
      } else {
        CheckValid(Email, { type: "email", error: setErrorEmail });
      }
    }
  };

  const handleOtpChange = (otp) => {
    setInputValue({
      ...inputValue,
      otp: otp,
    });
  };

  const clearForm = () => {
    setInputValue({ Email: "", otp: "", Password: "", Confirm_password: "" });
    setErrorEmail("");
    setErrorPassword("");
    setShowOtpForm(false);
  };
  console.log("showUpdatePasswordForm", showUpdatePasswordForm);
  return (
    <section className="login_wrapper">
      <div className="login_wrap_slider">
        <Image src={ForgotPasswordImage} alt="login image" />
      </div>
      <div className="login_form_wraps m-5">
        {!showOtpForm ? (
          <img
            src={KeyImage}
            alt="KeyImage"
            style={{ height: "2.5rem", width: "2.5rem" }}
            className="text-left mb-3"
          />
        ) : (
          <img
            src={EmailImage}
            alt="EmailImage"
            style={{ height: "2.5rem", width: "2.5rem" }}
            className="text-left mb-3"
          />
        )}

        <div className="">
          {showUpdatePasswordForm && <h4>Set new password</h4>}
          {!showUpdatePasswordForm && !showOtpForm && <h4>Forgot Password?</h4>}
          {!showUpdatePasswordForm && showOtpForm && <h4>Check your email</h4>}

          {!showOtpForm ? (
            <>
              {!showUpdatePasswordForm ? (
                <p>No worries, we'll send you reset instructions. </p>
              ) : (
                <p>Enter your new password below.</p>
              )}
            </>
          ) : (
            <></>
            // <h3>Check your email</h3>
          )}
        </div>
        {showUpdatePasswordForm && !showOtpForm && (
          <>
            <InputField
              type={showpassword ? "text" : "password"}
              value={Password}
              className={
                errorPassword !== ""
                  ? "form-control error-form"
                  : "form-control"
              }
              placeholder="Enter Password"
              label="Password"
              name="Password"
              onChange={handleChange}
              onKeyUp={(e) =>
                CheckValid(e.target.value, {
                  type: "password",
                  error: setErrorPassword,
                })
              }
              onKeyDown={EmptySpaceFieldValid}
              errormessage={errorPassword}
              onClick={ShowHidePassword}
              showpassword={showpassword}
              required={true}
            />
            <InputField
              type={showConfirmpassword ? "text" : "password"}
              value={Confirm_password}
              className={
                errorConfirm_password !== ""
                  ? "form-control error-form"
                  : "form-control"
              }
              placeholder="Confirm Password"
              label="Confirm Password"
              name="Confirm_password"
              onChange={handleChange}
              onKeyUp={(e) =>
                CheckValid(e.target.value, {
                  type: "password",
                  error: setErrorConfirm_password,
                })
              }
              onKeyDown={EmptySpaceFieldValid}
              errormessage={errorConfirm_password}
              onClick={ShowHideConformPassword}
              showConfirmpassword={showConfirmpassword}
              required={true}
            />
          </>
        )}
        {!showOtpForm && !showUpdatePasswordForm ? (
          <InputField
            type="text"
            value={Email}
            className={
              errorEmail !== "" ? "form-control error-form" : "form-control"
            }
            placeholder="Enter email"
            label="Email address"
            name="Email"
            onChange={handleChange}
            onKeyUp={(e) =>
              CheckValid(e.target.value, {
                type: "email",
                error: setErrorEmail,
              })
            }
            onKeyDown={EmptySpaceFieldValid}
            errormessage={errorEmail}
            required={true}
          />
        ) : (
          <>
            {!showUpdatePasswordForm && (
              <p className="secondary-text">
                Code sent to <b className="text-black">{Email}</b>{" "}
              </p>
            )}
          </>
        )}

        <div className="form-group-btn">
          {showOtpForm && !showUpdatePasswordForm ? (
            <form onSubmit={verifyOTP} className={"loginformstart"}>
              <div className="form-field otp_wrapper mb-3 ">
                <Otpinput
                  inputStyle="inputStyle"
                  numInputs={4}
                  isDisabled={false}
                  hasErrored={false}
                  errorStyle="error"
                  name="otp"
                  onChange={handleOtpChange}
                  separator={<span></span>}
                  isInputNum={true}
                  isInputSecure={false}
                  shouldAutoFocus
                  value={otp}
                  placeholder={""}
                />
              </div>
              <div className="text-center otp-login-info">
                {/* ... (IntervalTimer or other components) */}
              </div>
              <div className="form-actions auth-actions text-center w-100">
                <button
                  className="btn loginCmn_btn w-100"
                  type="button"
                  onClick={forgotPasswordfunct}
                >
                  Resend Code
                </button>
                {/* <Link className="fs-6 ms-4" onClick={forgotPasswordfunct}>Resend Code</Link> */}
                <Link
                  to={LOGIN_URL}
                  className="btn forgot_password_link d-flex justify-content-center"
                >
                  <i className="bi bi-arrow-left me-2"></i> Back To Sign In
                </Link>
              </div>
            </form>
          ) : (
            <div className="">
              <button
                className="btn loginCmn_btn w-100"
                onClick={(e) => forgotPasswordfunct(e)}
              >
                {showUpdatePasswordForm ? "Submit Password" : "Reset Password"}
              </button>
              <Link
                to={LOGIN_URL}
                className="btn forgot_password_link d-flex justify-content-center"
              >
                <i className="bi bi-arrow-left me-2"></i> Back To Sign In
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
