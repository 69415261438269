/* eslint-disable */
import React from "react";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  ADD_ADMIN_VENDOR_MANAGEMENT_URL,
  ADD_USER_URL,
  EDIT_ADMIN_VENDOR_MANAGEMENT_URL,
  ADMIN_APPROVAL_MANAGEMENT_URL,
  ADMIN_PAYMENT_MANAGEMENT_URL,
  FAQ_MANAGEMENT_URL,
  ADD_FAQ_MANAGEMENT_URL,
  VIEW_FAQ_MANAGEMENT_URL,
  EDIT_FAQ_MANAGEMENT_URL,
  EDIT_SUPPLIER_MANAGEMENT_URL,
  ADMIN_SETTING_MANAGEMENT_URL,
  VIEW_ADMIN_VENDOR_MANAGEMENT_URL,
  VIEW_SUPPLIER_MANAGEMENT_URL,
  MODEL_BRAND_MANAGEMENT_URL,
  DASHBOARD_URL,
  ADMIN_VENDOR_MANAGEMENT_URL,
  SUPPLIER_MANAGEMENT_URL,
  ADD_SUPPLIER_MANAGEMENT_URL,
  USER_MANAGEMENT_URL,
  VIEW_MODEL_BRANDS_MANAGEMENT_URL,
  EDIT_USER_URL,
  CATEGORY_MANAGEMENT_URL,
  SETTINGS_MANAGEMENT_URL,
  ADMIN_NOTIFICATION_MANAGEMENT_URL,
  ADMIN_SUPPORT_MANAGEMENT_URL,
  ADMIN_EVENT_MANAGEMENT_URL,
  ADMIN_ADD_ADMIN_EVENT_MANAGEMENT_URL,
  ADMIN_VIEW_ADMIN_EVENT_MANAGEMENT_URL,
  ADMIN_EDIT_ADMIN_EVENT_MANAGEMENT_URL,
  ADMIN_OFFER_MANAGEMENT_URL,
  ADMIN_ADD_ADMIN_OFFER_MANAGEMENT_URL,
  ADMIN_VIEW_ADMIN_OFFER_MANAGEMENT_URL,
  ADMIN_EDIT_ADMIN_OFFER_MANAGEMENT_URL,
  ADMIN_LIVE_MAP_MANAGEMENT_URL,
  ADMIN_ADD_ADMIN_LIVE_MAP_MANAGEMENT_URL,
  ADMIN_VIEW_ADMIN_LIVE_MAP_MANAGEMENT_URL,
  ADMIN_EDIT_ADMIN_LIVE_MAP_MANAGEMENT_URL,
  VENDOR_DASHBOARD_URL,
  VENDOR_OFFER_MANAGEMENT_URL,
  VENDOR_ADD_VENDOR_OFFER_MANAGEMENT_URL,
  VENDOR_VIEW_VENDOR_OFFER_MANAGEMENT_URL,
  VENDOR_EDIT_VENDOR_OFFER_MANAGEMENT_URL,
  VENDR_EVENT_MANAGEMENT_URL,
  VENDR_ADD_VENDR_EVENT_MANAGEMENT_URL,
  VENDR_EDIT_VENDR_EVENT_MANAGEMENT_URL,
  VENDR_VIEW_VENDR_EVENT_MANAGEMENT_URL,
  VENDOR_PAYMENT_MANAGEMENT_URL,
  VENDOR_COIN_TRANSACTION_URL,
  VENDOR_SUPPORT_MANAGEMENT_URL,
} from "../Shared/constant";
import BrandIcon from "../../assets/img/brand-image.png";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCurrentAddedBrandModel,
  selectCurrentAddedBrand,
  selectCurrentAddedSupplier,
  getSelectedCurrentBrandIDs,
  selectCurrentAddedCategory,
} from "../../redux/actions/adminActions";
import logoIcon from "../../assets/img/logoIcon.png";
import dashboardIcon from "../../assets/img/dashboardIcon.png";
import vendorIcon from "../../assets/img/VendorIcon.png";
import UserIcon from "../../assets/img/UserIcon.png";
import OffersIcon from "../../assets/img/OffersIcon.png";
import EventsIcon from "../../assets/img/EventsIcon.png";
import PaymentIcon from "../../assets/img/PaymentIcon.png";
import CategoryIcon from "../../assets/img/CategoryIcon.png";
import LiveMapIcon from "../../assets/img/LiveMapIcon.png";
import NotificationIcon from "../../assets/img/NotificationIcon.png";
import ApprovalIcon from "../../assets/img/ApprovalIcon.png";
import ReviewIcon from "../../assets/img/ReviewIcon.png";
import FAQIcon from "../../assets/img/FAQIcon.png";
import SupportIcon from "../../assets/img/SupportIcon.png";
import CoinsIcon from "../../assets/img/Coins.png";

const Sidebar = (props) => {
  const { setShowSidebar } = props;
  const Dispatch = useDispatch();
  const RouteUrl = useLocation()?.pathname;
  const RouteUrlEditView = "/" + RouteUrl.split("/")[1];
  const { selfInfo, notificationList, device_id } = useSelector(
    (state) => state.adminReducers
  );

  console.log("selfInfo", selfInfo);

  // sidebar close
  const SidbarActive = () => {
    Dispatch(selectCurrentAddedBrand({}));
    Dispatch(selectCurrentAddedBrandModel({}));
    Dispatch(getSelectedCurrentBrandIDs(""));
    Dispatch(selectCurrentAddedCategory({}));
    Dispatch(selectCurrentAddedSupplier({}));
    sessionStorage.setItem("currentPage", 1);
  };

  // sidebar close
  const SidbarActiveClose = () => {
    setShowSidebar(false);
    Dispatch(selectCurrentAddedBrand({}));
    Dispatch(selectCurrentAddedBrandModel({}));
    Dispatch(getSelectedCurrentBrandIDs(""));
    Dispatch(selectCurrentAddedCategory({}));
    Dispatch(selectCurrentAddedSupplier({}));
    sessionStorage.setItem("currentPage", 1);
  };
  return (
    <div className="sidebar">
      {/* <div className="navbarHeader">
            <h1>Logo</h1>
        </div> */}
      {window.screen.width < 901 && (
        <button
          className="close_sidebarbtn"
          onClick={() => SidbarActiveClose()}
        >
          <i className="bi bi-x-lg"></i>{" "}
        </button>
      )}
      <Scrollbars
        style={{ height: "calc(100vh)" }}
        renderView={(props) => <div {...props} className="view" />}
        className="ScrollbarsSidebar"
      >
        {window.screen.width < 901 ? (
          <Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
            {selfInfo?.user_type === "admin" && (
              <Link
                to={DASHBOARD_URL}
                onClick={() => SidbarActiveClose()}
                className={
                  RouteUrl === DASHBOARD_URL || RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                {/* <i className="bi bi-speedometer2"></i> */}
                <img src={dashboardIcon} alt="" />
                <span>Dashboard</span>
              </Link>
            )}
            {selfInfo?.user_type === "admin" && (
              <Link
                to={ADMIN_VENDOR_MANAGEMENT_URL}
                onClick={() => SidbarActiveClose()}
                className={
                  RouteUrl === ADMIN_VENDOR_MANAGEMENT_URL ||
                  RouteUrl === ADD_ADMIN_VENDOR_MANAGEMENT_URL ||
                  RouteUrlEditView === VIEW_ADMIN_VENDOR_MANAGEMENT_URL ||
                  RouteUrlEditView === EDIT_ADMIN_VENDOR_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <img src={vendorIcon} alt="" />
                <span>Vendor</span>
              </Link>
            )}
            {selfInfo?.user_type === "admin" && (
              <>
                {selfInfo?.user_type === "admin" && (
                  <Link
                    to={USER_MANAGEMENT_URL}
                    onClick={() => SidbarActiveClose()}
                    className={
                      RouteUrl === USER_MANAGEMENT_URL ||
                      RouteUrl === ADD_USER_URL ||
                      RouteUrlEditView === EDIT_USER_URL ||
                      RouteUrl === "/"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img src={UserIcon} alt="" />
                    <span>User</span>
                  </Link>
                )}
              </>
            )}
            {selfInfo?.user_type === "admin" && (
              <Link
                to={ADMIN_OFFER_MANAGEMENT_URL}
                onClick={() => SidbarActiveClose()}
                className={
                  RouteUrl === ADMIN_OFFER_MANAGEMENT_URL ||
                  RouteUrl === ADMIN_ADD_ADMIN_OFFER_MANAGEMENT_URL ||
                  RouteUrlEditView === ADMIN_VIEW_ADMIN_OFFER_MANAGEMENT_URL ||
                  RouteUrlEditView === ADMIN_EDIT_ADMIN_OFFER_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <img src={OffersIcon} alt="" />
                <span>Offers</span>
              </Link>
            )}

            {selfInfo?.user_type === "admin" && (
              <Link
                to={ADMIN_EVENT_MANAGEMENT_URL}
                onClick={() => SidbarActiveClose()}
                className={
                  RouteUrl === ADMIN_EVENT_MANAGEMENT_URL ||
                  RouteUrl === ADMIN_ADD_ADMIN_EVENT_MANAGEMENT_URL ||
                  RouteUrlEditView === ADMIN_VIEW_ADMIN_EVENT_MANAGEMENT_URL ||
                  RouteUrlEditView === ADMIN_EDIT_ADMIN_EVENT_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <img src={EventsIcon} alt="" />
                <span>Events</span>
              </Link>
            )}

            {selfInfo?.user_type === "admin" && (
              <Link
                to={ADMIN_PAYMENT_MANAGEMENT_URL}
                className={
                  RouteUrl === ADMIN_PAYMENT_MANAGEMENT_URL || RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <img src={PaymentIcon} alt="" />
                <span>Payment</span>
              </Link>
            )}
            {selfInfo?.user_type === "admin" && (
              <Link
                to={CATEGORY_MANAGEMENT_URL}
                onClick={() => SidbarActiveClose()}
                className={
                  RouteUrl === CATEGORY_MANAGEMENT_URL || RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <img src={CategoryIcon} alt="" />
                <span>Categories</span>
              </Link>
            )}
            {selfInfo?.user_type === "admin" && (
              <Link
                to={ADMIN_LIVE_MAP_MANAGEMENT_URL}
                onClick={() => SidbarActiveClose()}
                className={
                  RouteUrl === ADMIN_LIVE_MAP_MANAGEMENT_URL ||
                  RouteUrl === ADMIN_ADD_ADMIN_LIVE_MAP_MANAGEMENT_URL ||
                  RouteUrlEditView ===
                    ADMIN_VIEW_ADMIN_LIVE_MAP_MANAGEMENT_URL ||
                  RouteUrlEditView ===
                    ADMIN_EDIT_ADMIN_LIVE_MAP_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <img src={LiveMapIcon} alt="" />
                <span>Live Map</span>
              </Link>
            )}
            {selfInfo?.user_type === "admin" && (
              <Link
                to={ADMIN_NOTIFICATION_MANAGEMENT_URL}
                onClick={() => SidbarActiveClose()}
                className={
                  RouteUrl === ADMIN_NOTIFICATION_MANAGEMENT_URL
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <img src={NotificationIcon} alt="NotificationIcon" />
                <span className="d-flex">
                  Notification{" "}
                  <small class="number-message common-blue">
                    {notificationList?.list?.filter(
                      (notification) => notification?.n_status === "open"
                    )?.length || "0"}
                  </small>
                </span>
              </Link>
            )}
            {selfInfo?.user_type === "admin" && (
              <Link
                to={ADMIN_APPROVAL_MANAGEMENT_URL}
                onClick={() => SidbarActiveClose()}
                className={
                  RouteUrl === ADMIN_APPROVAL_MANAGEMENT_URL || RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <img src={ApprovalIcon} alt="" />
                <span>Approval</span>
              </Link>
            )}
            {selfInfo?.user_type === "admin" && (
              <Link
                to={FAQ_MANAGEMENT_URL}
                className={
                  RouteUrl === FAQ_MANAGEMENT_URL ||
                  RouteUrl === ADD_FAQ_MANAGEMENT_URL ||
                  RouteUrlEditView === VIEW_FAQ_MANAGEMENT_URL ||
                  RouteUrlEditView === EDIT_FAQ_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <img src={FAQIcon} alt="" />
                <span>FAQ's</span>
              </Link>
            )}

            {selfInfo?.user_type === "admin" && (
              <Link
                to={ADMIN_SUPPORT_MANAGEMENT_URL}
                onClick={() => SidbarActiveClose()}
                className={
                  RouteUrl === ADMIN_SUPPORT_MANAGEMENT_URL || RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <img src={SupportIcon} alt="" />
                <span>Support</span>
              </Link>
            )}

            {selfInfo?.user_type === "vendor" && (
              <Link
                to={VENDOR_DASHBOARD_URL}
                onClick={() => SidbarActiveClose()}
                className={
                  RouteUrl === VENDOR_DASHBOARD_URL || RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                {/* <i className="bi bi-speedometer2"></i> */}
                <img src={dashboardIcon} alt="" />
                <span>Dashboard</span>
              </Link>
            )}
            {selfInfo?.user_type === "vendor" && (
              <Link
                to={VENDOR_OFFER_MANAGEMENT_URL}
                onClick={() => SidbarActiveClose()}
                className={
                  RouteUrl === VENDOR_OFFER_MANAGEMENT_URL ||
                  RouteUrl === VENDOR_ADD_VENDOR_OFFER_MANAGEMENT_URL ||
                  RouteUrlEditView ===
                    VENDOR_VIEW_VENDOR_OFFER_MANAGEMENT_URL ||
                  RouteUrlEditView ===
                    VENDOR_EDIT_VENDOR_OFFER_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <img src={OffersIcon} alt="" />
                <span>Offers</span>
              </Link>
            )}
            {selfInfo?.user_type === "vendor" && (
              <Link
                to={VENDR_EVENT_MANAGEMENT_URL}
                onClick={() => SidbarActiveClose()}
                className={
                  RouteUrl === VENDR_EVENT_MANAGEMENT_URL ||
                  RouteUrl === VENDR_ADD_VENDR_EVENT_MANAGEMENT_URL ||
                  RouteUrlEditView === VENDR_VIEW_VENDR_EVENT_MANAGEMENT_URL ||
                  RouteUrlEditView === VENDR_EDIT_VENDR_EVENT_MANAGEMENT_URL ||
                  RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <img src={EventsIcon} alt="" />
                <span>Events</span>
              </Link>
            )}

            {selfInfo?.user_type === "vendor" && (
              <Link
                to={VENDOR_COIN_TRANSACTION_URL}
                className={
                  RouteUrl === VENDOR_COIN_TRANSACTION_URL || RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <img src={CoinsIcon} alt="" />
                <span>Coin Transactions</span>
              </Link>
            )}

            {selfInfo?.user_type === "vendor" && (
              <Link
                to={VENDOR_PAYMENT_MANAGEMENT_URL}
                className={
                  RouteUrl === VENDOR_PAYMENT_MANAGEMENT_URL || RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <img src={PaymentIcon} alt="" />
                <span>Payment</span>
              </Link>
            )}
            {selfInfo?.user_type === "vendor" && (
              <Link
                to={VENDOR_SUPPORT_MANAGEMENT_URL}
                onClick={() => SidbarActiveClose()}
                className={
                  RouteUrl === VENDOR_SUPPORT_MANAGEMENT_URL || RouteUrl === "/"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <img src={SupportIcon} alt="" />
                <span>Support</span>
              </Link>
            )}
          </Nav>
        ) : (
          <Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
            <div className="admin-panel-header d-flex justify-content-center align-items-center mb-3">
              <div>
                <img
                  src={logoIcon}
                  alt=""
                  className="me-1"
                  height={"30px"}
                  width={"30px"}
                />
              </div>
              <div>
                <div className="fw-600" style={{ fontSize: "0.9rem" }}>
                  E Find
                </div>
                <div style={{ color: "#9CBBFF", fontSize: "0.8rem" }}>
                  {selfInfo?.user_type === "admin" ? "Admin" : "Vendor"} Panel
                </div>
              </div>
            </div>

            {selfInfo?.user_type === "admin" && (
              <span className="d-flex">
                <span
                  className={RouteUrl === DASHBOARD_URL ? "spanactive" : ""}
                ></span>
                <Link
                  to={DASHBOARD_URL}
                  onClick={() => SidbarActive()}
                  className={
                    RouteUrl === DASHBOARD_URL || RouteUrl === "/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  {/* <i className="bi bi-speedometer2"></i> */}
                  <img src={dashboardIcon} alt="" />
                  <span>Dashboard</span>
                </Link>
              </span>
            )}
            {selfInfo?.user_type === "admin" && (
              <span className="d-flex">
                <span
                  className={
                    RouteUrl === ADMIN_VENDOR_MANAGEMENT_URL ||
                    RouteUrl === ADD_ADMIN_VENDOR_MANAGEMENT_URL ||
                    RouteUrlEditView === VIEW_ADMIN_VENDOR_MANAGEMENT_URL ||
                    RouteUrlEditView === EDIT_ADMIN_VENDOR_MANAGEMENT_URL
                      ? "spanactive"
                      : ""
                  }
                ></span>
                <Link
                  to={ADMIN_VENDOR_MANAGEMENT_URL}
                  onClick={() => SidbarActive()}
                  className={
                    RouteUrl === ADMIN_VENDOR_MANAGEMENT_URL ||
                    RouteUrl === ADD_ADMIN_VENDOR_MANAGEMENT_URL ||
                    RouteUrlEditView === VIEW_ADMIN_VENDOR_MANAGEMENT_URL ||
                    RouteUrlEditView === EDIT_ADMIN_VENDOR_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={vendorIcon} alt="" />
                  <span>Vendor</span>
                </Link>
              </span>
            )}
            {selfInfo?.user_type === "admin" && (
              <>
                <span className="d-flex">
                  <span
                    className={
                      RouteUrl === USER_MANAGEMENT_URL ||
                      RouteUrl === ADD_USER_URL ||
                      RouteUrlEditView === EDIT_USER_URL
                        ? "spanactive"
                        : ""
                    }
                  ></span>
                  {selfInfo?.user_type === "admin" && (
                    <Link
                      to={USER_MANAGEMENT_URL}
                      onClick={() => SidbarActive()}
                      className={
                        RouteUrl === USER_MANAGEMENT_URL ||
                        RouteUrl === ADD_USER_URL ||
                        RouteUrlEditView === EDIT_USER_URL ||
                        RouteUrl === "/"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      <img src={UserIcon} alt="" />
                      <span>User</span>
                    </Link>
                  )}
                </span>
              </>
            )}

            {selfInfo?.user_type === "admin" && (
              <span className="d-flex">
                <span
                  className={
                    RouteUrl === ADMIN_OFFER_MANAGEMENT_URL ||
                    RouteUrl === ADMIN_ADD_ADMIN_OFFER_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      ADMIN_VIEW_ADMIN_OFFER_MANAGEMENT_URL ||
                    RouteUrlEditView === ADMIN_EDIT_ADMIN_OFFER_MANAGEMENT_URL
                      ? "spanactive"
                      : ""
                  }
                ></span>
                <Link
                  to={ADMIN_OFFER_MANAGEMENT_URL}
                  onClick={() => SidbarActive()}
                  className={
                    RouteUrl === ADMIN_OFFER_MANAGEMENT_URL ||
                    RouteUrl === ADMIN_ADD_ADMIN_OFFER_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      ADMIN_VIEW_ADMIN_OFFER_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      ADMIN_EDIT_ADMIN_OFFER_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={OffersIcon} alt="" />
                  <span>Offers</span>
                </Link>{" "}
              </span>
            )}

            {selfInfo?.user_type === "admin" && (
              <span className="d-flex">
                <span
                  className={
                    RouteUrl === ADMIN_EVENT_MANAGEMENT_URL ||
                    RouteUrl === ADMIN_ADD_ADMIN_EVENT_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      ADMIN_VIEW_ADMIN_EVENT_MANAGEMENT_URL ||
                    RouteUrlEditView === ADMIN_EDIT_ADMIN_EVENT_MANAGEMENT_URL
                      ? "spanactive"
                      : ""
                  }
                ></span>
                <Link
                  to={ADMIN_EVENT_MANAGEMENT_URL}
                  onClick={() => SidbarActive()}
                  className={
                    RouteUrl === ADMIN_EVENT_MANAGEMENT_URL ||
                    RouteUrl === ADMIN_ADD_ADMIN_EVENT_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      ADMIN_VIEW_ADMIN_EVENT_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      ADMIN_EDIT_ADMIN_EVENT_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={EventsIcon} alt="" />
                  <span>Events</span>
                </Link>
              </span>
            )}

            {selfInfo?.user_type === "admin" && (
              <span className="d-flex">
                <span
                  className={
                    RouteUrl === ADMIN_PAYMENT_MANAGEMENT_URL
                      ? "spanactive"
                      : ""
                  }
                ></span>
                <Link
                  to={ADMIN_PAYMENT_MANAGEMENT_URL}
                  className={
                    RouteUrl === ADMIN_PAYMENT_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={PaymentIcon} alt="" />
                  <span>Payment</span>
                </Link>
              </span>
            )}
            {selfInfo?.user_type === "admin" && (
              <span className="d-flex">
                <span
                  className={
                    RouteUrl === CATEGORY_MANAGEMENT_URL ? "spanactive" : ""
                  }
                ></span>
                <Link
                  to={CATEGORY_MANAGEMENT_URL}
                  onClick={() => SidbarActive()}
                  className={
                    RouteUrl === CATEGORY_MANAGEMENT_URL || RouteUrl === "/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={CategoryIcon} alt="" />
                  <span>Categories</span>
                </Link>
              </span>
            )}
            {selfInfo?.user_type === "admin" && (
              <span className="d-flex">
                <span
                  className={
                    RouteUrl === ADMIN_LIVE_MAP_MANAGEMENT_URL ||
                    RouteUrl === ADMIN_ADD_ADMIN_LIVE_MAP_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      ADMIN_VIEW_ADMIN_LIVE_MAP_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      ADMIN_EDIT_ADMIN_LIVE_MAP_MANAGEMENT_URL
                      ? "spanactive"
                      : ""
                  }
                ></span>
                <Link
                  to={ADMIN_LIVE_MAP_MANAGEMENT_URL}
                  onClick={() => SidbarActive()}
                  className={
                    RouteUrl === ADMIN_LIVE_MAP_MANAGEMENT_URL ||
                    RouteUrl === ADMIN_ADD_ADMIN_LIVE_MAP_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      ADMIN_VIEW_ADMIN_LIVE_MAP_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      ADMIN_EDIT_ADMIN_LIVE_MAP_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={LiveMapIcon} alt="" />
                  <span>Live Map</span>
                </Link>
              </span>
            )}
            {selfInfo?.user_type === "admin" && (
              <span className="d-flex">
                <span
                  className={
                    RouteUrl === ADMIN_NOTIFICATION_MANAGEMENT_URL
                      ? "spanactive"
                      : ""
                  }
                ></span>
                <Link
                  to={ADMIN_NOTIFICATION_MANAGEMENT_URL}
                  className={
                    RouteUrl === ADMIN_NOTIFICATION_MANAGEMENT_URL
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={NotificationIcon} alt="NotificationIcon" />
                  <span className="d-flex">
                    Notification{" "}
                    <small class="number-message common-blue">
                      {notificationList?.list?.filter(
                        (notification) => notification?.n_status === "open"
                      )?.length || "0"}
                    </small>
                  </span>
                </Link>
              </span>
            )}
            {selfInfo?.user_type === "admin" && (
              <span className="d-flex">
                <span
                  className={
                    RouteUrl === ADMIN_APPROVAL_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "spanactive"
                      : ""
                  }
                ></span>
                <Link
                  to={ADMIN_APPROVAL_MANAGEMENT_URL}
                  className={
                    RouteUrl === ADMIN_APPROVAL_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={ApprovalIcon} alt="" />
                  <span>Approval</span>
                </Link>
              </span>
            )}
            {selfInfo?.user_type === "admin" && (
              <span className="d-flex">
                <span
                  className={
                    RouteUrl === FAQ_MANAGEMENT_URL ||
                    RouteUrl === ADD_FAQ_MANAGEMENT_URL ||
                    RouteUrlEditView === VIEW_FAQ_MANAGEMENT_URL ||
                    RouteUrlEditView === EDIT_FAQ_MANAGEMENT_URL
                      ? "spanactive"
                      : ""
                  }
                ></span>
                <Link
                  to={FAQ_MANAGEMENT_URL}
                  className={
                    RouteUrl === FAQ_MANAGEMENT_URL ||
                    RouteUrl === ADD_FAQ_MANAGEMENT_URL ||
                    RouteUrlEditView === VIEW_FAQ_MANAGEMENT_URL ||
                    RouteUrlEditView === EDIT_FAQ_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={FAQIcon} alt="" />
                  <span>FAQ's</span>
                </Link>{" "}
              </span>
            )}

            {selfInfo?.user_type === "admin" && (
              <span className="d-flex">
                <span
                  className={
                    RouteUrl === ADMIN_SUPPORT_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "spanactive"
                      : ""
                  }
                ></span>
                <Link
                  to={ADMIN_SUPPORT_MANAGEMENT_URL}
                  onClick={() => SidbarActive()}
                  className={
                    RouteUrl === ADMIN_SUPPORT_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={SupportIcon} alt="" />
                  <span>Support</span>
                </Link>
              </span>
            )}

            {selfInfo?.user_type === "vendor" && (
              <span className="d-flex">
                <span
                  className={
                    RouteUrl === VENDOR_DASHBOARD_URL || RouteUrl === "/"
                      ? "spanactive"
                      : ""
                  }
                ></span>
                <Link
                  to={VENDOR_DASHBOARD_URL}
                  onClick={() => SidbarActive()}
                  className={
                    RouteUrl === VENDOR_DASHBOARD_URL || RouteUrl === "/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  {/* <i className="bi bi-speedometer2"></i> */}
                  <img src={dashboardIcon} alt="" />
                  <span>Dashboard</span>
                </Link>
              </span>
            )}
            {selfInfo?.user_type === "vendor" && (
              <span className="d-flex">
                <span
                  className={
                    RouteUrl === VENDOR_OFFER_MANAGEMENT_URL ||
                    RouteUrl === VENDOR_ADD_VENDOR_OFFER_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      VENDOR_VIEW_VENDOR_OFFER_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      VENDOR_EDIT_VENDOR_OFFER_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "spanactive"
                      : ""
                  }
                ></span>
                <Link
                  to={VENDOR_OFFER_MANAGEMENT_URL}
                  onClick={() => SidbarActive()}
                  className={
                    RouteUrl === VENDOR_OFFER_MANAGEMENT_URL ||
                    RouteUrl === VENDOR_ADD_VENDOR_OFFER_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      VENDOR_VIEW_VENDOR_OFFER_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      VENDOR_EDIT_VENDOR_OFFER_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={OffersIcon} alt="" />
                  <span>Offers</span>
                </Link>
              </span>
            )}
            {selfInfo?.user_type === "vendor" && (
              <span className="d-flex">
                <span
                  className={
                    RouteUrl === VENDR_EVENT_MANAGEMENT_URL ||
                    RouteUrl === VENDR_ADD_VENDR_EVENT_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      VENDR_VIEW_VENDR_EVENT_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      VENDR_EDIT_VENDR_EVENT_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "spanactive"
                      : ""
                  }
                ></span>
                <Link
                  to={VENDR_EVENT_MANAGEMENT_URL}
                  onClick={() => SidbarActive()}
                  className={
                    RouteUrl === VENDR_EVENT_MANAGEMENT_URL ||
                    RouteUrl === VENDR_ADD_VENDR_EVENT_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      VENDR_VIEW_VENDR_EVENT_MANAGEMENT_URL ||
                    RouteUrlEditView ===
                      VENDR_EDIT_VENDR_EVENT_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={EventsIcon} alt="" />
                  <span>Events</span>
                </Link>
              </span>
            )}

            {selfInfo?.user_type === "vendor" && (
              <span className="d-flex">
                <span
                  className={
                    RouteUrl === VENDOR_COIN_TRANSACTION_URL || RouteUrl === "/"
                      ? "spanactive"
                      : ""
                  }
                ></span>
                <Link
                  to={VENDOR_COIN_TRANSACTION_URL}
                  className={
                    RouteUrl === VENDOR_COIN_TRANSACTION_URL || RouteUrl === "/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={CoinsIcon} alt="" />
                  <span className="text-align-left">Coin Transactions</span>
                </Link>
              </span>
            )}

            {selfInfo?.user_type === "vendor" && (
              <span className="d-flex">
                <span
                  className={
                    RouteUrl === VENDOR_PAYMENT_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "spanactive"
                      : ""
                  }
                ></span>
                <Link
                  to={VENDOR_PAYMENT_MANAGEMENT_URL}
                  className={
                    RouteUrl === VENDOR_PAYMENT_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={PaymentIcon} alt="" />
                  <span>Payment</span>
                </Link>
              </span>
            )}
            {selfInfo?.user_type === "vendor" && (
              <span className="d-flex">
                <span
                  className={
                    RouteUrl === VENDOR_SUPPORT_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "spanactive"
                      : ""
                  }
                ></span>
                <Link
                  to={VENDOR_SUPPORT_MANAGEMENT_URL}
                  onClick={() => SidbarActive()}
                  className={
                    RouteUrl === VENDOR_SUPPORT_MANAGEMENT_URL ||
                    RouteUrl === "/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={SupportIcon} alt="" />
                  <span>Support</span>
                </Link>
              </span>
            )}
          </Nav>
        )}
      </Scrollbars>
    </div>
  );
};

export default Sidebar;
