/* eslint-disable */
import React, { useState, useContext, useEffect, useRef } from "react";
import {
  GOOGLE_MAP_API_KEY,
  ageOptions,
  formatDates,
  dubaiStatesOptions,
  imagesArray,
  isVideoUrl,
  parseDateTime,
  ADMIN_EVENT_MANAGEMENT_URL,
  extractIdFromUrl,
  maxSize,
  minDescLength,
  maxDescLength,
  isEndDateTimeGreaterThanStartDateTime,
  countPlainTextOccurrences,
} from "../Shared/constant";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import UploadFileAPI, { DeleteFileAPI } from "../../Api/UploadFileAPI";
import CommonModel from "../Common/CommonModel";
import AvatarGet from "../Common/AvatarGet";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import CommonLoader from "../Common/CommonLoader";
import "react-toastify/dist/ReactToastify.css";
import {
  LoaderStartMain,
  setConfirmModal,
} from "../../redux/actions/adminActions";
import MapPin from "../../assets/icons/MapPin.svg";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import Select from "react-select";
import menIcon from "../../assets/img/menIcon.png";
import womenIcon from "../../assets/img/womenIcon.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uploadIcon from "../../assets/icons/uploadIcon.svg";
import MediaViewer from "../Common/MediaViewer";
import closeIcon from "../../assets/img/closeIcon.png";
import { AddThumbnailModal } from "../VendorMangement/AddThumbnailModal";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { toast } from "react-toastify";
import TimePicker from "../Common/TimePicker/TimePicker.jsx";

const AddEditEvent = () => {
  const location = useLocation();
  const state = location.state;

  // Now you can access the state object
  const Navigate = useNavigate();
  const Dispatch = useDispatch();
  const [key, setKey] = useState("info");
  const [infoFormFill, setinfoFormFill] = useState(false);
  const params = useParams();
  const Location = useLocation()?.pathname?.split("/")[1];
  const { websocket } = useContext(WebSocketContext);
  const [inputValue, setInputValue] = useState({
    VendorName: "",
    address: "",
    EventAddress: "",
    Email: "",
    ContactNumber: "",
    BussinessContactNumber: "",
    EventTitle: "",
    DetailAddress: "",
    VendorLocation: "",
  });
  const {
    VendorName,
    address,
    EventTitle,
    ContactNumber,
    EventAddress,
    Email,
    BussinessContactNumber,

    DetailAddress,
    VendorLocation,
  } = inputValue;
  console.log("inputValue", inputValue);
  const [selectedLocation, setSelectedLocation] = useState({
    lat: "",
    lng: "",
  });
  const [errorVendorName, setErrorVendorName] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  const [errorCategory, seterrorCategory] = useState("");
  const [errorMedia, setErrorMedia] = useState("");
  const [errorEventAddress, setErrorEventAddress] = useState("");
  const [errorEventTitle, setErrorEventTitle] = useState("");
  const [errorDesc, setErrorDesc] = useState("");
  const [errorEventStart, setErrorEventStart] = useState("");
  const [errorEventEnd, setErrorEventEnd] = useState("");
  const [errorAge, setErrorAge] = useState("");
  const [errorGender, setErrorGender] = useState("");
  const [errorSelectedVendor, setErrorSelectedVendor] = useState("");

  const [editorState, setQueEditorState] = useState(EditorState.createEmpty());
  const [StoreDesc, setStoreDesc] = useState("");

  const {
    viewProduct,
    accessToken,
    device_id,
    loaderMain,
    selfInfo,
    categoryList,
    vendorList,
    subcategoryList,
  } = useSelector((state) => state.adminReducers);
  const [UploadFileLoader, setUploadFileLoader] = useState(false);
  const [AvatarImage, setAvatarImage] = useState("");
  const [AvatarImageId, setAvatarImageId] = useState("");
  const [fileData, setFileData] = useState([]);
  console.log("fileData",fileData)
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  const [charCount, setCharCount] = useState(0);
  const [submitClick, setSubmitClick] = useState(false);
  const [selectedVendor, setSelectedVednor] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const handleVednorChange = (selectedOption) => {
    setSelectedVednor(selectedOption);
    if (selectedOption && selectedOption?.interest) {
      // Split the categories string into an array
      const productCategories = selectedOption?.interest.split(",");

      // Filter categoryList to find objects whose name matches the product categories
      const filteredCategories = categoryList?.list?.filter((category) =>
        productCategories.includes(category.id)
      );

      // Map the filtered categories to the required structure and update the state
      const mappedCategories = filteredCategories.map((category) => ({
        value: category.id,
        label: category.name,
      }));

      setSelectedCategories(mappedCategories);
    }
  };
  const categoryOptions = categoryList?.list
    ?.filter((elm) => elm?.is_active === true)
    .map((elm) => ({ value: elm?.id, label: elm?.name }));
  const vendorListOption = vendorList?.list
    ?.filter((elm) => elm?.is_active === true)
    .map((elm) => ({
      value: elm?.id,
      id: elm?.id,
      label: elm?.business_name,
      code: elm?.vendor_code,
      address: elm?.address,
      name: elm?.business_name,
      state: elm?.state,
      country: elm?.country,
      email: elm?.owner_email_id,
      interest: elm?.interest,
    }));
  useEffect(() => {
    if (state?.userId) {
      const prevSelectedVendor = vendorList?.list?.find(
        (elm) => elm?.id === state?.userId
      );

      const selectedVendorData = prevSelectedVendor
        ? {
            value: prevSelectedVendor.id,
            id: prevSelectedVendor.id,
            label: prevSelectedVendor.business_name,
            code: prevSelectedVendor.vendor_code,
            address: prevSelectedVendor.address,
            name: prevSelectedVendor.business_name,
            state: prevSelectedVendor.state,
            country: prevSelectedVendor.country,
            email: prevSelectedVendor.owner_email_id,
            interest: prevSelectedVendor?.interest,
          }
        : null;
      if (selectedVendorData && selectedVendorData?.interest) {
        // Split the categories string into an array
        const productCategories = selectedVendorData?.interest.split(",");

        // Filter categoryList to find objects whose name matches the product categories
        const filteredCategories = categoryList?.list?.filter((category) =>
          productCategories.includes(category.id)
        );

        // Map the filtered categories to the required structure and update the state
        const mappedCategories = filteredCategories.map((category) => ({
          value: category.id,
          label: category.name,
        }));

        setSelectedCategories(mappedCategories);
      }

      setSelectedVednor(selectedVendorData);
    }
  }, [location]);
  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
  };
  const commaSeparatedCategories = selectedCategories
    .map((category) => category.value)
    .join(",");

  // get product details
  useEffect(() => {
    if (Location === "edit-event") {
      if (params?.roomId) {
        let param = {
          transmit: "single",
          url: "event_get",
          request: {
            event_id: params?.roomId,
            user_type: selfInfo?.user_type,
          },
        };
        wsSend_request(websocket, param);
      }
    }
  }, [params?.roomId, Location, websocket]);

  useEffect(() => {
    let param = {
      transmit: "single",
      url: "category_listing",
      request: {
        filter: "all",
        limit: 5000,
        page: 1,
        search: "",
        user_type: selfInfo?.user_type,
        category_by: selfInfo?.user_type,
      },
    };
    wsSend_request(websocket, param);
  }, [websocket]);
  useEffect(() => {
    let param = {
      transmit: "single",
      url: "vendor_admin_listing",
      request: {
        filter: "all",
        limit: 5000,
        page: 1,
        search: "",
        user_type: selfInfo?.user_type,
      },
    };
    wsSend_request(websocket, param);
  }, [websocket]);

  useEffect(() => {
    if (Location === "edit-event" && viewProduct) {
      const prevSelectedVendor = vendorList?.list?.find(
        (elm) => elm?.vendor_code === viewProduct?.vendor_code
      );

      const selectedVendorData = prevSelectedVendor
        ? {
            value: prevSelectedVendor.id,
            id: prevSelectedVendor.id,
            label: prevSelectedVendor.business_name,
            code: prevSelectedVendor.vendor_code,
            address: prevSelectedVendor.address,
            name: prevSelectedVendor.business_name,
            state: prevSelectedVendor.state,
            country: prevSelectedVendor.country,
            email: prevSelectedVendor.owner_email_id,
          }
        : null;

      setSelectedVednor(selectedVendorData);
      setSelectedDubaiState({
        value: viewProduct?.state,
        label: viewProduct?.state,
      });

      setInputValue({
        ...inputValue,
        EventTitle: viewProduct?.event_title,
        country: viewProduct?.country,
        city: viewProduct?.city,

        address: viewProduct?.address,
        location: viewProduct?.location,
        state_code: viewProduct?.state,
        state: viewProduct?.state,
      });

      setSelectedLocation({
        lat: viewProduct?.latitude,
        lng: viewProduct?.longitude,
      });
      if (viewProduct?.event_users) {
        const [startAge, endAge] = viewProduct?.event_users.age_range
          .split("-")
          .map((age) => parseInt(age));
        setStartAge({
          value: startAge,
          label: `${startAge} - Age`,
        });
        setEndAge({
          value: endAge,
          label: `${endAge} - Age`,
        });
      }
      if (viewProduct?.event_users?.gender?.includes("Male")) {
        setMenSelected(true);
      }
      if (viewProduct?.event_users?.gender?.includes("Female")) {
        setWomenSelected(true);
      }
      if (viewProduct?.event_description) {
        setErrorDesc("");
        const characterCount = countPlainTextOccurrences(
          viewProduct?.event_description
        );
        setCharCount(characterCount)
        const defaultStoreDescriptionContent = viewProduct.event_description;
        const storeDescriptionContentState = ContentState.createFromBlockArray(
          convertFromHTML(defaultStoreDescriptionContent)
        );
        const storeDescriptionEditorState = EditorState.createWithContent(
          storeDescriptionContentState
        );
        setQueEditorState(storeDescriptionEditorState);
      }

      if (viewProduct && viewProduct?.interest) {
        // Split the categories string into an array
        const productCategories = viewProduct?.interest.split(",");

        // Filter categoryList to find objects whose name matches the product categories
        const filteredCategories = categoryList?.list?.filter((category) =>
          productCategories.includes(category.name)
        );

        // Map the filtered categories to the required structure and update the state
        const mappedCategories = filteredCategories.map((category) => ({
          value: category.id,
          label: category.name,
        }));

        setSelectedCategories(mappedCategories);
      }

      setAvatarImage({
        url: {
          file_url: viewProduct?.logo?.file_url,
        },
      });
      if (viewProduct?.files?.length) {
        const updatedFileData = [];

        viewProduct.files.forEach((item) => {
          if (item?.url?.file_url) {
            updatedFileData.push(item);
          }
        });

        setFileData(updatedFileData);
      }

      if (viewProduct?.event_start_time) {
        const { date, time } = parseDateTime(viewProduct?.event_start_time);
        setStartDate(date);
        setStartTime(time);
      }
      if (viewProduct?.event_end_time) {
        const { date, time } = parseDateTime(viewProduct?.event_end_time);
        setEndDate(date);
        setEndTime(time);
      }
    }
  }, [viewProduct, websocket]);
  const onEditorChange = (newEditorState) => {
    // if (charCount < maxDescLength) {
      setQueEditorState(newEditorState);
      const contentState = newEditorState.getCurrentContent();
      const plainText = contentState.getPlainText("");
      const characterCount = plainText.length;
      setCharCount(characterCount);
      const contentAsRaw = convertToRaw(contentState);
      const htmlContent = draftToHtml(contentAsRaw);
      const styledHtmlContent = `<div style="font-family: Manrope;">${htmlContent}</div>`;

      setStoreDesc(styledHtmlContent);

      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        question: styledHtmlContent,
      }));
    // }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [showVideoModal, setShowVideoModal] = useState({
    show: false,
    to: "",
    id: "",
    parent_id: "",
  });
  const UploadImage = async (e) => {
    Dispatch(LoaderStartMain(true));
    const files = e.target.files[0];
    if (files && files.size > maxSize) {
      toast.error("File size exceeds 30MB limit.");
      e.target.value = null; // Clear the input field
      Dispatch(LoaderStartMain(false));
      return;
    }

    const formData = new FormData();
    formData.append("file", files, files.name);

    formData?.append("to", selectedVendor?.value);

    formData.append("is_admin", selfInfo?.user_type === "admin" ? "1" : "0");

    const fileresponse = await UploadFileAPI(accessToken, formData);

    if (fileresponse) {
      setFileData((prevFileData) => [...prevFileData, fileresponse[0]]);
      setFileData((prevFileData) =>
        prevFileData.map((file, index) => ({ ...file, sequence: index + 1 }))
      );
      if (files.type.startsWith("image")) {
        // Handle image upload
        console.log("Selected file is an image:", files);
        // Your image upload logic here
      }
      // Check if the selected file is a video
      else if (files.type.startsWith("video")) {
        // Handle video upload
        console.log("Selected file is a video:", files);
        setShowVideoModal((prev) => ({
          ...prev,
          show: true,
          to: formData?.to,
          id: params?.roomId || inputValue?.owner_email_id,
          parent_id: fileresponse[0]?.id,
        }));
      }
    }
    Dispatch(LoaderStartMain(false));
  };
  const checkIsValid = () => {
    if (
      inputValue?.address?.length &&
      selectedLocation?.lat &&
      selectedLocation?.lng &&
      inputValue?.EventTitle &&
      selectedCategories?.length &&
      fileData?.length &&
      selectedVendor &&
      startAge &&
      endAge &&
      startDate &&
      startTime &&
      endTime &&
      endDate &&
      errorEventEnd === "" &&
      charCount > minDescLength &&
      charCount < maxDescLength &&
      isEndDateTimeGreaterThanStartDateTime(
        startDate,
        startTime,
        endDate,
        endTime
      ) &&
      startAge?.value < endAge?.value
    ) {
      return true;
    } else {
     if (!startDate || !endDate || !startTime || !endTime) {
        // Handle the case where any of the time values are missing
        setErrorEventStart(
          "Please enter all required time values for the event"
        );
        console.log("setErrorEventStart");
      }

      if (!startDate || !startTime) {
        setErrorEventStart("Please select event start date and time");
      } else {
        setErrorEventStart("");
      }
      if (!endDate || !endTime) {
        setErrorEventEnd("Please select event end date and time");
      } else {
        setErrorEventEnd("");
      }
      if (!startAge || !endAge) {
        setErrorAge("Please select age");
      } else {
        setErrorAge("");
      }
      if (startAge?.value && endAge?.value && startAge?.value > endAge?.value) {
        setErrorAge(
          "Start age cannot be greater than end age, and end age must be greater than 0"
        );
      }
      if (!menSelected && !womenSelected) {
        setErrorGender("Please select gender");
      } else {
        setErrorGender("");
      }
      if (charCount < minDescLength) {
        setErrorDesc(
          `Please enter at least ${minDescLength} character description`
        );
      } else {
        setErrorDesc("");
      }
      if (charCount > maxDescLength && charCount > minDescLength) {
        setErrorDesc(
          `Please enter maximum ${maxDescLength} character description`
        );
      }
      // Set error states only when the condition fails
      CheckValid(inputValue?.address, {
        type: "Address",
        error: setErrorEventAddress,
      });
      CheckValid(inputValue?.EventTitle, {
        type: "Title",
        error: setErrorEventTitle,
      });

      if (selectedCategories?.length == 0) {
        seterrorCategory("Please select category");
      } else {
        seterrorCategory("");
      }

      if (fileData?.length == 0) {
        setErrorMedia("Please upload event media");
      } else {
        setErrorMedia("");
      }

      if (!selectedVendor) {
        setErrorSelectedVendor("Please select vendor");
      } else {
        setErrorSelectedVendor("");
      }
      if (
        !isEndDateTimeGreaterThanStartDateTime(
          startDate,
          startTime,
          endDate,
          endTime
        )
      ) {
        setErrorEventStart(
          "Please select an event end time greater than the start time"
        );
      }
      return false;
    }
  };

  const setEventMode = () => {
    setSubmitClick(true);
    const isValid = checkIsValid();
    console.log("isValid", isValid);

    if (isValid) {
      let paramRequest;

      paramRequest = {
        created_by: selfInfo?.user_type,
        vendor_code: selectedVendor?.code,
        vendor_id: selectedVendor?.value,
        event_title: inputValue?.EventTitle,
        event_start_time: formatDates(startDate, startTime),
        event_end_time: formatDates(endDate, endTime),
        interest: selectedCategories?.map((item) => item.value).join(","),
        event_description: StoreDesc || viewProduct?.event_description,
        event_users: {
          age_range: `${startAge?.value}-${endAge?.value}`,
          gender:
            menSelected && womenSelected
              ? "men,women"
              : menSelected
              ? "men"
              : womenSelected
              ? "women"
              : "",
        },
        files: fileData?.map(({ sequence, name }) => ({ sequence, name })),
        event_mode: "payment",
        location: `${selectedLocation?.lat},${selectedLocation?.lng}`,
        state: inputValue?.state_code || selectedDubaiState?.value,
        country: inputValue?.country_code || inputValue?.country,
        address: inputValue?.address,
        user_type: selfInfo?.user_type,
      };

      const paramfilter = Object.entries(paramRequest).filter((elm) => {
        if (elm[1] !== "") {
          return elm;
        }
      });
      let param = {
        transmit: "single",
        url: "event_add",
        request: Object.fromEntries(paramfilter),
      };
      wsSend_request(websocket, param);
      Navigate(ADMIN_EVENT_MANAGEMENT_URL);
    }
  };

  const CreateEvent = () => {
    setSubmitClick(true);
    const isValid = checkIsValid();
    console.log("isValid", isValid);

    if (isValid) {
      if (Location === "edit-event") {
        if (inputValue) {
          let param;

          param = {
            transmit: "single",
            url: "event_update",
            request: {
              event_title: inputValue?.EventTitle,
              event_start_time: formatDates(startDate, startTime),
              event_end_time: formatDates(endDate, endTime),
              interest: selectedCategories?.map((item) => item.value).join(","),
              event_description: StoreDesc || viewProduct?.event_description,

              files: fileData?.map(({ sequence, name }) => ({
                sequence,
                name,
              })),
              location: `${selectedLocation?.lat},${selectedLocation?.lng}`,
              state: selectedDubaiState?.value || inputValue?.state_code,
              country: inputValue?.country_code || inputValue?.country,
              address: inputValue?.address,
              user_type: selfInfo?.user_type,
              event_id: params?.roomId,
            },
          };

          wsSend_request(websocket, param);
          Navigate(ADMIN_EVENT_MANAGEMENT_URL);
        }
      } else {
        if (inputValue) {
          let paramRequest;

          paramRequest = {
            created_by: selfInfo?.user_type,
            vendor_code: selectedVendor?.code,
            vendor_id: selectedVendor?.value,
            event_title: inputValue?.EventTitle,
            event_start_time: formatDates(startDate, startTime),
            event_end_time: formatDates(endDate, endTime),
            interest: selectedCategories?.map((item) => item.value).join(","),
            event_description: StoreDesc || viewProduct?.event_description,
            event_users: {
              age_range: `${startAge?.value}-${endAge?.value}`,
              gender:
                menSelected && womenSelected
                  ? "men,women"
                  : menSelected
                  ? "men"
                  : womenSelected
                  ? "women"
                  : "",
            },
            files: fileData?.map(({ sequence, name }) => ({ sequence, name })),
            event_mode: "free",
            location: `${selectedLocation?.lat},${selectedLocation?.lng}`,
            state: inputValue?.state_code || selectedDubaiState?.value,
            country: inputValue?.country_code || inputValue?.country,
            address: inputValue?.address,
            user_type: selfInfo?.user_type,
          };

          const paramfilter = Object.entries(paramRequest).filter((elm) => {
            if (elm[1] !== "") {
              return elm;
            }
          });
          let param = {
            transmit: "single",
            url: "event_add",
            request: Object.fromEntries(paramfilter),
          };
          wsSend_request(websocket, param);
          Navigate(ADMIN_EVENT_MANAGEMENT_URL);
        }
      }
    }
  };

  const removeCategory = (categoryToRemove) => {
    const updatedCategories = selectedCategories.filter(
      (category) => category.value !== categoryToRemove.value
    );
    setSelectedCategories(updatedCategories);
  };

  const [startAge, setStartAge] = useState(null);
  const [endAge, setEndAge] = useState(null);
  const handleStartAgeChange = (selectedOption) => {
    setStartAge(selectedOption);
  };

  const handleEndAgeChange = (selectedOption) => {
    setEndAge(selectedOption);
  };
  const [menSelected, setMenSelected] = useState(false);
  const [womenSelected, setWomenSelected] = useState(false);

  const [selectedDubaiState, setSelectedDubaiState] = useState({
    value: Location === "edit-event" ? viewProduct?.state : "",
    label: Location === "edit-event" ? viewProduct?.state : "",
  });

  useEffect(() => {
    if (inputValue?.state) {
      setSelectedDubaiState({
        value: inputValue?.state_code,
        label: inputValue?.state,
      });
    }
  }, [inputValue?.state]);
  const handleDubaiStateChange = (selectedOption) => {
    setSelectedDubaiState(selectedOption);
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  console.log(
    "startDate",
    startDate,
    "endDate",
    endDate,
    "startTime",
    startTime,
    "endTime",
    endTime
  );
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    if (time && time.hour && time.minute) {
      // Construct the time string from hour and minute
      const timeString = `${time.hour}:${time.minute}`;
      setStartTime(timeString);
    }
  };

  const handleEndTimeChange = (time) => {
    if (time && time.hour && time.minute) {
      // Construct the time string from hour and minute
      const timeString = `${time.hour}:${time.minute}`;
      setEndTime(timeString);
    }
  };
  let inputRef = useRef();
  const libraries = ["places"];
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: libraries,
  });

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      console.log("handlePlaceChanged", place);

      // Extracting desired values from the place object
      const address = place.formatted_address;
      const addressComponents = place.address_components || [];
      let city = "";
      let postalCode = "";
      let state = "";
      let stateCode = "";
      let country = "";
      let countryCode = "";

      // Extracting city, postal code, state, country from address components
      addressComponents.forEach((component) => {
        if (component.types.includes("locality")) {
          city = component.long_name;
        } else if (component.types.includes("postal_code")) {
          postalCode = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          state = component.long_name;
          stateCode = component.short_name;
        } else if (component.types.includes("country")) {
          country = component.long_name;
          countryCode = component.short_name;
        }
      });

      // Update state with the retrieved data
      setInputValue((previnputValue) => ({
        ...previnputValue,
        address: address,
        city: city,
        postal_code: postalCode,
        location: address,
        state: state,
        state_code: stateCode,
        country: country,
        country_code: countryCode,
      }));

      // Set selected location
      setSelectedLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };

  useEffect(() => {
    if (selectedCategories?.length == 0 && selectedVendor && submitClick) {
      seterrorCategory("Please select category");
    } else {
      seterrorCategory("");
    }
    if (startAge && endAge && submitClick) {
      setErrorAge("");
    }
    if (menSelected || (womenSelected && submitClick)) {
      setErrorGender("");
    }
    if (inputValue?.EventTitle?.length && submitClick) {
      setErrorEventTitle("");
    }

    if (startDate && startTime && submitClick) {
      setErrorEventStart("");
    }
    if (endDate && endTime && submitClick) {
      setErrorEventEnd("");
    }

    if (
      (StoreDesc?.length == 0 || StoreDesc?.length == 49) &&
      selectedVendor &&
      submitClick
    ) {
      setErrorDesc("Please enter description");
    } else {
      setErrorDesc("");
    }
    if (fileData?.length == 0 && selectedVendor && submitClick) {
      setErrorMedia("Please upload media");
    } else {
      setErrorMedia("");
    }
    if (selectedVendor && inputValue?.address === "" && submitClick) {
      CheckValid(inputValue?.address, {
        type: "Address",
        error: setErrorEventAddress,
      });
    } else {
      setErrorEventAddress("");
    }
    if (viewProduct?.store_description && submitClick) {
      setErrorDesc("");
    }
  }, [
    selectedVendor,
    inputValue?.EventTitle,
    selectedCategories,
    menSelected,
    womenSelected,
    startDate,
    startTime,
    endDate,
    endTime,
    inputValue?.address,
    StoreDesc?.length,
    startAge,
    endAge,
    viewProduct,
    fileData,
  ]);
  const removeMediaAPIHandler = async (nameToRemove) => {
    Dispatch(LoaderStartMain(true));
    const response = await DeleteFileAPI(
      accessToken,
      extractIdFromUrl(nameToRemove)
    );
    if (response?.status === 200) {
      setFileData((prevFileData) =>
        prevFileData.filter((item) => item?.name !== nameToRemove)
      );
      toast.success(response?.file);
    } else {
      toast.error(response?.file || response?.msg);
    }
    Dispatch(LoaderStartMain(false));
  };
  const handleRemoveImage = async (nameToRemove) => {
    Dispatch(
      setConfirmModal({
        show: "MEDIA",
        callBack: () => removeMediaAPIHandler(nameToRemove),
      })
    );
  };

  return (
    <>
      {showVideoModal?.show === true ? (
        <AddThumbnailModal
          data={showVideoModal}
          setShowVideoModal={setShowVideoModal}
          setFileData={setFileData}
          fileData={fileData}
        />
      ) : (
        <div>
          <div className="breadcrumb ">
            <Link to={ADMIN_EVENT_MANAGEMENT_URL} className="text-black">
              Events
            </Link>
            <span> > </span>
            <Link>
              {" "}
              {Location === "edit-event" ? "Edit Event" : "Add New Event"}
            </Link>
          </div>
          <div className="p-1 h-100 w-100">
            <div className="">
              <section className="dashboard_wrapper">
                <div className="view_details">
                  <Scrollbars
                    style={{ height: "calc(100vh - 153px)" }}
                    className="ScrollbarsWrapper"
                    renderView={(props) => <div {...props} className="view" />}
                  >
                    <div className="row" style={{ margin: "0 auto" }}>
                      <div className="col-md-5">
                        <h6 className="mb-2 col-md-12">
                          Select Vendor Details
                        </h6>

                        <div className="bg-f8 col-md-12 ms-1 row rounded border mb-3">
                          <div className="col-md-12 d-flex  gap-3">
                            <div className="selected-vendor col-md-6  py-2 ">
                              <label
                                className="fs-14px mb-1"
                                htmlFor="selectedVendor"
                              >
                                Select Vendor{" "}
                                <span className="common-blue">*</span>
                              </label>
                              <Select
                                value={selectedVendor}
                                onChange={handleVednorChange}
                                options={vendorListOption}
                                isSearchable
                                placeholder="Select Vendor"
                                name="selectedVendor"
                                isDisabled={Location === "edit-event"}
                              />
                              {errorSelectedVendor && !selectedVendor && (
                                <div className="errormendatorr">
                                  <i className="bi bi-x-circle-fill"></i>
                                  {errorSelectedVendor}
                                </div>
                              )}
                            </div>
                            <div className="col-md-5 px-3 py-1 mb-1">
                              <InputField
                                type={"text"}
                                label={"Vendor Code"}
                                placeholder={"Enter Vendor Code"}
                                value={selectedVendor?.code}
                                className={
                                  errorVendorName !== ""
                                    ? "form-control error-form"
                                    : "form-control"
                                }
                                name="VendorName"
                                errormessage={errorVendorName}
                                readOnly={true}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <InputField
                              type={"text"}
                              value={selectedVendor?.address}
                              label={"Vendor Address"}
                              placeholder={"Enter Vendor Address"}
                              className={
                                errorAddress !== ""
                                  ? "form-control error-form"
                                  : "form-control"
                              }
                              name="Address"
                              onChange={handleChange}
                              onKeyUp={(e) =>
                                CheckValid(e.target.value, {
                                  type: "Address",
                                  error: setErrorAddress,
                                })
                              }
                              onKeyDown={EmptySpaceFieldValid}
                              errormessage={errorAddress}
                              readOnly={true}
                            />
                          </div>
                        </div>

                        <h6>Event Details</h6>
                        <div className="col-md-12 border py-3">
                          <div className="col-md-12 d-flex flex-column  p-2">
                            <label className="fs-14px mb-2">
                              Select Age Range{" "}
                              <span className="common-blue">*</span>
                            </label>
                            <div className="d-flex gap-3">
                              <div className="col-md-4">
                                <Select
                                  value={startAge}
                                  onChange={handleStartAgeChange}
                                  options={ageOptions}
                                  isSearchable
                                  placeholder="Start"
                                  isDisabled={Location === "edit-event"}
                                />
                              </div>
                              <label>To</label>
                              <div className="col-md-4">
                                <Select
                                  value={endAge}
                                  onChange={handleEndAgeChange}
                                  options={ageOptions}
                                  isSearchable
                                  placeholder="End"
                                  isDisabled={Location === "edit-event"}
                                />
                              </div>
                            </div>
                            {errorAge && (
                              <div className="errormendatorr">
                                <i className="bi bi-x-circle-fill"></i>
                                {errorAge}
                              </div>
                            )}
                          </div>
                          <div className="col-md-12 d-flex flex-wrap gap-5 p-2">
                            <div
                              className="d-flex align-items-center gap-2 border br-1rem px-2 py-1"
                              onClick={(e) => {
                                setMenSelected((prev) => !prev);
                              }}
                            >
                              <img
                                src={menIcon}
                                alt="menIcon"
                                className="h-1-5rem w-1-5rem"
                              />{" "}
                              <span
                                className={`${
                                  menSelected ? "text-primary" : "text-black"
                                }`}
                              >
                                Men
                              </span>
                              <i
                                className={`bi bi-check-circle-fill fs-1-3rem ${
                                  menSelected ? "text-primary" : "text-black"
                                }`}
                              ></i>
                            </div>
                            <div
                              className="d-flex align-items-center gap-2 border br-1rem px-2 py-1"
                              onClick={(e) => {
                                setWomenSelected((prev) => !prev);
                              }}
                            >
                              <img
                                src={womenIcon}
                                alt="womenIcon"
                                className="h-1-5rem w-1-5rem"
                              />{" "}
                              <span
                                className={`${
                                  womenSelected ? "text-primary" : "text-black"
                                }`}
                              >
                                Women
                              </span>
                              <i
                                className={`bi bi-check-circle-fill fs-1-3rem ${
                                  womenSelected ? "text-primary" : "text-black"
                                }`}
                              ></i>
                            </div>
                            {errorGender && (
                              <div className="errormendatorr col-md-12 mt--2 mb-0">
                                <i className="bi bi-x-circle-fill"></i>
                                {errorGender}
                              </div>
                            )}
                          </div>
                          <div className="col-md-12 mb-4 mt-2 p-2">
                            <label className="fs-14px mb-2">
                              Categories <span className="common-blue">*</span>
                            </label>
                            <div className="filedaddwraps justify-content-start mb-2">
                              <Select
                                value={selectedCategories}
                                onChange={handleCategoryChange}
                                options={categoryOptions}
                                isSearchable
                                isMulti
                                placeholder="Select Categories"
                              />
                            </div>
                            {errorCategory && (
                              <div className="errormendatorr">
                                <i className="bi bi-x-circle-fill"></i>
                                {errorCategory}
                              </div>
                            )}

                            {selectedCategories.map((category, index) => (
                              <div
                                key={index}
                                className="selected-category ms-2"
                              >
                                <i
                                  className="bi bi-dash-circle text-red"
                                  onClick={() => removeCategory(category)}
                                ></i>
                                <span className="ms-2"> {category.label}</span>
                              </div>
                            ))}
                          </div>
                          <div className="col-md-12 mt-2 p-2">
                            <label className="mb-2 fs-14px">
                              Select State{" "}
                              <span className="common-blue">*</span>
                            </label>
                            <Select
                              value={selectedDubaiState}
                              onChange={handleDubaiStateChange}
                              options={dubaiStatesOptions}
                              isSearchable
                              placeholder="Select State"
                            />
                          </div>
                          <div className="col-md-12">
                            <div className="col-md-12 p-2">
                              <InputField
                                type={"text"}
                                label={"Event Title"}
                                placeholder={"Enter Event Title"}
                                value={EventTitle}
                                className={
                                  errorEventTitle !== ""
                                    ? "form-control error-form"
                                    : "form-control"
                                }
                                name="EventTitle"
                                onChange={handleChange}
                                onKeyUp={(e) =>
                                  CheckValid(e.target.value, {
                                    type: "EventTitle",
                                    error: setErrorEventTitle,
                                  })
                                }
                                onKeyDown={EmptySpaceFieldValid}
                                errormessage={errorEventTitle}
                                required={true}
                                minLength={2}
                                maxLength={250}
                              />
                            </div>

                            <div className="col-md-12 p-2">
                              <label htmlFor="address" className="common-label">
                                Event Address{" "}
                                <span className="common-blue">*</span>
                              </label>
                              {isLoaded && (
                                <StandaloneSearchBox
                                  onLoad={(ref) => (inputRef.current = ref)}
                                  onPlacesChanged={handlePlaceChanged}
                                >
                                  <div className="">
                                    <input
                                      type="text"
                                      placeholder={
                                        inputValue?.address
                                          ? inputValue?.address
                                          : "Enter Event Address"
                                      }
                                      className="form-control form-input text-truncate pl-5"
                                    />
                                    {errorEventAddress &&
                                      !inputValue?.address && (
                                        <div className="errormendatorr">
                                          <i className="bi bi-x-circle-fill"></i>
                                          {errorEventAddress}
                                        </div>
                                      )}
                                  </div>
                                </StandaloneSearchBox>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12 p-2">
                            <label className="col-md-12 fs-14px mb-2">
                              Event Description{" "}
                              <span className="common-blue">*</span>
                            </label>
                            <div className="col-md-12">
                              <Editor
                                editorState={editorState}
                                onEditorStateChange={onEditorChange}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                wrapperStyle={{
                                  width: "100%",
                                  border: "1px solid #ccc",
                                  backgroundColor: "white",
                                  padding: "0px",
                                  borderRadius: "4px",
                                }}
                                editorStyle={{
                                  minHeight: "150px",
                                  border: "1px solid #ccc",
                                  padding: "10px",
                                  borderRadius: "4px",
                                  fontFamily: "sans-serif",
                                }}
                                toolbar={{
                                  options: [
                                    "inline",
                                    "blockType",
                                    "fontSize",
                                    "list",
                                    "textAlign",
                                    "colorPicker",
                                    "link",
                                    "emoji",
                                    "history",
                                  ],
                                  inline: {
                                    inDropdown: false,
                                    options: [
                                      "bold",
                                      "italic",
                                      "underline",
                                      "strikethrough",
                                      "superscript",
                                      "subscript",
                                    ],
                                  },
                                  list: {
                                    inDropdown: false,
                                    options: ["unordered", "ordered"],
                                  },
                                  textAlign: {
                                    inDropdown: true,
                                  },
                                  link: { inDropdown: false },
                                  history: { inDropdown: false },
                                }}
                              />
                            </div>
                            {errorDesc !== "" && (
                              <div className="errormendatorr">
                                <i className="bi bi-x-circle-fill"></i>
                                {errorDesc}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12 mt-3">
                          <h6 className="col-md-12 mb-2">Event Period</h6>
                          <div className="col-md-12 border p-2">
                            <div className="d-flex gap-3 flex-column col-md-12 p-3">
                              <label className="fs-14px">
                                Starting On{" "}
                                <span className="common-blue">*</span>
                              </label>
                              <div className="row flex-wrap gap-3 col-md-12 align-items-center">
                                <div className="col-md-6">
                                  <DatePicker
                                    selected={startDate}
                                    onChange={handleStartDateChange}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Select a date"
                                    minDate={new Date()}
                                  />
                                </div>
                                <div className="col-md-5" id="starting_on">
                                  <TimePicker
                                    name="startTime"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      handleStartTimeChange(newTime)
                                    }
                                    placeholder={startTime}
                                    time={startTime}
                                    timeFormat="HH:MM"
                                    theme="material"
                                    timeMode="24"
                                  />
                                </div>
                              </div>
                              <label className="fs-14px">
                                Expires On{" "}
                                <span className="common-blue">*</span>
                              </label>
                              <div className="row gap-3 col-md-12 align-items-center">
                                <div className="col-md-6">
                                  <DatePicker
                                    selected={endDate}
                                    onChange={handleEndDateChange}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Select a date"
                                    minDate={
                                      startDate
                                        ? new Date(startDate)
                                        : new Date()
                                    }
                                  />
                                </div>
                                <div className="col-md-5" id="starting_on">
                                  <TimePicker
                                    name="endTime"
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      handleEndTimeChange(newTime)
                                    }
                                    placeholder={endTime}
                                    time={endTime}
                                    timeFormat="HH:MM"
                                    theme="material"
                                    timeMode="24"
                                  />
                                </div>
                              </div>
                              {errorEventEnd && (
                                <div className="errormendatorr">
                                  <i className="bi bi-x-circle-fill"></i>
                                  {errorEventEnd}
                                </div>
                              )}
                              {errorEventStart && (
                                <div className="errormendatorr">
                                  <i className="bi bi-x-circle-fill"></i>
                                  {errorEventStart}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-6">
                        <div className="col-md-12">
                          <h6 className="col-md-12 mb-2">
                            Upload Event Media{" "}
                            <span className="common-blue">*</span>
                          </h6>
                          <div className="col-md-12 mt-1">
                            <form
                              className="file-upload-form"
                              id="add-edit-event"
                            >
                              <label
                                htmlFor="file"
                                className="file-upload-label"
                              >
                                <div className="file-upload-design">
                                  <img src={uploadIcon} alt="" />
                                  <p>
                                    <a className="browse-button px-1 py-0 text-primary">
                                      Click to upload
                                    </a>
                                    or drag and drop
                                  </p>
                                  <p className="secondary-text">
                                    Max. File Size: 30MB
                                  </p>
                                </div>
                                <input
                                  id="file"
                                  type="file"
                                  onChange={UploadImage}
                                  accept=".jpg, .jpeg, .png, .mp4, .avi"
                                />
                              </label>
                            </form>
                            {errorMedia && (
                              <div className="errormendatorr">
                                <i className="bi bi-x-circle-fill"></i>
                                {errorMedia}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          {fileData?.length > 0 &&
                            fileData.map((item, index) => (
                              <div
                                key={index}
                                className="col-md-12 my-2 position-relative"
                                id="add-edit-event-media"
                              >
                                <div
                                  className="d-flex  justify-content-end"
                                  style={{ width: "265px", height: "auto" }}
                                >
                                  <img
                                    src={closeIcon}
                                    alt="Close"
                                    className="bi bi-x rounded-circle mt-3 mr-2 w-1-5rem h-1-5rem"
                                    style={{
                                      cursor: "pointer",
                                      zIndex: 12,
                                      marginBottom: "-10px",
                                    }}
                                    onClick={() =>
                                      handleRemoveImage(item?.name)
                                    } // Assuming you have a function to remove the image
                                  />
                                </div>

                                <MediaViewer
                                  media={item?.url?.file_url}
                                  minHeight={"150px"}
                                  maxHeight={"150px"}
                                  minWidth={"252px"}
                                  maxWidth={"252px"}
                                  thumbnail={
                                    item?.url?.gif_url || item?.url?.thumbnail_url || item?.url?.file_url
                                  }
                                />
                              </div>
                            ))}
                        </div>
                        <div className="com-md-12 row">
                          <div className="col-md-6 mt-4 d-flex gap-4">
                            {Location !== "edit-event" && (
                              <button
                                className="btn w-100 pt-1 py-2 px-3 bg-pink text-white"
                                onClick={(e) => {
                                  setEventMode(e);
                                }}
                              >
                                Share with vendor
                              </button>
                            )}
                          </div>
                          <div className="col-md-6 mt-4" onClick={CreateEvent}>
                            <button className="btn w-100 pt-1 py-2 px-3 bg-primary text-white">
                              {Location === "edit-event"
                                ? "Save"
                                : " Create a Event"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Scrollbars>
                </div>
                {/* loader start here */}
                {loaderMain && <CommonLoader />}
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddEditEvent;
