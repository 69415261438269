import React from "react";
import { Pagination } from "react-pagination-bar";
import 'react-pagination-bar/dist/index.css';

const CommonPagination = (props) => {
    const { currentPage, paginationLength, currentFunction } = props;
    console.log("currentPage, paginationLength, currentFunction",currentPage, paginationLength, currentFunction)

    return (
        <div className="paginationCustom">
            <Pagination
                currentPage={parseInt(currentPage)}
                itemsPerPage={parseInt(paginationLength.record_limit)}
                onPageChange={(pageNumber) => currentFunction(pageNumber)}
                totalItems={paginationLength.total_records}
                pageNeighbours={2}
                showPrevNext={true}
                prevLabel={<span className="">&#8592; Previous</span>}  
                nextLabel={<span className="">Next &#8594;</span>} 
            />
        </div>
    );
}

export default CommonPagination;
