/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import CommonToggle from "../Common/CommonToggle";
import { useNavigate } from "react-router-dom";
import {
  ADD_FAQ_MANAGEMENT_URL,
  VIEW_FAQ_MANAGEMENT_URL,
  EDIT_FAQ_MANAGEMENT_URL,
  truncateString,
} from "../Shared/constant";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import CommonewModel from "../Common/CommonewModel";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const FAQManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const { selfInfo, faqList, device_id } = useSelector(
    (state) => state.adminReducers
  );
  const Navigate = useNavigate();
  const Dispatch = useDispatch();
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState("");
  const [userLimit, setUserLimit] = useState("");
  const [currentPage, setCurrentPage] = useState(
    sessionStorage.getItem("currentPage") || 1
  );
  const [showModalNew, setShowModalNew] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  useEffect(() => {
    setCurrentPage(1);
  }, [userLimit]);
  const [GetFilterCategoryIds, setGetFilterCategoryIds] = useState("");
  const [GetFilterLanguage, setGetFilterLanguage] = useState("");

  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  // clear search box
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  // select user type
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
    setCurrentPage(1);
  };

  // add new vendor
  const AddVendorFnct = () => {
    Navigate(ADD_FAQ_MANAGEMENT_URL);
  };
  useEffect(() => {
    Dispatch(
      ClearFormSetFutios({
        url: "",
        action: false,
      })
    );
  }, []);

  // get product list
  useEffect(() => {
    let paramRequest = {
      limit: userLimit || 10,
      page: currentPage || 1,
      user_type: selfInfo?.user_type || "admin",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => {
      if (elm[1] !== "") {
        return elm;
      }
    });
    let param = {
      transmit: "single",
      url: "faq_listing",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, [
    userSearch,
    userType,
    userLimit,
    currentPage,
    GetFilterLanguage,
    websocket,
  ]);

  const ResetFnct = () => {
    setUserSearch("");
    setUserType("");
    setUserLimit(10);
    setCurrentPage(1);
    setGetFilterLanguage("");
  };

  const DeleteFAQFnct = (data_) => {
    setShowModalNew({
      ...showModalNew,
      open: !showModalNew?.open,
      subtitle: "Delete FAQ",
      description: `Are you sure you want to delete this FAQ <h6 class="text-small mt-3 fs-16px">
       ${data_?.question}
      </h6>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
      closeText: "No",
      icon: "binIcon",
    });
  };
  const FAQActive = (user) => {
    // setShowModalNew({
    //   ...showModalNew,
    //   open: !showModalNew?.open,
    //   subtitle: "Active User",
    //   description: `Are you sure you want to ${booleancheck ? "remove " : ""} ${type.split("_")[1]} to this user <h6 class="text-small mt-3 fs-16px">${data_?.username}</h6>`,
    //   modalType: "not form",
    //   button: "Yes",
    //   Data: data_,
    //   closeText: "No",
    //   icon: "vendorAI"
    // });
    sessionStorage.setItem("currentPage", currentPage);
    Navigate(`${EDIT_FAQ_MANAGEMENT_URL}/${user?.id}`);
  };

  return (
    <section className="dashboard_wrapper p-0">
      <div className="filter_sectio">
        <div className="col-md-3" hidden>
          <div className="form-group">
            <React.Fragment>
              {userSearch !== undefined && (
                <div
                  className="searchBoxwithbtn text-left"
                  style={{ width: "100%" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    value={userSearch}
                    onChange={(e) => {
                      setUserSearch(e.target.value);
                      setCurrentPage(1);
                    }}
                    placeholder={`Search by business or vendor code`}
                    title="Search by business or vendor code"
                    maxLength={50}
                  />
                  {userSearch === "" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-search"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                      <path d="M21 21l-6 -6"></path>
                    </svg>
                  ) : (
                    <i
                      className="bi bi-x-circle-fill searchclear"
                      onClick={() => ClearSearchFiled()}
                    ></i>
                  )}
                </div>
              )}
            </React.Fragment>
          </div>
        </div>
        <div className="row  d-flex justify-content-end">
          <div className="col-md-auto">
            <div className="form-group">
              <select
                className="form-control userLimitselect"
                style={{ width: "100%" }}
                value={userLimit}
                onChange={(e) => setUserLimit(parseInt(e.target.value))}
              >
                <option value="" selected disabled>
                  Records
                </option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="60">60</option>
                <option value="70">70</option>
                <option value="80">80</option>
                <option value="90">90</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>

          <div className="col-md-2" hidden>
            <div className="form-group">
              <label>Status</label>
              <select
                className="form-control activestatusselet"
                style={{ width: "100%" }}
                value={userType}
                onChange={(e) => SelectUserTypeFnct(e)}
              >
                <option value="All">Select Status</option>

                <option value="all">All</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
          </div>
          {/* <div className="col-md-3">
                    <div className="form-group">
                        <label>Category</label>
                        <select className="form-control activestatusselet" value={GetFilterCategoryIds} onChange={(e) => setGetFilterCategoryIds(e.target.value)} style={{ width: "100%" }}>
                            <option value="all" selected >Select Category</option>
                            {categoryAllList.map((elm,index)=>{
                                return(<option  value={elm.id} key={index}>{elm.name}</option>)
                            })}
                        </select>
                    </div>
                </div> */}

          <div
            className="col-md-2"
            style={{ width: "auto", paddingRight: "0px" }}
          >
            <div className="form-group" hidden>
              <label>&nbsp;</label>
              <button
                className="btn head_reset_btn"
                style={{ marginLeft: "0px" }}
                onClick={() => ResetFnct()}
              >
                <i className="bi bi-x-circle"></i>
                Reset
              </button>
            </div>
          </div>
          <div className="col-md-2" style={{ width: "auto" }}>
            <div className="form-group">
              <button
                className="btn head_cmn_btn"
                style={{ marginLeft: "0px" }}
                onClick={() => AddVendorFnct()}
              >
                + Add New Question{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tableContent"
        style={{
          height:
            window.screen.width > 540
              ? "calc(100vh - 255px)"
              : "calc(100vh - 143px)",
        }}
      >
        <Scrollbars
          style={{
            height:
              parseInt(faqList?.pagination?.total_records) > 10
                ? window.screen.width < 767
                  ? "calc(100% - 10px)"
                  : "calc(100% - -38px)"
                : "calc(100% - 0px)",
          }}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Question</th>
                  <th>Answer</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {faqList &&
                  faqList?.list?.map((user, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: truncateString(user?.question, 150),
                              }}
                            />
                          </td>
                          <td>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: truncateString(user?.answer, 150),
                              }}
                            />
                          </td>
                          <td className="respoactionSupplier">
                            {(selfInfo?.user_type === "admin" ||
                              selfInfo?.permissions?.includes(
                                "update-category"
                              )) && (
                              <button
                                type="button"
                                className="btn actiontblebtn edit-btn"
                                onClick={() => FAQActive(user)}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                            )}
                            {(selfInfo?.user_type === "admin" ||
                              selfInfo?.permissions?.includes(
                                "delete-category"
                              )) && (
                              <button
                                type="button"
                                className="btn actiontblebtn delete-btn"
                                onClick={() => DeleteFAQFnct(user)}
                              >
                                <i className="bi bi-trash3"></i>
                              </button>
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                {faqList && faqList?.list?.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={10}>
                      FAQ Not Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {/* pagination */}
        {parseInt(faqList?.pagination?.total_records) > 10 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={faqList?.pagination}
            currentFunction={currentFunction}
          />
        )}
      </div>
      {showModalNew?.open && (
        <CommonewModel
          setCurrentPage={setCurrentPage}
          setShowModalNew={setShowModalNew}
          showModalNew={showModalNew}
        />
      )}
    </section>
  );
};

export default FAQManagement;
