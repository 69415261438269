/* global google */

import React, { useEffect, useRef } from "react";

export const UserDonutChart = (props) => {
  console.log("props", props);
  const chartContainerRef = useRef();

  useEffect(() => {
    google.charts.load("current", { packages: ["corechart"] });
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      const data = google.visualization.arrayToDataTable([
        ["User Status", "Count", { role: "style" }],
        ["Active", props?.active, "#FF5B5B"],
        ["Inactive", props?.inactive, "#FFE6E6"],
      ]);

      const options = {
        title: `Total Users ${props?.total}`,
        titlePosition: "out",
        pieHole: 0.4,
        legend: {
          position: "bottom", // Set legend position to bottom
          alignment: "center", // Align legend to the center
        },
      };

      const chart = new google.visualization.PieChart(
        chartContainerRef.current
      );

      chart.draw(data, options);
    }
  }, [props?.total,props?.inactive,props?.active]);

  return <div ref={chartContainerRef} className="h-100 w-100 rounded" />;
};
export const VendorDonutChart = (props) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    google.charts.load("current", { packages: ["corechart"] });
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      const data = google.visualization.arrayToDataTable([
        ["Vendor Status", "Count", { role: "style" }],
        ["Active", props?.active, "#FF69B4"],
        ["Inactive", props?.inactive, "#FED5D5"],
      ]);

      const options = {
        title: `Total Vendors ${props?.total}`,
        titlePosition: "out",
        pieHole: 0.4,
        legend: {
          position: "bottom", // Set legend position to bottom
          alignment: "center", // Align legend to the center
        },
      };

      const chart = new google.visualization.PieChart(
        chartContainerRef.current
      );

      chart.draw(data, options);
    }
  }, [props?.total,props?.inactive,props?.active]);

  return <div ref={chartContainerRef} className="h-100 w-100 rounded" />;
};
export const TotalAmountEarnedAreaChart = () => {
  const chartContainerRef = useRef();
  useEffect(() => {
    google.charts.load("current", { packages: ["corechart"] });
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      const data = new google.visualization.DataTable();
      data.addColumn("number", "X");
      data.addColumn("number", "Total Amount Earned 234 AED");

      // Replace with your actual data
      data.addRows([
        [0, 300], // Lower starting point
        [1, 400], // Slight increase
        [2, 500], // Moderate increase
        [3, 600], // Further increase
        [4, 650], // Gentle peak
        [5, 600], // Gradual decrease
        [6, 500], // Moderate decrease
        [7, 400], // Slow decrease
        [8, 350], // Lower ending point
      ]);

      const options = {
        title: "Total Amount Earned 234 AED",
        curveType: "function",
        legend: "none", // Hide legend
        vAxis: {
          gridlines: {
            color: "transparent", // Hide vertical gridlines
          },
          baselineColor: "transparent", // Hide baseline
          ticks: [], // Remove tick marks
          minorTicks: [], // Remove minor tick marks
          textStyle: {
            color: "transparent", // Hide tick labels
          },
        },
        hAxis: {
          gridlines: {
            color: "transparent", // Hide horizontal gridlines
          },
          baselineColor: "transparent", // Hide baseline
          ticks: [], // Remove tick marks
          minorTicks: [], // Remove minor tick marks
          textStyle: {
            color: "transparent", // Hide tick labels
          },
        },
        colors: ["#407BFF"], // Red with 40% transparency
        series: {
          0: { lineWidth: 2 }, // Adjust line width as needed
        },
      };

      const chart = new google.visualization.AreaChart(
        chartContainerRef.current
      );

      chart.draw(data, options);
    }
  }, []);

  return (
    <div
      ref={chartContainerRef}
      className="br-1rem bg-white"
      style={{ width: "100%", height: "400px" }}
    />
  );
};


export const TotalCoinRedemptionChart = (props) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    google.charts.load("current", { packages: ["corechart"] });
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      const data = new google.visualization.DataTable();
      data.addColumn("string", "Day");
      data.addColumn("number", "");
      data.addColumn("number", "");

      let chartData = [];
      let totalCount = 0;

      if (props?.data && Object.keys(props.data).length > 0) {
        chartData = Object.entries(props.data).map(
          ([day, registrations]) => [day, registrations, null]
        );

        const currentDate = new Date();
        const currentDay = currentDate.getDay();
        const currentMonth = currentDate.getMonth();

        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const monthNames = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];

        if (props.count === "week") {
          const lastSevenDays = [];
          for (let i = 0; i < 7; i++) {
            const dayIndex = (currentDay - i + 7) % 7;
            lastSevenDays.push(dayNames[dayIndex]);
          }

          chartData = chartData
            .filter(dataPoint => lastSevenDays.includes(dataPoint[0]))
            .sort((a, b) => lastSevenDays.indexOf(a[0]) - lastSevenDays.indexOf(b[0]));
        } else if (props.count === "half_year") {
          const lastSixMonths = [];
          for (let i = 0; i < 6; i++) {
            const monthIndex = (currentMonth - i + 12) % 12;
            lastSixMonths.push(monthNames[monthIndex]);
          }

          chartData = chartData
            .filter(dataPoint => lastSixMonths.includes(dataPoint[0]))
            .sort((a, b) => lastSixMonths.indexOf(a[0]) - lastSixMonths.indexOf(b[0]));
        } else if (props.count === "year") {
          const lastTwelveMonths = [];
          for (let i = 0; i < 12; i++) {
            const monthIndex = (currentMonth - i + 12) % 12;
            lastTwelveMonths.push(monthNames[monthIndex]);
          }

          chartData = chartData
            .filter(dataPoint => lastTwelveMonths.includes(dataPoint[0]))
            .sort((a, b) => lastTwelveMonths.indexOf(a[0]) - lastTwelveMonths.indexOf(b[0]));
        }

        totalCount = chartData.reduce(
          (acc, [_, registrations]) => acc + registrations,
          0
        );
      } else {
        chartData = [["", 0, 0]];
        totalCount = 0;
      }

      data.addRows(chartData.reverse());

      const maxRegistration = Math.max(
        ...chartData.map(([_, registrations]) => registrations)
      );

      const options = {
        title: `Total Coin Redemption - ${totalCount}`,
        curveType: "function",
        legend: "none",
        vAxis: {
          baselineColor: "transparent",
          textStyle: {
            color: "black",
          },
          title: "Coins Transaction",
          viewWindow: {
            min: 0,
          },
          ticks: Array.from(
            { length: Math.ceil(maxRegistration / 200) + 1 },
            (_, i) => i * 200
          ),
        },
        hAxis: {
          title: "",
          slantedText: true,
          slantedTextAngle: 45,
        },
        colors: ["#F41283", "transparent"],
        series: {
          0: { lineWidth: 2 },
          1: { type: "line", lineWidth: 1 },
        },
        chartArea: {
          left: 50,
          right: 20,
          top: 50,
          bottom: 120,
          width: "90%",
          height: "70%",
          background: {
            fill: "#F4F4F4",
            opacity: 0.2,
          },
        },
      };

      const chart = new google.visualization.LineChart(
        chartContainerRef.current
      );

      chart.draw(data, options);
    }
  }, [props.data, props.count]);

  return (
    <div ref={chartContainerRef} style={{ width: "100%", height: "400px" }} />
  );
};




export const MountainChart = (props) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (!google.visualization) return; // Exit early if Google Charts not loaded

    google.charts.load("current", { packages: ["corechart"] });
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      const data = new google.visualization.DataTable();
      data.addColumn("string", "Day");
      data.addColumn("number", "");

      let chartData = [];

      if (props.data) {
        chartData = Object.entries(props.data).map(([day, registrations]) => [
          day,
          registrations,
        ]);

        const currentDate = new Date();
        const currentDay = currentDate.getDay(); // 0-based index (0 is Sunday, 6 is Saturday)
        const currentMonth = currentDate.getMonth(); // 0-based index (0 is January, 11 is December)

        // List of day names in the correct order
        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        // List of month names in the correct order
        const monthNames = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];

        if (props.count === "week") {
          // Get the last 7 days in reverse order
          const lastSevenDays = [];
          for (let i = 0; i < 7; i++) {
            const dayIndex = (currentDay - i + 7) % 7;
            lastSevenDays.push(dayNames[dayIndex]);
          }

          // Filter and sort chartData based on the last 7 days
          chartData = chartData
            .filter(dataPoint => lastSevenDays.includes(dataPoint[0]))
            .sort((a, b) => lastSevenDays.indexOf(a[0]) - lastSevenDays.indexOf(b[0]));
        } else if (props.count === "half_year") {
          // Get the last 6 months in reverse order
          const lastSixMonths = [];
          for (let i = 0; i < 6; i++) {
            const monthIndex = (currentMonth - i + 12) % 12;
            lastSixMonths.push(monthNames[monthIndex]);
          }

          // Filter and sort chartData based on the last 6 months
          chartData = chartData
            .filter(dataPoint => lastSixMonths.includes(dataPoint[0]))
            .sort((a, b) => lastSixMonths.indexOf(a[0]) - lastSixMonths.indexOf(b[0]));
        } else if (props.count === "year") {
          // Get the last 12 months in reverse order
          const lastTwelveMonths = [];
          for (let i = 0; i < 12; i++) {
            const monthIndex = (currentMonth - i + 12) % 12;
            lastTwelveMonths.push(monthNames[monthIndex]);
          }

          // Filter and sort chartData based on the last 12 months
          chartData = chartData
            .filter(dataPoint => lastTwelveMonths.includes(dataPoint[0]))
            .sort((a, b) => lastTwelveMonths.indexOf(a[0]) - lastTwelveMonths.indexOf(b[0]));
        }
      }

      data.addRows(chartData.reverse());

      const totalCount = chartData.reduce(
        (acc, [_, registrations]) => acc + registrations,
        0
      );

      const options = {
        title: `New Registration - ${totalCount}`,
        curveType: "function",
        legend: "none", // Hide legend
        vAxis: {
          title: "Registrations",
          viewWindow: {
            min: 0,
          },
          format: '0', // Display axis labels as integers
        },
        colors: ["#6EC8EF"], // Blue with 40% transparency
        chartArea: {
          left: 50,
          right: 50,
        },
        hAxis: {
          title: "", // No hAxis title
          slantedText: true, // Rotate the labels
          slantedTextAngle: 45, // Rotate by 45 degrees
        },
      };

      const chart = new google.visualization.LineChart(
        chartContainerRef.current
      );

      chart.draw(data, options);
    }
  }, [props.data, props.count]);

  return (
    <div ref={chartContainerRef} style={{ width: "100%", height: "400px" }} />
  );
};




export const RevenueLineChart = (props) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    // Load Google Charts
    google.charts.load("current", { packages: ["corechart"] });
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      const data = new google.visualization.DataTable();
      data.addColumn("string", "");
      data.addColumn("number", "Event");
      data.addColumn("number", "Offer");

      let chartData = [];
      let totalRevenue = 0;

      if (props?.data && (props?.data?.offer?.all_time_units || props?.data?.event?.all_time_units)) {
        const eventChartData = props?.data?.event?.all_time_units ? 
          Object.entries(props?.data?.event?.all_time_units).map(([period, earnings]) => [period, earnings]) : [];
        const offerChartData = props?.data?.offer?.all_time_units ? 
          Object.entries(props?.data?.offer?.all_time_units).map(([period, earnings]) => [period, earnings]) : [];

        // Merge data for event and offer into a single array
        chartData = eventChartData.map((eventPeriod, index) => [
          eventPeriod[0], // Time period
          eventPeriod[1], // Event earnings
          offerChartData[index] ? offerChartData[index][1] : null, // Offer earnings (if available)
        ]);

        const currentDate = new Date();
        const currentDay = currentDate.getDay(); // 0-based index (0 is Sunday, 6 is Saturday)

        // List of day names in the correct order
        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        if (props.count === "half_year") {
          const currentMonth = currentDate.getMonth(); // 0-based index
          const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
          ];
          const lastSixMonths = [];
          for (let i = 0; i < 6; i++) {
            const monthIndex = (currentMonth - i + 12) % 12;
            lastSixMonths.push(monthNames[monthIndex]);
          }
          chartData = chartData
            .filter(dataPoint => lastSixMonths.includes(dataPoint[0]))
            .sort((a, b) => lastSixMonths.indexOf(b[0]) - lastSixMonths.indexOf(a[0]));
        } else if (props.count === "year") {
          const currentMonth = currentDate.getMonth(); // 0-based index
          const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
          ];
          const lastTwelveMonths = [];
          for (let i = 0; i < 12; i++) {
            const monthIndex = (currentMonth - i + 12) % 12;
            lastTwelveMonths.push(monthNames[monthIndex]);
          }
          chartData = chartData
            .filter(dataPoint => lastTwelveMonths.includes(dataPoint[0]))
            .sort((a, b) => lastTwelveMonths.indexOf(b[0]) - lastTwelveMonths.indexOf(a[0]));
        } else if (props.count === "week") {
          // Get the last 7 days in reverse order
          const lastSevenDays = [];
          for (let i = 0; i < 7; i++) {
            const dayIndex = (currentDay - i + 7) % 7;
            lastSevenDays.push(dayNames[dayIndex]);
          }

          // Filter and sort chartData based on the last 7 days
          chartData = chartData
            .filter(dataPoint => lastSevenDays.includes(dataPoint[0]))
            .sort((a, b) => lastSevenDays.indexOf(b[0]) - lastSevenDays.indexOf(a[0]));
        }

        totalRevenue = chartData.reduce(
          (total, [, eventEarnings, offerEarnings]) => {
            return total + (eventEarnings || 0) + (offerEarnings || 0);
          },
          0
        );
      } else {
        // Default data when no data is available
        chartData = [["", 0, 0]];
        totalRevenue = 0;
      }

      data.addRows(chartData);

      // Find maximum revenue
      const maxRevenue = Math.max(
        ...chartData
          .map(([_, eventEarnings, offerEarnings]) => [
            eventEarnings || 0,
            offerEarnings || 0,
          ])
          .flat()
      );

      // Calculate the maximum tick value rounded up to the nearest 200
      let maxTickValue = Math.ceil(maxRevenue / 200) * 200;

      // If maxTickValue is less than or equal to 200, set it to 1000
      if (maxTickValue <= 200) {
        maxTickValue = 1000;
      }

      // Generate ticks on intervals of 200 up to the maximum tick value
      const ticks = Array.from(
        { length: maxTickValue / 200 + 1 },
        (_, i) => i * 200
      );

      const options = {
        title: `Payment Revenue ${totalRevenue} AED`,
        curveType: "function",
        tooltip: { trigger: "hover" }, // Set tooltip trigger to "hover"
        legend: { position: "bottom" }, // Legend at bottom
        vAxis: {
          title: "Amount (AED)",
          viewWindow: {
            min: 0,
          },
          ticks: ticks, // Dynamic ticks
        },
        hAxis: {
          slantedText: true, // Rotate the labels
          slantedTextAngle: 45, // Rotate by 45 degrees
        },
        colors: ["#FF861A", "#285EF1"], // Blue for event, Orange for offer
        series: {
          0: { color: "#285EF1", labelInLegend: "Event" }, // Blue color for Event
          1: { color: "#FF861A", labelInLegend: "Offer" }, // Orange color for Offer
        },
        selectionMode: "single",
        chartArea: {
          left: 50, // Adjust the left margin as needed
          right: 30, // Adjust the right margin as needed
          top: 50, // Adjust the top margin as needed
          bottom: 120, // Adjust the bottom margin as needed
        },
      };

      const chart = new google.visualization.LineChart(
        chartContainerRef.current
      );
      chart.draw(data, options);
    }
  }, [props?.data, props?.count]);

  return (
    <div
      ref={chartContainerRef}
      className="br-1rem bg-white custom-width-calculate-for-graph"
      style={{ width: "100%", height: "400px" }}
    />
  );
};







// Vendor DashBoard Charts
export const VendorChart1 = (props) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    google.charts.load("current", { packages: ["corechart"] });
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      const data = google.visualization.arrayToDataTable([
        ["Option", "Count", { role: "style" }],
        ["Yes", props?.yes, "#29CC39"],
        ["No", props?.no, "#FF6633"],
        ["Maybe", props?.maybe, "#8833FF"],
      ]);

      const options = {
        titlePosition: "out",
        pieHole: 0.4,
        legend: {
          position: "bottom",
          alignment: "center",
        },
      };

      const chart = new google.visualization.PieChart(
        chartContainerRef.current
      );

      chart.draw(data, options);
    }
  }, [props.yes, props.no, props.maybe]); // Include props.yes, props.no, props.maybe in the dependency array.

  return <div ref={chartContainerRef} style={{ height: "250px" }} />;
};

export const VendorChart2 = (props) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    google.charts.load("current", { packages: ["corechart"] });
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      const data = google.visualization.arrayToDataTable([
        ["Option", "Count", { role: "style" }],
        ["Notify Users", 30, "#FFDB5A"], // 30% for Yes
        ["Visited", 70, "#D257E6"], // 70% for No
      ]);

      const options = {
        titlePosition: "out",
        pieHole: 0.4,
        legend: {
          position: "bottom", // Set legend position to bottom
          alignment: "center", // Align legend to the center
        },
      };

      const chart = new google.visualization.PieChart(
        chartContainerRef.current
      );

      chart.draw(data, options);
    }
  }, [props?.count]); // Include props.count in the dependency array if it's used in the chart title or options.

  return (
    <div ref={chartContainerRef} className="" style={{ height: "250px" }} />
  );
};
