/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from "react-custom-scrollbars-2";
import CommonToggle from "../Common/CommonToggle";
import moment from "moment";
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";
import CommonModel from "../Common/CommonModel";
import { Tab, Tabs } from "react-bootstrap";
import {
  RatingStars,
  ADMIN_VIEW_NOTIFICATION_MANAGEMENT_URL,
  ADMIN_VIEW_ADMIN_EVENT_MANAGEMENT_URL,
  ADMIN_VIEW_ADMIN_OFFER_MANAGEMENT_URL,
} from "../Shared/constant";
import { useLocation, useNavigate } from "react-router-dom";
import MediaViewer from "../Common/MediaViewer";
import AvatarGet from "../Common/AvatarGet";
import MediaModal from "../Common/MediaModal";
import { Collapse, Image } from "react-bootstrap";
import MoreIcon from "../../assets/img/more_options.png";
import { truncateString } from "../Shared/constant";
const ApprovalManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const { selfInfo, approvalDataList } = useSelector(
    (state) => state.adminReducers
  );
  const Navigate = useNavigate();
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState("");
  const [userLimit, setUserLimit] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  const [key, setKey] = useState("offer");

  const location = useLocation();
  const state = location.state;
  useEffect(()=>{
    if (state?.payload?.length) {
      console.log("redirectionstate",state?.payload)
      if(state?.payload === "Approval - Video"){
        setKey("video")
      }else if(state?.payload === "Approval - Review"){
        setKey("review")
      }else if(state?.payload === "Approval - Photo"){
        setKey("image")
      }else if(state?.payload === "Approval - Offer"){
        setKey("offer")
      }else if(state?.payload === "Approval - Event"){
        setKey("event")
      }
    }
  },[])

  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  // clear search box
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  // select user type
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
    setCurrentPage(1);
  };

  // get supplier list
  useEffect(() => {
    let param = "";
    param = {
      transmit: "single",
      url: "approval_listing",
      request: {
        limit: userLimit || 10,
        page: currentPage || 1,
        type: key,
      },
    };

    wsSend_request(websocket, param);
  }, [userSearch, userType, userLimit, currentPage, key,websocket]);

  const EditCategory = (data_, type, title) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Approval Success",
      subtitle: "Approval Success",
      description: `Do you really want to approve this ${type} <h5>${title}</h5>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
      type: type,
    });
  };

  // delete category
  const DeleteCategoryFnct = (data_, type, title) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Decline Approval",
      subtitle: "Decline Approval",
      description: `Do you really want to decline this ${type} <h5>${title}</h5>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
      type: type,
    });
  };

  // active supplier

  const [showMediaModal, setShowMediaModal] = useState({
    show: false,
    media: "",
    thumbnail: "",
    minWidth: "200px",
    maxWidth: "90%",
    minHeight: "200px",
    maxHeight: "60%",
  });
  console.log("showMediaModal", showMediaModal);

  const changeKey = (k) => {
    setKey(k);
    setCurrentPage(1);
    setUserLimit("");
  };
  const [activeTableDetails, setActiveTableDetails] = useState("");

  const ViewMoreDetails = (id) => {
    setActiveTableDetails(id);
  };

  // hide more user details
  const ElseMoreDetails = () => {
    setActiveTableDetails("");
  };
  return (
    <section className="dashboard_wrapper rounded h-96 common-border p-0">
      <div className="d-none">
        <span className="m1-2"> 2.9</span> <RatingStars rating={2.9} />
      </div>
      {/* <MediaViewer media={media} /> */}
      {showMediaModal?.show === true ? (
        <>
          <MediaModal
            data={showMediaModal}
            setShowMediaModal={setShowMediaModal}
          />
          {/* <MediaViewer
            media={showMediaModal?.media}
            thumbnail={showMediaModal?.thumbnail}
            minHeight={"200px"}
            minWidth={"200px"}
            maxHeight={"60%"}
            maxWidth={"80%"}
          /> */}
        </>
      ) : (
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => changeKey(k)}
          className="mb-3 ps-3"
        >
          <Tab eventKey="offer" title="Offers">
            <DataTableFilter
              // filterType={"By Category"}
              // userSearch={userSearch}
              // setUserSearch={setUserSearch}
              // userType={userType}
              // setUserType={setUserType}
              userLimit={userLimit}
              setUserLimit={setUserLimit}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              // ClearSearchFiled={ClearSearchFiled}
              // SelectUserTypeFnct={SelectUserTypeFnct}
              // buttonvisible={
              //   selfInfo?.user_type === "admin" ||
              //   selfInfo?.permissions?.includes("add-category")
              // }
              // ButtonSet={{
              //   buttontitle: "Add New Category",
              //   function: AddCategoryFnct,
              // }}
            />
            <div
              className="tableContent"
              style={{
                height:
                  window.screen.width > 540
                    ? "calc(100vh - 155px)"
                    : "calc(100vh - 143px)",
              }}
            >
              <Scrollbars
                style={{
                  height:
                    approvalDataList?.pagination?.total_records > 10
                      ? window.screen.width < 767
                        ? "calc(100% - 10px)"
                        : "calc(100% - 80px)"
                      : "calc(100% - 78px)",
                }}
                className="ScrollbarsWrapper"
                renderView={(props) => <div {...props} className="view" />}
              >
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Business Name</th>
                        <th>Vendor Code</th>
                        <th>Offer Code</th>
                        <th>Offer Title</th>
                        <th>Start Date & Time</th>
                        <th>End Date & Time</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {approvalDataList &&
                        approvalDataList?.list?.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td>{user?.business_name}</td>
                              <td>{user?.vendor_code}</td>
                              <td>{user?.offer_code}</td>
                              <td>{user?.offer_title}</td>
                              <td>
                                {moment(user.offer_start_time).format(
                                  "YYYY-MM-DD HH:mm:A"
                                )}
                              </td>
                              <td>
                                {moment(user.offer_end_time).format(
                                  "YYYY-MM-DD HH:mm:A"
                                )}
                              </td>
                              <td className="respoactionSupplier d-flex">
                                {selfInfo?.user_type === "admin" && (
                                  <>
                                    <button
                                      type="button"
                                      className="btn actiontblebtn view-btn"
                                      onClick={() =>
                                        Navigate(
                                          `${ADMIN_VIEW_ADMIN_OFFER_MANAGEMENT_URL}/${user?.id}`
                                        )
                                      }
                                    >
                                      <i className="bi bi-eye"></i>
                                    </button>

                                    <button
                                      type="button"
                                      className="btn actiontblebtn common-blue-bg px-2 py-1 fs-14px fw-500"
                                      onClick={() =>
                                        EditCategory(
                                          user,
                                          "offer",
                                          user?.offer_title
                                        )
                                      }
                                    >
                                      Approve
                                    </button>

                                    <button
                                      type="button"
                                      className="btn actiontblebtn delete-btn px-2 py-1 fs-14px fw-500"
                                      onClick={() =>
                                        DeleteCategoryFnct(
                                          user,
                                          "offer",
                                          user?.offer_title
                                        )
                                      }
                                    >
                                      Decline
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      {approvalDataList &&
                        approvalDataList?.list.length === 0 && (
                          <tr>
                            <td style={{ textAlign: "center" }} colSpan={8}>
                              Approval Not Found !
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </Scrollbars>
              {/* pagination */}
              {approvalDataList?.pagination?.total_records > 10 && (
                <CommonPagination
                  currentPage={currentPage}
                  paginationLength={approvalDataList?.pagination}
                  currentFunction={currentFunction}
                />
              )}
            </div>
          </Tab>
          <Tab eventKey="event" title="Events">
            <DataTableFilter
              userLimit={userLimit}
              setUserLimit={setUserLimit}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            <div
              className="tableContent"
              style={{
                height:
                  window.screen.width > 540
                    ? "calc(100vh - 155px)"
                    : "calc(100vh - 143px)",
              }}
            >
              <Scrollbars
                style={{
                  height:
                    approvalDataList?.pagination?.total_records > 10
                      ? window.screen.width < 767
                        ? "calc(100% - 10px)"
                        : "calc(100% - 80px)"
                      : "calc(100% - 78px)",
                }}
                className="ScrollbarsWrapper"
                renderView={(props) => <div {...props} className="view" />}
              >
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Business Name</th>
                        <th>Vendor Code</th>
                        <th>Event Code</th>
                        <th>Event Title</th>
                        <th>Start Date & Time</th>
                        <th>End Date & Time</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {approvalDataList &&
                        approvalDataList?.list?.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td>{user?.business_name}</td>
                              <td>{user?.vendor_code}</td>
                              <td>{user?.event_code}</td>
                              <td>{user?.event_title}</td>
                              <td>
                                {moment(user.event_start_time).format(
                                  "YYYY-MM-DD HH:mm:A"
                                )}
                              </td>
                              <td>
                                {moment(user.event_end_time).format(
                                  "YYYY-MM-DD HH:mm:A"
                                )}
                              </td>
                              <td className="respoactionSupplier d-flex">
                                {selfInfo?.user_type === "admin" && (
                                  <>
                                    <button
                                      type="button"
                                      className="btn actiontblebtn view-btn"
                                      onClick={() =>
                                        Navigate(
                                          `${ADMIN_VIEW_ADMIN_EVENT_MANAGEMENT_URL}/${user?.id}`
                                        )
                                      }
                                    >
                                      <i className="bi bi-eye"></i>
                                    </button>

                                    <button
                                      type="button"
                                      className="btn actiontblebtn common-blue-bg px-2 py-1 fs-14px fw-500"
                                      onClick={() =>
                                        EditCategory(
                                          user,
                                          "event",
                                          user?.event_title
                                        )
                                      }
                                    >
                                      Approve
                                    </button>

                                    <button
                                      type="button"
                                      className="btn actiontblebtn delete-btn px-2 py-1 fs-14px fw-500"
                                      onClick={() =>
                                        DeleteCategoryFnct(
                                          user,
                                          "event",
                                          user?.event_title
                                        )
                                      }
                                    >
                                      Decline
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      {approvalDataList &&
                        approvalDataList?.list.length === 0 && (
                          <tr>
                            <td style={{ textAlign: "center" }} colSpan={8}>
                              Events Not Found !
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </Scrollbars>
              {/* pagination */}
              {approvalDataList?.pagination?.total_records > 10 && (
                <CommonPagination
                  currentPage={currentPage}
                  paginationLength={approvalDataList?.pagination}
                  currentFunction={currentFunction}
                />
              )}
            </div>
          </Tab>
          <Tab eventKey="image" title="Photos">
            <DataTableFilter
              userLimit={userLimit}
              setUserLimit={setUserLimit}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            <div
              className="tableContent"
              style={{
                height:
                  window.screen.width > 540
                    ? "calc(100vh - 155px)"
                    : "calc(100vh - 143px)",
              }}
            >
              <Scrollbars
                style={{
                  height:
                    approvalDataList?.pagination?.total_records > 10
                      ? window.screen.width < 767
                        ? "calc(100% - 10px)"
                        : "calc(100% - 80px)"
                      : "calc(100% - 78px)",
                }}
                className="ScrollbarsWrapper"
                renderView={(props) => <div {...props} className="view" />}
              >
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Code</th>

                        <th>Created At</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {approvalDataList &&
                        approvalDataList?.list?.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td id="list-avatar">
                                <AvatarGet
                                  type="product"
                                  url={user?.url?.thumbnail_url}
                                />{" "}
                              </td>{" "}
                              <td>{user?.username}</td>
                              <td>{user?.usercode}</td>
                              <td>{moment(user.created_at).format("LL")}</td>
                              <td className="respoactionSupplier d-flex">
                                {selfInfo?.user_type === "admin" && (
                                  <>
                                    <button
                                      type="button"
                                      className="btn actiontblebtn view-btn"
                                      onClick={() =>
                                        // Navigate(
                                        //   `${ADMIN_VIEW_NOTIFICATION_MANAGEMENT_URL}/`
                                        // )
                                        setShowMediaModal({
                                          show: true,
                                          media: user?.url?.file_url,
                                        })
                                      }
                                    >
                                      <i className="bi bi-eye"></i>
                                    </button>

                                    <button
                                      type="button"
                                      className="btn actiontblebtn common-blue-bg px-2 py-1 fs-14px fw-500"
                                      onClick={() =>
                                        EditCategory(user, "image", "Image")
                                      }
                                    >
                                      Approve
                                    </button>

                                    <button
                                      type="button"
                                      className="btn actiontblebtn delete-btn px-2 py-1 fs-14px fw-500"
                                      onClick={() =>
                                        DeleteCategoryFnct(
                                          user,
                                          "image",
                                          "Image"
                                        )
                                      }
                                    >
                                      Decline
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      {approvalDataList &&
                        approvalDataList?.list.length === 0 && (
                          <tr>
                            <td style={{ textAlign: "center" }} colSpan={8}>
                              Photos Not Found !
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </Scrollbars>
              {/* pagination */}
              {approvalDataList?.pagination?.total_records > 10 && (
                <CommonPagination
                  currentPage={currentPage}
                  paginationLength={approvalDataList?.pagination}
                  currentFunction={currentFunction}
                />
              )}
            </div>
          </Tab>
          <Tab eventKey="video" title="Videos">
            <DataTableFilter
              userLimit={userLimit}
              setUserLimit={setUserLimit}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            <div
              className="tableContent"
              style={{
                height:
                  window.screen.width > 540
                    ? "calc(100vh - 155px)"
                    : "calc(100vh - 143px)",
              }}
            >
              <Scrollbars
                style={{
                  height:
                    approvalDataList?.pagination?.total_records > 10
                      ? window.screen.width < 767
                        ? "calc(100% - 10px)"
                        : "calc(100% - 80px)"
                      : "calc(100% - 78px)",
                }}
                className="ScrollbarsWrapper"
                renderView={(props) => <div {...props} className="view" />}
              >
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Video</th>
                        <th>Name</th>
                        <th>Code</th>

                        <th>Created At</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {approvalDataList &&
                        approvalDataList?.list?.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td id="list-avatar">
                                <AvatarGet
                                  type="product"
                                  url={user?.parent_url?.thumbnail_url}
                                />{" "}
                              </td>{" "}
                              <td>{user?.username}</td>
                              <td>{user?.usercode}</td>
                              <td>{moment(user.created_at).format("LL")}</td>
                              <td className="respoactionSupplier d-flex">
                                {selfInfo?.user_type === "admin" && (
                                  <>
                                    <button
                                      type="button"
                                      className="btn actiontblebtn view-btn"
                                      onClick={() =>
                                        setShowMediaModal({
                                          show: true,
                                          media: user?.url?.file_url,
                                        })
                                      }
                                    >
                                      <i className="bi bi-eye"></i>
                                    </button>

                                    <button
                                      type="button"
                                      className="btn actiontblebtn common-blue-bg px-2 py-1 fs-14px fw-500"
                                      onClick={() =>
                                        EditCategory(user, "video", "Video")
                                      }
                                    >
                                      Approve
                                    </button>

                                    <button
                                      type="button"
                                      className="btn actiontblebtn delete-btn px-2 py-1 fs-14px fw-500"
                                      onClick={() =>
                                        DeleteCategoryFnct(
                                          user,
                                          "video",
                                          "Video"
                                        )
                                      }
                                    >
                                      Decline
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      {approvalDataList &&
                        approvalDataList?.list.length === 0 && (
                          <tr>
                            <td style={{ textAlign: "center" }} colSpan={8}>
                              Videos Not Found !
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </Scrollbars>
              {/* pagination */}
              {approvalDataList?.pagination?.total_records > 10 && (
                <CommonPagination
                  currentPage={currentPage}
                  paginationLength={approvalDataList?.pagination}
                  currentFunction={currentFunction}
                />
              )}
            </div>
          </Tab>
          <Tab eventKey="review" title="Review & Ratings">
            <DataTableFilter
              userLimit={userLimit}
              setUserLimit={setUserLimit}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            <div
              className="tableContent"
              style={{
                height:
                  window.screen.width > 540
                    ? "calc(100vh - 155px)"
                    : "calc(100vh - 143px)",
              }}
            >
              <Scrollbars
                style={{
                  height:
                    approvalDataList?.pagination?.total_records > 10
                      ? window.screen.width < 767
                        ? "calc(100% - 10px)"
                        : "calc(100% - 80px)"
                      : "calc(100% - 78px)",
                }}
                className="ScrollbarsWrapper"
                renderView={(props) => <div {...props} className="view" />}
              >
                <div className="table-responsive">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th>User Profile</th>
                        <th>User Code</th>
                        <th>Business Name</th>
                        <th>Vendor Code</th>
                        <th>Created At</th>

                        <th>Review</th>
                        <th></th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {approvalDataList &&
                        approvalDataList?.list?.map((user, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr
                                key={index}
                                className={
                                  activeTableDetails === user?.id
                                    ? "active"
                                    : ""
                                }
                              >
                                <td id="list-avatar">
                                  <AvatarGet
                                    type="product"
                                    url={user?.vendor_logo?.thumbnail_url}
                                  />{" "}
                                </td>{" "}
                                <td>{user?.usercode}</td>
                                <td>{user?.business_name}</td>
                                <td>{user?.vendor_code}</td>
                                <td>{moment(user.created_at).format("LL")}</td>
                                <td>
                                  {user?.review
                                    ? truncateString(user?.review, 30)
                                    : "-"}
                                </td>
                                <td style={{ width: "10px" }}>
                                  <button
                                    className={
                                      activeTableDetails === user?.id
                                        ? "btn actionbtn active"
                                        : "btn actionbtn"
                                    }
                                    onClick={() =>
                                      activeTableDetails === user?.id
                                        ? ElseMoreDetails()
                                        : ViewMoreDetails(user?.id)
                                    }
                                    aria-controls="example-collapse-text"
                                    aria-expanded={
                                      activeTableDetails === user?.id
                                        ? true
                                        : false
                                    }
                                  >
                                    <Image src={MoreIcon} alt="more icon" />
                                  </button>
                                </td>
                                <td className="respoactionSupplier d-flex">
                                  {selfInfo?.user_type === "admin" && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn actiontblebtn common-blue-bg px-2 py-1 fs-14px fw-500"
                                        onClick={() =>
                                          EditCategory(
                                            user,
                                            "review",
                                            user?.review
                                          )
                                        }
                                      >
                                        Approve
                                      </button>

                                      <button
                                        type="button"
                                        className="btn actiontblebtn delete-btn px-2 py-1 fs-14px fw-500"
                                        onClick={() =>
                                          DeleteCategoryFnct(
                                            user,
                                            "review",
                                            user?.review
                                          )
                                        }
                                      >
                                        Decline
                                      </button>
                                    </>
                                  )}
                                </td>
                              </tr>
                              {activeTableDetails && (
                                <tr className="tablecollapsewraps">
                                  <td colSpan="7">
                                    <Collapse
                                      in={activeTableDetails === user?.id}
                                    >
                                      <div>
                                        <table className="table">
                                          <tbody>
                                            <tr>
                                              <th style={{ width: "20%" }}>
                                                Review
                                              </th>
                                              <td>
                                                {user?.review === null
                                                  ? "-"
                                                  : user?.review}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </Collapse>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })}
                      {approvalDataList &&
                        approvalDataList?.list.length === 0 && (
                          <tr>
                            <td style={{ textAlign: "center" }} colSpan={8}>
                              Review and Ratings Not Found !
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </Scrollbars>
              {/* pagination */}
              {approvalDataList?.pagination?.total_records > 10 && (
                <CommonPagination
                  currentPage={currentPage}
                  paginationLength={approvalDataList?.pagination}
                  currentFunction={currentFunction}
                />
              )}
            </div>
          </Tab>
        </Tabs>
      )}

      {showModal?.open && (
        <CommonModel
          setUserSearch={setUserSearch}
          setCurrentPage={setCurrentPage}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </section>
  );
};

export default ApprovalManagement;
