export const ActionTypes = {
  IS_LOGIN: "IS_LOGIN",
  GET_USER_ID: "GET_USER_ID",
  GET_DEVICE_ID: "GET_DEVICE_ID",
  SELF_DETAILS: "SELF_DETAILS",
  EDIT_SELF_DETAILS: "EDIT_SELF_DETAILS",
  GET_ACCESSTOKEN: "GET_ACCESSTOKEN",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",
  GET_PERMISSION_LIST: "GET_PERMISSION_LIST",
  GET_USER_LIST: "GET_USER_LIST",
  GET_USER_LIST_ID_WISE: "GET_USER_LIST_ID_WISE",
  ADD_USER_LIST: "ADD_USER_LIST",
  EDIT_USER_LIST: "EDIT_USER_LIST",
  DELETE_USER: "DELETE_USER",
  GET_BRAND_LIST: "GET_BRAND_LIST",
  ADD_NEW_BRAND: "ADD_NEW_BRAND",
  EDIT_BRAND: "EDIT_BRAND",
  DELETE_BRAND: "DELETE_BRAND",
  GET_MODEL_LIST: "GET_MODEL_LIST",
  ADD_NEW_MODEL: "ADD_NEW_MODEL",
  EDIT_MODEL: "EDIT_MODEL",
  DELETE_MODEL: "DELETE_MODEL",
  GET_BRAND_MODELS_LIST: "GET_BRAND_MODELS_LIST",
  GET_SUPPLIER_LIST: "GET_SUPPLIER_LIST",
  GET_PRODUCT_LIST: "GET_PRODUCT_LIST",
  GET_EVENT_LIST: "GET_EVENT_LIST",
  GET_VENDOR_EVENT_LIST: "GET_VENDOR_EVENT_LIST",
  GET_OFFER_LIST: "GET_OFFER_LIST",
  GET_VENDOR_OFFER_LIST: "GET_VENDOR_OFFER_LIST",
  ADD_SUPPLIER_LIST: "ADD_SUPPLIER_LIST",
  UPDATE_SUPPLIER_LIST: "UPDATE_SUPPLIER_LIST",
  GET_ALL_BRANDS_LIST: "GET_ALL_BRANDS_LIST",
  GET_ALL_MODEL_LIST: "GET_ALL_MODEL_LIST",
  SELECT_BRAND_GET_MODEL_LIST: "SELECT_BRAND_GET_MODEL_LIST",
  ADD_PRODUCT_LIST: "ADD_PRODUCT_LIST",
  UPDATE_PRODUCT_LIST: "UPDATE_PRODUCT_LIST",
  VIEW_PRODUCT_DETAILS: "VIEW_PRODUCT_DETAILS",
  ADD_OFFER_LIST: "ADD_OFFER_LIST",
  UPDATE_OFFER_LIST: "UPDATE_OFFER_LIST",
  VIEW_OFFER_DETAILS: "VIEW_OFFER_DETAILS",
  ADD_VENDOR_OFFER_LIST: "ADD_VENDOR_OFFER_LIST",
  UPDATE_VENDOR_OFFER_LIST: "UPDATE_VENDOR_OFFER_LIST",
  VIEW_VENDOR_OFFER_DETAILS: "VIEW_VENDOR_OFFER_DETAILS",
  ADD_EVENT_LIST: "ADD_EVENT_LIST",
  UPDATE_EVENT_LIST: "UPDATE_EVENT_LIST",
  VIEW_EVENT_DETAILS: "VIEW_EVENT_DETAILS",
  ADD_VENDOR_EVENT_LIST: "ADD_VENDOR_EVENT_LIST",
  UPDATE_VENDOR_EVENT_LIST: "UPDATE_VENDOR_EVENT_LIST",
  VIEW_VENDOR_EVENT_DETAILS: "VIEW_VENDOR_EVENT_DETAILS",
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_ADD_TO_CART: "REMOVE_ADD_TO_CART",
  GET_SUPPLIER_DETAILS: "GET_SUPPLIER_DETAILS",
  DELETE_SUPPLIER: "DELETE_SUPPLIER",
  LOADER_MAIN_START: "LOADER_MAIN_START",
  GET_ALL_SUPPLIERS_LIST: "GET_ALL_SUPPLIERS_LIST",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  GET_ALL_PRODUCT_LIST: "GET_ALL_PRODUCT_LIST",
  DELETE_OFFER: "DELETE_OFFER",
  GET_ALL_OFFER_LIST: "GET_ALL_OFFER_LIST",
  DELETE_VENDOR_OFFER: "DELETE_VENDOR_OFFER",
  GET_ALL_VENDOR_OFFER_LIST: "GET_ALL_VENDOR_OFFER_LIST",
  DELETE_EVENT: "DELETE_EVENT",
  GET_ALL_EVENT_LIST: "GET_ALL_EVENT_LIST",
  DELETE_VENDOR_EVENT: "DELETE_VENDOR_EVENT",
  GET_ALL_VENDOR_EVENT_LIST: "GET_ALL_VENDOR_EVENT_LIST",
  GET_DASHBOARD_DETAILS: "GET_DASHBOARD_DETAILS",
  ADD_CART_MULTIPLE_QUANTITY: "ADD_CART_MULTIPLE_QUANTITY",
  REMOVE_CART_MULTIPLE_QUANTITY: "REMOVE_CART_MULTIPLE_QUANTITY",
  GET_SETTING_COIN_EARNING_LIST: "GET_SETTING_COIN_EARNING_LIST",
  GET_SETTING_BADGE_LIST: "GET_SETTING_BADGE_LIST",
  GET_SETTING_MCR_LIST: "GET_SETTING_MCR_LIST",
  GET_CATEGORY_LIST: "GET_CATEGORY_LIST",
  GET_SUB_CATEGORY_LIST: "GET_SUB_CATEGORY_LIST",
  ADD_CATEGORY_LIST: "ADD_CATEGORY_LIST",
  ADD_SUB_CATEGORY_LIST: "ADD_SUB_CATEGORY_LIST",
  ADD_SETTING_COIN_EARNING_LIST: "ADD_SETTING_COIN_EARNING_LIST",
  ADD_SETTING_BADGE_LIST: "ADD_SETTING_BADGE_LIST",
  ADD_SETTING_MCR_LIST: "ADD_SETTING_MCR_LIST",
  UPDATE_CATEGORY_LIST: "UPDATE_CATEGORY_LIST",
  UPDATE_SUB_CATEGORY_LIST: "UPDATE_SUB_CATEGORY_LIST",
  UPDATE_SETTING_COIN_EARNING_LIST: "UPDATE_SETTING_COIN_EARNING_LIST",
  UPDATE_SETTING_BADGE_LIST: "UPDATE_SETTING_BADGE_LIST",
  UPDATE_SETTING_MCR_LIST: "UPDATE_SETTING_MCR_LIST",
  DELETE_CATEGORY_LIST: "DELETE_CATEGORY_LIST",
  DELETE_SUB_CATEGORY_LIST: "DELETE_SUB_CATEGORY_LIST",
  DELETE_SETTING_COIN_EARNING_LIST: "DELETE_SETTING_COIN_EARNING_LIST",
  DELETE_SETTING_BADGE_LIST: "DELETE_SETTING_BADGE_LIST",
  DELETE_SETTING_MCR_LIST: "DELETE_SETTING_MCR_LIST",
  GET_ALL_USER_CATEGORY_LIST: "GET_ALL_USER_CATEGORY_LIST",
  GET_ALL_CATEGORY_LIST: "GET_ALL_CATEGORY_LIST",
  GET_ALL_DETAIL_CATEGORY_LIST:"GET_ALL_DETAIL_CATEGORY_LIST",
  GET_ALL_SUB_CATEGORY_LIST: "GET_ALL_SUB_CATEGORY_LIST",
  GET_ALL_SETTING_COIN_EARNING_LIST: "GET_ALL_SETTING_COIN_EARNING_LIST",
  GET_ALL_SETTING_BADGE_LIST: "GET_ALL_SETTING_BADGE_LIST",
  GET_ALL_SETTING_MCR_LIST: "GET_ALL_SETTING_MCR_LIST",
  CLEAR_FORM_SET_FUCT: "CLEAR_FORM_SET_FUCT",
  GET_SETTINGS_LIST: "GET_SETTINGS_LIST",
  ADD_SETTINGS_LIST: "ADD_SETTINGS_LIST",
  DELETE_SETTINGS_LIST: "DELETE_SETTINGS_LIST",
  UPDATE_SETTINGS_LIST: "UPDATE_SETTINGS_LIST",
  SELECT_CURRENT_ADDED_BRAND: "SELECT_CURRENT_ADDED_BRAND",
  SELECT_CURRENT_ADDED_BRAND_MODEL: "SELECT_CURRENT_ADDED_BRAND_MODEL",
  GET_SELECTED_CURRENT_BRAND_IDS: "GET_SELECTED_CURRENT_BRAND_IDS",
  SELECT_CURRENT_ADDED_CATEGORY: "SELECT_CURRENT_ADDED_CATEGORY",
  SELECT_CURRENT_ADDED_SUPPLIER: "SELECT_CURRENT_ADDED_SUPPLIER",
  GET_ALL_FAQ_LIST: "GET_ALL_FAQ_LIST",
  GET_FAQ_LIST: "GET_FAQ_LIST",
  GET_NOTIFICATION_LIST: "GET_NOTIFICATION_LIST",
  UPDATE_FAQ_LIST: "UPDATE_FAQ_LIST",
  ADD_FAQ_LIST: "ADD_FAQ_LIST",
  DELETE_FAQ: "DELETE_FAQ",
  GET_SUPPORT_LIST: "GET_SUPPORT_LIST",
  APPROVAL_OFFER_LIST: "APPROVAL_OFFER_LIST",
  APPROVAL_DATA_LIST: "APPROVAL_DATA_LIST",
  GET_PAYMENT_LIST: "GET_PAYMENT_LIST",
  GET_ALL_VENDOR_OFFER_RESPONSE_LIST: "GET_ALL_VENDOR_OFFER_RESPONSE_LIST",
  GET_VENDOR_OFFER_RESPONSE_LIST: "GET_VENDOR_OFFER_RESPONSE_LIST",
  GET_ALL_VENDOR_EVENT_RESPONSE_LIST: "GET_ALL_VENDOR_EVENT_RESPONSE_LIST",
  GET_VENDOR_EVENT_RESPONSE_LIST: "GET_VENDOR_EVENT_RESPONSE_LIST",
  SUCCESS_MODAL_DATA: "SUCCESS_MODAL_DATA",
  PAYMENT_MODAL_DATA: "PAYMENT_MODAL_DATA",
  GET_VENDOR_PAYMENT_LIST: "GET_VENDOR_PAYMENT_LIST",
  GET_VENDOR_CT_LIST: "GET_VENDOR_CT_LIST",
  GET_VENDOR_SUPPORT_LIST: "GET_VENDOR_SUPPORT_LIST",
  GET_VENDOR_VIEW_OFFER_RESPONSE_LIST: "GET_VENDOR_VIEW_OFFER_RESPONSE_LIST",
  GET_VENDOR_VIEW_EVENT_RESPONSE_LIST: "GET_VENDOR_VIEW_EVENT_RESPONSE_LIST",
  GET_VENDOR_VIEW_GALLERY_LIST: "GET_VENDOR_VIEW_GALLERY_LIST",
  GET_VENDOR_STORE_GALLERY_LIST: "GET_VENDOR_STORE_GALLERY_LIST",
  GET_ALL_LIVE_MAP_USER_LIST: "GET_ALL_LIVE_MAP_USER_LIST",
  GET_VENDOR_VIEW_TC_LIST: "GET_VENDOR_VIEW_TC_LIST",
  GET_VENDOR_VIEW_REVIEW_LIST: "GET_VENDOR_VIEW_REVIEW_LIST",
  DASHBOARD_COIN_EARNING_LIST: "DASHBOARD_COIN_EARNING_LIST",
  DASHBOARD_REFERRAL_LIST: "DASHBOARD_REFERRAL_LIST",
  DASHBOARD_MOST_WHISHLIST_LIST: "DASHBOARD_MOST_WHISHLIST_LIST",
  DASHBOARD_MOST_LIKED_LIST: "DASHBOARD_MOST_LIKED_LIST",
  DASHBOARD_MOST_SUCCESS_EVENT_LIST: "DASHBOARD_MOST_SUCCESS_EVENT_LIST",
  DASHBOARD_MOST_SUCCESS_OFFER_LIST: "DASHBOARD_MOST_SUCCESS_OFFER_LIST",
  GET_DASHBOARD_UR_DETAILS: "GET_DASHBOARD_UR_DETAILS",
  GET_DASHBOARD_TR_DETAILS: "GET_DASHBOARD_TR_DETAILS",
  GET_DASHBOARD_PR_DETAILS: "GET_DASHBOARD_PR_DETAILS",
  CONFIRM_MODAL:"CONFIRM_MODAL",
  SET_STORE_FIREBASE_DATA:"SET_STORE_FIREBASE_DATA",
};
export const setStoreFirebaseData = (payload) =>{
  return {
      type:ActionTypes?.SET_STORE_FIREBASE_DATA,
      payload:payload,
  }
}
export const setConfirmModal = (status) => {
  return {
    type: ActionTypes.CONFIRM_MODAL,
    payload: status,
  };
};

export const checkLogin = (status) => {
  return {
    type: ActionTypes.IS_LOGIN,
    payload: status,
  };
};

export const getUserId = (id) => {
  return {
    type: ActionTypes.GET_USER_ID,
    payload: id,
  };
};

export const getDeviceId = (id) => {
  return {
    type: ActionTypes.GET_DEVICE_ID,
    payload: id,
  };
};

export const DeleteUser = (user) => {
  return {
    type: ActionTypes.DELETE_USER,
    payload: user,
  };
};

export const ClearFormSetFutios = (date_) => {
  return {
    type: ActionTypes.CLEAR_FORM_SET_FUCT,
    payload: date_,
  };
};

export const getDashBoardDetails = (dashboard_details) => {
  return {
    type: ActionTypes.GET_DASHBOARD_DETAILS,
    payload: dashboard_details,
  };
};
export const getDashBoardTRDetails = (dashboard_details) => {
  return {
    type: ActionTypes.GET_DASHBOARD_TR_DETAILS,
    payload: dashboard_details,
  };
};
export const getDashBoardPRDetails = (dashboard_details) => {
  return {
    type: ActionTypes.GET_DASHBOARD_PR_DETAILS,
    payload: dashboard_details,
  };
};
export const getDashBoardURDetails = (dashboard_details) => {
  return {
    type: ActionTypes.GET_DASHBOARD_UR_DETAILS,
    payload: dashboard_details,
  };
};

export const ViewProjectDetails = (project) => {
  return {
    type: ActionTypes.VIEW_PRODUCT_DETAILS,
    payload: project,
  };
};

export const LoaderStartMain = (loader) => {
  return {
    type: ActionTypes.LOADER_MAIN_START,
    payload: loader,
  };
};

export const getSelfDetails = (user) => {
  return {
    type: ActionTypes.SELF_DETAILS,
    payload: user,
  };
};

export const getUserList = (list) => {
  return {
    type: ActionTypes.GET_USER_LIST,
    payload: list,
  };
};

export const getUserListIdWise = (list) => {
  return {
    type: ActionTypes.GET_USER_LIST_ID_WISE,
    payload: list,
  };
};

export const getAllBrandList = (list) => {
  return {
    type: ActionTypes.GET_ALL_BRANDS_LIST,
    payload: list,
  };
};

export const getAllSupportList = (list) => {
  return {
    type: ActionTypes.GET_SUPPORT_LIST,
    payload: list,
  };
};
export const getAllVendorSupportList = (list) => {
  return {
    type: ActionTypes.GET_VENDOR_SUPPORT_LIST,
    payload: list,
  };
};

export const getCategoryList = (list) => {
  return {
    type: ActionTypes.GET_CATEGORY_LIST,
    payload: list,
  };
};

export const getAllCategoryList = (list) => {
  return {
    type: ActionTypes.GET_ALL_CATEGORY_LIST,
    payload: list,
  };
};
export const getAllDetailCategoryList = (list) => {
  return {
    type: ActionTypes.GET_ALL_DETAIL_CATEGORY_LIST,
    payload: list,
  };
};

export const addCategoryList = (list) => {
  return {
    type: ActionTypes.ADD_CATEGORY_LIST,
    payload: list,
  };
};

export const updateCategoryList = (list) => {
  return {
    type: ActionTypes.UPDATE_CATEGORY_LIST,
    payload: list,
  };
};

export const deleteCategoryList = (list) => {
  return {
    type: ActionTypes.DELETE_CATEGORY_LIST,
    payload: list,
  };
};

export const getApprovalDataList = (list) => {
  return {
    type: ActionTypes.APPROVAL_DATA_LIST,
    payload: list,
  };
};

export const getDashboardMostSuccessOfferList = (list) => {
  return {
    type: ActionTypes.DASHBOARD_MOST_SUCCESS_OFFER_LIST,
    payload: list,
  };
};

export const getDashboardMostSuccessEventList = (list) => {
  return {
    type: ActionTypes.DASHBOARD_MOST_SUCCESS_EVENT_LIST,
    payload: list,
  };
};

export const getDashboardMostLikedList = (list) => {
  return {
    type: ActionTypes.DASHBOARD_MOST_LIKED_LIST,
    payload: list,
  };
};
export const getDashboardMostWhishList = (list) => {
  return {
    type: ActionTypes.DASHBOARD_MOST_WHISHLIST_LIST,
    payload: list,
  };
};
export const getDashboardReferralList = (list) => {
  return {
    type: ActionTypes.DASHBOARD_REFERRAL_LIST,
    payload: list,
  };
};

export const getDashboardCoinEarningList = (list) => {
  return {
    type: ActionTypes.DASHBOARD_COIN_EARNING_LIST,
    payload: list,
  };
};

export const getSettingCoinEarningList = (list) => {
  return {
    type: ActionTypes.GET_SETTING_COIN_EARNING_LIST,
    payload: list,
  };
};
export const getSettingBadgeList = (list) => {
  return {
    type: ActionTypes.GET_SETTING_BADGE_LIST,
    payload: list,
  };
};
export const getSettingMCRList = (list) => {
  return {
    type: ActionTypes.GET_SETTING_MCR_LIST,
    payload: list,
  };
};
export const getSubCategoryList = (list) => {
  return {
    type: ActionTypes.GET_SUB_CATEGORY_LIST,
    payload: list,
  };
};
export const getVendorOfferResponseList = (list) => {
  return {
    type: ActionTypes.GET_VENDOR_OFFER_RESPONSE_LIST,
    payload: list,
  };
};
export const getVendorViewGalleryList = (list) => {
  return {
    type: ActionTypes.GET_VENDOR_VIEW_GALLERY_LIST,
    payload: list,
  };
};
export const getVendorViewReviewList = (list) => {
  return {
    type: ActionTypes.GET_VENDOR_VIEW_REVIEW_LIST,
    payload: list,
  };
};
export const getVendorViewTCList = (list) => {
  return {
    type: ActionTypes.GET_VENDOR_VIEW_TC_LIST,
    payload: list,
  };
};
export const getVendorStoreGalleryList = (list) => {
  return {
    type: ActionTypes.GET_VENDOR_STORE_GALLERY_LIST,
    payload: list,
  };
};
export const getViewPagesResponseList = (list) => {
  return {
    type: ActionTypes.GET_VENDOR_VIEW_OFFER_RESPONSE_LIST,
    payload: list,
  };
};
export const getVendorViewEventResponseList = (list) => {
  return {
    type: ActionTypes.GET_VENDOR_VIEW_EVENT_RESPONSE_LIST,
    payload: list,
  };
};
export const getAllVendorOfferResponseList = (list) => {
  return {
    type: ActionTypes.GET_ALL_VENDOR_OFFER_RESPONSE_LIST,
    payload: list,
  };
};
export const getVendorEventResponseList = (list) => {
  return {
    type: ActionTypes.GET_VENDOR_EVENT_RESPONSE_LIST,
    payload: list,
  };
};
export const getAllVendorEventResponseList = (list) => {
  return {
    type: ActionTypes.GET_ALL_VENDOR_EVENT_RESPONSE_LIST,
    payload: list,
  };
};
export const getAllUserCategoryList = (list) => {
  return {
    type: ActionTypes.GET_ALL_USER_CATEGORY_LIST,
    payload: list,
  };
};

export const getAllSubCategoryList = (list) => {
  return {
    type: ActionTypes.GET_ALL_SUB_CATEGORY_LIST,
    payload: list,
  };
};
export const getAllSettingCoinEarningList = (list) => {
  return {
    type: ActionTypes.GET_ALL_SETTING_COIN_EARNING_LIST,
    payload: list,
  };
};
export const getAllSettingBadgeList = (list) => {
  return {
    type: ActionTypes.GET_ALL_SETTING_BADGE_LIST,
    payload: list,
  };
};
export const getAllSettingMCRList = (list) => {
  return {
    type: ActionTypes.GET_ALL_SETTING_MCR_LIST,
    payload: list,
  };
};

export const addSubCategoryList = (list) => {
  return {
    type: ActionTypes.ADD_SUB_CATEGORY_LIST,
    payload: list,
  };
};
export const addSettingCoinEarningList = (list) => {
  return {
    type: ActionTypes.ADD_SETTING_COIN_EARNING_LIST,
    payload: list,
  };
};
export const addSettingBadgeList = (list) => {
  return {
    type: ActionTypes.ADD_SETTING_BADGE_LIST,
    payload: list,
  };
};
export const addSettingMCRList = (list) => {
  return {
    type: ActionTypes.ADD_SETTING_MCR_LIST,
    payload: list,
  };
};

export const updateSubCategoryList = (list) => {
  return {
    type: ActionTypes.UPDATE_SUB_CATEGORY_LIST,
    payload: list,
  };
};
export const updateSettingCoinEarningList = (list) => {
  return {
    type: ActionTypes.UPDATE_SETTING_COIN_EARNING_LIST,
    payload: list,
  };
};
export const updateSettingBadgeList = (list) => {
  return {
    type: ActionTypes.UPDATE_SETTING_BADGE_LIST,
    payload: list,
  };
};
export const updateSettingMCRList = (list) => {
  return {
    type: ActionTypes.UPDATE_SETTING_MCR_LIST,
    payload: list,
  };
};

export const deleteSubCategoryList = (list) => {
  return {
    type: ActionTypes.DELETE_SUB_CATEGORY_LIST,
    payload: list,
  };
};
export const deleteSettingCoinEarningList = (list) => {
  return {
    type: ActionTypes.DELETE_SETTING_COIN_EARNING_LIST,
    payload: list,
  };
};
export const deleteSettingBadgeList = (list) => {
  return {
    type: ActionTypes.DELETE_SETTING_BADGE_LIST,
    payload: list,
  };
};
export const deleteSettingMCRList = (list) => {
  return {
    type: ActionTypes.DELETE_SETTING_MCR_LIST,
    payload: list,
  };
};

export const getAllModelList = (list) => {
  return {
    type: ActionTypes.GET_ALL_MODEL_LIST,
    payload: list,
  };
};

export const getBrandList = (list) => {
  return {
    type: ActionTypes.GET_BRAND_LIST,
    payload: list,
  };
};

export const addNewBrand = (brandlist) => {
  return {
    type: ActionTypes.ADD_NEW_BRAND,
    payload: brandlist,
  };
};

export const editBrand = (brandlist) => {
  return {
    type: ActionTypes.EDIT_BRAND,
    payload: brandlist,
  };
};

export const editModel = (modellist) => {
  return {
    type: ActionTypes.EDIT_MODEL,
    payload: modellist,
  };
};

export const getBrandsWiseModelList = (list) => {
  return {
    type: ActionTypes.SELECT_BRAND_GET_MODEL_LIST,
    payload: list,
  };
};

export const deleteBrand = (brands) => {
  return {
    type: ActionTypes.DELETE_BRAND,
    payload: brands,
  };
};

export const deleteModel = (model) => {
  return {
    type: ActionTypes.DELETE_MODEL,
    payload: model,
  };
};

export const getModelList = (list) => {
  return {
    type: ActionTypes.GET_MODEL_LIST,
    payload: list,
  };
};

export const addNewModel = (brandlist) => {
  return {
    type: ActionTypes.ADD_NEW_MODEL,
    payload: brandlist,
  };
};

export const GetBrandModels = (brandsmodel) => {
  return {
    type: ActionTypes.GET_BRAND_MODELS_LIST,
    payload: brandsmodel,
  };
};

export const addUserList = (list) => {
  return {
    type: ActionTypes.ADD_USER_LIST,
    payload: list,
  };
};

export const editUserList = (list) => {
  return {
    type: ActionTypes.EDIT_USER_LIST,
    payload: list,
  };
};

export const getPermissionList = (permission) => {
  return {
    type: ActionTypes.GET_PERMISSION_LIST,
    payload: permission,
  };
};

export const getAccessToken = (token) => {
  return {
    type: ActionTypes.GET_ACCESSTOKEN,
    payload: token,
  };
};

export const getSupplierList = (list) => {
  return {
    type: ActionTypes.GET_SUPPLIER_LIST,
    payload: list,
  };
};

export const getSupplierAllList = (list) => {
  return {
    type: ActionTypes.GET_ALL_SUPPLIERS_LIST,
    payload: list,
  };
};

export const addSupplierList = (supplier) => {
  return {
    type: ActionTypes.ADD_SUPPLIER_LIST,
    payload: supplier,
  };
};

export const UpdateSupplierList = (supplier) => {
  return {
    type: ActionTypes.UPDATE_SUPPLIER_LIST,
    payload: supplier,
  };
};

export const getSupplierDetails = (details) => {
  return {
    type: ActionTypes.GET_SUPPLIER_DETAILS,
    payload: details,
  };
};

export const editSelfDetails = (user) => {
  return {
    type: ActionTypes.EDIT_SELF_DETAILS,
    payload: user,
  };
};

export const getProductList = (product) => {
  return {
    type: ActionTypes.GET_PRODUCT_LIST,
    payload: product,
  };
};
export const getEventList = (product) => {
  return {
    type: ActionTypes.GET_EVENT_LIST,
    payload: product,
  };
};

export const getAllEventList = (product) => {
  return {
    type: ActionTypes.GET_ALL_EVENT_LIST,
    payload: product,
  };
};

export const getVendorEventList = (list) => {
  // return {
  //   type: ActionTypes.GET_VENDOR_EVENT_LIST,
  //   payload: product,
  return {
    type: ActionTypes.GET_VENDOR_EVENT_LIST,
    payload: list,
    // };
  };
};

export const getAllVendorEventList = (product) => {
  return {
    type: ActionTypes.GET_ALL_VENDOR_EVENT_LIST,
    payload: product,
  };
};

export const getOfferList = (product) => {
  return {
    type: ActionTypes.GET_OFFER_LIST,
    payload: product,
  };
};

export const getAllOfferList = (product) => {
  return {
    type: ActionTypes.GET_ALL_OFFER_LIST,
    payload: product,
  };
};
export const liveMapUsersList = (product) => {
  return {
    type: ActionTypes.GET_ALL_LIVE_MAP_USER_LIST,
    payload: product,
  };
};

export const getVendorOfferList = (product) => {
  return {
    type: ActionTypes.GET_VENDOR_OFFER_LIST,
    payload: product,
  };
};

export const getAllVendorOfferList = (product) => {
  return {
    type: ActionTypes.GET_ALL_VENDOR_OFFER_LIST,
    payload: product,
  };
};

export const getAllProductList = (product) => {
  return {
    type: ActionTypes.GET_ALL_PRODUCT_LIST,
    payload: product,
  };
};

export const addVendorList = (product) => {
  return {
    type: ActionTypes.ADD_PRODUCT_LIST,
    payload: product,
  };
};
export const setShowSuccessModal = (payload, data) => {
  const updatedPayload = {
    ...payload,
    show: payload ? payload.show : false, // Assuming default value of 'show' is false
    data: data ? data : payload, // If 'data' is provided, use it, otherwise use the original payload
  };

  return {
    type: ActionTypes.SUCCESS_MODAL_DATA,
    payload: updatedPayload,
  };
};

export const setShowPaymentModal = (payload, data) => {
  const updatedPayload = {
    ...payload,
    show: payload ? payload.show : false, // Assuming default value of 'show' is false
    data: data ? data : payload, // If 'data' is provided, use it, otherwise use the original payload
  };

  return {
    type: ActionTypes.PAYMENT_MODAL_DATA,
    payload: updatedPayload,
  };
};

export const addVendorEventList = (product) => {
  return {
    type: ActionTypes.ADD_VENDOR_EVENT_LIST,
    payload: product,
  };
};

export const addEventList = (product) => {
  return {
    type: ActionTypes.ADD_EVENT_LIST,
    payload: product,
  };
};
export const addOfferList = (product) => {
  return {
    type: ActionTypes.ADD_OFFER_LIST,
    payload: product,
  };
};
export const addVendorOfferList = (product) => {
  return {
    type: ActionTypes.ADD_VENDOR_OFFER_LIST,
    payload: product,
  };
};

export const UpdateProductList = (product_update) => {
  return {
    type: ActionTypes.UPDATE_PRODUCT_LIST,
    payload: product_update,
  };
};

export const UpdateVendorEventList = (product_update) => {
  return {
    type: ActionTypes.UPDATE_VENDOR_EVENT_LIST,
    payload: product_update,
  };
};

export const UpdateEventList = (product_update) => {
  return {
    type: ActionTypes.UPDATE_EVENT_LIST,
    payload: product_update,
  };
};
export const UpdateOfferList = (product_update) => {
  return {
    type: ActionTypes.UPDATE_OFFER_LIST,
    payload: product_update,
  };
};
export const UpdateVendorOfferList = (product_update) => {
  return {
    type: ActionTypes.UPDATE_VENDOR_OFFER_LIST,
    payload: product_update,
  };
};

export const addToCartList = (product) => {
  return {
    type: ActionTypes.ADD_TO_CART,
    payload: {
      id: new Date().getTime().toString(),
      data: product?.productz,
      mainQuantity: product?.mainQuantity,
      mainCost: product?.mainCost,
    },
  };
};

export const addMultipleQauntityCart = (product) => {
  return {
    type: ActionTypes.ADD_CART_MULTIPLE_QUANTITY,
    payload: product,
  };
};

export const removeMultipleQauntityCart = (product) => {
  return {
    type: ActionTypes.REMOVE_CART_MULTIPLE_QUANTITY,
    payload: product,
  };
};

export const deleteSupplier = (supplier) => {
  return {
    type: ActionTypes.DELETE_SUPPLIER,
    payload: supplier,
  };
};

export const deleteProduct = (product) => {
  return {
    type: ActionTypes.DELETE_PRODUCT,
    payload: product,
  };
};
export const deleteVendorEvent = (product) => {
  return {
    type: ActionTypes.DELETE_VENDOR_EVENT,
    payload: product,
  };
};
export const deleteEvent = (product) => {
  return {
    type: ActionTypes.DELETE_EVENT,
    payload: product,
  };
};
export const deleteOffer = (product) => {
  return {
    type: ActionTypes.DELETE_OFFER,
    payload: product,
  };
};
export const deleteVendorOffer = (product) => {
  return {
    type: ActionTypes.DELETE_OFFER,
    payload: product,
  };
};

export const RemoveCartList = (cart) => {
  return {
    type: ActionTypes.REMOVE_ADD_TO_CART,
    payload: cart,
  };
};

export const getSettingList = (setting) => {
  return {
    type: ActionTypes.GET_SETTINGS_LIST,
    payload: setting,
  };
};

export const addSettingList = (setting) => {
  return {
    type: ActionTypes.ADD_SETTINGS_LIST,
    payload: setting,
  };
};

export const updateSettingList = (setting) => {
  return {
    type: ActionTypes.UPDATE_SETTINGS_LIST,
    payload: setting,
  };
};

export const deleteSettingList = (setting) => {
  return {
    type: ActionTypes.DELETE_SETTINGS_LIST,
    payload: setting,
  };
};

export const clearRedux = () => {
  return {
    type: ActionTypes.ADMIN_LOGOUT,
  };
};

export const selectCurrentAddedBrand = (brands) => {
  return {
    type: ActionTypes.SELECT_CURRENT_ADDED_BRAND,
    payload: brands,
  };
};

export const selectCurrentAddedBrandModel = (models) => {
  return {
    type: ActionTypes.SELECT_CURRENT_ADDED_BRAND_MODEL,
    payload: models,
  };
};

export const selectCurrentAddedCategory = (category) => {
  return {
    type: ActionTypes.SELECT_CURRENT_ADDED_CATEGORY,
    payload: category,
  };
};

export const selectCurrentAddedSupplier = (supplier) => {
  return {
    type: ActionTypes.SELECT_CURRENT_ADDED_SUPPLIER,
    payload: supplier,
  };
};

export const getSelectedCurrentBrandIDs = (brand) => {
  return {
    type: ActionTypes.GET_SELECTED_CURRENT_BRAND_IDS,
    payload: brand,
  };
};
export const getFAQList = (product) => {
  return {
    type: ActionTypes.GET_FAQ_LIST,
    payload: product,
  };
};
export const getPaymentList = (product) => {
  return {
    type: ActionTypes.GET_PAYMENT_LIST,
    payload: product,
  };
};
export const getVendorPaymentList = (product) => {
  return {
    type: ActionTypes.GET_VENDOR_PAYMENT_LIST,
    payload: product,
  };
};
export const getVendorCTList = (product) => {
  return {
    type: ActionTypes.GET_VENDOR_CT_LIST,
    payload: product,
  };
};
export const getNotificationList = (product) => {
  return {
    type: ActionTypes.GET_NOTIFICATION_LIST,
    payload: product,
  };
};
export const getAllFAQList = (product) => {
  return {
    type: ActionTypes.GET_ALL_FAQ_LIST,
    payload: product,
  };
};
export const addFAQList = (product) => {
  return {
    type: ActionTypes.ADD_FAQ_LIST,
    payload: product,
  };
};
export const UpdateFAQList = (product_update) => {
  return {
    type: ActionTypes.UPDATE_FAQ_LIST,
    payload: product_update,
  };
};
export const deleteFAQ = (product) => {
  return {
    type: ActionTypes.DELETE_FAQ,
    payload: product,
  };
};
