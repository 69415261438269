import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  editSelfDetails,
  getPermissionList,
  deleteSettingList,
  selectCurrentAddedCategory,
  addCategoryList,
  addSubCategoryList,
  addSettingCoinEarningList,
  addSettingBadgeList,
  addSettingMCRList,
  getSettingList,
  addSettingList,
  updateSettingList,
  ClearFormSetFutios,
  getAllCategoryList,
  getAllDetailCategoryList,
  getAllSupportList,
  getAllVendorSupportList,
  getAllSubCategoryList,
  getAllSettingCoinEarningList,
  getAllSettingBadgeList,
  getAllSettingMCRList,
  getSelfDetails,
  deleteCategoryList,
  deleteSubCategoryList,
  deleteSettingCoinEarningList,
  deleteSettingBadgeList,
  deleteSettingMCRList,
  updateCategoryList,
  updateSubCategoryList,
  updateSettingCoinEarningList,
  updateSettingBadgeList,
  updateSettingMCRList,
  getAllProductList,
  UpdateProductList,
  deleteProduct,
  addVendorList,
  liveMapUsersList,
  getProductList,
  LoaderStartMain,
  getSupplierDetails,
  deleteModel,
  editModel,
  ViewProjectDetails,
  getCategoryList,
  getSubCategoryList,
  getVendorViewGalleryList,
  getVendorViewReviewList,
  getVendorViewTCList,
  getVendorStoreGalleryList,
  getViewPagesResponseList,
  getSettingCoinEarningList,
  getSettingBadgeList,
  getSettingMCRList,
  getUserList,
  getApprovalDataList,
  getDashboardMostSuccessOfferList,
  getDashboardMostSuccessEventList,
  getDashboardMostLikedList,
  getDashboardMostWhishList,
  getDashboardReferralList,
  getNotificationList,
  getPaymentList,
  getVendorPaymentList,
  getVendorCTList,
  getAllFAQList,
  getFAQList,
  UpdateFAQList,
  addFAQList,
  deleteFAQ,
  getAllEventList,
  UpdateEventList,
  deleteEvent,
  addEventList,
  getEventList,
  getAllOfferList,
  UpdateOfferList,
  deleteOffer,
  addOfferList,
  getOfferList,
  setShowSuccessModal,
  setShowPaymentModal,
  UpdateVendorEventList,
  getVendorEventList,
  getAllVendorOfferList,
  UpdateVendorOfferList,
  addVendorOfferList,
  getVendorOfferList,
  getDashBoardDetails,
  getDashBoardPRDetails,
  getDashBoardURDetails,
  getDashBoardTRDetails,
} from "../../redux/actions/adminActions";

export const ws_response = ({ evt, ws }) => {
  return async (dispatch, getState) => {
    const ws_onmessage = JSON.parse(evt.data);
    const { device_id, selfInfo } = getState()?.adminReducers;
    console.log("ws_onmessage?.response", ws_onmessage?.response);
    // Sw notification setup
    if (ws_onmessage?.device_id === device_id) {
      switch (ws_onmessage.url) {
        case "user_profile_update":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(editSelfDetails(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "user_profile_update",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "user_profile_update",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "permission_list":
          dispatch(getPermissionList(ws_onmessage?.response));
          break;

        case "user_update":
          if (ws_onmessage?.response?.status === 200) {
            toast.success(ws_onmessage?.response?.msg);
          } else {
            toast.error(ws_onmessage?.response?.msg);
          }

          break;
        case "update_product_model":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(editModel(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "update_product_model",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "update_product_model",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "delete_product_model":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(deleteModel(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "vendor_admin_listing":
          if (ws_onmessage?.request) {
            dispatch(getAllProductList(ws_onmessage?.response));
            dispatch(getProductList(ws_onmessage?.response));
          }
          break;
        case "event_listing":
          dispatch(getAllEventList(ws_onmessage?.response));
          dispatch(getEventList(ws_onmessage?.response));
          dispatch(getVendorEventList(ws_onmessage?.response));
          dispatch(liveMapUsersList(""));

          dispatch(
            setShowPaymentModal({
              type: "",
              response: "",
              show: false,
            })
          );

          break;
        case "offer_listing":
          dispatch(getAllOfferList(ws_onmessage?.response));
          dispatch(getOfferList(ws_onmessage?.response));
          dispatch(getAllVendorOfferList(ws_onmessage?.response));
          dispatch(getVendorOfferList(ws_onmessage?.response));
          dispatch(liveMapUsersList(""));
          dispatch(
            setShowPaymentModal({
              type: "",
              response: "",
              show: false,
            })
          );
          break;
        case "user_live_map":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(liveMapUsersList(ws_onmessage?.response));
          } else {
            toast.error(ws_onmessage?.response?.msg);
            dispatch(liveMapUsersList(""));
          }
          break;
          case "notification_listing":
            if (ws_onmessage?.response?.status === 200) {
              dispatch(getNotificationList(ws_onmessage?.response));
            } else {
              toast.error(ws_onmessage?.response?.msg);
              dispatch(getNotificationList(""));
            }
            break;
        case "vendor_add":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(addVendorList(ws_onmessage?.response));
            dispatch(
              setShowSuccessModal({
                type: "vendorSuccess",
                response: ws_onmessage?.response,
                show: true,
              })
            );
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "vendor_add",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            dispatch(
              ClearFormSetFutios({
                url: "vendor_add",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.error(ws_onmessage?.response?.msg);
          }
          break;
        case "event_add":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(addEventList(ws_onmessage?.response));
            if (
              ws_onmessage?.response?.data?.created_by === "vendor" &&
              ws_onmessage?.response?.data?.event_mode === "payment"
            ) {
              dispatch(
                setShowPaymentModal({
                  type: "event",
                  response: { ...ws_onmessage?.response },
                  show: true,
                })
              );
            }
            if (selfInfo?.user_type === "admin") {
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "add_event",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "offer_add":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(addOfferList(ws_onmessage?.response));
            dispatch(addVendorOfferList(ws_onmessage?.response));
            if (
              ws_onmessage?.response?.data?.created_by === "vendor" &&
              ws_onmessage?.response?.data?.offer_mode === "payment"
            ) {
              dispatch(
                setShowPaymentModal({
                  type: "offer",
                  response: { ...ws_onmessage?.response },
                  show: true,
                })
              );
            }
            dispatch(
              ClearFormSetFutios({
                url: "offer_add",
                action: ws_onmessage?.response?.status,
              })
            );
            if (selfInfo?.user_type === "admin") {
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            toast.error(ws_onmessage?.response?.msg);
          }
          break;
        case "user_get":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(getSelfDetails(ws_onmessage?.response?.data));
          }
          break;
        case "vendor_update":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(UpdateProductList(ws_onmessage?.response?.data));

            dispatch(
              ClearFormSetFutios({
                url: "vendor_update",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          } else {
            toast.error(ws_onmessage?.response?.msg);
          }
          break;
        case "event_update":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(UpdateEventList(ws_onmessage?.response?.data));
            dispatch(UpdateVendorEventList(ws_onmessage?.response?.data));

            dispatch(
              ClearFormSetFutios({
                url: "event_update",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          } else {
            toast.error(ws_onmessage?.response?.msg);
          }
          break;
        case "offer_update":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(UpdateOfferList(ws_onmessage?.response?.data));
            dispatch(UpdateVendorOfferList(ws_onmessage?.response?.data));

            dispatch(
              ClearFormSetFutios({
                url: "offer_update",
                action: ws_onmessage?.response?.status,
              })
            );
            toast.success(ws_onmessage?.response?.msg);
          } else {
            toast.error(ws_onmessage?.response?.msg);
          }
          break;
        case "user_details":
          if (ws_onmessage?.response?.status) {
            if (ws_onmessage?.request) {
              dispatch(LoaderStartMain(true));
              dispatch(ViewProjectDetails(ws_onmessage?.response?.data));
              setTimeout(() => {
                dispatch(LoaderStartMain(false));
              }, 3000);
            }
          } else {
            dispatch(getSupplierDetails({}));
          }
          break;
        case "get_offer_detail":
          if (ws_onmessage?.response?.status) {
            if (ws_onmessage?.request) {
              dispatch(LoaderStartMain(true));
              dispatch(ViewProjectDetails(ws_onmessage?.response?.data));
              setTimeout(() => {
                dispatch(LoaderStartMain(false));
              }, 3000);
            }
          } else {
            dispatch(getSupplierDetails({}));
          }
          break;
        case "get_event_detail":
          if (ws_onmessage?.response?.status) {
            if (ws_onmessage?.request) {
              dispatch(LoaderStartMain(true));
              dispatch(ViewProjectDetails(ws_onmessage?.response?.data));
              setTimeout(() => {
                dispatch(LoaderStartMain(false));
              }, 3000);
            }
          } else {
            dispatch(getSupplierDetails({}));
          }
          break;
        case "delete_product":
          if (ws_onmessage?.response?.status) {
            dispatch(deleteProduct(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "delete_event":
          if (ws_onmessage?.response?.status) {
            dispatch(deleteEvent(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "delete_offer":
          if (ws_onmessage?.response?.status) {
            dispatch(deleteOffer(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "contact_us_listing":
          dispatch(getAllSupportList(ws_onmessage?.response));
          break;
        case "vendor_contact_us_listing":
          dispatch(getAllVendorSupportList(ws_onmessage?.response));
          break;

        case "approval_listing":
          dispatch(getApprovalDataList(ws_onmessage?.response));
          break;

        case "approval_action":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(getApprovalDataList(ws_onmessage?.response));
            toast.success(ws_onmessage?.response?.msg);
          } else {
            toast.error(ws_onmessage?.response?.msg);
          }
          break;

        case "dashboard_referral_listing":
          dispatch(getDashboardReferralList(ws_onmessage?.response));
          break;

        case "dashboard_most_wishlist":
          dispatch(getDashboardMostWhishList(ws_onmessage?.response));
          break;

        case "dashboard_most_liked":
          dispatch(getDashboardMostLikedList(ws_onmessage?.response));
          break;
        case "dashboard_most_successful":
          if (
            ws_onmessage?.response?.status === 200 &&
            ws_onmessage?.response?.data?.some((obj) =>
              obj.hasOwnProperty("offer_code")
            )
          ) {
            dispatch(getDashboardMostSuccessOfferList(ws_onmessage?.response));
          } else {
            dispatch(getDashboardMostSuccessEventList(ws_onmessage?.response));
          }

          break;

        case "coinearning_listing":
          dispatch(getAllSettingCoinEarningList(ws_onmessage?.response));
          dispatch(getSettingCoinEarningList(ws_onmessage?.response));
          break;
        case "badge_listing":
          dispatch(getAllSettingBadgeList(ws_onmessage?.response));
          dispatch(getSettingBadgeList(ws_onmessage?.response));
          break;
        case "mcr_listing":
          dispatch(getAllSettingMCRList(ws_onmessage?.response));
          dispatch(getSettingMCRList(ws_onmessage?.response));
          break;
        case "user_listing":
          dispatch(getUserList(ws_onmessage?.response));
          break;
        case "user_delete":
          if (ws_onmessage?.response?.status === 200) {
            toast.success(ws_onmessage?.response?.msg);
            dispatch(getUserList(ws_onmessage?.response));
          } else {
            toast.error(ws_onmessage?.response);
          }

          break;
        case "coinearning_update":
          if (ws_onmessage?.response?.status === 200) {
            toast.success(ws_onmessage?.response?.msg);
          } else {
            toast.error(ws_onmessage?.response);
          }

          break;
        case "review_delete":
          dispatch(getVendorViewReviewList(ws_onmessage?.response));
          toast.success(ws_onmessage?.response?.msg);
          break;
        case "subcategory_listing":
          dispatch(getAllSubCategoryList(ws_onmessage?.response));
          dispatch(getSubCategoryList(ws_onmessage?.response));
          break;
        case "category_detailed_listing":
          dispatch(getAllDetailCategoryList(ws_onmessage?.response));
          break;

        case "response_listing_admin":
          dispatch(getViewPagesResponseList(ws_onmessage?.response));

          break;
        case "vendor_gallery_listing":
          dispatch(getVendorViewGalleryList(ws_onmessage?.response));

          break;
        case "vendor_coin_transaction":
          dispatch(getVendorViewTCList(ws_onmessage?.response));
          break;
        case "review_listing":
          dispatch(getVendorViewReviewList(ws_onmessage?.response));

          break;
        case "vendor_store_gallery_listing":
          dispatch(getVendorStoreGalleryList(ws_onmessage?.response));
          break;

        case "category_listing":
          dispatch(getAllCategoryList(ws_onmessage?.response));
          dispatch(getCategoryList(ws_onmessage?.response));

          break;
        case "category_add":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(addCategoryList(ws_onmessage?.response?.data));
            dispatch(selectCurrentAddedCategory(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "category_add",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "category_add",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;

        case "coinearning_add":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(addSettingCoinEarningList(ws_onmessage?.response?.data));
            dispatch(selectCurrentAddedCategory(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "coinearning_add",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "coinearning_add",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "badge_add":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(addSettingBadgeList(ws_onmessage?.response?.data));
            dispatch(selectCurrentAddedCategory(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "badge_add",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "badge_add",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "mcr_add":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(addSettingMCRList(ws_onmessage?.response?.data));
            dispatch(selectCurrentAddedCategory(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "mcr_add",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "mcr_add",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "subcategory_add":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(addSubCategoryList(ws_onmessage?.response?.data));
            dispatch(selectCurrentAddedCategory(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "subcategory_add",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "subcategory_add",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "category_update":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(updateCategoryList(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "category_update",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "category_update",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "subcategory_update":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(updateSubCategoryList(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "subcategory_update",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "subcategory_update",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "update_coinearning":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(
              updateSettingCoinEarningList(ws_onmessage?.response?.data)
            );
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "update_coinearning",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "update_coinearning",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "badge_update":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(updateSettingBadgeList(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "badge_update",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "badge_update",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "update_mcr":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(updateSettingMCRList(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "update_mcr",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "update_mcr",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "category_delete":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(deleteCategoryList(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "subcategory_delete":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(deleteSubCategoryList(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "delete_coinearning":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(
              deleteSettingCoinEarningList(ws_onmessage?.response?.data)
            );
            if (ws_onmessage?.request) {
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "badge_delete":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(deleteSettingBadgeList(ws_onmessage?.response?.data));
            toast.success(ws_onmessage?.response?.msg);
          } else {
            if (ws_onmessage?.request) {
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "delete_mcr":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(deleteSettingMCRList(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "contact_us_replay":
          if (ws_onmessage?.response?.status === 200) {
            toast.success(ws_onmessage?.response?.msg);
          } else {
            toast.error(ws_onmessage?.response?.msg);
          }
          break;
        case "vendor_payment":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(liveMapUsersList(""));
            toast.success(ws_onmessage?.response?.msg);
          } else {
            // Iterate over each key-value pair in the msg object
            Object.entries(ws_onmessage?.response?.msg).forEach(
              ([key, value]) => {
                // Display an error toast for each message
                toast.error(value);
              }
            );
            dispatch(liveMapUsersList(""));
          }
          break;

        case "contact_us":
          if (ws_onmessage?.response?.status === 200) {
            toast.success(ws_onmessage?.response?.msg);
          } else {
            toast.error(ws_onmessage?.response?.msg);
          }
          break;
        case "product_settings_list":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(getSettingList(ws_onmessage?.response));
          }
          break;
        case "add_product_setting":
          if (ws_onmessage?.response?.status) {
            dispatch(addSettingList(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "add_product_setting",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "add_product_setting",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "update_product_setting":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(updateSettingList(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "update_product_setting",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "update_product_setting",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "delete_product_setting":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(deleteSettingList(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "notification_list":
          if (ws_onmessage?.request) {
            dispatch(getNotificationList(ws_onmessage?.response));
          }
          break;
        case "payment_list":
          if (ws_onmessage?.request) {
            dispatch(getPaymentList(ws_onmessage?.response));
          }
          break;
        case "vendor_payment_listing":
          dispatch(getVendorPaymentList(ws_onmessage?.response));
          dispatch(getPaymentList(ws_onmessage?.response));

          break;
        case "vendor_ct_list":
          if (ws_onmessage?.request) {
            dispatch(getVendorCTList(ws_onmessage?.response));
          }
          break;
        case "dashboard_admin_counts":
          dispatch(getDashBoardDetails(ws_onmessage?.response));
          break;
        case "dashboard_vendor_counts":
          dispatch(getDashBoardDetails(ws_onmessage?.response));
          break;
        case "dashboard_payment_revenue":
          dispatch(getDashBoardPRDetails(ws_onmessage?.response));
          break;
        case "dashboard_user_revenue":
          dispatch(getDashBoardURDetails(ws_onmessage?.response));
          break;
        case "dashboard_transaction_revenue":
          dispatch(getDashBoardTRDetails(ws_onmessage?.response));
          break;
        case "faq_listing":
          if (ws_onmessage?.request) {
            dispatch(getAllFAQList(ws_onmessage?.response));
            dispatch(getFAQList(ws_onmessage?.response));
          }
          break;
        case "faq_add":
          if (ws_onmessage?.response?.status) {
            dispatch(addFAQList(ws_onmessage?.response));
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "faq_add",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "faq_add",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "faq_update":
          if (ws_onmessage?.response?.status) {
            dispatch(UpdateFAQList(ws_onmessage?.response?.data));
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "faq_update",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.success(ws_onmessage?.response?.msg);
            }
          } else {
            if (ws_onmessage?.request) {
              dispatch(
                ClearFormSetFutios({
                  url: "faq_update",
                  action: ws_onmessage?.response?.status,
                })
              );
              toast.error(ws_onmessage?.response?.msg);
            }
          }
          break;
        case "faq_detail":
          if (ws_onmessage?.response?.status) {
            if (ws_onmessage?.request) {
              dispatch(LoaderStartMain(true));
              dispatch(ViewProjectDetails(ws_onmessage?.response?.data));
              setTimeout(() => {
                dispatch(LoaderStartMain(false));
              }, 3000);
            }
          } else {
            dispatch(getSupplierDetails({}));
          }
          break;
        case "faq_delete":
          if (ws_onmessage?.response?.status === 200) {
            dispatch(deleteFAQ(ws_onmessage?.response?.data));
            toast.success(
              ws_onmessage?.response?.msg || "FAQ deleted successfully"
            );
          } else {
            toast.error(ws_onmessage?.response?.msg);
          }
          break;
        case "vendor_get":
          if (ws_onmessage?.response?.status) {
            if (ws_onmessage?.request) {
              dispatch(LoaderStartMain(true));
              dispatch(ViewProjectDetails(ws_onmessage?.response?.data));
              setTimeout(() => {
                dispatch(LoaderStartMain(false));
              }, 3000);
            }
          } else {
            dispatch(getSupplierDetails({}));
          }
          break;
        case "offer_get":
          if (ws_onmessage?.response?.status) {
            if (ws_onmessage?.request) {
              dispatch(LoaderStartMain(true));
              dispatch(ViewProjectDetails(ws_onmessage?.response?.data));
              setTimeout(() => {
                dispatch(LoaderStartMain(false));
              }, 3000);
            }
          } else {
            dispatch(getSupplierDetails({}));
          }
          break;
        case "event_get":
          if (ws_onmessage?.response?.status) {
            if (ws_onmessage?.request) {
              dispatch(LoaderStartMain(true));
              dispatch(ViewProjectDetails(ws_onmessage?.response?.data));
              setTimeout(() => {
                dispatch(LoaderStartMain(false));
              }, 3000);
            }
          } else {
            dispatch(getSupplierDetails({}));
          }
          break;
        default:
          return;
      }
    }
  };
};
