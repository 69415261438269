export const PRIVACY_POLICY ="/privacy-policy"
export const LOGIN_URL = "/login";
export const DELETE_ACCOUNT_URL = "/delete-account";

export const FORGOT_PASSWORD_URL = "/forgot-password";
export const RESET_PASSWORD_URL = "/reset-password";
export const DASHBOARD_URL = "/dashboard";
export const USER_MANAGEMENT_URL = "/user";
export const ADD_USER_URL = "/add-user";
export const EDIT_USER_URL = "/edit-user";
export const VIEW_PROFILE_URL = "/profile";
export const VIEW_VENDOR_PROFILE_URL = "/vendor-profile";

export const ADMIN_VENDOR_MANAGEMENT_URL = "/vendor";
export const ADD_ADMIN_VENDOR_MANAGEMENT_URL = "/add-vendor";
export const EDIT_ADMIN_VENDOR_MANAGEMENT_URL = "/edit-vendor";
export const VIEW_ADMIN_VENDOR_MANAGEMENT_URL = "/view-vendor";
export const SUPPLIER_MANAGEMENT_URL = "/supplier";
export const ADD_SUPPLIER_MANAGEMENT_URL = "/add-supplier";
export const EDIT_SUPPLIER_MANAGEMENT_URL = "/edit-supplier";
export const VIEW_SUPPLIER_MANAGEMENT_URL = "/view-supplier";
export const MODEL_BRAND_MANAGEMENT_URL = "/brand-and-models";
export const VIEW_MODEL_BRANDS_MANAGEMENT_URL = "/view-model-brands";
export const CATEGORY_MANAGEMENT_URL = "/category";
export const SETTINGS_MANAGEMENT_URL = "/Fields";
export const FAQ_MANAGEMENT_URL = "/faq";
export const ADD_FAQ_MANAGEMENT_URL = "/add-faq";
export const EDIT_FAQ_MANAGEMENT_URL = "/edit-faq";
export const VIEW_FAQ_MANAGEMENT_URL = "/view-faq";
export const ADMIN_SETTING_MANAGEMENT_URL = "/settings";
export const ADMIN_NOTIFICATION_MANAGEMENT_URL = "/notification";
export const ADMIN_VIEW_NOTIFICATION_MANAGEMENT_URL = "/view-notification";
export const ADMIN_SUPPORT_MANAGEMENT_URL = "/support";
export const ADMIN_APPROVAL_MANAGEMENT_URL = "/approval";
export const ADMIN_PAYMENT_MANAGEMENT_URL = "/payment";
export const ADMIN_VIEW_USER_MANAGEMENT_URL = "/view-user";
export const ADMIN_EVENT_MANAGEMENT_URL = "/event";
export const ADMIN_ADD_ADMIN_EVENT_MANAGEMENT_URL = "/add-event";
export const ADMIN_EDIT_ADMIN_EVENT_MANAGEMENT_URL = "/edit-event";
export const ADMIN_VIEW_ADMIN_EVENT_MANAGEMENT_URL = "/view-event";
export const ADMIN_OFFER_MANAGEMENT_URL = "/offer";
export const ADMIN_ADD_ADMIN_OFFER_MANAGEMENT_URL = "/add-offer";
export const ADMIN_EDIT_ADMIN_OFFER_MANAGEMENT_URL = "/edit-offer";
export const ADMIN_VIEW_ADMIN_OFFER_MANAGEMENT_URL = "/view-offer";
export const ADMIN_LIVE_MAP_MANAGEMENT_URL = "/live-Map";
export const ADMIN_ADD_ADMIN_LIVE_MAP_MANAGEMENT_URL = "/add-liveMap";
export const ADMIN_EDIT_ADMIN_LIVE_MAP_MANAGEMENT_URL = "/edit-liveMap";
export const ADMIN_VIEW_ADMIN_LIVE_MAP_MANAGEMENT_URL = "/view-liveMap";
// ADMIN URL ENDS

// Vendor Urls Start Here
export const VENDOR_DASHBOARD_URL = "/vendor-dashboard";
export const VENDOR_OFFER_MANAGEMENT_URL = "/vendor-offer";
export const VENDOR_ADD_VENDOR_OFFER_MANAGEMENT_URL = "/vendor-add-offer";
export const VENDOR_EDIT_VENDOR_OFFER_MANAGEMENT_URL = "/vendor-edit-offer";
export const VENDOR_VIEW_VENDOR_OFFER_MANAGEMENT_URL = "/vendor-view-offer";
export const VENDR_EVENT_MANAGEMENT_URL = "/vendor-event";
export const VENDR_ADD_VENDR_EVENT_MANAGEMENT_URL = "/vendor-add-event";
export const VENDR_EDIT_VENDR_EVENT_MANAGEMENT_URL = "/vendor-edit-event";
export const VENDR_VIEW_VENDR_EVENT_MANAGEMENT_URL = "/vendor-view-event";
export const VENDOR_PAYMENT_MANAGEMENT_URL = "/vendor-payment";
export const VENDOR_SUPPORT_MANAGEMENT_URL = "/vendor-support";
export const VENDOR_COIN_TRANSACTION_URL = "/vendor-coins";
export const VENDOR_REVIEW_MANAGEMENT_URL = "/vendor-review";
export const VENDOR_GALLERY_MANAGEMENT_URL = "/vendor-gallery";
export const VENDOR_MY_BUSINESS_MANAGEMENT_URL = "/my-business";

export const MEDIA_FILE_URL = `${process.env.REACT_APP_BASE_URL}media/files/`;

export const GOOGLE_MAP_API_KEY =
  process.env.GOOGLE_MAP_API_KEY || "AIzaSyDJApguYQZtTNTcm30HlW1cMijZD0E2sjk";

// Api login urls
export const API_URL = process.env.REACT_APP_BASE_URL;
export const LOGIN_API_URL = API_URL + "admin/login";
export const LOGOUT_API_URL = API_URL + "user/logout";
export const GET_DASHBOARD_API_URL = API_URL + "admin/dashboard";
export const FORGOT_PASSWORD_API_URL = API_URL + "user/forgot-password";
export const CREATE_PASSWORD_API_URL = API_URL + "user/create-password";
export const ADMIN_ADD_USER_API_URL = API_URL + "admin/user/add";
export const ADMIN_UPDATE_USER_API_URL = API_URL + "admin/user/update";
export const ADMIN_GET_USER_LIST_API_URL = API_URL + "admin/user/list";
export const ADMIN_DELETE_USER_LIST_API_URL = API_URL + "admin/user/delete";
export const VERIFY_OTP_API_URL = API_URL + "user/verify-otp";
export const CHANGE_PASSWORD_API_URL = API_URL + "user/set-password";
export const SET_NEW_PASSWORD_API_URL = API_URL + "user/set-password";

// upload file urls
export const UPLOAD_FILE_API_URL = API_URL + "file/upload";
export const DELETE_FILE_API_URL = API_URL + "file/delete/";
export const THUMBNAIL_FILE_API_URL = API_URL + "file/detail/";

// import product file urls
export const IPMPORT_PRODUCT_FILE_API_URL = API_URL + "file/import/product";

// export product urls
export const EXPORT_PRODUCT_FILE_API_URL = API_URL + "file/export/product";
// common truncate string function
export const truncateString = (str, maxLength) => {
  if(str && maxLength){
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + "...";
}
};
export function countPlainTextOccurrences(htmlString) {
  // Create a temporary div element
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Get the text content from all text nodes within the document body
  const textContent = doc.body.textContent;

  // Return the length of the text content (plain text character count)
  return textContent.length;
}




export const RatingStars = ({ rating }) => {
  const filledStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  const emptyStars = 5 - filledStars - (hasHalfStar ? 1 : 0);

  const renderStars = () => {
    const stars = [];

    for (let i = 0; i < filledStars; i++) {
      stars.push(<i key={i} className="bi bi-star-fill golden"></i>);
    }

    if (hasHalfStar) {
      stars.push(<i key="half" className="bi bi-star-half golden"></i>);
    }

    for (let i = 0; i < emptyStars; i++) {
      stars.push(<i key={`empty-${i}`} className="bi bi-star golden"></i>);
    }

    return stars;
  };

  return <span>{renderStars()}</span>;
};
export const imagesArray = [
  "https://fastly.picsum.photos/id/525/200/200.jpg?hmac=_RnAIjIviTYNBh6qG-zjQg_m2UBgAnnh96jDb8ZDKSI",
  "https://fastly.picsum.photos/id/165/200/300.jpg?hmac=4P65Mkd3rtbFIw6TRq5Wc_c9_bOP2SClOjjOFZgbEPg",
  "https://www.youtube.com/watch?v=X8ipUgXH6jw",
  "https://fastly.picsum.photos/id/316/200/200.jpg?hmac=f0i62VkjVy8OPLP77Xf7mdZa3UBNlTOXFm9WpDMOiiA",
];

export const countryOptions = [
  { value: "other", label: "other" },
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "American Samoa", label: "American Samoa" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Anguilla", label: "Anguilla" },
  { value: "Antarctica", label: "Antarctica" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Aruba", label: "Aruba" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bermuda", label: "Bermuda" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  {
    value: "Bonaire, Sint Eustatius and Saba",
    label: "Bonaire, Sint Eustatius and Saba",
  },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Bouvet Island", label: "Bouvet Island" },
  { value: "Brazil", label: "Brazil" },
  {
    value: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory",
  },
  { value: "Brunei Darussalam", label: "Brunei Darussalam" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cabo Verde", label: "Cabo Verde" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Cayman Islands", label: "Cayman Islands" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Christmas Island", label: "Christmas Island" },
  { value: "Cocos Islands", label: "Cocos Islands" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  {
    value: "Congo, Democratic Republic of the",
    label: "Congo, Democratic Republic of the",
  },
  { value: "Cook Islands", label: "Cook Islands" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Curaçao", label: "Curaçao" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czechia", label: "Czechia" },
  { value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Eswatini", label: "Eswatini" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Falkland Islands", label: "Falkland Islands" },
  { value: "Faroe Islands", label: "Faroe Islands" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "French Guiana", label: "French Guiana" },
  { value: "French Polynesia", label: "French Polynesia" },
  {
    value: "French Southern Territories",
    label: "French Southern Territories",
  },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Gibraltar", label: "Gibraltar" },
  { value: "Greece", label: "Greece" },
  { value: "Greenland", label: "Greenland" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guadeloupe", label: "Guadeloupe" },
  { value: "Guam", label: "Guam" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guernsey", label: "Guernsey" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  {
    value: "Heard Island and McDonald Islands",
    label: "Heard Island and McDonald Islands",
  },
  { value: "Holy See", label: "Holy See" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Isle of Man", label: "Isle of Man" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jersey", label: "Jersey" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  {
    value: "Korea, Democratic People's Republic of",
    label: "Korea, Democratic People's Republic of",
  },
  { value: "Korea, Republic of", label: "Korea, Republic of" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  {
    value: "Lao People's Democratic Republic",
    label: "Lao People's Democratic Republic",
  },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Macao", label: "Macao" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Martinique", label: "Martinique" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mayotte", label: "Mayotte" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Caledonia", label: "New Caledonia" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Niue", label: "Niue" },
  { value: "Norfolk Island", label: "Norfolk Island" },
  { value: "North Macedonia", label: "North Macedonia" },
  { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Palestine, State of", label: "Palestine, State of" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Pitcairn", label: "Pitcairn" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Qatar", label: "Qatar" },
  { value: "Romania", label: "Romania" },
  { value: "Russian Federation", label: "Russian Federation" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Réunion", label: "Réunion" },
  { value: "Saint Barthélemy", label: "Saint Barthélemy" },
  {
    value: "Saint Helena, Ascension and Tristan da Cunha",
    label: "Saint Helena, Ascension and Tristan da Cunha",
  },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  { value: "Saint Martin", label: "Saint Martin" },
  { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Sint Maarten", label: "Sint Maarten" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  {
    value: "South Georgia and the South Sandwich Islands",
    label: "South Georgia and the South Sandwich Islands",
  },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syria Arab Republic", label: "Syria Arab Republic" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  {
    value: "Tanzania, the United Republic of",
    label: "Tanzania, the United Republic of",
  },
  { value: "Thailand", label: "Thailand" },
  { value: "Timor-Leste", label: "Timor-Leste" },
  { value: "Togo", label: "Togo" },
  { value: "Tokelau", label: "Tokelau" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Türkiye", label: "Türkiye" },
  { value: "US Minor Outlying Islands", label: "US Minor Outlying Islands" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States", label: "United States" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Viet Nam", label: "Viet Nam" },
  { value: "Virgin Islands, British", label: "Virgin Islands, British" },
  { value: "Virgin Islands, U.S.", label: "Virgin Islands, U.S." },
  { value: "Wallis and Futuna", label: "Wallis and Futuna" },
  { value: "Western Sahara", label: "Western Sahara" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
  { value: "Åland Islands", label: "Åland Islands" },
];
export const vendorCountryOptions = [
  {
    value: "UAE",
    label: "UAE",
  },
];
export const ageOptions = [];

for (let i = 0; i <= 100; i++) {
  ageOptions.push({
    value: i,
    label: `${i} - Age`,
  });
}

export const dubaiStatesOptions = [
  { value: "All", label: "All" },
  { value: "Dubai", label: "Dubai" },
  { value: "Abu Dhabi", label: "Abu Dhabi" },
  { value: "Sharjah", label: "Sharjah" },
  { value: "Ajman", label: "Ajman" },
  { value: "Umm Al Quwain", label: "Umm Al Quwain" },
  { value: "Ras Al Khaimah", label: "Ras Al Khaimah" },
  // { value: "Ras Al Khaimah and Fujairah", label: "Ras Al Khaimah and Fujairah" },

];
export const getStateWiseAmount = (state) => {
  console.log("getStateWiseAmount",state)
  switch (state) {
    case "DU":
      return 100;
    case "AUH":
      return 100;
    case "SH":
      return 100;
    case "AJ":
      return 100;
    case "UQ":
      return 100;
    case "RK-FU":
      return 100;
    default:
      return 200;
  }
};

export const mapContainerStyle = { height: "400px", width: "100%" };
export const center = { lat: -34, lng: 151 }; // Adjust the initial center as needed
export const zoom = 8;

export const locationsLatLong = [
  { id: 1, lat: -34.5, lng: 150.5, icon: "../../assets/img/menIcon.png" },
  { id: 2, lat: -34.7, lng: 150.8, icon: "../../assets/img/womenIcon.png" },
];
export const MapComponent = ({ icon }) => (
  <div>
    <img src={icon} alt="marker" style={{ width: "30px", height: "30px" }} />
  </div>
);

export function bytesToKB(bytes) {
  if (bytes === 0) return "0 KB";

  const kBytes = bytes / 1024;
  return kBytes.toFixed(2) + " KB";
}

export function isVideoUrl(url) {
  console.log("url", url);
  if (typeof url === "string") {
    // Check if url is a string
    const videoExtensions = [".mp4", ".avi", ".mkv", ".mov", ".wmv", ".flv"];
    const fileExtension = url.split(".").pop().toLowerCase();
    console.log("fileExtension", fileExtension); // Log fileExtension
    return videoExtensions.includes(`.${fileExtension}`);
  } else {
    return false; // or handle the case where url is not a string
  }
}

export function checkOpeningClosingTimes(openingHours) {
  if (openingHours && openingHours.length > 0) {
    const result = {
      sameOpeningAndClosingTimes: [],
      daysNotPresent: [],
      differentTimes: [],
    };

    const uniqueTimes = new Set();
    openingHours.forEach((day) => {
      const { opening_time, closing_time } = day.time;
      const dayTime = `${opening_time}-${closing_time}`;
      if (!uniqueTimes.has(dayTime)) {
        uniqueTimes.add(dayTime);
        const daysWithSameTime = openingHours
          .filter((d) => {
            return (
              d.time.opening_time === opening_time &&
              d.time.closing_time === closing_time
            );
          })
          .map((d) => d.day);
        if (daysWithSameTime.length > 1) {
          const timeFormat = `${convertToAmPm(opening_time)} to ${convertToAmPm(
            closing_time
          )}`;
          result.sameOpeningAndClosingTimes.push({
            days: daysWithSameTime.join(", "),
            time: timeFormat,
          });
        }
      }
    });

    const allDays = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];
    result.daysNotPresent = allDays.filter(
      (day) => !openingHours.some((o) => o.day === day)
    );

    const timeOccurrences = {};
    openingHours.forEach((day) => {
      const timeString = `${day.time.opening_time}-${day.time.closing_time}`;
      timeOccurrences[timeString] = (timeOccurrences[timeString] || 0) + 1;
    });

    const majorityTime = Object.keys(timeOccurrences).reduce((a, b) =>
      timeOccurrences[a] > timeOccurrences[b] ? a : b
    , "");

    result.differentTimes = openingHours
      .filter(
        (day) =>
          `${day.time.opening_time}-${day.time.closing_time}` !== majorityTime
      )
      .map((day) => day.day);

    return result;
  } else {
    // Handle case where openingHours is empty or undefined
    return {
      sameOpeningAndClosingTimes: [],
      daysNotPresent: [],
      differentTimes: [],
    };
  }
}
export function truncateStringToHTML(str, maxLength) {
  if (!str) return "-";
  
  // Check if the string length exceeds the maximum length
  if (str.length > maxLength) {
    // Truncate the string
    const truncatedStr = str.substring(0, maxLength) + '...';
    // Convert the truncated string to HTML
    return truncatedStr.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  } else {
    return str;
  }
}


export function capitalizeFirstLetter(str) {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
}

export function convertToAmPm(time) {
  const [hours, minutes] = time.split(":");
  let period = "AM";
  let hour = parseInt(hours);
  if (hour >= 12) {
    period = "PM";
    if (hour > 12) {
      hour -= 12;
    }
  }
  return `${hour}:${minutes} ${period}`;
}

export function calculateTimeLeft(futureDateTime) {
  // Current date and time
  const now = new Date();

  // Future date and time
  const futureDate = new Date(futureDateTime);

  // Check if the offer has expired
  if (now.getTime() > futureDate.getTime()) {
    return "Offer Expired";
  }

  // Calculate the difference in milliseconds
  const timeDifference = futureDate.getTime() - now.getTime();

  // Convert milliseconds to total hours and minutes
  const totalMinutes = Math.floor(timeDifference / (1000 * 60));
  const remainingHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;

  // Return the remaining time in "hours:minutes" format
  return `${remainingHours}:${remainingMinutes < 10 ? '0' : ''}${remainingMinutes} Hr`;
}


export function convertToUTC(startDate, startTime) {
  console.log("startDate, startTime", startDate, startTime);
  if (startDate && startTime) {
    // Extracting year, month, and day from the start date
    const year = startDate.getFullYear();
    const month = startDate.getMonth() + 1; // Month is zero-indexed, so add 1
    const day = startDate.getDate();

    // Extracting hours and minutes from the start time
    const [hours, minutes] = startTime.split(":");

    // Creating a new Date object with the extracted values
    const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes));

    // Converting to UTC format and removing milliseconds
    const utcDateString = utcDate.toISOString().slice(0, -5) + "Z";

    return utcDateString;
  }
}

export function convertToUTCTime(timeString) {
  // Split the time string into hours, minutes, and seconds
  const [hours, minutes, seconds = "00"] = timeString.split(":").map(Number);

  // Create a new Date object with the current date and the given time
  const localDate = new Date();
  localDate.setUTCHours(hours);
  localDate.setUTCMinutes(minutes);
  localDate.setUTCSeconds(seconds);

  // Get the UTC hours, minutes, and seconds from the Date object
  const utcHours = localDate.getUTCHours();
  const utcMinutes = localDate.getUTCMinutes();
  const utcSeconds = localDate.getUTCSeconds();

  // Format the UTC time string
  const utcTimeString = `${utcHours.toString().padStart(2, "0")}:${utcMinutes
    .toString()
    .padStart(2, "0")}:${utcSeconds.toString().padStart(2, "0")}`;

  return utcTimeString;
}

export function parseDateTime(offerStartTime) {
  const dateObject = new Date(offerStartTime);

  // Format date
  const date = dateObject;

  // Format time
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const time = `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;

  return { date, time };
}

export function formatDates(startDate, startTime) {
  // Parse startDate string into a Date object
  const date = new Date(startDate);

  // Split startTime into hours and minutes
  const [hours, minutes] = startTime.split(":").map(Number);

  // Set hours and minutes for the date
  date.setHours(hours);
  date.setMinutes(minutes);

  // Format the date as YYYY-MM-DD HH:mm:ss
  const formattedDate = date.toISOString().slice(0, 19).replace("T", " ");

  return formattedDate;
}
export function extractFilename(url) {
  if (url) {
    const parts = url.split("/");
    return parts[parts.length - 1];
  }
}
export function extractIdFromUrl(url) {
  if(url){
  const parts = url.split("/");
  const idWithExtension = parts[parts.length - 1];
  const id = idWithExtension.split(".")[0]; // Remove file extension if present
  return id;
  }
}
export const maxSize = 30 * 1024 * 1024; // 30 MB in bytes
export function getOfferStatus(offer_start_time, offer_end_time) {
  const current_time = new Date();

  // Convert offer start and end times to Date objects
  const start_time = new Date(offer_start_time);
  const end_time = new Date(offer_end_time);
  if (current_time > end_time) {
    return <span className="text-expire">Expired</span>;
  } else if (current_time < start_time) {
    return <span className="text-upcoming">Upcoming</span>;
  } else if (current_time >= start_time && current_time <= end_time) {
    return <span className="text-live">Live</span>;
  }
}

export function isEventLive(offer_start_time, offer_end_time) {
  const current_time = new Date();

  // Convert offer start and end times to Date objects
  const start_time = new Date(offer_start_time);
  const end_time = new Date(offer_end_time);
  if (current_time > end_time) {
    return "Expired";
  } else if (current_time < start_time) {
    return "Upcoming";
  } else if (current_time >= start_time && current_time <= end_time) {
    return "Live";
  }
}

/* eslint-disable eqeqeq */
export const uuidv4 = () => {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};
export const minDescLength = 10
export const maxDescLength = 1024
const CustomMultiValueRemove = (props) => {
  return null; // Returning null will remove the remove icon
};

// Custom ClearIndicator component
const CustomClearIndicator = (props) => {
  return null; // Returning null will remove the clear icon
};

// Apply custom components
export const customComponents = {
  MultiValueRemove: CustomMultiValueRemove,
  ClearIndicator: CustomClearIndicator,
};
export const handleKeyDownForSelect = (event) => {
  // Prevent default behavior when Enter key is pressed
  if (event.keyCode === 13 || event.keyCode === 8 || event.keyCode === 46) {
    event.preventDefault();
  }
};
export function isEndDateTimeGreaterThanStartDateTime(startDate, startTime, endDate, endTime) {
  if(!startDate || !startTime || !endDate || !endTime) return false
  // Concatenate start date and time
  const startDateTime = new Date(`${startDate.toDateString()} ${startTime}`);

  // Concatenate end date and time
  const endDateTime = new Date(`${endDate.toDateString()} ${endTime}`);

  // Compare end date and time with start date and time
  return endDateTime > startDateTime;
}


export function calculatePercentage(value, percentage) {
  // Ensure value and percentage are valid numbers
  if (typeof value !== 'number' || typeof percentage !== 'number') {
    return
  }

  // Convert percentage to decimal (0 to 1)
  const percentageDecimal = percentage / 100;

  // Calculate the percentage amount
  return value * percentageDecimal;
}