/* eslint-disable */
import React, { useEffect, useContext } from "react";
import BackButton from "../Common/BackButton";
import { FAQ_MANAGEMENT_URL } from "../Shared/constant";
import { Scrollbars } from "react-custom-scrollbars-2";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CommonLoader from "../Common/CommonLoader";
import moment from "moment";

const ViewFAQ = () => {
  const params = useParams();
  const { websocket } = useContext(WebSocketContext);
  const { selfInfo, viewProduct, userMemberList, loaderMain, device_id, faqList } =
    useSelector((state) => state.adminReducers);
    useEffect(() => {
      if (params?.roomId) {
        let param = {
          transmit: "single",
          url: "faq_listing",
          request: {
            filter: "all",
            limit: 5000,
            page: 1,
            search: "",
            type: "admin",
          DeviceId: device_id,
          },
        };
        wsSend_request(websocket, param);
      }
    }, [params?.roomId,websocket]);
  useEffect(() => {
    if (params?.roomId) {
      let param = {
        transmit: "single",
        url: "faq_detail",
        request: {
          faq_id: params?.roomId,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
    }
  }, [params?.roomId,websocket]);
  function handleLinkClick(event) {
    const target = event.target;

    // Check if the clicked element is an anchor (a) element
    if (target.tagName === "A") {
      // Prevent the default behavior of opening the link
      event.preventDefault();

      // Get the href attribute from the clicked link
      const href = target.getAttribute("href");

      // Redirect the user to the specified URL
      window.open(href, "_blank"); // Opens the link in a new tab
    }
  }
  return (
    <section className="dashboard_wrapper">
      <BackButton targeturl={FAQ_MANAGEMENT_URL} title={"Back"} />
      <div className="view_details">
        <Scrollbars
          style={{ height: "calc(100vh - 153px)" }}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="row" style={{ margin: "0 auto" }}>
            <div className="col-md-8">
              <div className="row">
                {selfInfo?.user_type === "admin" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Added By </label>
                      <div className="text-show">
                        {userMemberList?.list[viewProduct?.added_by]?.email || "-"}
                      </div>
                    </div>
                  </div>
                )}
        
        <div className="col-md-6">
  <div className="form-group">
    <label>Created At</label>
    <div className="text-show">
      {moment(viewProduct.created_at).format('LL')}
    </div>
  </div>
</div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>FAQ Question</label>
                    <div className="text-show">
                      <div
                        className="text-show"
                        dangerouslySetInnerHTML={{
                          __html: viewProduct?.question,
                        }}
                        onClick={handleLinkClick}
                      ></div>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>FAQ Answer</label>
                    <div className="text-show">
                      <div
                        className="text-show"
                        dangerouslySetInnerHTML={{
                          __html: viewProduct?.answer,
                        }}
                        onClick={handleLinkClick}
                      ></div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
      {/* loader start here */}
      {loaderMain && <CommonLoader />}
    </section>
  );
};

export default ViewFAQ;
