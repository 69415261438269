/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import BackButton from "../Common/BackButton";
import {
  USER_MANAGEMENT_URL,
  ADMIN_VIEW_USER_MANAGEMENT_URL,
} from "../Shared/constant";
import AvatarGet from "../Common/AvatarGet";
import { Scrollbars } from "react-custom-scrollbars-2";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import CommonLoader from "../Common/CommonLoader";
import CommonPagination from "../Common/CommonPagination";
import InputField from "../Common/InputField";
import { Tab, Tabs } from "react-bootstrap";
import moment from "moment";
import GradientDiv from "../Common/GradientDiv";
import mediaIcon from "../../assets/img/mediaIcon.png";
import starIcon from "../../assets/img/starIcon.png";
import referralIcon from "../../assets/img/referralIcon.png";
import coinIcon from "../../assets/img/coinIcon.png";

const ViewUser = () => {
  const Navigate = useNavigate();
  const params = useParams();
  const { websocket } = useContext(WebSocketContext);
  const { viewProduct, loaderMain } = useSelector(
    (state) => state.adminReducers
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [userMemberLimit, setuserMemberLimit] = useState("");
  const [userAlertLimit, setuserAlertLimit] = useState("");
  const [userPatientLimit, setuserPatientLimit] = useState("");
  const [userSOSLimit, setuserSOSLimit] = useState("");
  useEffect(() => {
    setCurrentPage(1);
  }, [userMemberLimit, userAlertLimit, userPatientLimit, userSOSLimit]);
  const [key, setKey] = useState("user-detail");
  // page change

  console.log("viewProduct", viewProduct);

  useEffect(() => {
    if (params?.roomId) {
      let param = {
        transmit: "single",
        url: "user_details",
        request: {
          user_id: params?.roomId,
        },
      };
      wsSend_request(websocket, param);
    }
  }, [params?.roomId,websocket]);

  const categories = viewProduct?.interest
    ? viewProduct?.interest.split(",")
    : [];
  const achievements = [
    {
      className: "coin-gradient",
      text: viewProduct?.coins,
      src: coinIcon,
      alt: "Coin",
    },
    {
      className: "badge-gradient",
      text: viewProduct?.badge?.level,
      src: viewProduct?.badge?.logo?.thumbnail_url,
      alt: "Badge",
      imgClass: "h-auto w-40px",
    },
  ];
  const UserContribution = [
    {
      className: "referral-gradient",
      text: viewProduct?.user_referral,
      src: referralIcon,
      alt: "Referrals",
    },
    {
      className: "rr-gradient",
      text: viewProduct?.user_ratings,
      src: starIcon,
      alt: "Ratings & Reviews",
    },
    {
      className: "media-gradient",
      text: viewProduct?.user_media,
      src: mediaIcon,
      alt: "Media",
    },
  ];
  const UserResponseOffers = [
    {
      className: "total-yes-gradient",
      text: viewProduct?.user_offer_response
        ?.filter((response) => response.response_value === "Yes")
        ?.reduce((total, response) => total + response.count, 0),
      subTitle: "Total Yes",
      cntClassName: "yes-cnt",
    },
    {
      className: "total-maybe-gradient",
      text: viewProduct?.user_offer_response
        ?.filter((response) => response.response_value === "Maybe")
        ?.reduce((total, response) => total + response.count, 0),
      subTitle: "Total Maybe",
      cntClassName: "maybe-cnt",
    },
    {
      className: "total-no-gradient",
      text: viewProduct?.user_offer_response
        ?.filter((response) => response.response_value === "No")
        ?.reduce((total, response) => total + response.count, 0),
      subTitle: "Total No",
      cntClassName: "no-cnt",
    },
  ];
  const UserResponseEvents = [
    {
      className: "total-yes-gradient",
      text: viewProduct?.user_event_response
        ?.filter((response) => response.response_value === "Yes")
        ?.reduce((total, response) => total + response.count, 0),
      subTitle: "Total Yes",
      cntClassName: "yes-cnt",
    },
    {
      className: "total-maybe-gradient",
      text: viewProduct?.user_event_response
        ?.filter((response) => response.response_value === "Maybe")
        ?.reduce((total, response) => total + response.count, 0),
      subTitle: "Total Maybe",
      cntClassName: "maybe-cnt",
    },
    {
      className: "total-no-gradient",
      text: viewProduct?.user_event_response
        ?.filter((response) => response.response_value === "No")
        ?.reduce((total, response) => total + response.count, 0),
      subTitle: "Total No",
      cntClassName: "no-cnt",
    },
  ];
  return (
    <div className="h-96">
      <div className="breadcrumb mb-3">
        <Link to={USER_MANAGEMENT_URL} className="text-black">
          User
        </Link>
        <span> > </span>
        <Link to="/faq-management">User Detail</Link>
      </div>
      <div className="border h-100 w-100">
        <div className="tab-view-user" id="view-user-tab">
          <Tabs
            id="controlled-tab-user"
            activeKey={key}
            onSelect={(k) => {
              setKey(k);
              setCurrentPage(1);
            }}
            className="mb-3 px-2"
          >
            <Tab eventKey="user-detail" title="View">
              <section className="dashboard_wrapper p-0">
                <div className="view_details">
                  <Scrollbars
                    style={{ height: "calc(100vh - 183px)" }}
                    className="ScrollbarsWrapper overflow-hidden pb-5"
                    renderView={(props) => <div {...props} className="view" />}
                  >
                    <div className="row" style={{ margin: "0 auto" }}>
                      <div className="col-md-6 gap-5 d-flex border-right">
                        <div className="col-md-2 me-2">
                          <div className="form-group">
                            <label className="secondary-text ms-1 col-md-3">
                              Profile Image
                            </label>
                            <div className="product_avatar" id="ViewUser">
                              <AvatarGet
                                type="product"
                                url={
                                  viewProduct?.avatar?.thumbnail_url
                                    ? viewProduct?.avatar?.thumbnail_url
                                    : null
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h6 className="secondary-text col-md-4">
                            User Details
                          </h6>
                          <div className="d-flex gap-1">
                            <div className="col-md-4 py-2">
                              <Link className="text-decoration-none">
                                User Name
                              </Link>
                              <h6>{viewProduct?.username}</h6>
                            </div>
                            <div className="col-md-4 py-2">
                              <Link className="text-decoration-none">
                                User Email
                              </Link>
                              <h6>{viewProduct?.email}</h6>
                            </div>
                          </div>
                          <div className="d-flex gap-1">
                            <div className="col-md-4 py-2">
                              <Link className="text-decoration-none">
                                Gender
                              </Link>
                              <h6>{viewProduct?.gender}</h6>
                            </div>
                            <div className="col-md-4 py-2">
                              <Link className="text-decoration-none">
                                Date Of Birth
                              </Link>
                              <h6>
                                {moment(viewProduct?.dob).format(
                                  "MMMM Do, YYYY"
                                )}
                              </h6>
                            </div>
                          </div>{" "}
                          <div className="d-flex gap-1">
                            <div className="col-md-4 py-2">
                              <Link className="text-decoration-none">
                                Date Of Registration
                              </Link>
                              <h6>
                                {moment(viewProduct?.created_at).format("LL")}
                              </h6>
                            </div>
                            <div className="col-md-4 py-2">
                              <Link className="text-decoration-none">
                                Country
                              </Link>
                              <h6>{viewProduct?.country}</h6>
                            </div>
                          </div>
                          <div className="d-flex gap-1">
                            <div className="col-md-4 py-2">
                              <Link className="text-decoration-none">
                                State
                              </Link>
                              <h6>{viewProduct?.state}</h6>
                            </div>
                            <div className="col-md-5 pe-1 py-2">
                              <Link className="text-decoration-none">
                                Last Location
                              </Link>
                              <h6>{viewProduct?.address}</h6>
                            </div>
                          </div>
                          <div className="d-flex gap-3 flex-wrap w-75 pe-2 mb-2">
                            <div className="col-12">
                              <Link className="text-decoration-none">
                                Category
                              </Link>
                            </div>
                            {categories?.map((category, index) => (
                              <div className="col-auto">
                                <div
                                  key={index}
                                  className="secondary-bg py-1 px-2 w-auto text-center"
                                >
                                  {category}
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="mb-2">
                            <Link className="text-decoration-none secondary-text col-md-4">
                              Achievement
                            </Link>
                          </div>
                          <div
                            className="mb-5 col-md-12 d-flex justify-content-start align-items-center gap-3"
                            id="view-user-gradient"
                          >
                            {achievements?.map((item, index) => (
                              <GradientDiv key={index} {...item} />
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12 px-5">
                          <div className="contributions mb-2">
                            <Link className="secondary-text col-md-4 text-decoration-none">
                              User Contribution
                            </Link>
                          </div>
                          <div
                            className="col-md-12 d-flex justify-content-start align-items-center gap-3"
                            id="view-user-gradient"
                          >
                            {UserContribution?.map((item, index) => (
                              <GradientDiv key={index} {...item} />
                            ))}
                          </div>
                        </div>
                        <div className="col-md-12 mt-5 px-5">
                          <div className="contributions mb-2">
                            <Link className="secondary-text col-md-12 text-decoration-none">
                              User Response
                            </Link>
                            <br />
                            <h6 className="mt-4 mb--10px">Offers</h6>
                          </div>
                          <div className="col-md-12 mt--18px d-flex justify-content-start align-items-center gap-3">
                            {UserResponseOffers?.map((item, index) => (
                              <GradientDiv key={index} {...item} />
                            ))}
                          </div>
                        </div>
                        <div className="col-md-12 mb-4"></div>
                        <div className="col-md-12 mt-3 px-5">
                          <h6 className="mb--10px">Events</h6>

                          <div className="col-md-12 d-flex justify-content-start align-items-center gap-3">
                            {UserResponseEvents?.map((item, index) => (
                              <GradientDiv key={index} {...item} />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Scrollbars>
                </div>
                {/* loader start here */}
                {loaderMain && <CommonLoader />}
              </section>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ViewUser;
