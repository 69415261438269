/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from "react-custom-scrollbars-2";
import CommonToggle from "../Common/CommonToggle";
import moment from "moment";
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";
import CommonModel from "../Common/CommonModel";
import { Collapse, Image } from "react-bootstrap";
import MoreIcon from "../../assets/img/more_options.png";
import { truncateString } from "../Shared/constant";

const SupportManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const { supportList } = useSelector((state) => state.adminReducers);
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState("");
  const [userLimit, setUserLimit] = useState();
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('currentPage') || 1);
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  console.log("supportList", supportList);

  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  // clear search box
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  // select user type
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
    setCurrentPage(1);
  };

  // get supplier list
  useEffect(() => {
    let param = {
      transmit: "single",
      url: "contact_us_listing",
      request: {
        type: userType || "all",
        limit: userLimit || 10,
        page: currentPage,
        search: userSearch.trim(),
      },
    };
    wsSend_request(websocket, param);
  }, [userSearch, userType, userLimit, currentPage,websocket]);

  // edit category
  const EditSupport = (data_) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Reply",
      subtitle: "Reply",
      description: ``,
      modalType: "form",
      button: "Submit",
      Data: data_,
    });
  };
  sessionStorage.setItem('currentPage', currentPage);
  const [activeTableDetails, setActiveTableDetails] = useState("");

  const ViewMoreDetails = (id) => {
    setActiveTableDetails(id);
  };

  // hide more user details
  const ElseMoreDetails = () => {
    setActiveTableDetails("");
  };
  console.log("activeTableDetails", activeTableDetails);

  return (
    <section className="dashboard_wrapper p-0">
      <div className="filter_sectio">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <React.Fragment>
                {userSearch !== undefined && (
                  <div className="searchBoxwithbtn" style={{ width: "100%" }}>
                    <input
                      type="text"
                      className="form-control"
                      value={userSearch}
                      onChange={(e) => {
                        setUserSearch(e.target.value);
                        setCurrentPage(1);
                      }}
                      placeholder={`Search by user name or email-address`}
                      title="Search by user name or email-address"
                      maxLength={50}
                    />
                    {userSearch === "" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-search"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                        <path d="M21 21l-6 -6"></path>
                      </svg>
                    ) : (
                      <i
                        className="bi bi-x-circle-fill searchclear"
                        onClick={() => ClearSearchFiled()}
                      ></i>
                    )}
                  </div>
                )}
              </React.Fragment>
            </div>
          </div>
          <div className="col-md-8 d-flex justify-content-end gap-2">
            <div className="col-md-auto">
              <div className="form-group">
                <select
                  className="form-control status-icon activestatusselet"
                  style={{ width: "100%" }}
                  value={userType}
                  onChange={(e) => SelectUserTypeFnct(e)}
                >
                  <option value="" disabled selected>
                    Status
                  </option>
                  <option value="all">All</option>
                  <option value="user">User</option>
                  <option value="vendor">Vendor</option>
                </select>
              </div>
            </div>

            <div className="col-md-auto">
              <div className="form-group">
                <select
                  className="form-control userLimitselect"
                  style={{ width: "100%" }}
                  value={userLimit}
                  onChange={(e) => setUserLimit(parseInt(e.target.value))}
                >
                  <option value="" disabled selected>
                    Records
                  </option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="60">60</option>
                  <option value="70">70</option>
                  <option value="80">80</option>
                  <option value="90">90</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="tableContent"
        style={{
          height:
            window.screen.width > 540
              ? "calc(100vh - 155px)"
              : "calc(100vh - 143px)",
        }}
      >
        <Scrollbars
          style={{
            height:
              supportList?.pagination?.total_records > 10
                ? window.screen.width < 767
                  ? "calc(100% - 10px)"
                  : "calc(100% - 38px)"
                : "calc(100% - 0px)",
          }}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Full Name</th>
                  <th>Email Address</th>
                  <th>Message</th>
                  <th></th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {supportList &&
                  supportList?.list?.map((user, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr
                          key={index}
                          className={
                            activeTableDetails === user?.id ? "active" : ""
                          }
                        >
                          <td className="capitalize">{user?.type}</td>
                          <td>{user?.username || "-"}</td>
                          <td>{user?.email || "-"}</td>
                          <td
                            dangerouslySetInnerHTML={{
                              __html:
                                user?.message === null
                                  ? "-"
                                  : user?.message.slice(0, 50),
                            }}
                          />

                          <td style={{ width: "10px" }}>
                            <button
                              className={
                                activeTableDetails === user?.id
                                  ? "btn actionbtn active"
                                  : "btn actionbtn"
                              }
                              onClick={() =>
                                activeTableDetails === user?.id
                                  ? ElseMoreDetails()
                                  : ViewMoreDetails(user?.id)
                              }
                              aria-controls="example-collapse-text"
                              aria-expanded={
                                activeTableDetails === user?.id ? true : false
                              }
                            >
                              <Image src={MoreIcon} alt="more icon" />
                            </button>
                          </td>
                          <td>{moment(user?.created_at).format("LL")}</td>
                          <td className="respoactionSupplier">
                            {user?.is_replied ? (
                              <button className="btn w-100 fs-14px text-voilet2 common-voilet-bg px-2 py-1">
                                Replied
                              </button>
                            ) : (
                              <button
                                className="btn w-100 fs-14px common-blue-bg common-voilet-bg px-2 py-1"
                                onClick={() => EditSupport(user)}
                              >
                                Reply
                              </button>
                            )}
                          </td>
                        </tr>
                        {activeTableDetails && (
                          <tr className="tablecollapsewraps">
                            <td colSpan="7">
                              <Collapse in={activeTableDetails === user?.id}>
                                <div>
                                  <table className="table">
                                    <tbody>
                                      <tr>
                                        <th style={{ width: "20%" }}>
                                          Message
                                        </th>
                                        <td
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              user?.message === null
                                                ? "-"
                                                : user?.message,
                                          }}
                                        />
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </Collapse>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                {supportList && supportList?.list.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={8}>
                      Support Not Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {/* pagination */}
        {supportList?.pagination?.total_records > 10 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={supportList?.pagination}
            currentFunction={currentFunction}
          />
        )}
      </div>
      {showModal?.open && (
        <CommonModel
          setUserSearch={setUserSearch}
          setCurrentPage={setCurrentPage}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </section>
  );
};

export default SupportManagement;
