import React from "react";
import removeIcon from "../../assets/img/revoveIconCircle.png";
import MediaViewer from "../Common/MediaViewer";
import { bytesToKB, extractIdFromUrl, isVideoUrl, truncateString } from "../Shared/constant";
import { DeleteFileAPI } from "../../Api/UploadFileAPI";
import { toast } from "react-toastify";
import dragIcon from "../../assets/icons/dragIcon.svg";
import { useDispatch } from "react-redux";
import { LoaderStartMain, setConfirmModal } from "../../redux/actions/adminActions";
const Box = ({
  box,
  draggable,
  onDragStart,
  onDragOver,
  onDrop,
  onRemove,
  imageUrl,
}) => {
  console.log(
    "box, draggable, onDragStart, onDragOver, onDrop, onRemove,imageUrl",
    box,
    draggable,
    onDragStart,
    onDragOver,
    onDrop,
    onRemove,
    imageUrl
  );
  const handleRemoveClick = () => {
    onRemove({ sequence: box.sequence, name: imageUrl });
  };
  
  return (
    <div
      className="box w-20 px-2"
      draggable={draggable}
      onDragStart={onDragStart({ sequence: box.sequence })}
      onDragOver={onDragOver({ sequence: box.sequence })}
      onDrop={onDrop({ sequence: box.sequence })}
      style={{ position: "relative", minHeight: "285px", minWidth: "255px" }}
    >
      <div className="content rounded d-flex flex-column position-relative">
        <MediaViewer
          media={imageUrl}
          thumbnail={
           box?.url?.gif_url ||
           box?.url?.thumbnail_url ||
           box?.url?.file_url 
          }
          maxWidth="255px"
          maxHeight="285px"
          minHeight={"285px"}
          minWidth={"255px"}
        />
        <div className="drag-icon-container">
          <img
            src={dragIcon}
            alt=""
            height={"30px"}
            width={"30px"}
            className="drag-icon"
          />
        </div>
        <span
          className="br-50 bg-primary d-flex align-items-center justify-content-center h-1-5rem w-1-5rem text-white p-2 position-absolute"
          style={{ right: "3%", bottom: "24%" }}
        >
          <small className="p-">{box?.sequence}</small>
        </span>
        <img
          src={removeIcon}
          alt="removeIcon"
          className="bi bi-x position-absolute cursor-pointer w-1-5rem rounded-circle "
          onClick={handleRemoveClick}
          style={{ right: "-1.5rem", top: "-5px", zIndex: "1" }} // Adjust the positioning as needed
        />
        <div className="fw-700 ms-3">
          <span className="">{truncateString(box?.original_name,25)}</span>
        </div>
        <div className="secondary-text ms-3">
          <span>{bytesToKB(box?.size)}</span>
        </div>
      </div>
    </div>
  );
};

const BoxesGroup = ({ boxes, setBoxes, accessToken }) => {
  console.log("boxes,setBoxes", boxes, setBoxes);

  const swapBoxes = (fromBox, toBox) => {
    setBoxes((prevBoxes) => {
      const newBoxes = [...prevBoxes];
      const fromIndex = newBoxes.findIndex(
        (box) => box.sequence === fromBox.sequence
      );
      const toIndex = newBoxes.findIndex(
        (box) => box.sequence === toBox.sequence
      );

      if (fromIndex !== -1 && toIndex !== -1) {
        const tempPosition = newBoxes[fromIndex].sequence;
        newBoxes[fromIndex] = {
          ...newBoxes[fromIndex],
          sequence: newBoxes[toIndex].sequence,
        };
        newBoxes[toIndex] = { ...newBoxes[toIndex], sequence: tempPosition };
      }

      return newBoxes;
    });
  };

  const handleDragStart = (data) => (event) => {
    const fromBox = JSON.stringify({ sequence: data.sequence });
    event.dataTransfer.setData("dragContent", fromBox);
  };

  const handleDragOver = (data) => (event) => {
    event.preventDefault();
    return false;
  };

  const handleDrop = (data) => (event) => {
    event.preventDefault();

    const fromBox = JSON.parse(event.dataTransfer.getData("dragContent"));
    const toBox = { sequence: data.sequence };

    swapBoxes(fromBox, toBox);

    // Update the sequence based on the new order
    setBoxes((prevBoxes) => {
      const sortedBoxes = [...prevBoxes].sort(
        (a, b) => a.sequence - b.sequence
      );
      const updatedBoxes = sortedBoxes.map((box, index) => ({
        ...box,
        sequence: index + 1,
      }));
      return updatedBoxes;
    });

    return false;
  };
  const Dispatch = useDispatch()
  const removeMediaAPIHandler = async (data) => {
    Dispatch(LoaderStartMain(true));
    console.log("data handleRemove", data);
    const result = await DeleteFileAPI(
      accessToken,
      extractIdFromUrl(data?.name)
    );
    if (result?.status === 200) {
      toast.success(result?.file);
      setBoxes((prevBoxes) => {
        const newBoxes = prevBoxes.filter(
          (box) => box.sequence !== data.sequence
        );
        return newBoxes.map((box, index) => ({ ...box, sequence: index+1 }));
      });
    } else {
      toast?.error(result?.file || result?.msg);
    }
      Dispatch(LoaderStartMain(false));
  }
  const handleRemove = async (data) => {
    Dispatch(setConfirmModal({
      show:"MEDIA",
      callBack: (e) => removeMediaAPIHandler(data)
    }))
  };

  const makeBoxes = () => {
    return (
      <div className="boxesGroup d-flex gap-5 w-100 flex-wrap">
        {boxes
          ?.sort((a, b) => a.sequence - b.sequence)
          ?.map((box) => (
            <Box
              key={box.sequence}
              box={box}
              draggable="true"
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onRemove={handleRemove}
              imageUrl={
                isVideoUrl(box?.url?.file_url)
                  ? box?.url?.file_url
                  : box?.url?.thumbnail_url
              }
            />
          ))}
      </div>
    );
  };

  return makeBoxes();
};

export default BoxesGroup;
