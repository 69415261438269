import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import fileUploadIcon from "../../assets/icons/fileUpload.png";
import successIcon from "../../assets/icons/SuccessIcon.png";
import UploadFileAPI, { ThumbnailFileAPI } from "../../Api/UploadFileAPI";
import { useDispatch, useSelector } from "react-redux";
import uploadIcon from "../../assets/icons/uploadIcon.svg";
import { LoaderStartMain } from "../../redux/actions/adminActions";
export const AddThumbnailModal = (props) => {
  const { accessToken, selfInfo } = useSelector(
    (state) => state.adminReducers
  );
  const Dispatch = useDispatch()
  const [fileUploadModal, setFileUploadModal] = useState(false);
  const [successModal, setShowSuccessModalThumbnail] = useState(false);


  const UploadImage = async (e) => {
    Dispatch(LoaderStartMain(true));

    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("is_admin", selfInfo?.user_type === "admin" ? "1" : "0");
    formData.append("to", props?.data?.to);
    formData.append("thumbnail", files, files.name);
    formData.append("parent_id", props?.data?.parent_id);
    setShowSuccessModalThumbnail(true);

    const fileresponse = await UploadFileAPI(accessToken, formData);
    console.log("fileresponse", fileresponse, formData);

    if (fileresponse) {
      const result = await ThumbnailFileAPI(
        accessToken,
        props?.data?.parent_id
      );
      console.log("result", result);
      // Assuming fileData is an array of objects
      const updatedFileData = props?.fileData?.map((file) => {
        if (file.id === props?.data?.parent_id) {
          // Update the specific object by merging with fileresponse
          return {
            ...file,
            thumbnail: result,
          };
        }
        return file; // Return unchanged objects
      });

      // Set the updated array back to state
      props?.setFileData(updatedFileData);
      setTimeout(() => {
        props?.setShowVideoModal((prev) => ({
          ...prev,
          show: false,
        }));
      }, 1000);
    }
    Dispatch(LoaderStartMain(false));

  };

  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const fileInput = fileInputRef.current;
    if (fileInput.files.length > 0) {
      UploadImage(e);
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Modal centered show id="thumbnail-modal">
      <Modal.Dialog>
        <Modal.Body className="text-center p-5">
          {fileUploadModal && !successModal ? (
            <div onClick={handleClick}>
              <div className="check-success-icon">
                <img src={fileUploadIcon} alt="fileUploadIcon" />
              </div>
              <p className="secondary-text mb-4 text-black fs-5">
                Upload Image
                <form className="file-upload-form mt-3" id="add-edit-event">
                  <label htmlFor="file" className="p-3 file-upload-label">
                    <div className="file-upload-design">
                      <img src={uploadIcon} alt="" />
                      <p>
                        <small className="browse-button px-1 py-0 text-primary">
                          Click to upload
                        </small>
                        or drag and drop
                      </p>
                    </div>
                    <input
                      ref={fileInputRef}
                      id="fileInput"
                      type="file"
                      style={{ display: "none" }}
                      className="form-control"
                      onChange={(e) => handleFileChange(e)}
                      accept=".png, .jpg, .jpeg, .gif"
                    />
                  </label>
                </form>
              </p>
            </div>
          ) : (
            successModal && (
              <div
                onClick={() => {
                  props?.setShowVideoModal((prev) => ({
                    ...prev,
                    show: false,
                  }));
                }}
              >
                <div className="check-success-icon">
                  <img src={successIcon} alt="fileUploadIcon" />
                </div>
                <p className="mb-4 text-black fs-5">Upload Complete</p>
              </div>
            )
          )}
          {!fileUploadModal && !successModal && (
            <>
              <div className="check-success-icon" onClick={handleClick}>
                <img src={fileUploadIcon} alt="fileUploadIcon" />
              </div>
              <p className="secondary-text mb-4 text-black fs-5">
                Would you like to upload a thumbnail image for this video?
              </p>
              <div className="d-flex justify-content-around gap-4">
                <Button
                  className="btn text-secondary secondary-bg px-5 py-3 w-50"
                  onClick={() => {
                    props?.setShowVideoModal((prev) => ({
                      ...prev,
                      show: false,
                    }));
                  }}
                >
                  No
                </Button>
                <Button
                  className="btn w-50 py-3 px-5"
                  onClick={() => {
                    setFileUploadModal(true);
                  }}
                >
                  Yes
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
};
