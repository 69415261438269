import React from "react";
import defaultThumbnail from "../../assets/img/logoIcon.png";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <br />
      <div className="row">
        <div className="col-md-12">
          <img src={defaultThumbnail} alt="logo" height={100} width={100} />
          <h4>E Find</h4>
        </div>
      </div>
      <h1>Privacy Policy</h1> <br />
      <div className="mb-5">
        <div className="page" title="Page 2">
          <div className="section">
            <div className="layoutArea">
              <div className="column">
                <strong>
                  Introduction
                  <br />
                </strong>
                We are committed to safeguarding the privacy of our Application
                users. This Privacy Policy sets out how we will treat your
                personal information.
                <br />
                <p>&nbsp;</p>
                <p>
                  <strong>
                    Data Collection
                    <br />
                  </strong>
                  We may collect, store, and use the following kinds of personal
                  data:
                  <br />
                  Information about your device and about your visits to and use
                  of this Application. Information that you provide to us for
                  the purpose of registering warranties.
                  <br />
                  Any other information that you choose to send to us.
                </p>
                <p>
                  <strong>
                    Using Your Personal Data
                    <br />
                  </strong>
                  Personal data submitted on this Application will be used for
                  the purposes specified in this Privacy Policy or in relevant
                  parts of the Application.
                  <br />
                  We may use your personal information to:
                  <br />
                  Administer and manage warranties.
                  <br />
                  Send you email notifications related to the Application.
                  <br />
                  Handle inquiries and complaints made by or about you relating
                  to the Application.
                </p>
                <p>
                  <strong>
                    Data Protection
                    <br />
                  </strong>
                  We will take reasonable technical and organisational
                  precautions to prevent the loss, misuse, or alteration of your
                  personal information.
                  <br />
                  All personal data submitted through this Application will be
                  processed in accordance with relevant data protection
                  regulations, including but not limited to the General Data
                  Protection Regulation (GDPR).
                </p>
                <p>
                  <br />
                  <strong>
                    Data Retention
                    <br />
                  </strong>
                  We will retain your personal data only for as long as
                  necessary to fulfil the purposes for which it was collected or
                  to comply with legal, regulatory, or internal policy
                  requirements. Your Rights
                </p>
                <p>
                  <strong>You have the right to:</strong>
                  <br />
                  Access the personal data we hold about you.
                  <br />
                  Request correction of any inaccurate personal data we hold
                  about you.
                  <br />
                  Request deletion of your personal data.
                  <br />
                  Object to the processing of your personal data.
                  <br />
                  Request data portability.
                  <br />
                  To exercise any of these rights, please contact us using the
                  provided contact details.
                </p>
                <p>
                  <strong>
                    Third Parties
                    <br />
                  </strong>
                  We will not sell, distribute, or lease your personal
                  information to third parties unless we have your permission or
                  are required by law to do so.
                  <br />
                  However, we may disclose your personal data to any member of
                  our group of companies (subsidiaries, our ultimate holding
                  company, and all its subsidiaries) as reasonably necessary for
                  the purposes set out in this policy.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="page" title="Page 3">
          <div className="section">
            <div className="layoutArea">
              <div className="column">
                <p>
                  <strong>
                    Cross-border Data Transfers
                    <br />
                  </strong>
                  Personal data that you submit for publication through the
                  Application may be available, via the internet, worldwide. We
                  cannot prevent the use or misuse of such information by
                  others.
                </p>
                <p>
                  <strong>
                    Updates to This Policy
                    <br />
                  </strong>
                  We may update this privacy policy from time to time. Any
                  changes will be posted on this page, and you are advised to
                  check this page regularly.
                </p>
                <p>
                  <strong>
                    Contact
                    <br />
                  </strong>
                  If you have any questions about this privacy policy or our
                  treatment of your personal data, please contact us at our
                  provided contact details.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
