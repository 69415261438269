/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import BackButton from "../Common/BackButton";
import {
  RatingStars,
  USER_MANAGEMENT_URL,
  ADMIN_VIEW_USER_MANAGEMENT_URL,
  checkOpeningClosingTimes,
  imagesArray,
  isVideoUrl,
  convertToAmPm,
  capitalizeFirstLetter,
  ADMIN_VENDOR_MANAGEMENT_URL,
} from "../Shared/constant";
import AvatarGet from "../Common/AvatarGet";
import { Scrollbars } from "react-custom-scrollbars-2";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import CommonLoader from "../Common/CommonLoader";
import CommonPagination from "../Common/CommonPagination";
import InputField from "../Common/InputField";
import { Tab, Tabs } from "react-bootstrap";
import moment from "moment";
import MediaViewer from "../Common/MediaViewer";
import OpeningHoursToggle from "./OpeningHoursToggle";
import { DeleteFileAPI } from "../../Api/UploadFileAPI";
import { toast } from "react-toastify";
import CommonewModel from "../Common/CommonewModel";
import { LoaderStartMain, setConfirmModal } from "../../redux/actions/adminActions";
const ViewVendor = () => {
  const Navigate = useNavigate();
  const params = useParams();
  const { websocket } = useContext(WebSocketContext);
  const {
    productList,
    viewProduct,
    loaderMain,
    accessToken,
    vendorViewGalleryList,
    selfInfo,
    alertList,
    usersSOSListByID,
    vendorViewTCList,
    vendorViewReviewList,
    userMemberList,
    userAlertListByIDall,
    userSOSListByIDall,
    userPatientListByIDall,
  } = useSelector((state) => state.adminReducers);
  console.log("viewProduct", viewProduct);
  const [currentPage, setCurrentPage] = useState(1);
  const [GalleryLimit, setGalleryLimit] = useState("");
  const [UTCLimit, setUTCLimit] = useState("");
  const [userPatientLimit, setuserPatientLimit] = useState("");
  const [ReviewLimit, setReviewLimit] = useState("");
  const [key, setKey] = useState("user-detail");
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState("");
  console.log("key", key, "vendorViewReviewList", vendorViewReviewList);
  const [showModalNew, setShowModalNew] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  useEffect(() => {
    setCurrentPage(1);
  }, [GalleryLimit, UTCLimit, userPatientLimit, ReviewLimit, key]);
  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (params?.roomId) {
      let param = {
        transmit: "single",
        url: "vendor_get",
        request: {
          vendor_id: params?.roomId,
          user_type: selfInfo?.user_type || "admin",
        },
      };
      wsSend_request(websocket, param);
    }
  }, [params?.roomId,websocket]);
  const getGalleryImagesHandler = async () => {
    const param = {
      transmit: "single",
      url: "vendor_gallery_listing",
      request: {
        limit: GalleryLimit || 10,
        user_type: selfInfo?.user_type || "admin",
        page: currentPage,
        search: userSearch.trim(),
        filter: userType,
        vendor_id: params?.roomId,
      },
    };
    wsSend_request(websocket, param);
  };
  useEffect(() => {
    let param = "";
    if (key === "gallery") {
      getGalleryImagesHandler();
    } else if (key === "utc") {
      param = {
        transmit: "single",
        url: "vendor_coin_transaction",
        request: {
          limit: UTCLimit || 10,
          user_type: "vendor",
          page: currentPage,
          vendor_id: params?.roomId,
        },
      };
      wsSend_request(websocket, param);
    } else if (key === "review") {
      param = {
        transmit: "single",
        url: "review_listing",
        request: {
          limit: ReviewLimit || 10,
          user_type: selfInfo?.user_type || "admin",
          page: currentPage,
          search: userSearch.trim(),
          filter: userType,
          vendor_id: params?.roomId,
        },
      };
      wsSend_request(websocket, param);
    }
  }, [
    params?.roomId,
    currentPage,
    UTCLimit,
    GalleryLimit,
    ReviewLimit,
    key,
    userType,
    userSearch,websocket
  ]);
  const Dispatch = useDispatch()
  const removeMediaAPIHandler = async (id) => {
    Dispatch(LoaderStartMain(true));
    const response = await DeleteFileAPI(accessToken, id);
    if (response?.status === 200) {
      toast.success(response?.file);
      getGalleryImagesHandler();
    } else {
      toast.error(response?.error);
    }
    console.log("response", response, "id", id, "accessToken", accessToken);
      Dispatch(LoaderStartMain(false));
  }
  const DeleteFile = async (id) => {
    Dispatch(setConfirmModal({
      show:"MEDIA",
      callBack: (e) => removeMediaAPIHandler(id)
    }))
  };
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
    setCurrentPage(1);
  };
  const DeleteUserFnct = (data_) => {
    setShowModalNew({
      ...showModalNew,
      open: !showModalNew?.open,
      subtitle: "Delete Vendor Review By Admin",
      description: `Are you sure you want to delete this review <h6 class="text-small mt-3 fs-16px">${data_?.review}</h6>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
      closeText: "No",
      icon: "binIcon",
    });
  };
  const openingClosingTimes = checkOpeningClosingTimes(
    viewProduct?.opening_hours
  );
  return (
    <div className="h-94 w-100">
      <div className="breadcrumb mb-3">
        <Link className="text-black" to={ADMIN_VENDOR_MANAGEMENT_URL}>
          Vendor List
        </Link>
        <span> > </span>
        <Link>View Details</Link>
      </div>
      <div className="h-100 w-100 border">
        <div className="tab-view-user" id="view-user-tab">
          <Tabs
            id="controlled-tab-user"
            activeKey={key}
            onSelect={(k) => {
              setKey(k);
              setCurrentPage(1);
            }}
            className="mb-3"
          >
            <Tab eventKey="user-detail" title="View">
              <section className="dashboard_wrapper p-0 border-0">
                <div className="view_details px-3 py-0">
                  <Scrollbars
                    style={{ height: "calc(100vh - 253px)" }}
                    className="ScrollbarsWrapper"
                    renderView={(props) => <div {...props} className="view" />}
                  >
                    <div className="mb-2">
                      <h6 className="fs-18px ms-3">Business Details</h6>
                    </div>
                    <div className="row" style={{ margin: "0 auto" }}>
                      <div className="contact-details col-md-12">
                        <div className="business-details col-md-12 border rounded p-4">
                          <div className="col-md-1">
                            <div className="form-group">
                              <div
                                className="product_avatar"
                                id="view-vendor-avatar"
                              >
                                <AvatarGet
                                  type="product"
                                  url={
                                    viewProduct?.logo?.file_url
                                      ? viewProduct?.logo?.thumbnail_url
                                      : null
                                  }
                                />
                                <label className="ms-4 fw-600">Logo</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-11 d-flex flex-column">
                            <div className="col-md-12 d-flex">
                              <p className="secondary-text fs-16px col-md-2 text-end">
                                Business Name
                              </p>
                              <p className="ms-3 fs-16px col-md-9">
                                {viewProduct?.business_name
                                  ? viewProduct?.business_name
                                  : "-"}
                              </p>
                            </div>
                            <div className="col-md-12 d-flex">
                              <p className="secondary-text fs-16px col-md-2 text-end">
                                Open Close time{" "}
                              </p>
                              <p className="ms-3 fs-16px col-md-9">
                                {viewProduct?.opening_hours ? (
                                  <>
                                    {openingClosingTimes
                                      ?.sameOpeningAndClosingTimes?.length >
                                      0 && (
                                      <>
                                        {openingClosingTimes.sameOpeningAndClosingTimes.map(
                                          (entry, index) => (
                                            <React.Fragment key={index}>
                                              {index > 0 && ", "}
                                              {capitalizeFirstLetter(
                                                entry.days
                                              )}
                                              :{" "}
                                              <strong>
                                                {entry.time.split(" to ")[0]} to{" "}
                                                {entry.time.split(" to ")[1]}
                                              </strong>
                                              <br />
                                            </React.Fragment>
                                          )
                                        )}
                                      </>
                                    )}

                                    {openingClosingTimes?.differentTimes
                                      ?.length > 0 && (
                                      <>
                                        {openingClosingTimes.differentTimes.map(
                                          (day, index) => (
                                            <React.Fragment key={index}>
                                              {index > 0 && ", "}
                                              {capitalizeFirstLetter(day)}:{" "}
                                              <strong>
                                                {convertToAmPm(
                                                  viewProduct.opening_hours.find(
                                                    (o) => o.day === day
                                                  )?.time.opening_time
                                                )}{" "}
                                                to{" "}
                                                {convertToAmPm(
                                                  viewProduct.opening_hours.find(
                                                    (o) => o.day === day
                                                  )?.time.closing_time
                                                )}
                                              </strong>
                                            </React.Fragment>
                                          )
                                        )}
                                        <br />
                                      </>
                                    )}

                                    {openingClosingTimes?.daysNotPresent
                                      ?.length > 0 && (
                                      <>
                                        {capitalizeFirstLetter(
                                          openingClosingTimes.daysNotPresent
                                            .map((day) =>
                                              capitalizeFirstLetter(day)
                                            )
                                            .join(", ")
                                        ) || "-"}{" "}
                                        <strong>: Closed</strong>
                                        <br />
                                      </>
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </p>
                            </div>{" "}
                            <div className="col-md-12 d-flex">
                              <p className="secondary-text fs-16px col-md-2 text-end">
                                Category
                              </p>
                              <p className="ms-3 fs-16px col-md-9">
                                {viewProduct?.interest
                                  ? viewProduct?.interest
                                  : "-"}
                              </p>
                            </div>{" "}
                            <div className="col-md-12 d-flex">
                              <p className="secondary-text fs-16px col-md-2 text-end">
                                Tags
                              </p>
                              <p className="ms-3 fs-16px col-md-9">
                                {viewProduct?.sub_interest
                                  ? viewProduct?.sub_interest
                                  : "-"}
                              </p>
                            </div>{" "}
                            <div className="col-md-12 d-flex">
                              <p className="secondary-text fs-16px col-md-2 text-end">
                                Coin
                              </p>
                              <p className="ms-3 fs-16px col-md-9">
                                {viewProduct?.coins ? viewProduct?.coins : "-"}
                              </p>
                            </div>{" "}
                            <div className="col-md-12 d-flex">
                              <p className="secondary-text fs-16px col-md-2 text-end">
                                Address
                              </p>
                              <p className="ms-3 fs-16px col-md-9">
                                {viewProduct?.address
                                  ? viewProduct?.address
                                  : "-"}
                              </p>
                            </div>
                            <div className="col-md-12 d-flex">
                              <p className="secondary-text fs-16px col-md-2 text-end">
                                Description
                              </p>
                              <p
                                className="mx-3 col-md-10"
                                dangerouslySetInnerHTML={{
                                  __html: viewProduct?.store_description,
                                }}
                              />
                            </div>
                            <div className="col-md-12 d-flex">
                              <p className="secondary-text fs-16px col-md-2 text-end">
                                Ratings
                              </p>

                              <p className="ms-3 fs-16px col-md-6 d-flex gap-1">
                                {viewProduct?.rating}{" "}
                                <RatingStars rating={viewProduct?.rating} />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mb-1 mt-3">
                          <h6 className="fs-18px">Contact Details</h6>
                        </div>

                        <div className="col-md-12  d-flex flex-column border rounded p-4 mt-2">
                          <div className="col-md-12 d-flex align-items-center">
                            <p className="secondary-text fs-16px col-md-3 text-end">
                              Business Contact Person
                            </p>
                            <p className="ms-3 fs-16px col-md-6">
                              {viewProduct?.business_contact_person
                                ? viewProduct?.business_contact_person
                                : "-"}
                            </p>
                          </div>
                          <div className="col-md-12 d-flex align-items-center">
                            <p className="secondary-text fs-16px col-md-3 text-end">
                              Business email ID
                            </p>
                            <p className="ms-3 fs-16px col-md-6">
                              {viewProduct?.business_email_id
                                ? viewProduct?.business_email_id
                                : "-"}
                            </p>
                          </div>{" "}
                          <div className="col-md-12 d-flex align-items-center">
                            <p className="secondary-text fs-16px col-md-3 text-end">
                              Business Contact Number
                            </p>
                            <p className="ms-3 fs-16px col-md-6">
                              {viewProduct?.business_contact_number
                                ? viewProduct?.business_contact_number
                                : "-"}
                            </p>
                          </div>{" "}
                          <div className="col-md-12 d-flex align-items-center">
                            <p className="secondary-text fs-16px col-md-3 text-end">
                              Contact Person
                            </p>
                            <p className="ms-3 fs-16px col-md-6">
                              {viewProduct?.owner_contact_person || "-"}
                            </p>
                          </div>{" "}
                          <div className="col-md-12 d-flex align-items-center">
                            <p className="secondary-text fs-16px col-md-3 text-end">
                              Contact Number
                            </p>
                            <p className="ms-3 fs-16px col-md-6">
                              {viewProduct?.owner_contact_number || "-"}
                            </p>
                          </div>{" "}
                          <div className="col-md-12 d-flex align-items-center">
                            <p className="secondary-text fs-16px col-md-3 text-end">
                              Email ID
                            </p>
                            <p className="ms-3 fs-16px col-md-6">
                              {viewProduct?.owner_email_id || "-"}
                            </p>
                          </div>
                        </div>

                        <div className=" mt-3 d-flex justify-content-between">
                          <h6 className="fs-18px">Image & Video</h6>
                          <Link className="text-decoration-none me-5" hidden>
                            View All
                          </Link>
                        </div>

                        <div className="col-md-12  d-flex flex-column border rounded p-2 mt-1">
                          <div className="col-md-3 d-flex align-items-center gap-2">
                            {viewProduct?.files?.length > 0 &&
                              viewProduct?.files?.map((item, index) => {
                                return (
                                  <MediaViewer
                                    media={item?.url?.file_url}
                                    maxHeight={"150px"}
                                    maxWidth={"150px"}
                                    thumbnail={
                                      isVideoUrl(item?.url?.file_url)
                                        ? item?.url?.thumbnail_url
                                        : ""
                                    }
                                  />
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Scrollbars>
                </div>
                {/* loader start here */}
                {loaderMain && <CommonLoader />}
              </section>
            </Tab>
            <Tab eventKey="gallery" title="Gallery">
              <section className="dashboard_wrapper p-0 border-0">
                <div className="row d-flex justify-content-end">
                  <div className="col-md-auto">
                    <div className="form-group">
                      <React.Fragment>
                        {userSearch !== undefined && (
                          <div
                            className="searchBoxwithbtn"
                            style={{ width: "100%" }}
                          >
                            <input
                              type="text"
                              className="form-control"
                              value={userSearch}
                              onChange={(e) => {
                                setUserSearch(e.target.value);
                                setCurrentPage(1);
                              }}
                              placeholder={`Search by code`}
                              title="Search by code"
                      maxLength={50}
                            />
                            {userSearch === "" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-search"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                <path d="M21 21l-6 -6"></path>
                              </svg>
                            ) : (
                              <i
                                className="bi bi-x-circle-fill searchclear"
                                onClick={() => ClearSearchFiled()}
                              ></i>
                            )}
                          </div>
                        )}
                      </React.Fragment>
                    </div>
                  </div>
                  <div className="col-md-auto">
                    <div className="form-group">
                      <select
                        className="form-control activestatusselet"
                        style={{ width: "100%" }}
                        value={userType}
                        onChange={(e) => SelectUserTypeFnct(e)}
                      >
                        <option value="" disabled selected>
                          Type
                        </option>
                        <option value="all">All</option>
                        <option value="image">Image</option>
                        <option value="video">Video</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-auto">
                    <div className="form-group">
                      <select
                        className="form-control GalleryLimitselect"
                        style={{ width: "100%" }}
                        value={GalleryLimit}
                        onChange={(e) =>
                          setGalleryLimit(parseInt(e.target.value))
                        }
                      >
                        <option value="" disabled selected>
                          Records
                        </option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="60">60</option>
                        <option value="70">70</option>
                        <option value="80">80</option>
                        <option value="90">90</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="view_details">
                  <Scrollbars
                    style={{
                      height:
                        vendorViewGalleryList?.pagination?.total_records > 10
                          ? window.screen.width < 767
                            ? "calc(100% - 10px)"
                            : "calc(100% - 38px)"
                          : "calc(100% - 0px)",
                    }}
                    className="ScrollbarsWrapper"
                    renderView={(props) => <div {...props} className="view" />}
                  >
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>File</th>
                            <th>Format</th>

                            <th>Name</th>

                            <th>User Code</th>
                            <th>Created At</th>
                            <th>Likes</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {vendorViewGalleryList &&
                            vendorViewGalleryList?.list?.map((user, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td>
                                      {" "}
                                      <p className="w-1-5rem">
                                        <MediaViewer
                                          maxHeight={"50px"}
                                          maxHeight={"50px"}
                                          media={user?.url?.file_url}
                                          minHeight={"50px"}
                                          minWidth={"50px"}
                                          thumbnail={
                                            user?.url?.gif_url || user?.url?.thumbnail_url || user?.url?.file_url
                                          }
                                        />
                                      </p>
                                    </td>
                                    <td>
                                      {user?.ext === "image"
                                        ? "Image"
                                        : "Video"}
                                    </td>
                                    <td>
                                      {user?.username ? user?.username : "-"}
                                    </td>

                                    <td>
                                      {user?.usercode ? user?.usercode : "-"}
                                    </td>
                                    <td>
                                      {user?.created_at
                                        ? moment(user?.created_at).format("LL")
                                        : "-"}
                                    </td>
                                    <td>
                                      {user?.like_count
                                        ? user?.like_count
                                        : "-"}
                                    </td>

                                    <td className="respoactionSupplier">
                                      {(selfInfo?.user_type === "admin" ||
                                        selfInfo?.permissions?.includes(
                                          "update-category"
                                        )) && (
                                        <button
                                          type="button"
                                          className="btn actiontblebtn view-btn"
                                          hidden
                                        >
                                          <i className="bi bi-eye"></i>
                                        </button>
                                      )}
                                      {(selfInfo?.user_type === "admin" ||
                                        selfInfo?.permissions?.includes(
                                          "delete-category"
                                        )) && (
                                        <button
                                          type="button"
                                          className="btn actiontblebtn delete-btn"
                                          onClick={() => DeleteFile(user?.id)}
                                        >
                                          <i className="bi bi-trash3"></i>
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                          {vendorViewGalleryList &&
                            vendorViewGalleryList?.list?.length === 0 && (
                              <tr>
                                <td
                                  style={{ textAlign: "center" }}
                                  colSpan={10}
                                >
                                  Media Not Found !
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </Scrollbars>
                </div>
                {/* loader start here */}
                {parseInt(vendorViewGalleryList?.pagination?.total_records) >
                  10 && (
                  <CommonPagination
                    currentPage={currentPage}
                    paginationLength={vendorViewGalleryList?.pagination}
                    currentFunction={currentFunction}
                  />
                )}
                {loaderMain && <CommonLoader />}
              </section>
            </Tab>
            <Tab eventKey="utc" title="Users Coin Transaction">
              <section className="dashboard_wrapper p-0 border-0">
                <div className="view_details">
                  <Scrollbars
                    style={{ height: "calc(100vh - 153px)" }}
                    className="ScrollbarsWrapper"
                    renderView={(props) => <div {...props} className="view" />}
                  >
                    <div className="row" style={{ margin: "0 auto" }}>
                      <div
                        className="tableContent p-0"
                        style={{
                          height:
                            window.screen.width > 540
                              ? "calc(100vh - 262px)"
                              : "calc(100vh - 143px)",
                        }}
                      >
                        <Scrollbars
                          style={{
                            height:
                              vendorViewTCList?.pagination?.total_records > 10
                                ? window.screen.width < 767
                                  ? "calc(100% - 10px)"
                                  : "calc(100% - -20px)"
                                : "calc(100% - 0px)",
                          }}
                          className="ScrollbarsWrapper"
                          renderView={(props) => (
                            <div {...props} className="view" />
                          )}
                        >
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>User Name</th>
                                  <th>User Code</th>
                                  <th>Bill Number</th>
                                  <th>Bill Amount</th>
                                  {/* <th>Coin Amount</th>
                                  <th>Coin</th> */}
                                  <th>Coin Percentage</th>
                                  <th>Coin Transaction ID</th>
                                  <th>Created At</th>
                                </tr>
                              </thead>
                              <tbody>
                                {vendorViewTCList?.list &&
                                  vendorViewTCList?.list?.map((user, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        <tr>
                                          <td
                                            className="d-flex aic"
                                            id="list-avatar"
                                          >
                                            <AvatarGet
                                              type="product"
                                              url={
                                                user?.url?.avatar?.thumbnail_url
                                              }
                                            />
                                            <span className="mt-1">
                                              {" "}
                                              {user?.user?.name}{" "}
                                            </span>
                                          </td>

                                          <td>{user?.code}</td>
                                          <td>{user?.billing_number}</td>
                                          <td>
                                            {user?.total_amount}{" "}
                                            <span className="secondary-text">
                                             {user?.total_amount ? "AED" : "-"} 
                                            </span>{" "}
                                          </td>
                                          <td>
                                            {user?.percentage_redemption}
                                            {user?.percentage_redemption ? "%" : "-"}
                                          </td>
                                          <td>{user?.transaction_id}</td>

                                          <td>
                                            {moment(user?.created_at).format(
                                              "LL"
                                            )}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  })}
                                {vendorViewTCList &&
                                  vendorViewTCList?.list?.length === 0 && (
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan={10}
                                      >
                                        Data Not Found !
                                      </td>
                                    </tr>
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </Scrollbars>
                        {/* pagination */}
                        {vendorViewTCList?.pagination?.total_records > 10 && (
                          <CommonPagination
                            currentPage={currentPage}
                            paginationLength={vendorViewTCList?.pagination}
                            currentFunction={currentFunction}
                          />
                        )}
                      </div>
                    </div>
                  </Scrollbars>
                </div>
                {/* loader start here */}
                {loaderMain && <CommonLoader />}
              </section>
            </Tab>
            <Tab eventKey="review" title="Review">
              <section className="dashboard_wrapper p-0 border-0">
                <div className="view_details">
                  <Scrollbars
                    style={{ height: "calc(100vh - 153px)" }}
                    className="ScrollbarsWrapper"
                    renderView={(props) => <div {...props} className="view" />}
                  >
                    <div className="row" style={{ margin: "0 auto" }}>
                      <div
                        className="tableContent p-0"
                        style={{
                          height:
                            window.screen.width > 540
                              ? "calc(100vh - 255px)"
                              : "calc(100vh - 143px)",
                        }}
                      >
                        <Scrollbars
                          style={{
                            height:
                              vendorViewReviewList?.pagination?.total_records >
                              10
                                ? window.screen.width < 767
                                  ? "calc(100% - 10px)"
                                  : "calc(100% - 38px)"
                                : "calc(100% - 0px)",
                          }}
                          className="ScrollbarsWrapper"
                          renderView={(props) => (
                            <div {...props} className="view" />
                          )}
                        >
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Profile</th>
                                  <th>User Name</th>
                                  <th>Create At</th>
                                  <th>Comment</th>
                                  <th>Review</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {vendorViewReviewList?.list &&
                                  vendorViewReviewList?.list?.reviews?.map(
                                    (user, index) => {
                                      return (
                                        <React.Fragment key={index}>
                                          <tr>
                                            <td id="list-avatar">
                                              <AvatarGet
                                                type="product"
                                                url={
                                                  user?.user_avatar
                                                    ?.thumbnail_url
                                                }
                                              />
                                            </td>
                                            <td>{user?.username}</td>
                                            <td>
                                              {" "}
                                              {user?.created_at
                                                ? moment(
                                                    user.created_at
                                                  ).format("LL LT")
                                                : "-"}
                                            </td>
                                            <td>{user?.review}</td>

                                            <td>
                                              {user?.rating}{" "}
                                              <RatingStars
                                                rating={user?.rating}
                                              />
                                            </td>
                                            <td className="respoactionSupplier">
                                              {(selfInfo?.user_type ===
                                                "admin" ||
                                                selfInfo?.permissions?.includes(
                                                  "delete-user"
                                                )) && (
                                                <button
                                                  type="button"
                                                  className="btn actiontblebtn"
                                                  onClick={() =>
                                                    DeleteUserFnct(user)
                                                  }
                                                >
                                                  <i className="bi bi-trash3 rounded delete-btn"></i>
                                                </button>
                                              )}
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                {vendorViewReviewList &&
                                  vendorViewReviewList?.list?.length === 0 && (
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan={10}
                                      >
                                        Data Not Found !
                                      </td>
                                    </tr>
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </Scrollbars>
                        {/* pagination */}
                        {vendorViewReviewList?.pagination?.total_records >
                          10 && (
                          <CommonPagination
                            currentPage={currentPage}
                            paginationLength={vendorViewReviewList?.pagination}
                            currentFunction={currentFunction}
                          />
                        )}
                      </div>
                    </div>
                  </Scrollbars>
                </div>
                {/* loader start here */}
                {loaderMain && <CommonLoader />}
              </section>
            </Tab>
          </Tabs>
        </div>
      </div>
      {showModalNew?.open && (
        <CommonewModel
          setCurrentPage={setCurrentPage}
          setShowModalNew={setShowModalNew}
          showModalNew={showModalNew}
        />
      )}
    </div>
  );
};

export default ViewVendor;
