/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import CommonToggle from "../Common/CommonToggle";
import { useNavigate } from "react-router-dom";
import {
  ADD_FAQ_MANAGEMENT_URL,
  VIEW_FAQ_MANAGEMENT_URL,
  EDIT_FAQ_MANAGEMENT_URL,
  truncateString,
  ADMIN_VIEW_NOTIFICATION_MANAGEMENT_URL,
} from "../Shared/constant";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import CommonewModel from "../Common/CommonewModel";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import mastercard from "../../assets/img/mastercard.png";
import visa from "../../assets/img/Visa.png";
import nupay from "../../assets/img/nupay.png";
import mercadoPago from "../../assets/img/mercadoPago.png";
import bankImg from "../../assets/img/bank.png";
import moment from "moment";
import CommonModel from "../Common/CommonModel";

const VendorPaymentManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const { selfInfo, vendorPaymentList, device_id } = useSelector(
    (state) => state.adminReducers
  );
  console.log("vendorPaymentList", vendorPaymentList);
  const Navigate = useNavigate();
  const Dispatch = useDispatch();
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState("");
  const [userLimit, setUserLimit] = useState("");
  const [userPaymentMethod, setuserPaymentMethod] = useState("");
  const [userOffer, setuserOffer] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showModalNew, setShowModalNew] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  useEffect(() => {
    setCurrentPage(1);
  }, [userLimit]);
  const [GetFilterCategoryIds, setGetFilterCategoryIds] = useState("");
  const [GetFilterLanguage, setGetFilterLanguage] = useState("");

  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  // clear search box
  const ClearSearchFiled = () => {
    setUserSearch("");
  };
  // select user status
  const SelectUserPaymentMethodFnct = (e) => {
    setuserPaymentMethod(e.target.value);
    setCurrentPage(1);
  };
  const SelectUserOfferFnct = (e) => {
    setuserOffer(e.target.value);
    setCurrentPage(1);
  };
  // select user type
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
    setCurrentPage(1);
  };

  // add new vendor
  const AddVendorFnct = () => {
    Navigate(ADD_FAQ_MANAGEMENT_URL);
  };
  useEffect(() => {
    Dispatch(
      ClearFormSetFutios({
        url: "",
        action: false,
      })
    );
  }, []);

  // get product list
  useEffect(() => {
    let paramRequest = {
      status: userType || "all",
      limit: userLimit || 10,
      page: currentPage || 1,
      search: userSearch.trim(),
      user_type: selfInfo?.user_type,
      particular: userPaymentMethod || "all",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => {
      if (elm[1] !== "") {
        return elm;
      }
    });
    let param = {
      transmit: "single",
      url: "vendor_payment_listing",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, [
    userSearch,
    userType,
    userLimit,
    currentPage,
    userPaymentMethod,
    GetFilterLanguage,websocket
  ]);

  const ResetFnct = () => {
    setUserSearch("");
    setUserType("");
    setUserLimit(10);
    setCurrentPage(1);
    setGetFilterLanguage("");
    setuserPaymentMethod("");
    setuserOffer("");
  };
  const shareFile = async (data_, type) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Raise Ticket",
      subtitle: "Share Payment",
      modalType: "form",
      button: "Submit",
      Data: data_,
      type: type,
    });
  };

  return (
    <section className="dashboard_wrapper p-0">
      <div className="filter_sectio">
        <div className="col-md-4">
          <div className="form-group">
            <React.Fragment>
              {userSearch !== undefined && (
                <div
                  className="searchBoxwithbtn text-left"
                  style={{ width: "100%" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    value={userSearch}
                    onChange={(e) => {
                      setUserSearch(e.target.value);
                      setCurrentPage(1);
                    }}
                    placeholder={`Search by code, amount or transaction id`}
                    title="Search by code, amount or transaction id"
                    maxLength={50}

                  />
                  {userSearch === "" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-search"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                      <path d="M21 21l-6 -6"></path>
                    </svg>
                  ) : (
                    <i
                      className="bi bi-x-circle-fill searchclear"
                      onClick={() => ClearSearchFiled()}
                    ></i>
                  )}
                </div>
              )}
            </React.Fragment>
          </div>
        </div>
        <div className="row  d-flex justify-content-end">
          <div className="col-md-2">
            <div className="form-group">
              <select
                className="form-control activestatusselet"
                style={{ width: "100%" }}
                value={userType}
                onChange={(e) => SelectUserTypeFnct(e)}
              >
                <option value="" selected disabled>
                  Select Status
                </option>

                <option value="all">All</option>
                <option value="paid">Paid</option>
                <option value="unpaid">Unpaid</option>
              </select>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <select
                className="form-control activestatusselet"
                style={{ width: "100%" }}
                value={userPaymentMethod}
                onChange={(e) => SelectUserPaymentMethodFnct(e)}
              >
                <option value="" disabled selected>
                  Payment Type
                </option>

                <option value="all">All</option>
                <option value="offer">Offer</option>
                <option value="event">Event</option>
              </select>
            </div>
          </div>

          <div className="col-auto">
            <div className="form-group">
              <select
                className="form-control userLimitselect"
                style={{ width: "100%" }}
                value={userLimit}
                onChange={(e) => setUserLimit(parseInt(e.target.value))}
              >
                <option value="">Records</option>
                <option value="10">10</option>

                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="60">60</option>
                <option value="70">70</option>
                <option value="80">80</option>
                <option value="90">90</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>

          <div
            hidden
            className="col-md-2"
            style={{ width: "auto", paddingRight: "0px" }}
          >
            <div className="form-group">
              <label>&nbsp;</label>
              <button
                className="btn head_reset_btn"
                style={{ marginLeft: "0px" }}
                onClick={() => ResetFnct()}
              >
                <i className="bi bi-x-circle"></i>
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tableContent"
        style={{
          height:
            window.screen.width > 540
              ? "calc(100vh - 255px)"
              : "calc(100vh - 143px)",
        }}
      >
        <Scrollbars
          style={{
            height:
              parseInt(vendorPaymentList?.pagination?.total_records) > 10
                ? window.screen.width < 767
                  ? "calc(100% - 10px)"
                  : "calc(100% - -38px)"
                : "calc(100% - 0px)",
          }}
          className="ScrollbarsWrapper"
          renderView={(props) => <div {...props} className="view" />}
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Title Name</th>
                  <th>Transaction ID</th>
                  <th>Code</th>
                  <th>Particular</th>
                  <th>Payment Method</th>
                  <th>Amount</th>
                  <th>Created At</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {vendorPaymentList &&
                  vendorPaymentList?.list?.map((user, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{user?.title || "-"}</td>
                          <td>{user?.transactions_id || "-"}</td>
                          <td>{user?.vendor_code || "-"}</td>
                          <td className="text-capitalize">{user?.particular || "-"}</td>
                          <td>{user?.payment_method || "-"}</td>
                          <td>{user?.total_amount || "-"}</td>
                          <td>
                            {moment(user?.created_at).format("LL") || "-"}
                          </td>
                          <td>
                            {user?.is_paid === true ? (
                              <button className="bg-green btn px-2 py-1 d-flex align-items-center">
                                <i className="bi bi-check-circle-fill text-green me-1"></i>
                                <span className="font-normal text-green">
                                  Success
                                </span>
                              </button>
                            ) : user?.is_paid === false ? (
                              <button className="bg-pending btn px-2 py-1 d-flex align-items-center">
                                <i className="bi bi-clock-fill text-pending me-1"></i>
                                <span className="font-normal text-pending">
                                  Pending
                                </span>
                              </button>
                            ) : (
                              <button className="bg-decline btn px-2 py-1 d-flex align-items-center">
                                <i className="bi bi-dash-circle-fill text-decline me-1"></i>
                                <span className="font-normal text-decline">
                                  Declined
                                </span>
                              </button>
                            )}
                          </td>

                          <td className="respoactionproduct">
                            <button
                              type="button"
                              className="btn actiontblebtn share-btn"
                              onClick={() => shareFile(user, "coin")}
                            >
                              <i className="bi bi-share-fill"></i>
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                {vendorPaymentList && vendorPaymentList?.list?.length === 0 && (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={10}>
                      Payments Not Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
        {/* pagination */}
        {parseInt(vendorPaymentList?.pagination?.total_records) > 10 && (
          <CommonPagination
            currentPage={currentPage}
            paginationLength={vendorPaymentList?.pagination}
            currentFunction={currentFunction}
          />
        )}
      </div>
      {showModalNew?.open && (
        <CommonewModel
          setCurrentPage={setCurrentPage}
          setShowModalNew={setShowModalNew}
          showModalNew={showModalNew}
        />
      )}
      {showModal?.open && (
        <CommonModel
          setUserSearch={setUserSearch}
          setCurrentPage={setCurrentPage}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </section>
  );
};

export default VendorPaymentManagement;
