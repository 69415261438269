/* eslint-disable */
import React, { useContext, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_VENDOR_MANAGEMENT_URL,
  USER_MANAGEMENT_URL,
  ADMIN_OFFER_MANAGEMENT_URL,
  ADMIN_EVENT_MANAGEMENT_URL,
  CATEGORY_MANAGEMENT_URL,
} from "../Shared/constant";
import DashboardTables from "./DashboardTables";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";

const Dashboard = () => {
  const { websocket } = useContext(WebSocketContext);
  const Navigate = useNavigate();
  const { accessToken, dashboardCount, selfInfo } = useSelector(
    (state) => state.adminReducers
  );
  console.log("dashboardCount", dashboardCount);

  useEffect(() => {
    if (
      accessToken &&
      ((selfInfo?.user_type === "admin") === true ||
        selfInfo?.is_staff === true)
    ) {
      // FetchAllData();
    }
  }, [accessToken]);

  // fetch all data list
  const FetchAllData = async () => {
    let param = {
      transmit: "single",
      url: "dashboard_admin_counts",
      request: {},
    };
    wsSend_request(websocket, param);
    let paramRequest = {
      limit:  10,
      page:  1,
      type: "all",
      user_type: "admin",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => {
      if (elm[1] !== "") {
        return elm;
      }
    });
    let params = {
      transmit: "single",
      url: "notification_listing",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, params);
  };
  useEffect(() => {
    FetchAllData();
  }, [websocket]);
  //   useEffect(() => {
  //     // Check if Google Charts library is loaded
  //     if (typeof google !== "undefined" && typeof google.charts !== "undefined") {
  //       // Load Google Charts API
  //       google.charts.load("current", { packages: ["corechart", "bar"] });
  //       google.charts.setOnLoadCallback(() => {
  //         if (dashboardCount) {
  //           // Check if container or related elements are defined
  //           if (
  //             typeof drawChartUsers === "function" &&
  //             typeof drawChartProducts === "function" &&
  //             typeof drawChartSupplier === "function"
  //           ) {
  //             drawChartUsers();
  //             drawChartProducts();
  //             drawChartSupplier();
  //           } else {
  //             console.error(
  //               "One or more chart-drawing functions not properly defined."
  //             );
  //           }
  //         }
  //       });
  //     } else {
  //       console.error("Google Charts API not loaded");
  //     }
  //   }, [dashboardCount]);

  const getLastSixMonth = [];
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var today =
    dashboardCount?.past_six_month_user_count?.past_six_month_count.length > 0
      ? new Date(
          dashboardCount?.past_six_month_user_count?.past_six_month_count[
            dashboardCount?.past_six_month_user_count?.past_six_month_count
              ?.length - 1
          ]?.month
        )
      : new Date();
  var d;
  var month;
  for (var i = 6; i >= 0; i -= 1) {
    d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    month = monthNames[d.getMonth()];
    getLastSixMonth.push(`${month} 1, ${moment(d).format("YYYY")}`);
  }
  var EmptyMonthArray = [
    [getLastSixMonth[0], 0],
    [getLastSixMonth[1], 0],
    [getLastSixMonth[2], 0],
    [getLastSixMonth[3], 0],
    [getLastSixMonth[4], 0],
    [getLastSixMonth[5], 0],
    [getLastSixMonth[6], 0],
  ];
  function drawChartUsers() {
    const PastSixPamentArrays = [["Year", "count"]];
    const PastSixPamentArraysNew = [];

    if (
      dashboardCount?.past_six_month_user_count?.past_six_month_count.length > 0
    ) {
      dashboardCount?.past_six_month_user_count?.past_six_month_count.filter(
        (elm) => {
          PastSixPamentArraysNew.push([
            moment.utc(elm.month).local().format("MMMM D, YYYY"),
            elm.count,
          ]);
        }
      );
      const PastData_ = PastSixPamentArraysNew.map(
        (elm) => elm[0].split(" ")[0]
      ).toString();
      EmptyMonthArray?.filter((elm) => {
        const currentValue = elm[0].split(" ")[0];
        if (PastData_.includes(currentValue) !== true) {
          PastSixPamentArraysNew.push(elm);
        }
      });
    }

    function sortByMonth(arr) {
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      arr.sort(function (a, b) {
        return (
          months.indexOf(a[0].split(" ")[0]) -
          months.indexOf(b[0].split(" ")[0])
        );
      });
    }
    sortByMonth(PastSixPamentArraysNew);
    var data = google.visualization.arrayToDataTable(
      PastSixPamentArrays.concat(PastSixPamentArraysNew)
    );

    var options = {
      chart: {
        // title: 'Company Performance',
        // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
      },
    };
    var chart = new google.charts.Bar(
      document.getElementById("sixmonthUsers_material")
    );
    chart.draw(data, google.charts.Bar.convertOptions(options));
  }

  const getLastSixMonthNew = [];
  var monthNamess = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var todays =
    dashboardCount?.past_six_month_product_count?.past_six_month_count.length >
    0
      ? new Date(
          dashboardCount?.past_six_month_product_count?.past_six_month_count[
            dashboardCount?.past_six_month_product_count?.past_six_month_count
              ?.length - 1
          ]?.month
        )
      : new Date();
  var ds;
  var months;
  for (var i = 6; i >= 0; i -= 1) {
    ds = new Date(todays.getFullYear(), todays.getMonth() - i, 1);
    months = monthNamess[ds.getMonth()];
    getLastSixMonthNew.push(`${months} 1, ${moment(ds).format("YYYY")}`);
  }
  var EmptyMonthArrays = [
    [getLastSixMonthNew[0], 0],
    [getLastSixMonthNew[1], 0],
    [getLastSixMonthNew[2], 0],
    [getLastSixMonthNew[3], 0],
    [getLastSixMonthNew[4], 0],
    [getLastSixMonthNew[5], 0],
    [getLastSixMonthNew[6], 0],
  ];
  function drawChartProducts() {
    const PastSixPamentArrays = [["Year", "count"]];
    const PastSixPamentArraysNew = [];

    if (
      dashboardCount?.past_six_month_product_count?.past_six_month_count
        .length > 0
    ) {
      dashboardCount?.past_six_month_product_count?.past_six_month_count.filter(
        (elm) => {
          PastSixPamentArraysNew.push([
            moment.utc(elm.month).local().format("MMMM D, YYYY"),
            elm.count,
          ]);
        }
      );
      const PastData_ = PastSixPamentArraysNew.map(
        (elm) => elm[0].split(" ")[0]
      ).toString();
      EmptyMonthArrays?.filter((elm) => {
        const currentValue = elm[0].split(" ")[0];
        if (PastData_.includes(currentValue) !== true) {
          PastSixPamentArraysNew.push(elm);
        }
      });
    }

    function sortByMonth(arr) {
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      arr.sort(function (a, b) {
        return (
          months.indexOf(a[0].split(" ")[0]) -
          months.indexOf(b[0].split(" ")[0])
        );
      });
    }
    sortByMonth(PastSixPamentArraysNew);

    var data = google.visualization.arrayToDataTable(
      PastSixPamentArrays.concat(PastSixPamentArraysNew)
    );

    var options = {
      chart: {
        // title: 'Company Performance',
        // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
      },
    };
    var chart = new google.charts.Bar(
      document.getElementById("sixmonthProduct_material")
    );
    chart.draw(data, google.charts.Bar.convertOptions(options));
  }

  const getLastSixMonthNewz = [];
  var monthNamessz = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var todaysz =
    dashboardCount?.past_six_month_supplier_count?.past_six_month_count.length >
    0
      ? new Date(
          dashboardCount?.past_six_month_supplier_count?.past_six_month_count[
            dashboardCount?.past_six_month_supplier_count?.past_six_month_count
              ?.length - 1
          ]?.month
        )
      : new Date();
  var dss;
  var monthsz;
  for (var i = 6; i >= 0; i -= 1) {
    dss = new Date(todaysz.getFullYear(), todaysz.getMonth() - i, 1);
    monthsz = monthNamessz[dss.getMonth()];
    getLastSixMonthNewz.push(`${monthsz} 1, ${moment(dss).format("YYYY")}`);
  }
  var EmptyMonthArraysz = [
    [getLastSixMonthNewz[0], 0],
    [getLastSixMonthNewz[1], 0],
    [getLastSixMonthNewz[2], 0],
    [getLastSixMonthNewz[3], 0],
    [getLastSixMonthNewz[4], 0],
    [getLastSixMonthNewz[5], 0],
    [getLastSixMonthNewz[6], 0],
  ];
  function drawChartSupplier() {
    const PastSixPamentArrays = [["Year", "count"]];
    const PastSixPamentArraysNew = [];

    if (
      dashboardCount?.past_six_month_supplier_count?.past_six_month_count
        .length > 0
    ) {
      dashboardCount?.past_six_month_supplier_count?.past_six_month_count.filter(
        (elm) => {
          PastSixPamentArraysNew.push([
            moment.utc(elm.month).local().format("MMMM D, YYYY"),
            elm.count,
          ]);
        }
      );
      const PastData_ = PastSixPamentArraysNew.map(
        (elm) => elm[0].split(" ")[0]
      ).toString();
      EmptyMonthArraysz?.filter((elm) => {
        const currentValue = elm[0].split(" ")[0];
        if (PastData_.includes(currentValue) !== true) {
          PastSixPamentArraysNew.push(elm);
        }
      });
    }

    function sortByMonth(arr) {
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      arr.sort(function (a, b) {
        return (
          months.indexOf(a[0].split(" ")[0]) -
          months.indexOf(b[0].split(" ")[0])
        );
      });
    }
    sortByMonth(PastSixPamentArraysNew);
    var data = google.visualization.arrayToDataTable(
      PastSixPamentArrays.concat(PastSixPamentArraysNew)
    );

    var options = {
      chart: {
        // title: 'Company Performance',
        // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
      },
    };
    var chart = new google.charts.Bar(
      document.getElementById("sixmonthSupplier_material")
    );
    chart.draw(data, google.charts.Bar.convertOptions(options));
  }
  const data = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
  ];

  const options = {
    title: "My Daily Activities",
  };
  return (
    <section
      className="dashboard_wrapper dashboard-page-bg"
      id="dashboard-section"
    >
      <Scrollbars
        style={{ height: "calc(100vh - 100px)" }}
        className="ScrollbarsWrapper"
        renderView={(props) => <div {...props} className="view" />}
      >
        <div className="column_count_colus  row">
          {selfInfo?.user_type === "admin" && (
            <div className="col-md-3">
              <div
                className="dashColswe cursor-pointer"
                onClick={() => {
                  Navigate(ADMIN_OFFER_MANAGEMENT_URL);
                }}
              >
                <div className="d-flex align-items-center gap-3">
                  {" "}
                  <div className="dashIcon orange-bg">
                    <i className="bi bi-handbag-fill"></i>
                    {/* <img src={bagIcon} alt="" /> */}
                  </div>
                  <h6 className="total-card-heading">Total Offers</h6>
                </div>
                <h4 className="text-black fs-4 fw-700 mt-2 mb-1 ">
                  {dashboardCount?.data?.total_offers || 0}
                </h4>
              </div>
            </div>
          )}
          {selfInfo?.user_type === "admin" && (
            <div className="col-md-3">
              <div
                className="dashColswe cursor-pointer"
                onClick={() => {
                  Navigate(ADMIN_OFFER_MANAGEMENT_URL);
                }}
              >
                <div className="d-flex align-items-center gap-3">
                  {" "}
                  <div className="dashIcon pink-bg">
                    <i className="bi bi-briefcase-fill"></i>
                    {/* <img src={workIcon} alt="" /> */}
                  </div>
                  <h6 className="total-card-heading">Total Offers Earning</h6>
                </div>
                <h4 className="d-flex text-black fs-4 fw-700  mt-2 mb-1 align-items-center gap-3">
                  {dashboardCount?.data?.total_offer_earning || 0}
                  <h4 className="secondary-text">AED</h4>
                </h4>
              </div>
            </div>
          )}
          {selfInfo?.user_type === "admin" && (
            <div className="col-md-3">
              <div
                className="dashColswe cursor-pointer"
                onClick={() => {
                  Navigate(ADMIN_EVENT_MANAGEMENT_URL);
                }}
              >
                <div className="d-flex align-items-center gap-3">
                  {" "}
                  <div className="dashIcon dark-blue-bg">
                    <i className="bi bi-briefcase-fill"></i>
                  </div>
                  <h6 className="total-card-heading">Total Events</h6>
                </div>
                <h4 className="text-black fs-4 fw-700 mt-2 mb-1 ">
                  {dashboardCount?.data?.total_events || 0}
                </h4>
              </div>
            </div>
          )}
          {selfInfo?.user_type === "admin" && (
            <div className="col-md-3">
              <div
                className="dashColswe cursor-pointer"
                onClick={() => {
                  Navigate(ADMIN_EVENT_MANAGEMENT_URL);
                }}
              >
                <div className="d-flex align-items-center gap-3">
                  {" "}
                  <div className="dashIcon green-bg">
                    <i className="bi bi-briefcase-fill"></i>
                  </div>
                  <h6 className="total-card-heading">Total Events Earning</h6>
                </div>
                <h4 className="d-flex text-black fs-4 fw-700  mt-2 mb-1 align-items-center gap-3">
                  {dashboardCount?.data?.total_event_earning || 0}
                  <h4 className="secondary-text">AED</h4>
                </h4>
              </div>
            </div>
          )}
          {selfInfo?.user_type === "admin" && (
            <div className="col-md-3">
              <div
                className="dashColswe cursor-pointer"
                onClick={() => {
                  Navigate(USER_MANAGEMENT_URL);
                }}
              >
                <div className="d-flex align-items-center gap-3">
                  {" "}
                  <div className="dashIcon light-blue-bg">
                    <i className="bi bi-heart-fill"></i>
                  </div>
                  <h6 className="total-card-heading">Total Users</h6>
                </div>
                <h4 className="text-black fs-4 fw-700 mt-2 mb-1 ">
                  {dashboardCount?.data?.total_users || 0}
                </h4>
              </div>
            </div>
          )}{" "}
          {selfInfo?.user_type === "admin" && (
            <div className="col-md-3">
              <div
                className="dashColswe cursor-pointer"
                onClick={() => {
                  Navigate(ADMIN_VENDOR_MANAGEMENT_URL);
                }}
              >
                <div className="d-flex align-items-center gap-3">
                  {" "}
                  <div className="dashIcon dark-yellow-bg">
                    <i className="bi bi-shop"></i>
                  </div>
                  <h6 className="total-card-heading">Total Vendors</h6>
                </div>
                <h4 className="text-black fs-4 fw-700 mt-2 mb-1 ">
                  {dashboardCount?.data?.total_vendors || 0}
                </h4>
              </div>
            </div>
          )}{" "}
          {selfInfo?.user_type === "admin" && (
            <div className="col-md-3">
              <div
                className="dashColswe cursor-pointer"
                onClick={() => {
                  Navigate(CATEGORY_MANAGEMENT_URL);
                }}
              >
                <div className="d-flex align-items-center gap-3">
                  {" "}
                  <div className="dashIcon orange-bg">
                    <i className="bi bi-handbag-fill"></i>
                  </div>
                  <h6 className="total-card-heading">Total Categories</h6>
                </div>
                <h4 className="text-black fs-4 fw-700 mt-2 mb-1 ">
                  {dashboardCount?.data?.total_admin_categories || 0}
                </h4>
              </div>
            </div>
          )}{" "}
          {selfInfo?.user_type === "admin" && (
            <div className="col-md-3">
              <div
                className="dashColswe cursor-pointer"
                onClick={() => {
                  Navigate(CATEGORY_MANAGEMENT_URL);
                }}
              >
                <div className="d-flex align-items-center gap-3">
                  {" "}
                  <div className="dashIcon pink-bg">
                    <i className="bi bi-briefcase-fill"></i>
                  </div>
                  <h6 className="total-card-heading">Total Sub Categories</h6>
                </div>
                <h4 className="text-black fs-4 fw-700 mt-2 mb-1 ">
                  {dashboardCount?.data?.total_sub_categories || 0}
                </h4>
              </div>
            </div>
          )}
        </div>
        <div id="chartsandtables">
          <DashboardTables />
        </div>

        {/* {(selfInfo?.user_type === "admin" ||
          selfInfo?.permissions?.includes("user-list") ||
          selfInfo?.permissions?.includes("add-user") ||
          selfInfo?.permissions?.includes("update-user") ||
          selfInfo?.permissions?.includes("delete-user")) && (
          <div className="graphs_columns">
            <h6>Past Six Month Users</h6>
            {dashboardCount?.past_six_month_user_count?.total > 0 ? (
              <div
                id="sixmonthUsers_material"
                className="piechartwraps"
                style={{ width: "100%", height: "300px" }}
              ></div>
            ) : (
              <div
                className="piechartwraps"
                style={{
                  width: "100%",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f3f3f3",
                  fontSize: "17px",
                }}
              >
                There are no users available for now.
              </div>
            )}
          </div>
        )}
        {(selfInfo?.user_type === "admin" ||
          selfInfo?.permissions?.includes("product-list") ||
          selfInfo?.permissions?.includes("add-product") ||
          selfInfo?.permissions?.includes("update-product") ||
          selfInfo?.permissions?.includes("delete-product")) && (
          <div className="graphs_columns">
            <h6>Past Six Month Products</h6>
            {dashboardCount?.past_six_month_product_count?.total > 0 ? (
              <div
                id="sixmonthProduct_material"
                className="piechartwraps"
                style={{ width: "100%", height: "300px" }}
              ></div>
            ) : (
              <div
                className="piechartwraps"
                style={{
                  width: "100%",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f3f3f3",
                  fontSize: "17px",
                }}
              >
                There are no products available for now.
              </div>
            )}
          </div>
        )}
        {(selfInfo?.user_type === "admin" ||
          selfInfo?.permissions?.includes("add-supplier") ||
          selfInfo?.permissions?.includes("update-supplier") ||
          selfInfo?.permissions?.includes("delete-supplier") ||
          selfInfo?.permissions?.includes("supplier-list")) && (
          <div className="graphs_columns">
            <h6>Past Six Month Suppliers</h6>
            {dashboardCount?.past_six_month_supplier_count?.total > 0 ? (
              <div
                id="sixmonthSupplier_material"
                className="piechartwraps"
                style={{ width: "100%", height: "300px" }}
              ></div>
            ) : (
              <div
                className="piechartwraps"
                style={{
                  width: "100%",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f3f3f3",
                  fontSize: "17px",
                }}
              >
                There are no suppliers available for now.
              </div>
            )}
          </div> */}
        {/* )} */}
      </Scrollbars>
    </section>
  );
};

export default Dashboard;
