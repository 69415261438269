/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useNavigate } from "react-router-dom";
import {
  API_URL,
  VENDOR_OFFER_MANAGEMENT_URL,
  VENDR_EVENT_MANAGEMENT_URL,
  calculatePercentage,
  getStateWiseAmount,
} from "../Shared/constant";
import { GetRequestCall } from "./GetRequestCall";
import {
  setShowPaymentModal,
  setShowSuccessModal,
} from "../../redux/actions/adminActions";

function PaymentModal(props) {
  const { websocket } = useContext(WebSocketContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { liveMapUserList, paymentModalData, selfInfo, accessToken } =
    useSelector((state) => state.adminReducers);
  console.log("paymentModalData", paymentModalData);
  const [inputValue, setInputValue] = useState({
    card_holder_name: "",
    card_number: "",
    CVV: "",
  });
  const { card_holder_name, card_number, CVV } = inputValue;

  const [selectedDate, setSelectedDate] = useState(null);
  const [errorcard_holder_name, setErrorcard_holder_name] = useState("");
  const [errorcard_number, setErrorcard_number] = useState("");
  const [errorCVV, setErrorCVV] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [coinLimit, setCoinLimit] = useState({
    percentage: "",
    ratio: {
      dma_coins: "",
      value: "",
    },
  });

  const TotalUser = parseFloat(
    paymentModalData?.data?.response?.data?.user_count ||
      paymentModalData?.data?.response?.user_count ||
      liveMapUserList?.list?.counts?.total_users
  );

  const TotalAmount =
    paymentModalData?.data?.type === "event"
      ? getStateWiseAmount(
          paymentModalData?.data?.response?.state ||
            paymentModalData?.data?.response?.data?.state
        )
      : parseFloat(TotalUser * 10);

  const oneCoinValue =
    parseFloat(coinLimit?.ratio?.value) /
    parseFloat(coinLimit?.ratio?.dma_coins);

  const coinLimitValue = Math.min(
    selfInfo?.coins ? selfInfo?.coins : 0,
    calculatePercentage(parseFloat(TotalAmount), parseFloat(coinLimit?.percentage)) / oneCoinValue
  );
  const RedeemTotal = parseFloat(
    parseFloat(selectedOption?.value || 0) * oneCoinValue
  );

  const GrandTotal = parseFloat(TotalAmount - RedeemTotal);

  const options = Array.from({ length: coinLimitValue }, (_, index) => ({
    value: index + 1,
    label: `${index + 1}`,
  }));

  console.log(
    "PaymentModal TotalAmount / (parseFloat(coinLimit?.percentage))",
    parseFloat(TotalAmount / parseFloat(coinLimit?.percentage))
  );
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const coinHandler = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const paymentModalHide = () => {
    dispatch(
      setShowSuccessModal({
        type: "",
        response: "",
        show: false,
      })
    );
    dispatch(
      setShowPaymentModal({
        type: "",
        response: "",
        show: false,
      })
    );
    navigate(
      paymentModalData?.data?.type === "offer"
        ? VENDOR_OFFER_MANAGEMENT_URL
        : VENDR_EVENT_MANAGEMENT_URL
    );
  };

  const handleClose = () => {
    let param = {
      transmit: "single",
      url: "vendor_payment",
      request: {
        action: "approve",
        particular_id:
          paymentModalData?.data?.response?.data?.id ||
          paymentModalData?.data?.response?.id,
        particular_type: paymentModalData?.data?.type,
        total_amount: GrandTotal,
      },
    };

    wsSend_request(websocket, param);
    paymentModalHide();

    const listingParam = {
      transmit: "single",
      url:
        paymentModalData?.data?.type === "offer"
          ? "offer_listing"
          : "event_listing",
      request: {
        limit: 10,
        page: 1,
        user_type: selfInfo?.user_type,
      },
    };

    wsSend_request(websocket, listingParam);
    navigate(
      paymentModalData?.data?.type === "offer"
        ? VENDOR_OFFER_MANAGEMENT_URL
        : VENDR_EVENT_MANAGEMENT_URL
    );
  };

  const percentageHandler = async () => {
    try {
      const url = `${API_URL}admin/setting/percentage-redemption/null/V2U/view`;
      const response = await GetRequestCall(url, {}, accessToken);
      if (response?.data) {
        setCoinLimit(response?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    percentageHandler();
  }, []);

  return (
    <>
      <Modal centered show={paymentModalData?.show} onHide={paymentModalHide}>
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body className="row" id="payment-modal">
          <div className="col-md-12">
            <div className="bg-blue w-100 ">
              <p className="info px-2 py-1">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2187_37685)">
                    <path
                      d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM9.6875 5.625C9.87292 5.625 10.0542 5.67998 10.2084 5.783C10.3625 5.88601 10.4827 6.03243 10.5536 6.20373C10.6246 6.37504 10.6432 6.56354 10.607 6.7454C10.5708 6.92725 10.4815 7.0943 10.3504 7.22541C10.2193 7.35652 10.0523 7.44581 9.8704 7.48199C9.68854 7.51816 9.50004 7.49959 9.32874 7.42864C9.15743 7.35768 9.01101 7.23752 8.908 7.08335C8.80499 6.92918 8.75 6.74792 8.75 6.5625C8.75 6.31386 8.84878 6.0754 9.02459 5.89959C9.20041 5.72377 9.43886 5.625 9.6875 5.625ZM10.625 14.375C10.2935 14.375 9.97554 14.2433 9.74112 14.0089C9.5067 13.7745 9.375 13.4565 9.375 13.125V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75C9.70652 8.75 10.0245 8.8817 10.2589 9.11612C10.4933 9.35054 10.625 9.66848 10.625 10V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z"
                      fill="#407BFF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2187_37685">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className="common-blue">
                  Each user corresponds to a value of 10 AED.
                </span>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <InputField
              type="text"
              value={card_holder_name}
              className={
                errorcard_holder_name !== ""
                  ? "form-control error-form"
                  : "form-control"
              }
              placeholder="Ex John Doe"
              label="Card holder name <span class='redmednstar'>*</span>"
              name="card_holder_name"
              onChange={handleChange}
              onKeyUp={(e) =>
                CheckValid(e.target.value, {
                  type: "card_holder_name",
                  error: setErrorcard_holder_name,
                })
              }
              onKeyDown={EmptySpaceFieldValid}
              errormessage={errorcard_holder_name}
              required={true}
            />
          </div>
          <div className="col-md-12">
            <InputField
              type="text"
              value={card_number}
              className={
                errorcard_number !== ""
                  ? "form-control error-form"
                  : "form-control"
              }
              placeholder="Enter card number"
              label="Card Number <span class='redmednstar'>*</span>"
              name="card_number"
              onChange={handleChange}
              onKeyUp={(e) =>
                CheckValid(e.target.value, {
                  type: "card_number",
                  error: setErrorcard_number,
                })
              }
              onKeyDown={EmptySpaceFieldValid}
              errormessage={errorcard_number}
              required={true}
            />
          </div>
          <div className="col-md-12 d-flex">
            <div className="col-md-6">
              <label htmlFor="" className="fs-14px mb-1 fw-400">
                Expiry Date <span className="common-blue">*</span>
              </label>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                showMonthYearPicker
                dateFormat="MM/yyyy"
              />
            </div>
            <div className="col-md-6">
              <InputField
                type="text"
                value={CVV}
                className={
                  errorCVV !== "" ? "form-control error-form" : "form-control"
                }
                placeholder="CVV"
                label="Security Code <span class='redmednstar'>*</span>"
                name="CVV"
                onChange={handleChange}
                onKeyUp={(e) =>
                  CheckValid(e.target.value, {
                    type: "CVV",
                    error: setErrorCVV,
                  })
                }
                onKeyDown={EmptySpaceFieldValid}
                errormessage={errorCVV}
                required={true}
                maxLength={3}
                minLength={3}
              />
            </div>
          </div>
          <div className="col-md-12">
            <label htmlFor="">Coins Redeem</label>
            <Select
              value={selectedOption}
              onChange={coinHandler}
              options={options}
              isSearchable
              placeholder="Select a number..."
            />
          </div>
          <div className="col-md-12 my-2">
            <div className="col-md-12 d-flex justify-content-between align-items-center">
              <p className="secondary-text">Offer Amount</p>
              <p>{TotalAmount}</p>
            </div>
            <div className="col-md-12 d-flex justify-content-between align-items-center border-bottom-dotted">
              <p className="secondary-text">Coins Redeem</p>
              <p className="delete-text">
                {selectedOption?.value} ( For - {RedeemTotal.toFixed(2)} AED)
              </p>
            </div>
            <div className="col-md-12 d-flex my-2 justify-content-between align-items-center border-bottom-dotted">
              <p className="fw-700">Grand Total</p>
              <p className="fw-800">{GrandTotal} </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn w-100 p-3"
            variant="primary"
            onClick={handleClose}
          >
            Pay {GrandTotal} AED
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PaymentModal;
