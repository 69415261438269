
import React from "react";
import maleIcon from "../../assets/icons/menPin.png";
import femaleIcon from "../../assets/icons/womenPin.png";
import L from "leaflet"; // Import Leaflet library
import Pin from "../../assets/icons/Pin.png";
import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const pinIcon = L.icon({
  iconUrl: Pin,
  iconSize: [30, 30], // Size of the icon
  iconAnchor: [15, 30], // Position of the icon relative to marker's location
});

export function InitialMap() {
  window.dispatchEvent(new Event('resize'));
  return (
    <MapContainer
      center={[25.2048, 55.2708]}
      zoom={7}
      style={{ height: "400px", width: "100%" }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    </MapContainer>
  );
}
export function MultiplePinMap(props) {
  window.dispatchEvent(new Event('resize'));
  const position = props?.position; // Specify the latitude and longitude
  return (
    <MapContainer
      center={position} // Set the center to the specified position
      zoom={7}
      style={{ height: "400px", width: "100%" }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={position} icon={pinIcon} />
    </MapContainer>
  );
}

export function FilteredMap({ data }) {
  window.dispatchEvent(new Event('resize'));
  const result = data?.list?.map((item) => ({
    id: item.id,
    name: item.username ? item.username : item.business_name,
    icon:
      item?.gender === "Male"
        ? maleIcon
        : item.gender === "Female"
        ? femaleIcon
        : Pin,
    position: {
      lat: parseFloat(item.latitude),
      lng: parseFloat(item.longitude),
    },
    type: item.username ? "user" : "store",
    gender: item?.gender,
  }));

  const getCenterAndRadius = () => {
    if (result.length === 0) {
      return { center: [0, 0], radius: 0 };
    }

    // Calculate the center
    let sumLat = 0;
    let sumLng = 0;
    result.forEach(({ position }) => {
      sumLat += position.lat;
      sumLng += position.lng;
    });
    const center = [sumLat / result.length, sumLng / result.length];

    // Calculate the distance from the center to the farthest marker
    let maxDistance = 0;
    result.forEach(({ position }) => {
      const distance = Math.sqrt(
        Math.pow(position.lat - center[0] + 0.2, 2) +
          Math.pow(position.lng - center[1] + 0.2, 2)
      );
      maxDistance = Math.max(maxDistance, distance);
    });

    return { center, radius: maxDistance * 100000 };
  };

  const { center, radius } = getCenterAndRadius();
  console.log("center, radius", center, radius);

  return (
    <MapContainer
      center={center}
      zoom={10}
      style={{ height: "60%", width: "100%", borderRadius: "6px" }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {/* Render Markers */}
      {result.map(({ id, name, position, icon, type }) => {
        const markerIcon = L.icon({
          iconUrl: icon,
          iconSize: [30, 30],
          iconAnchor: [15, 30],
        });

        return (
          <Marker key={id} position={position} icon={markerIcon}>
            {(type === "store" || type === "state") && (
              <Popup>
                <div
                  className={`p-2 ${
                    type === "store" ? "bg-yellow fw-700" : "white"
                  }`}
                >
                  {name}
                </div>
              </Popup>
            )}
          </Marker>
        );
      })}
      {/* Add Circle for radius */}
      <Circle
        center={center}
        radius={radius}
        pathOptions={{ color: "#FF0000" }}
      />
      {/* Title displaying total users count */}
      <div className="leaflet-top leaflet-right">
        <div className="leaflet-control leaflet-bar bg-white rounded">
          <div className="leaflet-control-custom fw-bolder px-2 py-1">
            Total Users: {data.list.length}
          </div>
        </div>
      </div>
    </MapContainer>
  );
}
