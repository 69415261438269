import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useState } from "react";

const AuthLayout = ({children}) => {
    const [ showSidebar, setShowSidebar ] = useState(false);

    return(<section className="adminmain">
        {window.screen.width < 901 ? (<React.Fragment>{showSidebar && (<Sidebar setShowSidebar={setShowSidebar} />)}</React.Fragment>) : (<Sidebar />)}
        <div className={"panelwrapper active"}>
            <Header setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
            <div className="panelContentwrapper border-top">
                {children}
            </div>
        </div>
    </section>)
}

export default AuthLayout;