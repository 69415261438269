/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import {
  DASHBOARD_URL,
  MODEL_BRAND_MANAGEMENT_URL,
  SUPPLIER_MANAGEMENT_URL,
  ADMIN_SETTING_MANAGEMENT_URL,
  ADMIN_VENDOR_MANAGEMENT_URL,
  USER_MANAGEMENT_URL,
  extractIdFromUrl,
  VENDOR_DASHBOARD_URL,
} from "../Shared/constant";
import BackButton from "../Common/BackButton";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CheckValid from "../Common/CheckValid";
import InputField from "../Common/InputField";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useNavigate } from "react-router";
import AvatarGet from "../Common/AvatarGet";
import { Spinner } from "react-bootstrap";
import UploadFileAPI, { DeleteFileAPI } from "../../Api/UploadFileAPI";
import imageSelect from "../../assets/img/imageSelect.png";
import { SuccessModal } from "../Common/SuccessModal";
import { toast } from "react-toastify";
import {
  LoaderStartMain,
  setConfirmModal,
} from "../../redux/actions/adminActions";
const VendorProfile = () => {
  const Navigate = useNavigate();
  const { selfInfo, device_id, accessToken } = useSelector(
    (state) => state.adminReducers
  );
  const Dispatch = useDispatch();
  const [inputValue, setInputValue] = useState({
    Fname: "",
    Lname: "",
    Address: "",
    contact_person:
      selfInfo?.business_contact_person || selfInfo?.owner_contact_person,
    contact_number:
      selfInfo?.business_contact_number || selfInfo?.owner_contact_number,
    Email: selfInfo?.business_email_id || selfInfo?.owner_email_id,
  });
  const [showModal, setShowModal] = useState(false);
  const {
    Fname,
    Lname,
    Address,
    contact_person,
    Password,
    Email,
    contact_number,
  } = inputValue;
  const [showpassword, setshowpassword] = useState(false);
  const [showcontact_person, setshowcontact_person] = useState(false);
  const [ChangePasswordState, setChangePasswordState] = useState(false);
  const { websocket } = useContext(WebSocketContext);

  // error message
  const [errorEmail, setErrorEmail] = useState("");
  const [errorcontact_number, setErrorcontact_number] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  const [errorcontact_person, setErrorcontact_person] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const PasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line

  useEffect(() => {
    if (ChangePasswordState === true) {
      const MainWrapsId = document.getElementById("chnagepasswordblog");
      MainWrapsId.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [ChangePasswordState]);

  useEffect(() => {
    if (selfInfo) {
      setInputValue({
        ...inputValue,
        Fname: selfInfo?.first_name,
        Lname: selfInfo?.last_name,
        Address: selfInfo?.address === null ? "" : selfInfo?.address,
      });
    }
  }, [selfInfo]);

  // Textfield common onchange function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // show password
  const ShowHidePassword = () => {
    setshowpassword(!showpassword);
  };

  // show password
  const ShowOldHidePassword = () => {
    setshowcontact_person(!showcontact_person);
  };

  const RoutUrlSet = () => {
    if (
      selfInfo?.user_type === "admin" ||
      selfInfo?.permissions?.includes("dashboard")
    ) {
      return DASHBOARD_URL;
    } else {
      if (selfInfo?.permissions !== null) {
        if (
          selfInfo?.permissions?.split(",")[0] === "user-list" ||
          selfInfo?.permissions?.split(",")[0] === "add-user" ||
          selfInfo?.permissions?.split(",")[0] === "update-user" ||
          selfInfo?.permissions?.split(",")[0] === "delete-user"
        ) {
          return USER_MANAGEMENT_URL;
        } else if (
          selfInfo?.permissions?.split(",")[0] === "product-list" ||
          selfInfo?.permissions?.split(",")[0] === "add-product" ||
          selfInfo?.permissions?.split(",")[0] === "update-product" ||
          selfInfo?.permissions?.split(",")[0] === "delete-product"
        ) {
          return ADMIN_VENDOR_MANAGEMENT_URL;
        } else if (
          selfInfo?.permissions?.split(",")[0] === "add-cart" ||
          selfInfo?.permissions?.split(",")[0] === "export-cart" ||
          selfInfo?.permissions?.split(",")[0] === "cart-list"
        ) {
          return ADMIN_SETTING_MANAGEMENT_URL;
        } else if (
          selfInfo?.permissions?.split(",")[0] === "add-supplier" ||
          selfInfo?.permissions?.split(",")[0] === "update-supplier" ||
          selfInfo?.permissions?.split(",")[0] === "delete-supplier" ||
          selfInfo?.permissions?.split(",")[0] === "supplier-list"
        ) {
          return SUPPLIER_MANAGEMENT_URL;
        } else if (
          selfInfo?.permissions?.split(",")[0] === "model-list" ||
          selfInfo?.permissions?.split(",")[0] === "add-model" ||
          selfInfo?.permissions?.split(",")[0] === "update-model" ||
          selfInfo?.permissions?.split(",")[0] === "delete-model" ||
          selfInfo?.permissions?.split(",")[0] === "brand-list" ||
          selfInfo?.permissions?.split(",")[0] === "add-brand" ||
          selfInfo?.permissions?.split(",")[0] === "update-brand" ||
          selfInfo?.permissions?.split(",")[0] === "delete-brand"
        ) {
          return MODEL_BRAND_MANAGEMENT_URL;
        }
      }
    }
  };

  // edit profile
  const EditProfile = () => {
    if (
      inputValue?.Fname !== "" ||
      inputValue?.Lname !== "" ||
      inputValue?.Address !== ""
    ) {
      let param = {
        transmit: "single",
        url: "user_profile_update",
        request: {
          first_name: inputValue?.Fname,
          last_name: inputValue?.Lname,
          address: inputValue?.Address,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
      let requestPara = {
        transmit: "single",
        url: "user_get",
        request: {
          user_type: selfInfo?.user_type,
        },
      };
      wsSend_request(websocket, requestPara);
      setInputValue({
        ...inputValue,
        Fname: "",
        Lname: "",
        Address: "",
      });
      setTimeout(() => Navigate(RoutUrlSet()), 200);
    } else {
      CheckValid(inputValue?.Fname, { type: "Fname", error: setErrorFname });
      CheckValid(inputValue?.Lname, { type: "Lname", error: setErrorLname });
      CheckValid(inputValue?.Address, {
        type: "Address",
        error: setErrorAddress,
      });
    }
  };

  // change password api
  const EditProfilePassword = () => {
    if (inputValue?.contact_person !== "" && inputValue?.Password !== "") {
      if (
        PasswordRegex.test(inputValue?.contact_person) === true &&
        PasswordRegex.test(inputValue?.Password) === true
      ) {
        if (inputValue?.contact_person !== inputValue?.Password) {
          let param = {
            transmit: "single",
            url: "user_profile_update",
            request: {
              contact_person: inputValue?.contact_person,
              new_password: inputValue?.Password,
              DeviceId: device_id,
            },
          };
          wsSend_request(websocket, param);
          setInputValue({
            ...inputValue,
            contact_person: "",
            Password: "",
          });
          let requestPara = {
            transmit: "single",
            url: "user_get",
            request: {
              user_type: selfInfo?.user_type,
            },
          };
          wsSend_request(websocket, requestPara);
          setTimeout(() => Navigate(VENDOR_DASHBOARD_URL), 200);
        } else {
          if (inputValue?.contact_person === inputValue?.Password) {
            setErrorPassword("Old Password and new password cannot be same.");
          } else {
            setErrorPassword("");
          }
        }
      } else {
        CheckValid(inputValue?.contact_person, {
          type: "contact_person",
          error: setErrorcontact_person,
        });
        CheckValid(inputValue?.Password, {
          type: "newpassword",
          error: setErrorPassword,
        });
      }
    } else {
      CheckValid(inputValue?.contact_person, {
        type: "contact_person",
        error: setErrorcontact_person,
      });
      CheckValid(inputValue?.Password, {
        type: "newpassword",
        error: setErrorPassword,
      });
    }
  };
  const [UploadFileLoader, setUploadFileLoader] = useState(false);
  const [AvatarImage, setAvatarImage] = useState(selfInfo?.logo);
  useEffect(() => {
    setAvatarImage(selfInfo?.logo);
  }, [selfInfo]);
  const [AvatarImageId, setAvatarImageId] = useState("");
  // remove image uploaded
  const removeMediaAPIHandler = async () => {
    Dispatch(LoaderStartMain(true));
    const response = await DeleteFileAPI(
      accessToken,
      extractIdFromUrl(AvatarImage?.url?.file_url || AvatarImage?.file_url)
    );
    console.log(
      "response",
      response,
      extractIdFromUrl(AvatarImage?.url?.file_url || AvatarImage?.file_url)
    );
    if (response?.status === 200) {
      setAvatarImage("");
      toast.success(response?.file);
    } else {
      setChangeImageClicked(false);
      toast.error(response?.file || response?.msg);
    }
    Dispatch(LoaderStartMain(false));
  };
  const RemoveUploadImage = async () => {
    Dispatch(
      setConfirmModal({
        show: "MEDIA",
        callBack: (e) => removeMediaAPIHandler(e),
      })
    );
  };
  // upload product image
  const UploadImage = async (e) => {
    console.log("UploadImage Inside");
    Dispatch(LoaderStartMain(true));

    setUploadFileLoader(true);
    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("avatar", files, files.name);
    formData.append("is_admin", selfInfo?.user_type === "admin" ? 1 : 0);
    formData?.append(
      "to",
      selfInfo?.owner_email_id || selfInfo?.business_email_id
    );
    const fileresponse = await UploadFileAPI(accessToken, formData);
    console.log("fileresponse", fileresponse);
    if (fileresponse) {
      setUploadFileLoader(false);
      setAvatarImage(fileresponse[0]);
      setAvatarImageId(fileresponse[0]?.id);
      let requestPara = {
        transmit: "single",
        url: "user_get",
        request: {
          user_type: selfInfo?.user_type,
        },
      };
      setTimeout(() => {
        wsSend_request(websocket, requestPara);
      }, 2000);
    }
    Dispatch(LoaderStartMain(false));
  };
  useEffect(() => {
    let requestPara = {
      transmit: "single",
      url: "user_get",
      request: {
        user_type: selfInfo?.user_type,
      },
    };
    wsSend_request(websocket, requestPara);
  }, [websocket]);
  const [changeImageClicked, setChangeImageClicked] = useState(false);
  const fileInputRef = useRef(null);

  const handleImageChangeClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      setChangeImageClicked(true);
    }
  };
  return (
    <>
      {showModal ? (
        <SuccessModal />
      ) : (
        <section className="dashboard_wrapper border-0">
          <Scrollbars
            style={{ height: "calc(100% - 53px)" }}
            className="ScrollbarsWrapper"
            renderView={(props) => <div {...props} className="view" />}
          >
            <div className="form-sections border rounded p-3 w-100">
              <div className="row p-3">
                <div
                  className="col-md-6"
                  style={{ borderRight: "2px solid #DEE3EA" }}
                >
                  <h6 className="headecenter secondary-text">Profile Image</h6>

                  <div className="col-md-4">
                    {/* Avatar */}
                    <div className="form-group fileUploadingform">
                      <div
                        className="d-flex align-items-center justify-content-center mt-3"
                        onClick={handleImageChangeClick}
                      >
                        <img src={imageSelect} alt="" />
                        <span className="common-blue ms-2">
                          Change Profile Image
                        </span>
                      </div>
                      {/* Uploaded Image */}
                      {console.log(
                        "AvatarImage",
                        AvatarImage,
                        "changeImageClicked",
                        changeImageClicked
                      )}
                      {(AvatarImage?.file_url ||
                        AvatarImage?.url?.file_url) && (
                        <div className="uploadedImagewrps mt-3">
                          <AvatarGet
                            type="product"
                            url={
                              AvatarImage?.url?.file_url ||
                              AvatarImage?.file_url
                            }
                          />
                          <div
                            className="closeIocn"
                            onClick={RemoveUploadImage}
                          >
                            <i className="bi bi-x-circle-fill"></i>
                          </div>
                        </div>
                      )}

                      {/* File Input */}
                      <input
                        type="file"
                        ref={fileInputRef}
                        className="form-control visually-hidden"
                        onChange={UploadImage}
                        disabled={UploadFileLoader}
                        accept=".png, .jpg, .jpeg"
                        style={{ visibility: "hidden" }}
                      />
                      {/* Loading Spinner */}
                      {changeImageClicked && AvatarImage === "" && (
                        <>
                          <input
                            type="file"
                            className="form-control visually-hidden"
                            onChange={UploadImage}
                            disabled={UploadFileLoader}
                            accept=".png, .jpg, .jpeg"
                          />
                          {UploadFileLoader && (
                            <div className="filUploading">
                              <Spinner animation="border" role="status">
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </Spinner>
                              Please wait, your file is uploading.
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-4 ms-5">
                  <h6 className="headecenter secondary-text capitalize">
                    {selfInfo?.user_type} Details
                  </h6>
                  <div className="row">
                    <div className="col-md-12">
                      <InputField
                        type={"text"}
                        value={contact_person}
                        className={
                          errorcontact_person !== ""
                            ? "form-control error-form"
                            : "form-control"
                        }
                        placeholder="Contact person name"
                        label="Contact Person"
                        name="contact_person"
                        onChange={handleChange}
                        onKeyUp={(e) =>
                          CheckValid(e.target.value, {
                            type: "contact_person",
                            error: setErrorcontact_person,
                          })
                        }
                        onKeyDown={EmptySpaceFieldValid}
                        errormessage={errorcontact_person}
                        onClick={ShowOldHidePassword}
                        readOnly={true}
                      />
                    </div>
                    <div className="col-md-12">
                      <InputField
                        type="text"
                        value={Email}
                        className={
                          errorEmail !== ""
                            ? "form-control error-form"
                            : "form-control"
                        }
                        placeholder="Enter email address"
                        label="Email Address <span class='redmednstar'>*</span>"
                        name="Email"
                        onChange={handleChange}
                        onKeyUp={(e) =>
                          CheckValid(e.target.value, {
                            type: "email",
                            error: setErrorEmail,
                          })
                        }
                        onKeyDown={EmptySpaceFieldValid}
                        errormessage={errorEmail}
                        readOnly={true}
                      />
                      <InputField
                        type="text"
                        value={contact_number}
                        className={
                          errorcontact_number !== ""
                            ? "form-control error-form"
                            : "form-control"
                        }
                        placeholder="Enter contact number"
                        label="Contact Number <span class='redmednstar'>*</span>"
                        name="contact_number"
                        onChange={handleChange}
                        onKeyUp={(e) =>
                          CheckValid(e.target.value, {
                            type: "contact_number",
                            error: setErrorcontact_number,
                          })
                        }
                        onKeyDown={EmptySpaceFieldValid}
                        errormessage={errorcontact_number}
                        readOnly={true}
                      />
                    </div>
                    <div className="col-md-12 d-none">
                      <div className="form-group-btn">
                        <button
                          type="button"
                          className="btn addbtncmn w-100 py-3"
                          onClick={() => EditProfilePassword()}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </section>
      )}
    </>
  );
};

export default VendorProfile;
