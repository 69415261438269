import React, { useState } from "react";
import ReactModal from "react-modal";
import ReactPlayer from "react-player";
import playIcon from "../../assets/img/playIcon.png";
import defaultThumbnail from "../../assets/img/logoIcon.png";
import { isVideoUrl } from "../Shared/constant";

ReactModal.setAppElement("#root"); // Set the root element of your app here

const MediaViewer = ({
  media,
  maxHeight,
  maxWidth,
  minWidth,
  minHeight,
  thumbnail,
  showFull,
}) => {
  console.log("Mediaviewer media", media, "thumbnail", thumbnail);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showFullSize, setShowFullSize] = useState(showFull || false);

  const openModal = () => {
    setIsModalOpen(true);
    setShowFullSize(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleFullSize = () => {
    setShowFullSize((prev) => !prev);
  };

  const renderMedia = () => {
    if (isVideoUrl(media)) {
      const videoThumbnail =
        thumbnail && thumbnail.length && !isVideoUrl(thumbnail)
          ? thumbnail
          : defaultThumbnail;
      console.log(
        "Mediaviewer videoThumbnail",
        videoThumbnail,
        thumbnail,
        media
      );
      return (
        <div
          onClick={openModal}
          style={{
            cursor: "pointer",
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <img
            src={videoThumbnail}
            alt="Video Thumbnail"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "8px",
              maxHeight: maxHeight,
              maxWidth: maxWidth,
              minWidth: minWidth,
              minHeight: minHeight,
            }}
          />
          {console.log("Mediaviewer media", media, "thumbnail", thumbnail,isVideoUrl(media))}

          <img
            src={playIcon}
            alt="Play Icon"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "30px",
              height: "30px",
            }}
            id="playIcon"
          />
          {console.log("Mediaviewer media", media, "thumbnail", thumbnail,isVideoUrl(media))}
        </div>
      );
    } else {
      return (
        <>
          {console.log("Mediaviewer media", media, "thumbnail", thumbnail,isVideoUrl(media))}

          <img
            src={media}
            alt="Media"
            onClick={() => {
              openModal();
              toggleFullSize();
            }}
            style={{
              cursor: "pointer",
              width: "100%",
              height: showFullSize ? "100%" : "auto",
              borderRadius: "8px",
              maxHeight: maxHeight,
              maxWidth: maxWidth,
              minWidth: minWidth,
              minHeight: minHeight,
            }}
          />
        </>
      );
    }
  };

  return (
    <div id="MediaViewer">
      {renderMedia()}
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Media Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
          content: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            background: "transparent",
          },
        }}
      >
        <i
          onClick={closeModal}
          className="bi bi-x-circle-fill cursor-pointer"
          style={{
            color: "red",
            fontSize: "2rem",
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        ></i>
        {thumbnail?.length && isVideoUrl(media) ? (
          <ReactPlayer
            url={media}
            controls
            width={showFullSize ? "100%" : "60%"}
            height={showFullSize ? "100%" : "90%"}
          />
        ) : (
          <>
            {console.log("Mediaviewer media", media, "thumbnail", thumbnail)}

            <img
              src={media}
              alt="Media"
              width={"auto"}
              height={"auto"}
              className="rounded"
              style={{ maxHeight: "419px", margin: "30px 0 10px" }}
            />
          </>
        )}
      </ReactModal>
    </div>
  );
};

export default MediaViewer;
