/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import BackButton from "../Common/BackButton";
import {
  USER_MANAGEMENT_URL,
  ADMIN_VIEW_USER_MANAGEMENT_URL,
  calculateTimeLeft,
  ADMIN_OFFER_MANAGEMENT_URL,
  getOfferStatus,
} from "../Shared/constant";
import AvatarGet from "../Common/AvatarGet";
import { Scrollbars } from "react-custom-scrollbars-2";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import CommonLoader from "../Common/CommonLoader";
import CommonPagination from "../Common/CommonPagination";
import InputField from "../Common/InputField";
import { Tab, Tabs } from "react-bootstrap";
import moment from "moment";
import GradientDiv from "../Common/GradientDiv";

const ViewOffer = () => {
  const Navigate = useNavigate();
  const params = useParams();
  const { websocket } = useContext(WebSocketContext);
  const {
    vendorList,
    viewProduct,
    loaderMain,
    device_id,
    selfInfo,

    ViewPagesResponseList,
  } = useSelector((state) => state.adminReducers);
  console.log("ViewPagesResponseList", ViewPagesResponseList);
  const [currentPage, setCurrentPage] = useState(1);
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState("");
  const [userState, setUserState] = useState("");
  const [userCategory, setUserCategory] = useState("");

  const [userLimit, setUserLimit] = useState("");
  const [activeTableDetails, setActiveTableDetails] = useState("");
  const [paginationLength, setPaginationLength] = useState({});
  const [key, setKey] = useState("view");

  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (params?.roomId) {
      let param = {
        transmit: "single",
        url: "offer_get",
        request: {
          offer_id: params?.roomId,
          user_type: selfInfo?.user_type,
        },
      };
      wsSend_request(websocket, param);
    }
  }, [params?.roomId,websocket]);
  useEffect(() => {
    if (params?.roomId) {
      let param = {
        transmit: "single",
        url: "response_listing_admin",
        request: {
          item_id: params?.roomId,
          limit: userLimit || 10,
          page: currentPage || 1,
          user_type: selfInfo?.user_type,
          item_type: "offer",
        },
      };
      wsSend_request(websocket, param);
    }
  }, [params?.roomId, currentPage, userLimit,websocket]);

  const UserResponseEvents = [
    {
      className: "total-yes-gradient",
      text: viewProduct?.user_response
        ?.filter((response) => response.response_value === "Yes")
        ?.reduce((total, response) => total + response.count, 0),
      subTitle: "Yes",
      cntClassName: "yes-cnt",
    },
    {
      className: "total-maybe-gradient",
      text: viewProduct?.user_response
        ?.filter((response) => response.response_value === "Maybe")
        ?.reduce((total, response) => total + response.count, 0),
      subTitle: "Maybe",
      cntClassName: "maybe-cnt",
    },
    {
      className: "total-no-gradient",
      text: viewProduct?.user_response
        ?.filter((response) => response.response_value === "No")
        ?.reduce((total, response) => total + response.count, 0),
      subTitle: "No",
      cntClassName: "no-cnt",
    },
  ];
  console.log("viewProduct", viewProduct);
  const timeLeft = calculateTimeLeft(viewProduct?.offer_end_time);
  return (
    <>
      <div className="breadcrumb mb-3">
        <Link to={ADMIN_OFFER_MANAGEMENT_URL} className="text-black">
          Offer List
        </Link>
        <span> > </span>
        <Link to="/offer">Offer Detail</Link>
      </div>
      <div
        className="border overflow-hidden h-96 w-100"
        style={{ height: "calc(100% - 15px)" }}
      >
        <div className="tab-view-user" id="view-user-tab">
          <Tabs
            id="controlled-tab-user"
            activeKey={key}
            onSelect={(k) => {
              setKey(k);
              setCurrentPage(1);
            }}
            className="mb-3"
          >
            <Tab eventKey="view" title="View">
              <section className="dashboard_wrapper p-0 border-0">
                <div className="view_details px-3 py-0">
                  <Scrollbars
                    style={{ height: "calc(100vh - 153px)" }}
                    className="ScrollbarsWrapper"
                    renderView={(props) => <div {...props} className="view" />}
                  >
                    <h6 className="fs-16px">Offer Details</h6>

                    <div className="row" style={{ margin: "0 auto" }}>
                      <div className="col-md-11 gap-5 d-flex border-right p-3 border">
                        <div className="col-md-2">
                          <div className="form-group">
                            <div className="product_avatar" id="ViewOffer">
                              <AvatarGet
                                type="product"
                                url={
                                  viewProduct?.logo?.file_url
                                    ? viewProduct?.logo?.file_url
                                    : null
                                }
                              />
                              <small className="fw-700 ms-2">Offer Image</small>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="col-12 d-flex justify-content-start flex-column gap-2">
                            <div className="col-md-12 d-flex flex-md-column flex-lg-row gap-3 align-items-center">
                              <h6 className="text-decoration-none fs-16px col-md-2 secondary-text text-end">
                                Time Left
                              </h6>
                              {typeof timeLeft === "string" ? (
                                <h6 className="text-danger fs-16px">
                                  {timeLeft}
                                </h6>
                              ) : (
                                <h6 className="fs-16px">{timeLeft} Hours</h6>
                              )}
                            </div>

                            <div className="col-md-12 d-flex gap-3">
                              <h6 className="text-decoration-none fs-16px col-md-2 secondary-text text-end">
                                Offer Title
                              </h6>
                              <h6 className="fs-16px">
                                {viewProduct?.offer_title}
                              </h6>
                            </div>

                            <div className="col-md-12 d-flex gap-3">
                              <h6 className="text-decoration-none fs-16px col-md-2 secondary-text text-end">
                                Offers Description
                              </h6>
                              <h6
                                className="col-md-7 fs-16px"
                                dangerouslySetInnerHTML={{
                                  __html: viewProduct?.offer_description,
                                }}
                              ></h6>
                            </div>

                            <div className="col-md-12 d-flex gap-3">
                              <h6 className="text-decoration-none fs-16px col-md-2 secondary-text text-end">
                                Start Date & Time
                              </h6>
                              <h6 className="fs-16px">
                                {viewProduct?.offer_start_time
                                  ? moment(
                                      viewProduct?.offer_start_time
                                    ).format("DD MMM YYYY - h:mmA")
                                  : "-"}
                              </h6>
                            </div>

                            <div className="col-md-12 d-flex gap-3">
                              <h6 className="text-decoration-none fs-16px col-md-2 secondary-text text-end">
                                End Date & Time
                              </h6>
                              <h6 className="fs-16px">
                                {moment(viewProduct?.offer_end_time).format(
                                  "DD MMM YYYY - h:mmA"
                                )}
                              </h6>
                            </div>

                            <div className="col-md-12 d-flex gap-3">
                              <h6 className="text-decoration-none fs-16px col-md-2 secondary-text text-end">
                                Offer Code
                              </h6>
                              <h6 className="fs-16px">
                                {viewProduct?.offer_code}
                              </h6>
                            </div>

                            <div className="col-md-12 d-flex gap-3">
                              <h6 className="text-decoration-none fs-16px col-md-2 secondary-text text-end">
                                Vendor Code
                              </h6>
                              <h6 className="fs-16px">
                                {viewProduct?.vendor_code}
                              </h6>
                            </div>

                            <div className="col-md-12 d-flex gap-3">
                              <h6 className="text-decoration-none fs-16px col-md-2 secondary-text text-end">
                                Created at
                              </h6>
                              <h6 className="fs-16px">
                                {moment(viewProduct?.created_at).format("LL")}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h6 className="mt-3 fs-16px mb-0 ml--12px">Status</h6>
                      <div className="col-md-11 gap-5 d-flex border-right p-3 border my-2">
                        <div className="col-md-12">
                          <div className="col-auto d-flex align-items-start flex-column gap-2 p-2">
                            <div className="col-md-12 d-flex gap-3">
                              <h6
                                className="text-decoration-none fs-16px col-md-2 secondary-text text-end"
                                style={{ minWidth: "120px" }}
                              >
                                Created by
                              </h6>
                              <h6 className="fs-16px capitalize">
                                {viewProduct?.created_by === "admin"
                                  ? "Self"
                                  : viewProduct?.created_by}
                              </h6>
                            </div>

                            <div className="col-md-12 d-flex gap-3">
                              <h6
                                className="text-decoration-none fs-16px col-md-2 secondary-text text-end"
                                style={{ minWidth: "120px" }}
                              >
                                Status
                              </h6>
                              <h6 className="fs-16px">
                                {viewProduct?.is_approved === true ? (
                                  <span className="text-parrot">Approved</span>
                                ) : viewProduct?.is_approved === false ? (
                                  <span className="delete-text">Declined</span>
                                ) : (
                                  "-"
                                )}
                              </h6>
                            </div>

                            <div className="col-md-12 d-flex gap-3">
                              <h6
                                className="text-decoration-none fs-16px col-md-2 secondary-text text-end"
                                style={{ minWidth: "120px" }}
                              >
                                Sub Status
                              </h6>
                              <h6 className="fs-16px capitalize">
                                {getOfferStatus(
                                  viewProduct?.offer_start_time,
                                  viewProduct?.offer_end_time
                                )}
                              </h6>
                            </div>

                            <div className="col-md-12 d-flex gap-3">
                              <h6
                                className="text-decoration-none fs-16px col-md-2 secondary-text text-end"
                                style={{ minWidth: "120px" }}
                              >
                                Offer Mode
                              </h6>
                              <h6 className="fs-16px capitalize">
                                {viewProduct?.offer_mode}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <h6 className="mt-1 mb-1 fs-16px ml--12px">Voting</h6>
                      <div className="col-md-11 mt-2 pb-5 py-2  px-5 mb-5 border">
                        <div className="col-md-12 d-flex justify-content-start gap-3">
                          {UserResponseEvents?.map((item, index) => (
                            <GradientDiv key={index} {...item} />
                          ))}
                        </div>
                      </div>
                      <div className="col-md-12 mb-5"></div>
                    </div>
                  </Scrollbars>
                </div>
                {/* loader start here */}
                {loaderMain && <CommonLoader />}
              </section>
            </Tab>
            <Tab eventKey="response" title="Response">
              <div className="filter_sectio">
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-end gap-2">
                    <div className="col-md-auto">
                      <div className="form-group">
                        <select
                          className="form-control userLimitselect"
                          style={{ width: "100%" }}
                          value={userLimit}
                          onChange={(e) =>
                            setUserLimit(parseInt(e.target.value))
                          }
                        >
                          <option value="" disabled selected>
                            Records
                          </option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                          <option value="60">60</option>
                          <option value="70">70</option>
                          <option value="80">80</option>
                          <option value="90">90</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tableContent"
                style={{
                  height:
                    window.screen.width > 540
                      ? "calc(100vh - 255px)"
                      : "calc(100vh - 143px)",
                }}
              >
                <Scrollbars
                  style={{
                    height:
                      parseInt(
                        ViewPagesResponseList?.pagination?.total_records
                      ) > 10
                        ? window.screen.width < 767
                          ? "calc(100% - 10px)"
                          : "calc(100% - 38px)"
                        : "calc(100% - 0px)",
                  }}
                  className="ScrollbarsWrapper"
                  renderView={(props) => <div {...props} className="view" />}
                >
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>User Name</th>
                          <th>User Email</th>
                          <th>User Code</th>
                          <th>Gender</th>
                          <th>Response</th>
                          {/* <th>Visit</th> */}
                          <th>Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ViewPagesResponseList &&
                          ViewPagesResponseList?.list?.map((user, index) => {
                            return (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>{user?.username || "-"}</td>
                                  <td>{user?.email || "-"}</td>
                                  <td>{user?.usercode || "-"}</td>
                                  <td className="capitalize">
                                    {user?.gender || "-"}
                                  </td>
                                  <td className="capitalize">
                                    {user?.user_response?.response || "-"}
                                  </td>
                                  {/* <td className="capitalize">{user?.visit || "-"}</td> */}
                                  <td>
                                    {moment(user?.created_at).format("LL")}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })}
                        {ViewPagesResponseList &&
                          ViewPagesResponseList?.list?.length === 0 && (
                            <tr>
                              <td style={{ textAlign: "center" }} colSpan={10}>
                                Response Not Found !
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </Scrollbars>
                {/* pagination */}
                {parseInt(ViewPagesResponseList?.pagination?.total_records) >
                  10 && (
                  <CommonPagination
                    currentPage={currentPage}
                    paginationLength={ViewPagesResponseList?.pagination}
                    currentFunction={currentFunction}
                  />
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default ViewOffer;
