/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  RatingStars,
  VENDOR_ADD_VENDOR_OFFER_MANAGEMENT_URL,
  VENDR_ADD_VENDR_EVENT_MANAGEMENT_URL,
  VIEW_VENDOR_PROFILE_URL,
  VENDOR_REVIEW_MANAGEMENT_URL,
  VENDOR_GALLERY_MANAGEMENT_URL,
  VENDOR_MY_BUSINESS_MANAGEMENT_URL,
} from "../Shared/constant";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { VendorChart1, VendorChart2 } from "../Common/PieChart";
import shopIcon from "../../assets/icons/Storefront.svg";
import reviewIcon from "../../assets/icons/reviewIcon.svg";
import galleryIcon from "../../assets/icons/galleryIcon.svg";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import coinIcon from "../../assets/icons/coinIcon.svg";
import addIcon from "../../assets/icons/addIcon.svg";
import InfoCard from "../Common/InfoCard";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";

const VendorDashboard = () => {
  const { websocket } = useContext(WebSocketContext);

  const Dispatch = useDispatch();
  const Navigate = useNavigate();
  const {
    dashboardCount,
    vendorEventList,
    vendorOfferList,
    selfInfo,
  } = useSelector((state) => state.adminReducers);
  const [userType, setUserType] = useState("");
  const [userStatus, setuserStatus] = useState("");
  const [userTimeInterval, setuserTimeInterval] = useState("");
  console.log("dashboardCount", dashboardCount);
  const [showModalNew, setShowModalNew] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  const [key, setKey] = useState("offer");
  useEffect(() => {
    let paramRequest = {
      limit: 5000,
      page: 1,
      user_type: selfInfo?.user_type,
      sub_status: "live",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => {
      if (elm[1] !== "") {
        return elm;
      }
    });
    let param = {
      transmit: "single",
      url: "event_listing",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, [websocket]);
  useEffect(() => {
    let paramRequest = {
      limit: 5000,
      page: 1,
      user_type: selfInfo?.user_type,
      sub_status: "live",
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => {
      if (elm[1] !== "") {
        return elm;
      }
    });
    let param = {
      transmit: "single",
      url: "offer_listing",
      request: Object.fromEntries(paramfilter),
    };
    wsSend_request(websocket, param);
  }, [websocket]);
  useEffect(() => {
    let dashboard_param = {
      transmit: "single",
      url: "dashboard_vendor_counts",
      request: {
        status: userStatus,
        input_time: userTimeInterval,
      },
    };
    if(!userStatus && !userTimeInterval){
      dashboard_param.request.status = "all"
    }
    wsSend_request(websocket, dashboard_param);
  }, [userStatus, userType, userTimeInterval,websocket]);
  const countData = [
    {
      title: "Yes",
      className: "yes-green-bg",
      body:
        userType === "offer"
          ? dashboardCount?.data?.total_offer_response
              ?.filter((response) => response.response_value === "Yes")
              ?.reduce((total, response) => total + response.count, 0)
          : dashboardCount?.data?.total_event_response
              ?.filter((response) => response.response_value === "Yes")
              ?.reduce((total, response) => total + response.count, 0),
      redirect: "",
    },
    {
      title: "Maybe",
      className: "maybe-voilet-bg",
      body:
        userType === "offer"
          ? dashboardCount?.data?.total_offer_response
              ?.filter((response) => response.response_value === "Maybe")
              ?.reduce((total, response) => total + response.count, 0)
          : dashboardCount?.data?.total_event_response
              ?.filter((response) => response.response_value === "Maybe")
              ?.reduce((total, response) => total + response.count, 0),
      redirect: "",
    },
    {
      title: "No",
      className: "no-red-bg",
      body:
        userType === "offer"
          ? dashboardCount?.data?.total_offer_response
              ?.filter((response) => response.response_value === "No")
              ?.reduce((total, response) => total + response.count, 0)
          : dashboardCount?.data?.total_event_response
              ?.filter((response) => response.response_value === "No")
              ?.reduce((total, response) => total + response.count, 0),
      redirect: "",
    },
    {
      title: "Visited",
      className: "visited-blue-bg",
      body: "34",
      redirect: "",
    },
    {
      title:  userType === "event" ? "Events" : "Offer",
      className: "offers-black-bg",
      body:
        userType === "offer"
          ? dashboardCount?.data?.total_offers
          : dashboardCount?.data?.total_events,
      redirect: "",
    },
  ];
  const rating = selfInfo?.rating;
  const percentage = (rating / 5) * 100;
  // select user type
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
  };
  return (
    <section className="dashboard_wrapper border-0">
      <Scrollbars
        style={{ height: "calc(100vh - 100px)" }}
        className="ScrollbarsWrapper"
        renderView={(props) => <div {...props} className="view" />}
      >
        <div className="row m-0">
          <div className="col-md-4">
            <div className="m-0 border px-2 position-sticky overflow-x-hidden overflow-y-scroll scrollable-height-vendor-dashboard">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 d-flex justify-content-space-evenly"
              >
                <Tab eventKey="offer" title="Current Offers">
                  {vendorOfferList?.list?.map((item, index) => {
                    return (
                      <div className="my-2" key={index}>
                        <InfoCard data={item} />
                      </div>
                    );
                  })}
                </Tab>
                <Tab eventKey="event" title="Current Events">
                  {vendorEventList?.list?.map((item, index) => {
                    return (
                      <div className="my-2" key={index}>
                        <InfoCard data={item} />
                      </div>
                    );
                  })}
                </Tab>
              </Tabs>
            </div>
          </div>
          <div className="col-md-5">
            <div className="col-md-12 d-flex justify-content-end">
              <div className="filter_sectio">
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-end gap-2">
                    <div className="col-md-auto">
                      <div className="form-group">
                        <select
                          className="form-control activestatusselet"
                          style={{ width: "100%" }}
                          value={userType}
                          onChange={(e) => SelectUserTypeFnct(e)}
                        >
                          <option value="" disabled selected>
                            Type
                          </option>
                          <option value="offer">Offer</option>
                          <option value="event">Event</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-auto">
                      <div className="form-group">
                        <select
                          className="form-control activestatusselet"
                          style={{ width: "100%" }}
                          value={userStatus}
                          onChange={(e) => {
                            setuserStatus(e.target.value);
                            setuserTimeInterval("");
                          }}
                        >
                          <option value="" disabled selected>
                            Status
                          </option>
                          <option value="live">Live</option>
                          <option value="upcoming">Upcoming</option>
                          <option value="expire">Expire</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-auto">
                      <div className="form-group">
                        <select
                          className="form-control activestatusselet"
                          value={userTimeInterval}
                          onChange={(e) => {
                            setuserTimeInterval(e.target.value);
                            setuserStatus("");
                          }}
                          style={{ width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Date
                          </option>
                          <option value="today">Today</option>
                          <option value="week">Week</option>
                          <option value="month">Month</option>
                          <option value="half_year ">6 Months</option>
                          <option value="all">All Time</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col d-flex gap-2 flex-wrap">
              {countData?.map((item, index) => (
                <div
                  className={`col-custom-dashboard-card d-flex justify-content-between flex-column gap-3 text-white py-4 ps-3 px-2 br-1rem ${item?.className}`}
                  key={index}
                >
                  <div>
                    <span>{item?.title}</span>
                    <h4>{item?.body}</h4>
                    <button
                      className={`text-white br-1rem fs-14px px-2 py-2 d-none`}
                    >
                      View All Details
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-md-12 my-3 border row m-0 br-1rem">
              <h6 className="col-md-12 ms-2 mt-2 capitalize">
                {userType} Grow
              </h6>
              <div className="col-md-12 d-flex">
                <div className="col-md-6 border-right" id="VendorChart1">
                  <VendorChart1
                    yes={
                      userType === "offer"
                        ? dashboardCount?.data?.total_offer_response
                            ?.filter(
                              (response) => response.response_value === "Yes"
                            )
                            ?.reduce(
                              (total, response) => total + response.count,
                              0
                            )
                        : dashboardCount?.data?.total_event_response
                            ?.filter(
                              (response) => response.response_value === "Yes"
                            )
                            ?.reduce(
                              (total, response) => total + response.count,
                              0
                            )
                    }
                    no={
                      userType === "offer"
                        ? dashboardCount?.data?.total_offer_response
                            ?.filter(
                              (response) => response.response_value === "No"
                            )
                            ?.reduce(
                              (total, response) => total + response.count,
                              0
                            )
                        : dashboardCount?.data?.total_event_response
                            ?.filter(
                              (response) => response.response_value === "No"
                            )
                            ?.reduce(
                              (total, response) => total + response.count,
                              0
                            )
                    }
                    maybe={
                      userType === "offer"
                        ? dashboardCount?.data?.total_offer_response
                            ?.filter(
                              (response) => response.response_value === "Maybe"
                            )
                            ?.reduce(
                              (total, response) => total + response.count,
                              0
                            )
                        : dashboardCount?.data?.total_event_response
                            ?.filter(
                              (response) => response.response_value === "Maybe"
                            )
                            ?.reduce(
                              (total, response) => total + response.count,
                              0
                            )
                    }
                  />
                </div>
                <div className="col-md-6" id="VendorChart2">
                  <VendorChart2 />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 p-0 border br-1rem h-fit-content">
            <div className="col-md-11 d-flex flex-column py-5">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    src={selfInfo?.logo?.file_url}
                    alt=""
                    className="w-10rem h-10rem rounded-circle"
                  />
                  <img
                    hidden
                    src={addIcon}
                    alt=""
                    style={{ position: "absolute", bottom: "1rem", right: 0 }}
                    onClick={() => {
                      Navigate(VIEW_VENDOR_PROFILE_URL);
                    }}
                  />
                </div>
                <h5 className="secondary-text title my-2">
                  {selfInfo?.business_name}
                </h5>
                <h6 className="text-primary">{selfInfo?.vendor_code}</h6>
                <div className="my-2 ms-4 w-100">
                  <div className="vendor-dashboard-card-bg br-1rem">
                    <h6 className="tertiary-heading">Your Balance Coin</h6>
                    <div className="d-flex gap-2">
                      <img
                        src={coinIcon}
                        alt="coinIcon"
                        className="h-1-5rem w-1-5rem"
                      />{" "}
                      <h5 className="text-white fw-900">{selfInfo?.coins}</h5>
                    </div>
                    <h6 className="secondary-heading">
                      Use this coins for creating Offers & Events
                    </h6>
                    <div className="d-flex justify-content-space-evenly gap-2 w-100 mt-3">
                      <button
                        className="btn fw-500 fs-13px "
                        style={{ background: "#B1C9FF" }}
                        onClick={() => {
                          Navigate(VENDOR_ADD_VENDOR_OFFER_MANAGEMENT_URL);
                        }}
                      >
                        Create Offer
                      </button>
                      <button
                        className="btn fw-500 fs-13px  bg-white"
                        onClick={() => {
                          Navigate(VENDR_ADD_VENDR_EVENT_MANAGEMENT_URL);
                        }}
                      >
                        Create Event
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column ms-4 my-2">
                <h6 className="mb-2 title ">Your Store Account</h6>

                <div
                  className="d-flex align-items-center gap-2 my-2"
                  onClick={() => {
                    Navigate(VENDOR_MY_BUSINESS_MANAGEMENT_URL);
                  }}
                >
                  <img
                    src={shopIcon}
                    alt=""
                    className="icon-bg-grey p-2 rounded-circle ms-2"
                  />
                  <span className="cursor-pointer">My Business</span>
                </div>

                <div
                  className="d-flex align-items-center my-2 gap-2"
                  onClick={() => {
                    Navigate(VENDOR_GALLERY_MANAGEMENT_URL);
                  }}
                >
                  <img
                    src={galleryIcon}
                    alt=""
                    className="icon-bg-grey p-2 rounded-circle ms-2"
                  />
                  <span className="cursor-pointer">Gallery</span>
                </div>

                <div
                  className="d-flex align-items-center gap-2 my-2"
                  onClick={() => {
                    Navigate(VENDOR_REVIEW_MANAGEMENT_URL);
                  }}
                >
                  <img
                    src={reviewIcon}
                    alt=""
                    className="icon-bg-grey p-2 rounded-circle ms-2"
                  />
                  <span className="cursor-pointer">Reviews</span>
                </div>
              </div>
              <div className="ms-4">
                <h6 className="title">Your Store Ratings</h6>
                <div style={{ textAlign: "center", position: "relative" }}>
                  <SemiCircleProgressBar
                    percentage={percentage}
                    strokeWidth={8}
                    diameter={150} // Increase the diameter to increase size
                    background={"#d6d6d6"}
                    progress={percentage} // Progress percentage
                    strokeLinecap={"round"} // Round line ends
                    trailWidth={8} // Width of the trail
                    trailColor={"#fff"} // Color of the trail
                    strokeColor={"#00cc00"} // Green color
                    style={{ display: "inline-block" }} // Center the progress bar
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "75%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#2E2E2E",
                    }}
                  >
                    <div className="d-flex flex-column">
                      <span>{rating}</span>
                      <span>
                        <RatingStars rating={rating} />
                      </span>
                    </div>
                  </div>
                </div>
                <h6 className="title mt-3 text-center">
                  Reviews & Ratings {`(${selfInfo?.rating || "0"})`}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    </section>
  );
};

export default VendorDashboard;
