import React from "react";
import DefaultPorduct from "../../assets/img/DefaultProduct.jpg";
import DummyAvatar from "../../assets/img/dummyimage.png";

const AvatarGet = (props) => {
    const { type, url } = props;

    return(<div className="avatrshow">
        {type === "product" ? ((url === "" || url === null || url === undefined) ? <img src={DefaultPorduct} alt="product" /> : <img src={url} onError={(e)=> {e.target.src = DefaultPorduct}} alt="product"/>) :
        ((url === "" || url === null || url === undefined) ? <img src={DummyAvatar} alt="avatar" /> : <img src={DefaultPorduct} alt="avatar" />)}
    </div>)
}

export default AvatarGet;