/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import {
  FAQ_MANAGEMENT_URL,
  maxDescLength,
  minDescLength,
} from "../Shared/constant";
import BackButton from "../Common/BackButton";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { Spinner } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonModel from "../Common/CommonModel";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import CommonLoader from "../Common/CommonLoader";
import "react-toastify/dist/ReactToastify.css";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import InputField from "../Common/InputField";
const VendorSupportManagement = () => {
  const Navigate = useNavigate();
  const params = useParams();
  const Location = useLocation()?.pathname?.split("/")[1];
  const { websocket } = useContext(WebSocketContext);
  const [inputValue, setInputValue] = useState({
    question: "",
    answer: "",
    faq_id: "",
    email: "",
  });
  const { question, answer, email } = inputValue;
  const [charCount, setCharCount] = useState(0);
  const [ErrorQuestion, setErrorQuestion] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [editorState, setQueEditorState] = useState(EditorState.createEmpty());
  const [questionEditorState, setAnsEditorState] = useState(
    EditorState.createEmpty()
  );
  const { viewProduct, selfInfo, loaderMain, ClearFormSet } = useSelector(
    (state) => state.adminReducers
  );
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  const [faqQuestion, setFaqQuestion] = useState("");
  useEffect(() => {
    if (Location === "edit-faq") {
      if (viewProduct?.question) {
        const defaultquestionContent = viewProduct?.question;
        const questionContentState = ContentState.createFromBlockArray(
          convertFromHTML(defaultquestionContent)
        );
        const questionEditorState =
          EditorState.createWithContent(questionContentState);
        setQueEditorState(questionEditorState);
      }
      if (viewProduct?.answer) {
        const defaultanswerContent = viewProduct?.answer;
        const answerContentState = ContentState.createFromBlockArray(
          convertFromHTML(defaultanswerContent)
        );
        const answerEditorState =
          EditorState.createWithContent(answerContentState);
        setAnsEditorState(answerEditorState);
      }

      if (inputValue?.question) {
        setInputValue({
          ...inputValue,
          answer: viewProduct?.answer,
          question: viewProduct?.question,
          lang_parent: viewProduct?.lang_parent,
          faq_id: viewProduct?.faq_id || params?.roomId,
        });
      }
    }
  }, [viewProduct, websocket]);
  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const onEditorChange = (newEditorState) => {
    // if (charCount < maxDescLength) {
      setErrorQuestion("");
      setQueEditorState(newEditorState);
      const contentState = newEditorState.getCurrentContent();
      const plainText = contentState.getPlainText("");
      const characterCount = plainText.length;
      setCharCount(characterCount);
      const contentAsRaw = convertToRaw(contentState);
      const htmlContent = draftToHtml(contentAsRaw);
      const styledHtmlContent = `<div style="font-family: sans-serif;">${htmlContent}</div>`;

      setFaqQuestion(styledHtmlContent);

      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        question: styledHtmlContent,
      }));
    // }
  };

  const CreateDocument = () => {
    if (errorEmail === "" && inputValue?.email && charCount > minDescLength) {
      let paramRequest = {
        email: inputValue?.email,
        message: inputValue?.question || faqQuestion,
        type: selfInfo?.user_type,
        vendor_code: selfInfo?.vendor_code,
        username:
          selfInfo?.business_contact_person ||
          selfInfo?.owner_contact_person ||
          selfInfo?.business_name,
      };

      const paramfilter = Object.entries(paramRequest).filter(
        (elm) => elm[1] !== ""
      );
      let param = {
        transmit: "single",
        url: "contact_us",
        request: Object.fromEntries(paramfilter),
      };

      wsSend_request(websocket, param);
      setInputValue({
        question: "",
        answer: "",
        email: "",
      });
      setFaqQuestion("");
      setQueEditorState(EditorState.createEmpty());
      setAnsEditorState(EditorState.createEmpty());
    } else {
      if (charCount < minDescLength) {
        setErrorQuestion(
          "Please enter your message at least 10 character long"
        );
      }
      CheckValid(inputValue?.email, {
        type: "email",
        error: setErrorEmail,
      });
    }
  };

  useEffect(() => {
    if (ClearFormSet.url === "faq_update" && ClearFormSet.action === true) {
      Navigate(FAQ_MANAGEMENT_URL);
      ClearForm();
    } else if (ClearFormSet.url === "faq_add" && ClearFormSet.action === true) {
      Navigate(FAQ_MANAGEMENT_URL);
      ClearForm();
    }
  }, [ClearFormSet]);

  // clear form
  const ClearForm = () => {
    setInputValue({
      ...inputValue,
      question: "",
      answer: "",
      lang_parent: "",
    });
  };

  return (
    <React.Fragment>
      <section className="dashboard_wrapper_form">
        <div className="scrollform">
          <Scrollbars
            style={{ height: "calc(100vh - 150px)" }}
            className="ScrollbarsWrapper"
            renderView={(props) => <div {...props} className="view" />}
          >
            <div className="scrollformCustom h-auto p-3">
              <div className="row">
                <div className="col-md-8">
                  <div className="bg-blue w-100">
                    <p className="info px-2">
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2187_37685)">
                          <path
                            d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM9.6875 5.625C9.87292 5.625 10.0542 5.67998 10.2084 5.783C10.3625 5.88601 10.4827 6.03243 10.5536 6.20373C10.6246 6.37504 10.6432 6.56354 10.607 6.7454C10.5708 6.92725 10.4815 7.0943 10.3504 7.22541C10.2193 7.35652 10.0523 7.44581 9.8704 7.48199C9.68854 7.51816 9.50004 7.49959 9.32874 7.42864C9.15743 7.35768 9.01101 7.23752 8.908 7.08335C8.80499 6.92918 8.75 6.74792 8.75 6.5625C8.75 6.31386 8.84878 6.0754 9.02459 5.89959C9.20041 5.72377 9.43886 5.625 9.6875 5.625ZM10.625 14.375C10.2935 14.375 9.97554 14.2433 9.74112 14.0089C9.5067 13.7745 9.375 13.4565 9.375 13.125V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75C9.70652 8.75 10.0245 8.8817 10.2589 9.11612C10.4933 9.35054 10.625 9.66848 10.625 10V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z"
                            fill="#407BFF"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2187_37685">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="common-blue">
                        Requesting administrative support for user assistance{" "}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-8">
                      <InputField
                        type={"text"}
                        value={email}
                        label={"Email Address"}
                        placeholder={"Enter Email Address"}
                        className={
                          errorEmail !== ""
                            ? "form-control error-form"
                            : "form-control"
                        }
                        name="email"
                        onChange={handleChange}
                        onKeyUp={(e) =>
                          CheckValid(e.target.value, {
                            type: "email",
                            error: setErrorEmail,
                          })
                        }
                        onKeyDown={EmptySpaceFieldValid}
                        errormessage={errorEmail}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <label
                        htmlFor="toolbarClassName"
                        className="mb-2 fs-14px"
                      >
                        Message <span className="common-blue">*</span>
                      </label>
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={onEditorChange}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        wrapperStyle={{
                          border: "1px solid #ccc",
                          backgroundColor: "white",
                          padding: "10px",
                          borderRadius: "4px",
                        }}
                        editorStyle={{
                          minHeight: "150px",
                          border: "1px solid #ccc",
                          padding: "10px",
                          borderRadius: "4px",
                          fontFamily: "sans-serif",
                        }}
                        toolbar={{
                          options: [
                            "inline",
                            "blockType",
                            "fontSize",
                            "list",
                            "textAlign",
                            "colorPicker",
                            "link",
                            "emoji",
                            "history",
                          ],
                          inline: {
                            inDropdown: false,
                            options: [
                              "bold",
                              "italic",
                              "underline",
                              "strikethrough",
                              "superscript",
                              "subscript",
                            ],
                          },
                          list: {
                            inDropdown: false,
                            options: ["unordered", "ordered"],
                          },
                          textAlign: {
                            inDropdown: true,
                          },
                          link: { inDropdown: false },
                          history: { inDropdown: false },
                        }}
                      />
                      {ErrorQuestion && (
                        <div className="errormendatorr">
                          <i className="bi bi-x-circle-fill"></i>
                          {ErrorQuestion}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-8 mt-3">
                  <div
                    className="form-group-btn"
                    style={{ textAlign: "right" }}
                  >
                    <button
                      onClick={() => CreateDocument()}
                      className="btn addbtncmn"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
        {/* loader start here */}
        {loaderMain && <CommonLoader />}
      </section>
      {showModal?.open && (
        <CommonModel
          fromUrl={"product"}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </React.Fragment>
  );
};

export default VendorSupportManagement;
