/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import BackButton from "../Common/BackButton";

import AvatarGet from "../Common/AvatarGet";
import { Scrollbars } from "react-custom-scrollbars-2";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import CommonLoader from "../Common/CommonLoader";
import CommonPagination from "../Common/CommonPagination";
import InputField from "../Common/InputField";
import { Tab, Tabs } from "react-bootstrap";
import moment from "moment";
import MediaViewer from "../Common/MediaViewer";
import { toast } from "react-toastify";
import { VENDOR_DASHBOARD_URL, isVideoUrl } from "../Shared/constant";
import CommonModel from "../Common/CommonModel";
import MediaModal from "../Common/MediaModal";
const VendorGallery = () => {
  const Navigate = useNavigate();
  const params = useParams();
  const { websocket } = useContext(WebSocketContext);
  const {
    viewProduct,
    loaderMain,
    vendorViewGalleryList,
    selfInfo,
  
  } = useSelector((state) => state.adminReducers);
  console.log("viewProduct", viewProduct);
  const [currentPage, setCurrentPage] = useState(1);
  const [GalleryLimit, setGalleryLimit] = useState("");
  const [UTCLimit, setUTCLimit] = useState("");
  const [userPatientLimit, setuserPatientLimit] = useState("");
  const [ReviewLimit, setReviewLimit] = useState("");
  const [key, setKey] = useState("gallery");
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState("");
  console.log("key", key, "vendorViewGalleryList", vendorViewGalleryList);
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  useEffect(() => {
    setCurrentPage(1);
  }, [GalleryLimit, UTCLimit, userPatientLimit, ReviewLimit, key]);
  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (params?.roomId) {
      let param = {
        transmit: "single",
        url: "vendor_get",
        request: {
          vendor_id: params?.roomId,
          user_type: selfInfo?.user_type || "admin",
        },
      };
      wsSend_request(websocket, param);
    }
  }, [params?.roomId, websocket]);
  const getGalleryImagesHandler = async () => {
    const param = {
      transmit: "single",
      url: "vendor_gallery_listing",
      request: {
        limit: GalleryLimit || 10,
        user_type: selfInfo?.user_type || "admin",
        page: currentPage,
        search: userSearch.trim(),
        filter: userType,
        vendor_id: params?.roomId,
      },
    };
    wsSend_request(websocket, param);
  };
  useEffect(() => {
    getGalleryImagesHandler();
  }, [
    params?.roomId,
    currentPage,
    UTCLimit,
    GalleryLimit,
    ReviewLimit,
    key,
    userType,
    userSearch,
    websocket,
  ]);

  const DeleteFile = async (data_) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Request to Delete",
      subtitle: "Delete Image",
      modalType: "form",
      button: "Submit",
      Data: data_,
    });
  };
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
    setCurrentPage(1);
  };
  const [showMediaModal, setShowMediaModal] = useState({
    show: false,
    media: "",
    thumbnail: "",
    minWidth: "200px",
    maxWidth: "90%",
    minHeight: "200px",
    maxHeight: "60%",
  });
  console.log("showMediaModal", showMediaModal);
  return (
    <div className="h-94 w-100">
      <div className="breadcrumb mb-3">
        <Link className="text-black" to={VENDOR_DASHBOARD_URL}>
          Dashboard
        </Link>
        <span> > </span>
        <Link>Gallery</Link>
      </div>
      <div className="h-100 w-100 border">
        <div className="tab-view-user" id="view-user-tab">
          {showMediaModal?.show === true ? (
            <>
              <MediaModal
                data={showMediaModal}
                setShowMediaModal={setShowMediaModal}
              />
              {/* <MediaViewer
            media={showMediaModal?.media}
            thumbnail={showMediaModal?.thumbnail}
            minHeight={"200px"}
            minWidth={"200px"}
            maxHeight={"60%"}
            maxWidth={"80%"}
          /> */}
            </>
          ) : (
            <Tabs
              id="controlled-tab-user"
              activeKey={key}
              onSelect={(k) => {
                setKey(k);
                setCurrentPage(1);
              }}
              className="mb-3"
            >
              <Tab eventKey="gallery" title="Gallery">
                <section className="dashboard_wrapper p-0 border-0">
                  <div className="row d-flex justify-content-end">
                    <div className="col-md-auto">
                      <div className="form-group">
                        <React.Fragment>
                          {userSearch !== undefined && (
                            <div
                              className="searchBoxwithbtn"
                              style={{ width: "100%" }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                value={userSearch}
                                onChange={(e) => {
                                  setUserSearch(e.target.value);
                                  setCurrentPage(1);
                                }}
                                placeholder={`Search by code`}
                                title="Search by code"
                                maxLength={50}
                              />
                              {userSearch === "" ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon icon-tabler icon-tabler-search"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                  <path d="M21 21l-6 -6"></path>
                                </svg>
                              ) : (
                                <i
                                  className="bi bi-x-circle-fill searchclear"
                                  onClick={() => setUserSearch("")}
                                ></i>
                              )}
                            </div>
                          )}
                        </React.Fragment>
                      </div>
                    </div>
                    <div className="col-md-auto">
                      <div className="form-group">
                        <select
                          className="form-control activestatusselet"
                          style={{ width: "100%" }}
                          value={userType}
                          onChange={(e) => SelectUserTypeFnct(e)}
                        >
                          <option value="" disabled selected>
                            Type
                          </option>
                          <option value="all">All</option>
                          <option value="image">Image</option>
                          <option value="video">Video</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-auto me-2">
                      <div className="form-group">
                        <select
                          className="form-control GalleryLimitselect"
                          style={{ width: "100%" }}
                          value={GalleryLimit}
                          onChange={(e) =>
                            setGalleryLimit(parseInt(e.target.value))
                          }
                        >
                          <option value="" disabled selected>
                            Records
                          </option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                          <option value="60">60</option>
                          <option value="70">70</option>
                          <option value="80">80</option>
                          <option value="90">90</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="view_details">
                    <Scrollbars
                      style={{
                        height:
                          vendorViewGalleryList?.pagination?.total_records > 10
                            ? window.screen.width < 767
                              ? "calc(100% - 10px)"
                              : "calc(100% - 10px)"
                            : "calc(100% - 0px)",
                      }}
                      className="ScrollbarsWrapper"
                      renderView={(props) => (
                        <div {...props} className="view" />
                      )}
                    >
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>File</th>
                              <th>Format</th>

                              <th>Name</th>

                              <th>User Code</th>
                              <th>Created At</th>
                              <th>Likes</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {vendorViewGalleryList &&
                              vendorViewGalleryList?.list?.map(
                                (user, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr>
                                        {" "}
                                        <td id="list-avatar">
                                          <AvatarGet
                                            type="product"
                                            url={
                                             user?.url?.gif_url || user?.url?.thumbnail_url || user?.url?.file_url
                                            }
                                          />{" "}
                                          <p className="w-1-5rem" hidden>
                                            <MediaViewer
                                              maxHeight={"50px"}
                                              maxHeight={"50px"}
                                              media={user?.url?.file_url}
                                              minHeight={"50px"}
                                              minWidth={"50px"}
                                              thumbnail={
                                                user?.url?.gif_url || user?.url?.thumbnail_url || user?.url?.file_url

                                              }
                                            />
                                          </p>
                                        </td>
                                        <td>
                                          {user?.ext === "image"
                                            ? "Image"
                                            : "Video"}
                                        </td>
                                        <td>
                                          {user?.username
                                            ? user?.username
                                            : "-"}
                                        </td>
                                        <td>
                                          {user?.usercode
                                            ? user?.usercode
                                            : "-"}
                                        </td>
                                        <td>
                                          {user?.created_at
                                            ? moment(user?.created_at).format(
                                                "LL"
                                              )
                                            : "-"}
                                        </td>
                                        <td>
                                          {user?.like_count
                                            ? user?.like_count
                                            : "-"}
                                        </td>
                                        <td className="respoactionSupplier">
                                          <button
                                            type="button"
                                            className="btn actiontblebtn view-btn"
                                            onClick={() =>
                                              // Navigate(
                                              //   `${ADMIN_VIEW_NOTIFICATION_MANAGEMENT_URL}/`
                                              // )
                                              setShowMediaModal({
                                                show: true,
                                                media: user?.url?.file_url,
                                              })
                                            }
                                          >
                                            <i className="bi bi-eye"></i>
                                          </button>

                                          <button
                                            type="button"
                                            className="btn actiontblebtn delete-btn"
                                            onClick={() =>
                                              DeleteFile(user, "image", "Image")
                                            }
                                          >
                                            <i className="bi bi-trash3"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                }
                              )}
                            {vendorViewGalleryList &&
                              vendorViewGalleryList?.list?.length === 0 && (
                                <tr>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan={10}
                                  >
                                    Media Not Found !
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </Scrollbars>
                  </div>
                  {/* loader start here */}
                  {parseInt(vendorViewGalleryList?.pagination?.total_records) >
                    10 && (
                    <CommonPagination
                      currentPage={currentPage}
                      paginationLength={vendorViewGalleryList?.pagination}
                      currentFunction={currentFunction}
                    />
                  )}
                  {loaderMain && <CommonLoader />}
                </section>
              </Tab>
            </Tabs>
          )}
        </div>
      </div>
      {showModal?.open && (
        <CommonModel
          setUserSearch={setUserSearch}
          setCurrentPage={setCurrentPage}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
};

export default VendorGallery;
