/* eslint-disable */
import React, { useState, useContext, useEffect, useRef } from "react";
import {
  GOOGLE_MAP_API_KEY,
  ADMIN_VENDOR_MANAGEMENT_URL,
  bytesToKB,
  convertToUTCTime,
  dubaiStatesOptions,
  extractIdFromUrl,
  locationsLatLong,
  vendorCountryOptions,
  maxSize,
  extractFilename,
  maxDescLength,
  minDescLength,
  customReactSelectStyles,
  customComponents,
  handleKeyDownForSelect,
  truncateString,
  countPlainTextOccurrences,
} from "../Shared/constant";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import UploadFileAPI, { DeleteFileAPI } from "../../Api/UploadFileAPI";
import CommonModel from "../Common/CommonModel";
import AvatarGet from "../Common/AvatarGet";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-clock/dist/Clock.css";
import CommonLoader from "../Common/CommonLoader";
import "react-toastify/dist/ReactToastify.css";
import { Tab, Tabs } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import Select from "react-select";
import MapPin from "../../assets/icons/MapPin.svg";
import { VendorSuccessModal } from "../Common/SuccessModal";
import BoxesGroup from "./DragableMedia";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { AddThumbnailModal } from "./AddThumbnailModal.jsx";
import closeIcon from "../../assets/img/closeIcon.png";
import { toast } from "react-toastify";
import TimePicker from "../Common/TimePicker/TimePicker.jsx";
import {
  LoaderStartMain,
  setConfirmModal,
} from "../../redux/actions/adminActions.js";

const AddEditVendor = () => {
  const Navigate = useNavigate();
  const Dispatch = useDispatch();
  const [key, setKey] = useState("info");
  const [infoFormFill, setinfoFormFill] = useState(false);
  const params = useParams();
  const Location = useLocation()?.pathname?.split("/")[1];
  const { websocket } = useContext(WebSocketContext);
  const [inputValue, setInputValue] = useState({
    business_name: "",
    owner_contact_person: "",
    business_email_id: "",
    owner_email_id: "",
    owner_contact_number: "",
    business_contact_number: "",
    business_contact_person: "",
    address: "",
    location: "",
  });
  const {
    business_name,
    owner_contact_person,
    business_contact_person,
    owner_contact_number,
    business_email_id,
    owner_email_id,
    business_contact_number,

    address,
    location,
  } = inputValue;
  // error field business_contact_number
  const [errorowner_contact_person, setErrorowner_contact_person] =
    useState("");
  const [errorowner_contact_number, setErrorowner_contact_number] =
    useState("");
  const [errorbusiness_name, setErrorbusiness_name] = useState("");
  const [errorbusiness_contact_person, setErrorbusiness_contact_person] =
    useState("");
  const [errorbusiness_email_id, setErrorbusiness_email_id] = useState("");
  const [errorowner_email_id, setErrorowner_email_id] = useState("");
  const [errorState, setErrorState] = useState("");
  const [errorCountry, setErrorCountry] = useState("");
  const [erroraddress, setErroraddress] = useState("");
  const [errorlocation, setErrorlocation] = useState("");
  const [errorDesc, setErrorDesc] = useState("");
  const [errorCategory, setErrorCategory] = useState("");
  const [errorTags, setErrorTags] = useState("");
  const [errorLogo, setErrorLogo] = useState("");
  const [errorMedia, setErrorMedia] = useState("");

  const [errorbusiness_contact_number, setErrorbusiness_contact_number] =
    useState("");
  const [editorState, setQueEditorState] = useState(EditorState.createEmpty());
  const [StoreDesc, setStoreDesc] = useState("");
  const [Category, setCategory] = useState("");

  const {
    viewProduct,
    accessToken,
    successModalData,
    loaderMain,
    ClearFormSet,
    selfInfo,
    detailcategoryAllList,
    subcategoryList,
  } = useSelector((state) => state.adminReducers);
  const [UploadFileLoader, setUploadFileLoader] = useState(false);
  const [AvatarImage, setAvatarImage] = useState("");
  const [AvatarImageId, setAvatarImageId] = useState("");
  const [selectedLocation, setSelectedLocation] = useState({
    lat: "",
    lng: "",
  });

  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  const [fileData, setFileData] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  // State to store the selected country
  const [selectedCountry, setSelectedCountry] = useState({
    value: Location === "edit-vendor" ? viewProduct?.country : "",
    label: Location === "edit-vendor" ? viewProduct?.country : "",
  });
  const [selectedState, setSelectedState] = useState({
    value: Location === "edit-vendor" ? viewProduct?.state : "",
    label: Location === "edit-vendor" ? viewProduct?.state : "",
  });
  useEffect(() => {
    if (selectedState?.label && !selectedState?.value) {
      setSelectedState((prev) => ({ ...prev, value: viewProduct?.state }));
    }
  }, [selectedState]);

  useEffect(() => {
    if (inputValue?.state) {
      setSelectedState({
        value: inputValue?.state_code,
        label: inputValue?.state,
      });
      if (inputValue?.country) {
        setSelectedCountry({
          value: inputValue?.country_code,
          label: inputValue?.country,
        });
      }
    }
  }, [inputValue?.state, inputValue?.country]);
  const [charCount, setCharCount] = useState(0);

  // Handle change when a country is selected
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const [tabChange, setTabchange] = useState(false);
  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
  };
  const categoryOptions = detailcategoryAllList
    ?.filter((elm) => elm?.is_active === true)
    ?.map((elm) => ({
      value: elm?.id,
      label: elm?.name,
      sub_categories: elm?.sub_categories,
    }));
  const subcategoryOptions = subcategoryList?.list
    .filter((elm) => elm?.is_active === true)
    .map((elm) => ({ value: elm?.id, label: elm?.name }));
  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
    const tagsToAdd = [];
    selectedOptions.forEach((option) => {
      if (option.sub_categories && option.sub_categories.length) {
        option.sub_categories.forEach((subCategory) => {
          tagsToAdd.push({
            label: subCategory.name,
            value: subCategory.id,
          });
        });
      }
    });
    setSelectedTags((prev) => {
      // Use a Set to store unique tag values
      const tagSet = new Set(prev.map((tag) => tag.value));
      // Iterate over the tagsToAdd array and add each tag to the newTags array if it's not already present
      tagsToAdd.forEach((tag) => {
        if (!tagSet.has(tag.value)) {
          prev.push(tag);
          tagSet.add(tag.value);
        }
      });
      // Return the new state array
      return prev;
    });
  };

  const commaSeparatedCategories = selectedCategories
    .map((category) => category.value)
    .join(",");

  const handleTagsChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };
  const commaSeparatedTags = selectedTags.map((Tags) => Tags.value).join(",");

  // get product details
  const fetchVendorDetails = () => {
    if (Location === "edit-vendor") {
      if (params?.roomId) {
        let param = {
          transmit: "single",
          url: "vendor_get",
          request: {
            vendor_id: params?.roomId,
            user_type: selfInfo?.user_type,
          },
        };
        wsSend_request(websocket, param);
      }
    }
  };
  useEffect(() => {
    fetchVendorDetails();
  }, [params?.roomId, Location, websocket]);

  // get product list
  useEffect(() => {
    let param = {
      transmit: "single",
      url: "category_detailed_listing",
      request: {
        filter: null,
        limit: 5000,
        page: 1,
        search: null,
        user_type: selfInfo?.user_type,
        category_by: "admin",
      },
    };
    wsSend_request(websocket, param);
  }, [websocket]);
  useEffect(() => {
    let param = {
      transmit: "single",
      url: "subcategory_listing",
      request: {
        filter: null,
        limit: 5000,
        page: 1,
        search: null,
        user_type: selfInfo?.user_type || "admin",
      },
    };
    wsSend_request(websocket, param);
  }, [websocket]);
  const onEditorChange = (newEditorState) => {
    // if (charCount < maxDescLength) {
      setQueEditorState(newEditorState);
      const contentState = newEditorState.getCurrentContent();
      const plainText = contentState.getPlainText("");
      const characterCount = plainText.length;
      setCharCount(characterCount);
      const contentAsRaw = convertToRaw(contentState);
      const htmlContent = draftToHtml(contentAsRaw);
      const styledHtmlContent = `<div style="font-family: Manrope;">${htmlContent}</div>`;

      setStoreDesc(styledHtmlContent);
    // }
  };
  useEffect(() => {
    if (Location === "edit-vendor" && viewProduct) {
      setInputValue({
        ...inputValue,
        business_name: viewProduct?.business_name,
        owner_contact_person: viewProduct?.owner_contact_person,
        business_email_id: viewProduct?.business_email_id,
        owner_email_id: viewProduct?.owner_email_id,
        owner_contact_number: viewProduct?.owner_contact_number,
        business_contact_number: viewProduct?.business_contact_number,
        business_contact_person: viewProduct?.business_contact_person,
        address: viewProduct?.address,
        location: viewProduct?.location,
        state: viewProduct?.state,
      });

      setSelectedLocation({
        lat: viewProduct?.latitude,
        lng: viewProduct?.longitude,
      });

      if (viewProduct?.store_description) {
        setErrorDesc("");
        const defaultStoreDescriptionContent = viewProduct.store_description;
        const storeDescriptionContentState = ContentState.createFromBlockArray(
          convertFromHTML(defaultStoreDescriptionContent)
        );
        const storeDescriptionEditorState = EditorState.createWithContent(
          storeDescriptionContentState
        );
        setQueEditorState(storeDescriptionEditorState);
        const characterCount = countPlainTextOccurrences(
          viewProduct?.store_description
        );
        setCharCount(characterCount);
      }

      if (viewProduct && viewProduct?.interest) {
        // Split the categories string into an array
        const productCategories = viewProduct?.interest.split(",");

        // Filter categoryList to find objects whose name matches the product categories
        const filteredCategories = detailcategoryAllList?.filter((category) =>
          productCategories.includes(category.name)
        );

        // Map the filtered categories to the required structure and update the state
        const mappedCategories = filteredCategories.map((category) => ({
          value: category.id,
          label: category.name,
        }));

        setSelectedCategories(mappedCategories);
      }
      if (viewProduct && viewProduct?.sub_interest) {
        // Split the categories string into an array
        const productCategories = viewProduct?.sub_interest.split(",");

        // Filter categoryList to find objects whose name matches the product categories
        const filteredCategories = subcategoryList?.list?.filter((category) =>
          productCategories.includes(category.name)
        );

        // Map the filtered categories to the required structure and update the state
        const mappedCategories = filteredCategories.map((category) => ({
          value: category.id,
          label: category.name,
        }));

        setSelectedTags(mappedCategories);
      }
      if (viewProduct?.logo?.file_url) {
        setAvatarImage({
          url: {
            file_url: viewProduct?.logo?.file_url,
          },
          size: viewProduct?.logo?.size,
          name: viewProduct?.logo?.original_name,
        });
      } else {
        setAvatarImage("");
      }
      if (viewProduct?.files) {
        const filesWithDataInUrl = viewProduct?.files.filter((file) => {
          return Object.keys(file.url).length !== 0; // Check if there is any data in the 'url' object
        });

        setFileData(filesWithDataInUrl);
      }

      if (viewProduct && viewProduct.opening_hours) {
        const updatedStoreData = { ...storeData };

        viewProduct.opening_hours.forEach((day) => {
          const { day: openingDay, time } = day;
          const dayName =
            openingDay.charAt(0).toUpperCase() + openingDay.slice(1); // Capitalize the day name
          const openTime = time.opening_time;
          const closeTime = time.closing_time;

          // Update the storeData state based on the opening hours
          updatedStoreData[dayName] = {
            [`${dayName}_Active`]: true,
            open_time: openTime,
            close_time: closeTime,
          };
        });

        // Set the updated storeData state
        setStoreData(updatedStoreData);
      }
      if (viewProduct?.state) {
        setSelectedState({
          value: viewProduct?.state,
          label: viewProduct?.state,
        });
      }
    }
  }, [viewProduct, Location, websocket]);
  // return (<SearchMap/>)
  let inputRef = useRef();
  const libraries = ["places"];
  const handleClearInput = () => {
    inputRef.current.value = ""; // Set the input value to an empty string
  };

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: libraries,
  });

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      // Extracting desired values from the place object
      const address = place.formatted_address;
      const addressComponents = place.address_components || [];
      let city = "";
      let postalCode = "";
      let state = "";
      let stateCode = "";
      let country = "";
      let countryCode = "";

      // Extracting city, postal code, state, country from address components
      addressComponents.forEach((component) => {
        if (component.types.includes("locality")) {
          city = component.long_name;
        } else if (component.types.includes("postal_code")) {
          postalCode = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          state = component.long_name;
          stateCode = component.short_name;
        } else if (component.types.includes("country")) {
          country = component.long_name;
          countryCode = component.short_name;
        }
      });
      setInputValue((previnputValue) => ({
        ...previnputValue,
        address: address,
        city: city,
        postal_code: postalCode,
        location: address,
        state: state,
        state_code: stateCode,
        country: country,
        country_code: countryCode,
      }));

      // Set selected location
      setSelectedLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFormClick = () => {
    if (AvatarImage?.name) {
      toast.error("Only a single logo upload is allowed.");
      return;
    }
    fileInputRef.current.click();
  };
  const handleFormClickImage = () => {
    if (fileData?.length < 20) {
      fileInputRefImage.current.click();
    } else {
      toast.error("Maximum 20 files can be upload");
    }
  };
  const handleFormClickVideo = () => {
    if (fileData?.length < 20) {
      fileInputRefVideo.current.click();
    } else {
      toast.error("Maximum 20 files can be upload");
    }
  };
  const [showVideoModal, setShowVideoModal] = useState({
    show: false,
    to: "",
    id: "",
    parent_id: "",
  });
  // upload product image

  const UploadImage = async (e) => {
    Dispatch(LoaderStartMain(true));

    setUploadFileLoader(true);
    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("file", files, files.name);

    if (params?.roomId) {
      formData?.append("to", params?.roomId);
    } else {
      formData?.append("to", inputValue?.owner_email_id);
    }

    formData.append("is_admin", selfInfo?.user_type === "admin" ? "1" : "0");

    const fileresponse = await UploadFileAPI(accessToken, formData);

    if (fileresponse) {
      setFileData((prevFileData) => [...prevFileData, fileresponse[0]]);
      setFileData((prevFileData) =>
        prevFileData.map((file, index) => ({ ...file, sequence: index + 1 }))
      );
    }
    Dispatch(LoaderStartMain(false));
  };

  const UploadVideo = async (e) => {
    Dispatch(LoaderStartMain(true));

    setUploadFileLoader(true);
    const files = e.target.files[0];
    if (files && files.size > maxSize) {
      toast.error("File size exceeds 30MB limit.");
      e.target.value = null; // Clear the input field
      Dispatch(LoaderStartMain(false));

      return;
    }

    const formData = new FormData();
    formData.append("file", files, files.name);

    if (params?.roomId) {
      formData?.append("to", params?.roomId);
    } else {
      formData?.append("to", inputValue?.owner_email_id);
    }

    formData.append("is_admin", selfInfo?.user_type === "admin" ? "1" : "0");

    const fileresponse = await UploadFileAPI(accessToken, formData);

    if (fileresponse) {
      setFileData((prevFileData) => [...prevFileData, fileresponse[0]]);
      setFileData((prevFileData) =>
        prevFileData.map((file, index) => ({ ...file, sequence: index + 1 }))
      );

      setShowVideoModal((prev) => ({
        ...prev,
        show: true,
        to: formData?.to,
        id: params?.roomId || inputValue?.owner_email_id,
        parent_id: fileresponse[0]?.id,
      }));
    }
    Dispatch(LoaderStartMain(false));
  };

  const UploadLogo = async (e) => {
    setUploadFileLoader(true);
    Dispatch(LoaderStartMain(true));

    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("avatar", files, files.name);

    if (params?.roomId) {
      formData?.append("to", params?.roomId);
    } else {
      formData?.append("to", inputValue?.owner_email_id);
    }
    formData.append("is_admin", selfInfo?.user_type === "admin" ? "1" : "0");
    const fileresponse = await UploadFileAPI(accessToken, formData);
    if (fileresponse) {
      setUploadFileLoader(false);
      setAvatarImage(fileresponse[0]);
      setAvatarImageId(fileresponse[0]?.id);
    }
    Dispatch(LoaderStartMain(false));
  };

  const handleFileChange = (e) => {
    const fileInput = fileInputRef.current;
    // if (fileInput.files.length > 0 && !AvatarImage) {
    UploadLogo(e);
    // }
  };
  const handleFileChangeImage = (e) => {
    const fileInput = fileInputRefImage.current;
    if (fileInput.files.length > 0) {
      UploadImage(e);
    }
  };
  const handleFileChangeVideo = (e) => {
    const fileInput = fileInputRefVideo.current;
    if (fileInput.files.length > 0) {
      UploadVideo(e);
    }
  };
  const [storeData, setStoreData] = useState({
    Monday: {
      Monday_Active: false,
      open_time: "",
      close_time: "",
    },
    Tuesday: {
      Tuesday_Active: false,
      open_time: "",
      close_time: "",
    },
    Wednesday: {
      Wednesday_Active: false,
      open_time: "",
      close_time: "",
    },
    Thursday: {
      Thursday_Active: false,
      open_time: "",
      close_time: "",
    },
    Friday: {
      Friday_Active: false,
      open_time: "",
      close_time: "",
    },
    Saturday: {
      Saturday_Active: false,
      open_time: "",
      close_time: "",
    },
    Sunday: {
      Sunday_Active: false,
      open_time: "",
      close_time: "",
    },
  });
  const [SameOpenAndClose, setSameOpenAndClose] = useState(false);
  const setInitialTimings = () => {
    if (SameOpenAndClose) {
      setStoreData({
        Monday: {
          Monday_Active: false,
          open_time: "",
          close_time: "",
        },
        Tuesday: {
          Tuesday_Active: false,
          open_time: "",
          close_time: "",
        },
        Wednesday: {
          Wednesday_Active: false,
          open_time: "",
          close_time: "",
        },
        Thursday: {
          Thursday_Active: false,
          open_time: "",
          close_time: "",
        },
        Friday: {
          Friday_Active: false,
          open_time: "",
          close_time: "",
        },
        Saturday: {
          Saturday_Active: false,
          open_time: "",
          close_time: "",
        },
        Sunday: {
          Sunday_Active: false,
          open_time: "",
          close_time: "",
        },
      });
    }
  };
  useEffect(() => {
    if (SameOpenAndClose) {
      // Set same open_time and close_time as per Monday for all days
      const { open_time, close_time } = storeData.Monday;
      const updatedStoreData = Object.keys(storeData).reduce((acc, day) => {
        acc[day] = {
          [`${day}_Active`]: true,
          open_time,
          close_time,
        };
        return acc;
      }, {});
      setStoreData(updatedStoreData);
    }
  }, [SameOpenAndClose, websocket]);

  const outputSchedule = Object.entries(storeData)
    .filter(([day, data]) => data[`${day}_Active`])
    .map(([day, data]) => ({
      day: day.toLowerCase(),
      time: {
        opening_time: convertToUTCTime(data.open_time),
        closing_time: convertToUTCTime(data.close_time),
      },
    }));

  function changeHandler(event, day, name) {
    if (event && event.hour && event.minute) {
      // Construct the time string from hour and minute
      const timeString = `${event.hour}:${event.minute}`;

      // Update storeData using the provided name parameter
      setStoreData((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          [name]: timeString,
        },
      }));
    }
    if (event?.target) {
      const { name, value, checked, type } = event.target;

      setStoreData((prev) => {
        return {
          ...prev,
          [day]: {
            ...prev[day],
            [name]: type === "checkbox" ? checked : value,
          },
        };
      });
    }
  }
  useEffect(() => {
    if (
      selectedCategories?.length == 0 &&
      inputValue?.business_name &&
      tabChange
    ) {
      setErrorCategory("Please select category");
    } else {
      setErrorCategory("");
    }
    if (selectedTags?.length == 0 && inputValue?.business_name && tabChange) {
      setErrorTags("Please select tags");
    } else {
      setErrorTags("");
    }

    if (isEditorEmpty() && tabChange) {
      setErrorDesc("Please enter description");
    } else {
      setErrorDesc("");
    }
    if (key === "media" && inputValue?.business_name && tabChange) {
      if (fileData?.length == 0 && tabChange) {
        setErrorMedia("Please upload media");
      } else {
        setErrorMedia("");
      }
      if (!AvatarImage?.name && tabChange) {
        setErrorLogo("Please upload logo");
      } else {
        setErrorLogo("");
      }
      CheckValid(inputValue?.address, {
        type: "Address",
        error: setErroraddress,
      });
    }
    if (viewProduct?.store_description && tabChange) {
      setErrorDesc("");
    }
    if (!selectedCountry?.value && tabChange) {
      setErrorCountry("Please select country");
    } else {
      setErrorCountry("");
    }
    if (!selectedState?.value && tabChange) {
      setErrorState("Please select state");
    } else {
      setErrorState("");
    }
  }, [
    inputValue?.address,
    selectedCategories,
    selectedTags,
    storeData,
    StoreDesc?.length,
    fileData,
    AvatarImage,
    selectedState?.value,
    selectedCountry?.value,
  ]);
  const isEditorEmpty = () => {
    const contentState = editorState.getCurrentContent();
    const hasText = contentState.hasText();
    return !hasText;
  };

  const checkIsValid = () => {
    const isEmpty = isEditorEmpty();
    if (
      !isEmpty &&
      charCount > minDescLength &&
      charCount < maxDescLength &&
      inputValue?.business_name &&
      inputValue?.address &&
      // inputValue?.owner_contact_person &&
      // inputValue?.owner_email_id &&
      // inputValue?.owner_contact_number &&
      inputValue?.business_contact_person &&
      inputValue?.business_contact_number &&
      inputValue?.business_email_id &&
      inputValue?.business_name &&
      commaSeparatedCategories &&
      commaSeparatedTags &&
      selectedCategories &&
      selectedTags &&
      errorbusiness_name === "" &&
      // errorowner_contact_person === "" &&
      // errorowner_contact_number === "" &&
      errorbusiness_contact_number === "" &&
      errorbusiness_contact_person === "" &&
      errorbusiness_email_id === "" &&
      errorowner_email_id === "" &&
      errorState === "" &&
      errorCountry === "" &&
      erroraddress === "" &&
      errorlocation === "" &&
      errorDesc === "" &&
      errorCategory === "" &&
      errorTags === "" &&
      selectedState?.value &&
      errorState === "" &&
      selectedCountry?.value &&
      errorCountry === ""
    ) {
      return true;
    } else {
      if (!selectedCountry?.value) {
        setErrorCountry("Please select country");
      } else {
        setErrorCountry("");
      }
      if (!selectedState?.value && tabChange) {
        setErrorState("Please select state");
      } else {
        setErrorState("");
      }
      if (selectedCategories?.length == 0) {
        setErrorCategory("Please select category");
      } else {
        setErrorCategory("");
      }
      if (selectedTags?.length == 0) {
        setErrorTags("Please select tags");
      } else {
        setErrorTags("");
      }

      if (isEmpty) {
        setErrorDesc("Please enter description");
      } else {
        setErrorDesc("");
      }

      if (charCount < minDescLength) {
        setErrorDesc(
          `Please enter at least ${minDescLength} character description`
        );
      } else {
        setErrorDesc("");
      }
      if (charCount > maxDescLength && charCount > minDescLength) {
        setErrorDesc(
          `Please enter maximum ${maxDescLength} character description`
        );
      }
      if (Location === "edit-vendor" && !viewProduct?.store_description) {
        setErrorDesc("Please enter description");
      }
      if (Location === "edit-vendor" && !viewProduct?.store_description) {
        setErrorDesc("Please enter description");
      }
      CheckValid(inputValue?.address, {
        type: "Address",
        error: setErroraddress,
      });
      CheckValid(inputValue?.business_name, {
        type: "business_name",
        error: setErrorbusiness_name,
      });
      // CheckValid(inputValue?.owner_contact_person, {
      //   type: "owner_contact_person",
      //   error: setErrorowner_contact_person,
      // });
      // CheckValid(inputValue?.owner_contact_number, {
      //   type: "owner_contact_number",
      //   error: setErrorowner_contact_number,
      // });
      CheckValid(inputValue?.business_contact_person, {
        type: "owner_contact_person",
        error: setErrorbusiness_contact_person,
      });
      CheckValid(inputValue?.business_contact_number, {
        type: "owner_contact_number",
        error: setErrorbusiness_contact_number,
      });
      CheckValid(inputValue?.business_email_id, {
        type: "email",
        error: setErrorbusiness_email_id,
      });
      // CheckValid(inputValue?.owner_email_id, {
      //   type: "email",
      //   error: setErrorowner_email_id,
      // });
      return false;
    }
  };
  const checkIsValidFiles = () => {
    if (
      // AvatarImage?.name &&

      fileData?.length > 0
    ) {
      return true;
    } else {
      if (fileData?.length == 0) {
        setErrorMedia("Please upload media");
      } else {
        setErrorMedia("");
      }
      if (!AvatarImage?.name) {
        setErrorLogo("Please upload logo");
      } else {
        setErrorLogo("");
      }
      return false;
    }
  };
  const removeMediaAPIHandler = async () => {
    Dispatch(LoaderStartMain(true));

    const response = await DeleteFileAPI(
      accessToken,
      extractIdFromUrl(AvatarImage?.url?.file_url)
    );
    if (response?.status === 200) {
      setAvatarImage("");
      setAvatarImageId("");
      toast.success(response?.file);
    } else {
      toast.error(response?.file || response?.msg);
    }
    Dispatch(LoaderStartMain(false));
  };
  // remove image uploaded
  const RemoveUploadImage = async () => {
    Dispatch(
      setConfirmModal({
        show: "MEDIA",
        callBack: (e) => removeMediaAPIHandler(e),
      })
    );
  };
  const [submitClick, setSubmitClick] = useState(false);
  const CreateVendor = () => {
    console.log("checkIsValid", checkIsValid(), checkIsValidFiles());
    setSubmitClick(true);
    if (Location === "edit-vendor") {
      if (checkIsValid() && checkIsValidFiles()) {
        let param;

        param = {
          transmit: "single",
          url: "vendor_update",
          request: {
            logo: extractFilename(AvatarImage?.url?.file_url),
            business_name: inputValue?.business_name,
            owner_contact_person: inputValue?.owner_contact_person,
            business_email_id: inputValue?.business_email_id,
            owner_email_id: inputValue?.owner_email_id,
            owner_contact_number: inputValue?.owner_contact_number,
            business_contact_number: inputValue?.business_contact_number,
            business_contact_person: inputValue?.business_contact_person,
            address: inputValue?.location,
            location: `${selectedLocation?.lat},${selectedLocation?.lng}`,
            country: selectedCountry?.value || inputValue?.country_code,
            state: selectedState?.value || inputValue?.state_code,
            interest: commaSeparatedCategories,
            sub_interest: commaSeparatedTags,
            store_description: StoreDesc?.length
              ? StoreDesc
              : viewProduct?.store_description,
            files: fileData?.map(({ sequence, name }) => ({ sequence, name })),
            opening_hours: outputSchedule?.length > 0 ? outputSchedule : [],
            vendor_id: params?.roomId,
            user_type: selfInfo?.user_type,
          },
        };
        wsSend_request(websocket, param);
        Navigate(ADMIN_VENDOR_MANAGEMENT_URL);
      }
    } else {
      if (checkIsValid() && checkIsValidFiles()) {
        let paramRequest;

        paramRequest = {
          logo: AvatarImage?.name,
          business_name: inputValue?.business_name,
          owner_contact_person: inputValue?.owner_contact_person,
          business_email_id: inputValue?.business_email_id,
          owner_email_id: inputValue?.owner_email_id,
          owner_contact_number: inputValue?.owner_contact_number,
          business_contact_number: inputValue?.business_contact_number,
          business_contact_person: inputValue?.business_contact_person,
          address: inputValue?.location,
          location: `${selectedLocation?.lat},${selectedLocation?.lng}`,
          country: selectedCountry?.value || inputValue?.country_code,
          state: selectedState?.value || inputValue?.state_code,
          interest: commaSeparatedCategories,
          sub_interest: commaSeparatedTags,
          store_description: StoreDesc,
          files: fileData?.map(({ sequence, name }) => ({ sequence, name })),
          opening_hours: outputSchedule?.length > 0 ? outputSchedule : [],
        };
        const paramfilter = Object.entries(paramRequest).filter((elm) => {
          if (elm[1] !== "") {
            return elm;
          }
        });
        let param = {
          transmit: "single",
          url: "vendor_add",
          request: Object.fromEntries(paramfilter),
        };
        wsSend_request(websocket, param);
      }
    }
  };
  const resetAllFilledData = () => {
    setInputValue({
      business_name: "",
      owner_contact_person: "",
      business_email_id: "",
      owner_email_id: "",
      owner_contact_number: "",
      business_contact_number: "",
      business_contact_person: "",
      address: "",
      location: "",
    });
    setAvatarImage("");
    setAvatarImageId("");
    setFileData([]);
    setStoreData({
      Monday: {
        Monday_Active: false,
        open_time: "",
        close_time: "",
      },
      Tuesday: {
        Tuesday_Active: false,
        open_time: "",
        close_time: "",
      },
      Wednesday: {
        Wednesday_Active: false,
        open_time: "",
        close_time: "",
      },
      Thursday: {
        Thursday_Active: false,
        open_time: "",
        close_time: "",
      },
      Friday: {
        Friday_Active: false,
        open_time: "",
        close_time: "",
      },
      Saturday: {
        Saturday_Active: false,
        open_time: "",
        close_time: "",
      },
      Sunday: {
        Sunday_Active: false,
        open_time: "",
        close_time: "",
      },
    });
    setSelectedState(null);
    setErrorCountry(null);
    setErrorState(null);
    setKey("info");
    setSelectedCategories([]);
    setSelectedTags([]);
    setSelectedCountry("");
  };

  const AddCategoryFnct = () => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Add Category",
      subtitle: "Add Category",
      description: ``,
      modalType: "form",
      button: "Yes",
    });
  };
  // add new category
  const AddSubCategoryFnct = () => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Add Sub Category",
      subtitle: "Add Sub Category",
      description: ``,
      modalType: "form",
      button: "Save",
    });
  };

  const removeCategory = (categoryToRemove) => {
    const updatedCategories = selectedCategories.filter(
      (category) => category.value !== categoryToRemove.value
    );
    setSelectedCategories(updatedCategories);

    // Check if categoryToRemove has sub_categories
    if (categoryToRemove?.sub_categories) {
      // Create a Set of sub_category ids for efficient lookup
      const subCategoryIds = new Set(
        categoryToRemove.sub_categories.map((subCat) => subCat.id)
      );
      // Remove tags whose id matches with any of the sub_category ids
      setSelectedTags((prevTags) =>
        prevTags.filter((tag) => !subCategoryIds.has(tag.value))
      );
    }
  };

  const removeTags = (TagsToRemove) => {
    const updatedTags = selectedTags.filter(
      (Tags) => Tags.value !== TagsToRemove.value
    );
    setSelectedTags(updatedTags);
  };
  const BaicInfoHandler = (key) => {
    setTabchange(true);
    if (checkIsValid()) {
      setKey(key);
      setinfoFormFill(true);
    } else {
      setinfoFormFill(false);
    }
  };
  // return(
  //   <VendorSuccessModal />
  // )
  const fileInputRef = useRef(null);
  const fileInputRefImage = useRef(null);

  const fileInputRefVideo = useRef(null);

  const [selectedCountryCode, setSelectedCountryCountry] = useState("");
  const [states, setStates] = useState([]);
  const [error, setError] = useState(null);
  return (
    <div id="add-edit-vendor">
      {/* <SearchMap /> */}
      {showVideoModal?.show === true ? (
        <AddThumbnailModal
          data={showVideoModal}
          setShowVideoModal={setShowVideoModal}
          setFileData={setFileData}
          fileData={fileData}
        />
      ) : successModalData?.show ? (
        <VendorSuccessModal data={successModalData} />
      ) : (
        <>
          <div className="breadcrumb">
            <Link className="text-black" to={ADMIN_VENDOR_MANAGEMENT_URL}>
              Vendor
            </Link>
            <span> > </span>
            <span>
              <Link>
                {" "}
                {Location === "edit-vendor" ? "Edit Vendor" : "Add New Vendor"}
              </Link>
            </span>
          </div>
          <section className="dashboard_wrapper_form border p-3">
            <div className="scrollform row m-0">
              <Scrollbars
                style={{ height: "calc(100vh - 170px)" }}
                className="ScrollbarsWrapper"
                renderView={(props) => <div {...props} className="view" />}
              >
                <div className="position-relative">
                  <div className="position-absolute end-0 me-5 mt-1">
                    <div className="d-flex gap-2">
                      <button
                        className="discard-btn btn pt-1"
                        onClick={resetAllFilledData}
                      >
                        Discard
                      </button>
                      <button
                        className="common-blue-bg btn pt-1"
                        onClick={() =>
                          key === "media"
                            ? CreateVendor()
                            : BaicInfoHandler("media")
                        }
                      >
                        {key === "media" ? "Submit" : "Save And Continue"}
                      </button>
                    </div>
                  </div>
                </div>

                <Tabs
                  id="vendor-tabbed-add-edit"
                  activeKey={key}
                  onSelect={(k) => BaicInfoHandler(k)}
                  className="mb-3"
                >
                  <Tab
                    eventKey="info"
                    title={
                      <div className={`${infoFormFill ? "common-blue" : ""}`}>
                        <i
                          className={`bi bi-1-circle-fill ${
                            infoFormFill ? "common-blue" : ""
                          }`}
                        ></i>{" "}
                        <span
                          onClick={() => {
                            setKey("info");
                          }}
                        >
                          {" "}
                          Basic Information{" "}
                        </span>
                      </div>
                    }
                  >
                    <div className="scrollformCustom">
                      <div className="row m-0" style={{ margin: "0 auto" }}>
                        <div className="col-md-9">
                          <InputField
                            type="text"
                            value={business_name}
                            className={
                              errorbusiness_name !== ""
                                ? "form-control error-form"
                                : "form-control"
                            }
                            placeholder="Enter Business Name"
                            label="Business Name <span class='redmednstar'>*</span>"
                            name="business_name"
                            onChange={handleChange}
                            onKeyUp={(e) =>
                              CheckValid(e.target.value, {
                                type: "business_name",
                                error: setErrorbusiness_name,
                              })
                            }
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorbusiness_name}
                            required={true}
                            maxLength={80}
                            minLength={1}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3">
                          <InputField
                            type="text"
                            value={owner_contact_person}
                            className={
                              errorowner_contact_person !== ""
                                ? "form-control error-form"
                                : "form-control"
                            }
                            placeholder="Enter Contact Person Name"
                            label="Contact Person <span class='redmednstar'>*</span>"
                            name="owner_contact_person"
                            onChange={handleChange}
                            errormessage={""}
                            // onKeyUp={(e) =>
                            //   CheckValid(e.target.value, {
                            //     type: "owner_contact_person",
                            //     error: setErrorowner_contact_person,
                            //   })
                            // }
                            // onKeyDown={EmptySpaceFieldValid}
                            // errormessage={errorowner_contact_person}
                            // required={true}
                            // maxLength={30}
                            // minLength={1}
                          />
                        </div>
                        <div className="col-md-3">
                          <InputField
                            type="tel"
                            value={owner_contact_number}
                            className={
                              errorowner_contact_number !== ""
                                ? "form-control error-form"
                                : "form-control"
                            }
                            placeholder="Enter Contact Number +971"
                            label="Contact Number <span class='redmednstar'>*</span>"
                            name="owner_contact_number"
                            onChange={handleChange}
                            errormessage={""}
                            // onKeyUp={(e) =>
                            //   CheckValid(e.target.value, {
                            //     type: "owner_contact_number",
                            //     error: setErrorowner_contact_number,
                            //   })
                            // }
                            // onKeyDown={EmptySpaceFieldValid}
                            // errormessage={errorowner_contact_number}
                            // required={true}
                            // maxLength={13}
                            // minLength={9}
                          />
                        </div>
                        <div className="col-md-3">
                          <InputField
                            type="text"
                            value={owner_email_id}
                            className={
                              errorowner_email_id !== ""
                                ? "form-control error-form"
                                : "form-control"
                            }
                            placeholder="Enter email"
                            label="Email Address"
                            name="owner_email_id"
                            errormessage={""}
                            onChange={(e) => {
                              handleChange(e);
                              // CheckValid(e.target.value, {
                              //   type: "email",
                              //   error: setErrorowner_email_id,
                              // });
                            }}
                            // onKeyUp={(e) =>
                            //   CheckValid(e.target.value, {
                            //     type: "email",
                            //     error: setErrorowner_email_id,
                            //   })
                            // }
                            // onKeyDown={EmptySpaceFieldValid}
                            // errormessage={errorowner_email_id}
                            // required={true}
                            // maxLength={30}
                            // minLength={4}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3">
                          <InputField
                            type="text"
                            value={business_contact_person}
                            className={"form-control"}
                            placeholder="Enter Contact Person"
                            label="Business Contact Person"
                            name="business_contact_person"
                            onChange={handleChange}
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorbusiness_contact_person}
                            onKeyUp={(e) =>
                              CheckValid(e.target.value, {
                                type: "owner_contact_person",
                                error: setErrorbusiness_contact_person,
                              })
                            }
                            maxLength={30}
                            minLength={1}
                            required={true}
                          />
                        </div>

                        <div className="col-md-3">
                          <InputField
                            type="tel"
                            value={business_contact_number}
                            className={
                              errorbusiness_contact_number !== ""
                                ? "form-control error-form"
                                : "form-control"
                            }
                            placeholder="Enter Contact Number +971"
                            label="Business Contact Number <span class='redmednstar'>*</span>"
                            name="business_contact_number"
                            onChange={handleChange}
                            onKeyUp={(e) =>
                              CheckValid(e.target.value, {
                                type: "owner_contact_number",
                                error: setErrorbusiness_contact_number,
                              })
                            }
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorbusiness_contact_number}
                            required={true}
                            maxLength={16}
                            minLength={9}
                          />
                        </div>
                        <div className="col-md-3">
                          <InputField
                            type="text"
                            value={business_email_id}
                            className={
                              errorbusiness_email_id !== ""
                                ? "form-control error-form"
                                : "form-control"
                            }
                            placeholder="Enter email address"
                            label="Business Email Address"
                            name="business_email_id"
                            onChange={(e) => {
                              handleChange(e);
                              CheckValid(e.target.value, {
                                type: "email",
                                error: setErrorbusiness_email_id,
                              });
                            }}
                            errormessage={errorbusiness_email_id}
                            onKeyUp={(e) =>
                              CheckValid(e.target.value, {
                                type: "email",
                                error: setErrorbusiness_email_id,
                              })
                            }
                            onKeyDown={EmptySpaceFieldValid}
                            maxLength={50}
                            minLength={1}
                            required={true}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-9 row">
                          <div className="col-md-12">
                            <label
                              htmlFor="toolbarClassName"
                              className="mb-2 fs-14px fw-600"
                            >
                              Store Description{" "}
                              <span className="common-blue">*</span>
                            </label>
                            <Editor
                              editorState={editorState}
                              onEditorStateChange={onEditorChange}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              wrapperStyle={{
                                width: "100%",
                                border: "1px solid #ccc",
                                backgroundColor: "white",
                                padding: "10px",
                                borderRadius: "4px",
                              }}
                              editorStyle={{
                                minHeight: "150px",
                                border: "1px solid #ccc",
                                padding: "10px",
                                borderRadius: "4px",
                                fontFamily: "sans-serif",
                              }}
                              toolbar={{
                                options: [
                                  "inline",
                                  "blockType",
                                  "fontSize",
                                  "list",
                                  "textAlign",
                                  "colorPicker",
                                  "link",
                                  "emoji",
                                  "history",
                                ],
                                inline: {
                                  inDropdown: false,
                                  options: [
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "superscript",
                                    "subscript",
                                  ],
                                },
                                list: {
                                  inDropdown: false,
                                  options: ["unordered", "ordered"],
                                },
                                textAlign: {
                                  inDropdown: true,
                                },
                                link: { inDropdown: false },
                                history: { inDropdown: false },
                              }}
                            />
                          </div>
                          {errorDesc && (
                            <div className="errormendatorr">
                              <i className="bi bi-x-circle-fill"></i>
                              {errorDesc}
                            </div>
                          )}
                        </div>
                        <div className="col-md-9 row my-3">
                          <div className="col-md-12 gap-4 d-flex">
                            <div className="col-md-6 bg-f8 p-2 border rounded">
                              <div className="d-flex flex-column">
                                <h6>
                                  Categories{" "}
                                  <span className="common-blue">*</span>
                                </h6>
                                <div className="filedaddwraps justify-content-start mb-2">
                                  <Select
                                    value={selectedCategories}
                                    onChange={handleCategoryChange}
                                    options={categoryOptions}
                                    isSearchable
                                    isMulti
                                    placeholder="Select Categories"
                                    components={customComponents}
                                    onKeyDown={handleKeyDownForSelect}
                                  />
                                  <OverlayTrigger
                                    placement="bottom"
                                    trigger={["hover", "focus"]}
                                    overlay={
                                      <Tooltip
                                        id="button-tooltip-2"
                                        style={{ position: "fixed" }}
                                      >
                                        Add Category
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="btn addbtn"
                                      onClick={(e) => AddCategoryFnct(e)}
                                    >
                                      <i className="bi bi-plus"></i>
                                    </button>
                                  </OverlayTrigger>
                                </div>
                                {errorCategory !== "" && (
                                  <div className="errormendatorr">
                                    <i className="bi bi-x-circle-fill"></i>
                                    {errorCategory}
                                  </div>
                                )}

                                {selectedCategories.map((category, index) => (
                                  <div
                                    key={index}
                                    className="selected-category ms-2"
                                  >
                                    <i
                                      className="bi bi-dash-circle text-red"
                                      onClick={() => removeCategory(category)}
                                    ></i>
                                    <span className="ms-2">
                                      {" "}
                                      {category.label}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="col-md-6 bg-f8 pe-2 p-2 border rounded">
                              <div className="d-flex flex-column">
                                <h6>
                                  Tags <span className="common-blue">*</span>
                                </h6>
                                <div className="filedaddwraps justify-content-start mb-2">
                                  <Select
                                    value={selectedTags}
                                    onChange={handleTagsChange}
                                    options={subcategoryOptions}
                                    isSearchable
                                    isMulti
                                    placeholder="Select Tags"
                                    components={customComponents}
                                    onKeyDown={handleKeyDownForSelect}
                                  />
                                  <OverlayTrigger
                                    placement="bottom"
                                    trigger={["hover", "focus"]}
                                    overlay={
                                      <Tooltip
                                        id="button-tooltip-2"
                                        style={{ position: "fixed" }}
                                      >
                                        Add Tags
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="btn addbtn"
                                      onClick={(e) => AddSubCategoryFnct(e)}
                                    >
                                      <i className="bi bi-plus"></i>
                                    </button>
                                  </OverlayTrigger>
                                </div>
                                {errorTags !== "" && (
                                  <div className="errormendatorr">
                                    <i className="bi bi-x-circle-fill"></i>
                                    {errorTags}
                                  </div>
                                )}

                                {selectedTags.map((Tag, index) => (
                                  <div
                                    key={index}
                                    className="selected-Tag ms-2"
                                  >
                                    <i
                                      className="bi bi-dash-circle text-red"
                                      onClick={() => removeTags(Tag)}
                                    ></i>
                                    <span className="ms-2"> {Tag.label}</span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-9 border-top">
                          <div className="col-md-12 d-flex gap-2 my-3">
                            <div className="col-md-6">
                              <div className="d-flex flex-column">
                                <label className="fs-14px mb-1">
                                  Country <span className="common-blue">*</span>
                                </label>
                                <div className="filedaddwraps justify-content-start mb-2">
                                  <Select
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                    options={vendorCountryOptions}
                                    placeholder="Select Country"
                                    required={true}
                                  />
                                </div>
                              </div>
                              {errorCountry !== "" && tabChange && (
                                <div className="errormendatorr">
                                  <i className="bi bi-x-circle-fill"></i>
                                  {errorCountry}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6">
                              <div className="d-flex flex-column">
                                <label className="fs-14px mb-1">
                                  State <span className="common-blue">*</span>
                                </label>
                                <div className="filedaddwraps justify-content-start mb-2">
                                  <Select
                                    value={selectedState}
                                    onChange={handleStateChange}
                                    options={dubaiStatesOptions}
                                    placeholder="Select State"
                                    required={true}
                                  />
                                </div>
                              </div>
                              {errorState !== "" && tabChange && (
                                <div className="errormendatorr">
                                  <i className="bi bi-x-circle-fill"></i>
                                  {errorState}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-9">
                          <InputField
                            type="text"
                            value={address}
                            className={
                              erroraddress !== ""
                                ? "form-control error-form"
                                : "form-control"
                            }
                            placeholder="Enter address in detail"
                            label="Detail Address "
                            name="address"
                            onChange={handleChange}
                            onKeyDown={EmptySpaceFieldValid}
                            onKeyUp={(e) =>
                              CheckValid(e.target.value, {
                                type: "Address",
                                error: setErroraddress,
                              })
                            }
                            errormessage={erroraddress}
                            required={true}
                          />
                        </div>

                        <div className="col-md-9 mb-5">
                          <div className="form">
                            {isLoaded && (
                              <StandaloneSearchBox
                                onLoad={(ref) => (inputRef.current = ref)}
                                onPlacesChanged={handlePlaceChanged}
                              >
                                <div className="position-relative">
                                  {" "}
                                  {/* Wrap both the image and input in a relative position container */}
                                  <img
                                    className="icon icon-car position-absolute top-50 start-25 translate-middle"
                                    style={{
                                      transform:
                                        "translateX(-50%) translateY(-50%)",
                                      left: "2%",
                                    }}
                                    src={MapPin}
                                    alt="Map Pin"
                                  />
                                  <input
                                    type="text"
                                    placeholder="Enter Location"
                                    className="form-control form-input text-black right-aligned-input text-truncate"
                                    style={{ paddingLeft: "40px" }}
                                    // readOnly={
                                    //   inputRef?.current?.getPlaces()?.length > 0
                                    // }
                                  />
                                  {inputRef?.current?.getPlaces() && (
                                    <img
                                      className="icon icon-car position-absolute top-50 end-25 translate-middle w-1rem"
                                      style={{
                                        transform:
                                          "translateX(-50%) translateY(-50%)",
                                        right: "2%",
                                      }}
                                      src={closeIcon}
                                      alt="closeIcon"
                                      onClick={handleClearInput}
                                      hidden
                                    />
                                  )}
                                </div>
                              </StandaloneSearchBox>
                            )}
                          </div>
                        </div>
                        {/* <div
                          className="col-md-9"
                          hidden
                          style={{ maxHeight: "400px", overflow: "hidden" }}
                        >
                          <GoogleMap
                            mapContainerStyle={{
                              height: "400px",
                              width: "100%",
                              borderRadius: "6px",
                            }}
                            zoom={7}
                            center={selectedLocation}
                            mapTypeId="roadmap"
                            onClick={handleMapClick}
                            options={mapOptions}
                          >
                            <Marker position={selectedLocation} />
                          </GoogleMap>
                        </div> */}
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="media"
                    title={
                      <div>
                        <i
                          className={`bi bi-2-circle-fill ${
                            infoFormFill && key === "media"
                              ? "common-blue"
                              : "text-secondary"
                          }`}
                        ></i>{" "}
                        Upload Photos & Videos
                      </div>
                    }
                  >
                    <div className="h-100 scrollformCustom overflow-x-hidden">
                      <div className="row" style={{ margin: "0 auto" }}>
                        <div className="col-md-12">
                          <div className="col-md-12">
                            <h5 className="fs-18px">
                              Add Business Logo{" "}
                              <span className="common-blue">*</span>
                            </h5>
                          </div>
                          <div className="w-50 d-flex gap-5">
                            <form
                              className="file-upload-form border-dotted max-w-50 w-auto"
                              onClick={handleFormClick}
                            >
                              <label
                                htmlFor="file"
                                className="file-upload-label"
                              >
                                <div className="d-flex align-items-center justify-content-center">
                                  <div className="common-blue-bg upload-icon rounded me-4 mt-1 ms-2">
                                    <i className="bi bi-cloud-arrow-down-fill text-white py-2 px-3"></i>
                                  </div>
                                  <div className="d-flex flex-column mb-1">
                                    <div className="mt--1">
                                      <input
                                        ref={fileInputRef}
                                        id="fileInput"
                                        type="file"
                                        style={{ visibility: "hidden" }}
                                        className="form-control"
                                        onChange={(e) => handleFileChange(e)}
                                        name="logo" // Added the name attribute
                                        disabled={UploadFileLoader}
                                        accept=".png, .jpg, .jpeg"
                                      />
                                    </div>
                                    <div className="me-0">
                                      <h6 className="mb-1 my-0 me-1">
                                        Upload Files
                                      </h6>
                                      <small className="fs-12px secondary-text">
                                        JPG JPEG, PNG
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </label>
                            </form>
                            {AvatarImage && (
                              <div className="col-auto border br-1rem d-flex position-relative">
                                <img
                                  src={closeIcon}
                                  alt=""
                                  className="position-absolute top--5 end--7 w-1rem"
                                  onClick={RemoveUploadImage}
                                />
                                <div className="ps-2 pe-4 py-2">
                                  <img
                                    className="w-3rem"
                                    src={AvatarImage?.url?.file_url}
                                    alt=""
                                    style={{ maxWidth: "4rem" }}
                                  />
                                </div>
                                <div className="d-flex flex-column gap-0 me-2">
                                  <i className="mb-0">
                                    {truncateString(
                                      AvatarImage?.original_name,
                                      18
                                    )}
                                  </i>
                                  <p className="secondary-text">
                                    {bytesToKB(AvatarImage?.size)}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                          {errorLogo !== "" && submitClick && (
                            <div className="errormendatorr">
                              <i className="bi bi-x-circle-fill"></i>
                              {errorLogo}
                            </div>
                          )}
                        </div>
                        <div className="col-md-12 border-bottom mt-3 mx-5"></div>
                        <div className="col-md-12 mt-3">
                          <div className="col-md-5">
                            <div className="d-flex gap-2">
                              <div>
                                <h5 className="col-md-12 mb-3 fs-18px">
                                  Upload Images{" "}
                                  <span className="common-blue">*</span>
                                </h5>
                                <form
                                  className="file-upload-form col-md-8 border-dotted"
                                  onClick={handleFormClickImage}
                                >
                                  <label
                                    htmlFor="file"
                                    className="file-upload-label"
                                  >
                                    <div className="d-flex align-items-center justify-content-center">
                                      <div className="common-blue-bg upload-icon rounded me-4 mt-1 ms-2">
                                        <i className="bi bi-cloud-arrow-down-fill text-white"></i>
                                      </div>
                                      <div className="d-flex flex-column mb-1">
                                        <div className="mt--1">
                                          <input
                                            ref={fileInputRefImage}
                                            id="fileInputImage"
                                            type="file"
                                            style={{ visibility: "hidden" }}
                                            onChange={(e) => {
                                              handleFileChangeImage(e);
                                            }}
                                            accept=".png, .jpg, .jpeg"
                                          />
                                        </div>
                                        <div className="me-0">
                                          <h6 className="mb-1 my-0 me-1">
                                            Upload Files
                                          </h6>
                                          <small className="fs-12px secondary-text">
                                            JPG JPEG, PNG
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                  </label>
                                </form>
                              </div>{" "}
                              <div>
                                <h5 className="col-md-12 mb-3 fs-18px">
                                  Upload Videos
                                </h5>
                                <form
                                  className="file-upload-form col-md-9 border-dotted"
                                  onClick={handleFormClickVideo}
                                >
                                  <label
                                    htmlFor="file"
                                    className="file-upload-label"
                                  >
                                    <div className="d-flex align-items-center justify-content-center">
                                      <div className="common-blue-bg upload-icon rounded me-4 mt-1 ms-2">
                                        <i className="bi bi-cloud-arrow-down-fill text-white"></i>
                                      </div>
                                      <div className="d-flex flex-column mb-1">
                                        <div className="mt--1">
                                          <input
                                            ref={fileInputRefVideo}
                                            id="fileInputVideo"
                                            type="file"
                                            style={{ visibility: "hidden" }}
                                            onChange={(e) => {
                                              handleFileChangeVideo(e);
                                            }}
                                            accept=".mp4, .avi"
                                          />
                                        </div>
                                        <div className="me-0">
                                          <h6 className="mb-1 my-0 me-1">
                                            Upload Files
                                          </h6>
                                          <small className="fs-12px secondary-text">
                                            mp4,avi,mov,size 30MB
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                  </label>
                                </form>
                              </div>
                            </div>
                            {errorMedia !== "" && submitClick && (
                              <div className="errormendatorr">
                                <i className="bi bi-x-circle-fill"></i>
                                {errorMedia}
                              </div>
                            )}
                          </div>
                          <div className="col-md-7"></div>
                          <div className="col-md-12 my-4">
                            <div
                              className="draggable-container"
                              style={{ display: "flex" }}
                            >
                              <BoxesGroup
                                boxes={fileData}
                                setBoxes={setFileData}
                                accessToken={accessToken}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-12 mb-4"
                          id="open-close-add-edit-vendor"
                        >
                          <div className="col-md-12 d-flex align-items-baseline justify-content-between">
                            <h5 className="my-4 fs-18px">
                              Store Opening Time & Closing Time
                            </h5>
                            <div className="me-1 gap-2 d-flex align-items-center">
                              <label
                                htmlFor="SameOpenAndClose"
                                className="secondary-text"
                              >
                                Set same opening and closing time
                              </label>
                              <input
                                type="checkbox"
                                id="SameOpenAndClose"
                                name="SameOpenAndClose"
                                checked={SameOpenAndClose}
                                onChange={() => {
                                  setSameOpenAndClose(!SameOpenAndClose);
                                  setInitialTimings();
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 d-flex gap-3 flex-column">
                            <div className="day-input-container d-flex align-items-center gap-4">
                              <input
                                type="checkbox"
                                id="Monday"
                                name="Monday_Active"
                                checked={storeData?.Monday?.Monday_Active}
                                onChange={(event) =>
                                  changeHandler(event, "Monday")
                                }
                              />
                              {!storeData?.Monday?.Monday_Active ? (
                                <>
                                  <span className="col-md-2">Monday</span>
                                  <span className="col-md-2 secondary-text">
                                    Closed
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="col-md-2">Monday</span>

                                  <label
                                    htmlFor="open_time"
                                    className="secondary-text"
                                  >
                                    Opens At
                                  </label>
                                  <input
                                    type="time"
                                    name="open_time"
                                    value={storeData?.Monday?.open_time}
                                    placeholder="Select Open Time"
                                    onChange={(event) =>
                                      changeHandler(event, "Monday")
                                    }
                                    hidden
                                  />
                                  <TimePicker
                                    name="open_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Monday",
                                        "open_time"
                                      )
                                    }
                                    time={storeData?.Monday?.open_time}
                                    placeholder={storeData?.Monday?.open_time}
                                  />

                                  <label
                                    htmlFor="close_time"
                                    className="secondary-text"
                                  >
                                    Closes At
                                  </label>
                                  <input
                                    hidden
                                    type="time"
                                    name="close_time"
                                    value={storeData?.Monday?.close_time}
                                    placeholder="Select Close Time"
                                    onChange={(event) =>
                                      changeHandler(event, "Monday")
                                    }
                                  />
                                  <TimePicker
                                    name="close_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Monday",
                                        "close_time"
                                      )
                                    }
                                    time={storeData?.Monday?.close_time}
                                    placeholder={storeData?.Monday?.close_time}
                                  />
                                </>
                              )}
                            </div>

                            <div className="day-input-container d-flex align-items-center gap-4">
                              <input
                                type="checkbox"
                                id="Tuesday"
                                name="Tuesday_Active"
                                checked={storeData?.Tuesday?.Tuesday_Active}
                                onChange={(event) =>
                                  changeHandler(event, "Tuesday")
                                }
                              />
                              {!storeData?.Tuesday?.Tuesday_Active ? (
                                <>
                                  <span className="col-md-2">Tuesday</span>
                                  <span className="col-md-2 secondary-text">
                                    Closed
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="col-md-2">Tuesday</span>

                                  <label
                                    htmlFor="open_time"
                                    className="secondary-text"
                                  >
                                    Opens At
                                  </label>
                                  <input
                                    hidden
                                    type="time"
                                    name="open_time"
                                    value={storeData?.Tuesday?.open_time}
                                    placeholder="Select Open Time"
                                    onChange={(event) =>
                                      changeHandler(event, "Tuesday")
                                    }
                                  />
                                  <TimePicker
                                    name="open_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Tuesday",
                                        "open_time"
                                      )
                                    }
                                    time={storeData?.Tuesday?.open_time}
                                    placeholder={storeData?.Tuesday?.open_time}
                                  />
                                  <label
                                    htmlFor="close_time"
                                    className="secondary-text"
                                  >
                                    Closes At
                                  </label>
                                  <input
                                    hidden
                                    type="time"
                                    name="close_time"
                                    value={storeData?.Tuesday?.close_time}
                                    placeholder="Select Close Time"
                                    onChange={(event) =>
                                      changeHandler(event, "Tuesday")
                                    }
                                  />
                                  <TimePicker
                                    name="close_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Tuesday",
                                        "close_time"
                                      )
                                    }
                                    time={storeData?.Tuesday?.close_time}
                                    placeholder={storeData?.Tuesday?.close_time}
                                  />
                                </>
                              )}
                            </div>

                            <div className="d-flex align-items-center gap-4">
                              <input
                                type="checkbox"
                                id="Wednesday"
                                name="Wednesday_Active"
                                checked={storeData?.Wednesday?.Wednesday_Active}
                                onChange={(event) =>
                                  changeHandler(event, "Wednesday")
                                }
                              />
                              {!storeData?.Wednesday?.Wednesday_Active ? (
                                <>
                                  <span className="col-md-2">Wednesday</span>
                                  <span className="col-md-2 secondary-text">
                                    Closed
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="col-md-2">Wednesday</span>

                                  <label
                                    htmlFor="open_time"
                                    className="secondary-text"
                                  >
                                    Opens At
                                  </label>

                                  <input
                                    hidden
                                    type={"time"}
                                    name={"open_time"}
                                    value={storeData?.Wednesday?.open_time}
                                    placeholder={"Select Open Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Wednesday")
                                    }
                                  />
                                  <TimePicker
                                    name="open_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Wednesday",
                                        "open_time"
                                      )
                                    }
                                    time={storeData?.Wednesday?.open_time}
                                    placeholder={
                                      storeData?.Wednesday?.open_time
                                    }
                                  />
                                  <label
                                    htmlFor="close_time"
                                    className="secondary-text"
                                  >
                                    Closes At
                                  </label>
                                  <input
                                    hidden
                                    type={"time"}
                                    name={"close_time"}
                                    value={storeData?.Wednesday?.close_time}
                                    placeholder={"Select Close Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Wednesday")
                                    }
                                  />
                                  <TimePicker
                                    name="close_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Wednesday",
                                        "close_time"
                                      )
                                    }
                                    time={storeData?.Wednesday?.close_time}
                                    placeholder={
                                      storeData?.Wednesday?.close_time
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div className="d-flex align-items-center gap-4">
                              <input
                                type="checkbox"
                                id="Thursday"
                                name="Thursday_Active"
                                checked={storeData?.Thursday?.Thursday_Active}
                                onChange={(event) =>
                                  changeHandler(event, "Thursday")
                                }
                              />
                              {!storeData?.Thursday?.Thursday_Active ? (
                                <>
                                  <span className="col-md-2">Thursday</span>
                                  <span className="col-md-2 secondary-text">
                                    Closed
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="col-md-2">Thursday</span>

                                  <label
                                    htmlFor="open_time"
                                    className="secondary-text"
                                  >
                                    Opens At
                                  </label>

                                  <input
                                    hidden
                                    type={"time"}
                                    name={"open_time"}
                                    value={storeData?.Thursday?.open_time}
                                    placeholder={"Select Open Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Thursday")
                                    }
                                  />
                                  <TimePicker
                                    name="open_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Thursday",
                                        "open_time"
                                      )
                                    }
                                    time={storeData?.Thursday?.open_time}
                                    placeholder={storeData?.Thursday?.open_time}
                                  />
                                  <label
                                    htmlFor="close_time"
                                    className="secondary-text"
                                  >
                                    Closes At
                                  </label>
                                  <input
                                    hidden
                                    type={"time"}
                                    name={"close_time"}
                                    value={storeData?.Thursday?.close_time}
                                    placeholder={"Select Close Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Thursday")
                                    }
                                  />
                                  <TimePicker
                                    name="close_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Thursday",
                                        "close_time"
                                      )
                                    }
                                    time={storeData?.Thursday?.close_time}
                                    placeholder={
                                      storeData?.Thursday?.close_time
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div className="d-flex align-items-center gap-4">
                              <input
                                type="checkbox"
                                id="Friday"
                                name="Friday_Active"
                                checked={storeData?.Friday?.Friday_Active}
                                onChange={(event) =>
                                  changeHandler(event, "Friday")
                                }
                              />
                              {!storeData?.Friday?.Friday_Active ? (
                                <>
                                  <span className="col-md-2">Friday</span>
                                  <span className="col-md-2 secondary-text">
                                    Closed
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="col-md-2">Friday</span>

                                  <label
                                    htmlFor="open_time"
                                    className="secondary-text"
                                  >
                                    Opens At
                                  </label>

                                  <input
                                    hidden
                                    type={"time"}
                                    name={"open_time"}
                                    value={storeData?.Friday?.open_time}
                                    placeholder={"Select Open Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Friday")
                                    }
                                  />
                                  <TimePicker
                                    name="open_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Friday",
                                        "open_time"
                                      )
                                    }
                                    time={storeData?.Friday?.open_time}
                                    placeholder={storeData?.Friday?.open_time}
                                  />
                                  <label
                                    htmlFor="close_time"
                                    className="secondary-text"
                                  >
                                    Closes At
                                  </label>
                                  <input
                                    hidden
                                    type={"time"}
                                    name={"close_time"}
                                    value={storeData?.Friday?.close_time}
                                    placeholder={"Select Close Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Friday")
                                    }
                                  />
                                  <TimePicker
                                    name="close_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Friday",
                                        "close_time"
                                      )
                                    }
                                    time={storeData?.Friday?.close_time}
                                    placeholder={storeData?.Friday?.close_time}
                                  />
                                </>
                              )}
                            </div>
                            <div className="d-flex align-items-center gap-4">
                              <input
                                type="checkbox"
                                id="Saturday"
                                name="Saturday_Active"
                                checked={storeData?.Saturday?.Saturday_Active}
                                onChange={(event) =>
                                  changeHandler(event, "Saturday")
                                }
                              />
                              {!storeData?.Saturday?.Saturday_Active ? (
                                <>
                                  <span className="col-md-2">Saturday</span>
                                  <span className="col-md-2 secondary-text">
                                    Closed
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="col-md-2">Saturday</span>

                                  <label
                                    htmlFor="open_time"
                                    className="secondary-text"
                                  >
                                    Opens At
                                  </label>

                                  <input
                                    hidden
                                    type={"time"}
                                    name={"open_time"}
                                    value={storeData?.Saturday?.open_time}
                                    placeholder={"Select Open Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Saturday")
                                    }
                                  />
                                  <TimePicker
                                    name="open_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Saturday",
                                        "open_time"
                                      )
                                    }
                                    time={storeData?.Saturday?.open_time}
                                    placeholder={storeData?.Saturday?.open_time}
                                  />

                                  <label
                                    htmlFor="close_time"
                                    className="secondary-text"
                                  >
                                    Closes At
                                  </label>
                                  <input
                                    hidden
                                    type={"time"}
                                    name={"close_time"}
                                    value={storeData?.Saturday?.close_time}
                                    placeholder={"Select Close Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Saturday")
                                    }
                                  />

                                  <TimePicker
                                    name="close_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Saturday",
                                        "close_time"
                                      )
                                    }
                                    time={storeData?.Saturday?.close_time}
                                    placeholder={
                                      storeData?.Saturday?.close_time
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div className="d-flex align-items-center gap-4">
                              <input
                                type="checkbox"
                                id="Sunday"
                                name="Sunday_Active"
                                checked={storeData?.Sunday?.Sunday_Active}
                                onChange={(event) =>
                                  changeHandler(event, "Sunday")
                                }
                              />
                              {!storeData?.Sunday?.Sunday_Active ? (
                                <>
                                  <span className="col-md-2">Sunday</span>
                                  <span className="col-md-2 secondary-text">
                                    Closed
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="col-md-2">Sunday</span>

                                  <label
                                    htmlFor="open_time"
                                    className="secondary-text"
                                  >
                                    Opens At
                                  </label>

                                  <input
                                    hidden
                                    type={"time"}
                                    name={"open_time"}
                                    value={storeData?.Sunday?.open_time}
                                    placeholder={"Select Open Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Sunday")
                                    }
                                  />
                                  <TimePicker
                                    name="open_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Sunday",
                                        "open_time"
                                      )
                                    }
                                    time={storeData?.Sunday?.open_time}
                                    placeholder={storeData?.Sunday?.open_time}
                                  />
                                  <label
                                    htmlFor="close_time"
                                    className="secondary-text"
                                  >
                                    Closes At
                                  </label>
                                  <input
                                    hidden
                                    type={"time"}
                                    name={"close_time"}
                                    value={storeData?.Sunday?.close_time}
                                    placeholder={"Select Close Time"}
                                    onChange={(event) =>
                                      changeHandler(event, "Sunday")
                                    }
                                  />
                                  <TimePicker
                                    name="close_time"
                                    onFocusChange={console.log}
                                    minuteStep={1}
                                    onTimeChange={(newTime) =>
                                      changeHandler(
                                        newTime,
                                        "Sunday",
                                        "close_time"
                                      )
                                    }
                                    time={storeData?.Sunday?.close_time}
                                    placeholder={storeData?.Sunday?.close_time}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-22" hidden>
                          <div
                            className="form-group-btn"
                            onClick={() => CreateVendor()}
                            style={{ textAlign: "right" }}
                          >
                            <button className="btn addbtncmn">
                              {Location === "edit-product" ? "Save" : "Create"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </Scrollbars>
            </div>
            {/* loader start here */}
            {loaderMain && <CommonLoader />}
          </section>
          {showModal?.open && (
            <CommonModel
              fromUrl={"product"}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AddEditVendor;
