/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from "react-custom-scrollbars-2";
import CommonToggle from "../Common/CommonToggle";
import moment from "moment";
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";
import CommonModel from "../Common/CommonModel";
import { Tab, Tabs } from "react-bootstrap";

const CategoryManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const { selfInfo, categoryList, subcategoryList } = useSelector(
    (state) => state.adminReducers
  );
  console.log("categoryList", categoryList);
  const [userSearch, setUserSearch] = useState("");
  const [userType, setUserType] = useState("");
  const [userLimit, setUserLimit] = useState("");
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem("currentPage") || 1);
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
const resetCurrentPage = () => {
  setCurrentPage(1)
}
  useEffect(()=>{
    resetCurrentPage()
  },[userSearch])
  const [key, setKey] = useState("maincategory");
  console.log("categoryList", categoryList);

  // page change
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  // clear search box
  const ClearSearchFiled = () => {
    setUserSearch("");
  };

  // select user type
  const SelectUserTypeFnct = (e) => {
    setUserType(e.target.value);
    setCurrentPage(1);
  };

  const getParentCategoryById = (id) => {
    const selectedCategory = categoryList?.list?.find(
      (item) => item?.id === id
    );
    if (selectedCategory) {
      return selectedCategory?.name;
    } else {
      return "Category Not Found";
    }
  };
  const getAllCategories = () => {
    const inputParam = {
      transmit: "single",
      url: "category_listing",
      request: {
        filter: "all",
        limit: 5000,
        page: 1,
        search: null,
        user_type: selfInfo?.user_type,
        category_by: selfInfo?.user_type,
      },
    };
    wsSend_request(websocket, inputParam);
  };
  useEffect(() => {
    setUserLimit("");
    setCurrentPage(1);
    setUserType("");
    setUserSearch("");
  }, [key]);
 
  
  // get supplier list
  useEffect(() => {
    let param = "";
    if (key === "maincategory") {
      param = {
        transmit: "single",
        url: "category_listing",
        request: {
          filter: userType,
          limit: userLimit || 10,
          page: currentPage,
          search: userSearch.trim(),
          user_type: selfInfo?.user_type,
          category_by: selfInfo?.user_type,
        },
      };
    } else if (key === "usercategory") {
      param = {
        transmit: "single",
        url: "category_listing",
        request: {
          filter: userType,
          limit: userLimit || 10,
          page: currentPage,
          search: userSearch.trim(),
          user_type: selfInfo?.user_type,
          category_by: "user",
        },
      };
    } else {
      getAllCategories();
      param = {
        transmit: "single",
        url: "subcategory_listing",
        request: {
          filter: userType,
          limit: userLimit || 10,
          page: currentPage,
          search: userSearch.trim(),
          user_type: selfInfo?.user_type,
        },
      };
    }
    wsSend_request(websocket, param);
    // getAllCategories()
  }, [userSearch, userType, userLimit, currentPage, key,websocket]);

  // add new category
  const AddCategoryFnct = () => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Add Category",
      subtitle: "Add Category",
      description: ``,
      modalType: "form",
      button: "Yes",
    });
  };

  // edit category
  const EditCategory = (data_) => {
    sessionStorage.setItem(
      "currentPage",
      currentPage
    );
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Edit Category",
      subtitle: "Edit Category",
      description: ``,
      modalType: "form",
      button: "Save",
      Data: data_,
    });
  };

  // delete category
  const DeleteCategoryFnct = (data_) => {
    sessionStorage.setItem(
      "currentPage",
      currentPage
    );
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Delete Category",
      subtitle: "Delete Category",
      description: `Do you really want to delete <h5>${data_?.name}</h5>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
    });
  };

  const DeleteUserCategoryFnct = (data_) => {
    sessionStorage.setItem(
      "currentPage",
      currentPage
    );
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Delete Category",
      subtitle: "Delete User Category",
      description: `Do you really want to delete <h5>${data_?.name}</h5>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
    });
  };

  // active supplier
  const AllowCategoryGet = (data_, type, booleancheck) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Are you sure?",
      subtitle: "Active Category",
      description: `Do you really want to ${booleancheck ? "remove " : ""} ${
        type.split("_")[1]
      } to <h5>${data_?.name}</h5>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
    });
  };
  const AllowUserCategoryGet = (data_, type, booleancheck) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Are you sure?",
      subtitle: "Active User Category",
      description: `Do you really want to ${booleancheck ? "remove " : ""} ${
        type.split("_")[1]
      } to <h5>${data_?.name}</h5>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
    });
  };
  const AllowLiveUserCategoryGet = (data_, type, booleancheck) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Are you sure?",
      subtitle: "Live User Category",
      description: `Do you really want to live the category <h5>${data_?.name}</h5>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
    });
  };

  // add new category
  const AddSubCategoryFnct = () => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Add Sub Category",
      subtitle: "Add Sub Category",
      description: ``,
      modalType: "form",
      button: "Save",
    });
  };

  // edit category
  const EditSubCategory = (data_) => {
    sessionStorage.setItem(
      "currentPage",
      currentPage
    );
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Edit Sub Category",
      subtitle: "Edit Sub Category",
      description: ``,
      modalType: "form",
      button: "Save",
      Data: data_,
    });
  };

  // delete category
  const DeleteSubCategoryFnct = (data_) => {
    sessionStorage.setItem(
      "currentPage",
      currentPage
    );
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Delete Sub Category",
      subtitle: "Delete Sub Category",
      description: `Do you really want to delete <h5>${data_?.name}</h5>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
    });
  };

  // active supplier
  const AllowSubCategoryGet = (data_, type, booleancheck) => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Are you sure?",
      subtitle: "Active Sub Category",
      description: `Do you really want to ${booleancheck ? "remove " : ""} ${
        type.split("_")[1]
      } to <h5>${data_?.name}</h5>`,
      modalType: "not form",
      button: "Yes",
      Data: data_,
    });
  };
  return (
    <section className="dashboard_wrapper h-96 common-border  p-0 rounded">
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 ps-3"
      >
        <Tab eventKey="maincategory" title="Categories">
          <DataTableFilter
            filterType={"By Category"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={
              selfInfo?.user_type === "admin" ||
              selfInfo?.permissions?.includes("add-category")
            }
            ButtonSet={{
              buttontitle: "Add New Category",
              function: AddCategoryFnct,
            }}
          />
          <div
            className="tableContent"
            style={{
              height:
                window.screen.width > 540
                  ? "calc(100vh - 115px)"
                  : "calc(100vh - 143px)",
            }}
          >
            <Scrollbars
              style={{
                height:
                  categoryList?.pagination?.total_records > 10
                    ? window.screen.width < 767
                      ? "calc(100% - 10px)"
                      : "calc(100% - 7.5rem)"
                    : "calc(100% - 95px)",
              }}
              className="ScrollbarsWrapper"
              renderView={(props) => <div {...props} className="view" />}
            >
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Category Name</th>
                      <th>Created At</th>
                      {(selfInfo?.user_type === "admin" ||
                        selfInfo?.permissions?.includes("update-category")) && (
                        <th>Active</th>
                      )}

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoryList &&
                      categoryList?.list?.map((user, index) => {
                        return (
                          <tr key={index}>
                            <td>{user?.name}</td>

                            <td>{moment(user.created_at).format("LL")}</td>
                            {(selfInfo?.user_type === "admin" ||
                              selfInfo?.permissions?.includes(
                                "update-category"
                              )) && (
                              <td>
                                <CommonToggle
                                  valueToggle={user?.is_active}
                                  setValueToggle={() =>
                                    AllowCategoryGet(
                                      user,
                                      "is_active",
                                      user?.is_active
                                    )
                                  }
                                  name={""}
                                />
                              </td>
                            )}
                            <td className="respoactionSupplier">
                              {(selfInfo?.user_type === "admin" ||
                                selfInfo?.permissions?.includes(
                                  "update-category"
                                )) && (
                                <button
                                  type="button"
                                  className="btn actiontblebtn edit-btn"
                                  onClick={() => EditCategory(user)}
                                >
                                  <i className="bi bi-pencil-square"></i>
                                </button>
                              )}
                              {(selfInfo?.user_type === "admin" ||
                                selfInfo?.permissions?.includes(
                                  "delete-category"
                                )) && (
                                <button
                                  type="button"
                                  className="btn actiontblebtn delete-btn"
                                  onClick={() => DeleteCategoryFnct(user)}
                                >
                                  <i className="bi bi-trash3"></i>
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    {categoryList && categoryList?.list.length === 0 && (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={8}>
                          Category Not Found !
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Scrollbars>
            {/* pagination */}
            {console.log(
              "categoryList?.pagination?.total_records > 10",
              categoryList?.pagination?.total_records > 10
            )}
            {categoryList?.pagination?.total_records > 10 && (
              <CommonPagination
                currentPage={currentPage}
                paginationLength={categoryList?.pagination}
                currentFunction={currentFunction}
              />
            )}
          </div>
        </Tab>
        <Tab eventKey="usercategory" title="User Categories">
          <DataTableFilter
            filterType={"By User Category"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
          />
          <div
            className="tableContent"
            style={{
              height:
                window.screen.width > 540
                  ? "calc(100vh - 222px)"
                  : "calc(100vh - 143px)",
            }}
          >
            <Scrollbars
              style={{
                height:
                  categoryList?.pagination?.total_records > 10
                    ? window.screen.width < 767
                      ? "calc(100% - 10px)"
                      : "calc(100% - 1rem)"
                    : "calc(100% - 0px)",
              }}
              className="ScrollbarsWrapper"
              renderView={(props) => <div {...props} className="view" />}
            >
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>User Category Name</th>

                      <th>Created At</th>

                      {(selfInfo?.user_type === "admin" ||
                        selfInfo?.permissions?.includes("update-category")) && (
                        <th>Action</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {categoryList &&
                      categoryList?.list?.map((user, index) => {
                        return (
                          <tr key={index}>
                            <td>{user?.name}</td>

                            <td>{moment(user.created_at).format("LL")}</td>

                            {(selfInfo?.user_type === "admin" ||
                              selfInfo?.permissions?.includes(
                                "update-category"
                              )) && (
                              <td className="respoactionSupplier">
                                <CommonToggle
                                  valueToggle={user?.is_active}
                                  setValueToggle={() =>
                                    AllowLiveUserCategoryGet(
                                      user,
                                      "is_active",
                                      user?.is_active
                                    )
                                  }
                                  name={""}
                                />
                                {(selfInfo?.user_type === "admin" ||
                                  selfInfo?.permissions?.includes(
                                    "delete-category"
                                  )) && (
                                  <button
                                    type="button"
                                    className="btn actiontblebtn delete-btn"
                                    onClick={() => DeleteUserCategoryFnct(user)}
                                  >
                                    <i className="bi bi-trash3"></i>
                                  </button>
                                )}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    {categoryList && categoryList?.list.length === 0 && (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={8}>
                          User Category Not Found !
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Scrollbars>
            {/* pagination */}
            {parseInt(categoryList?.pagination?.total_records) > 10 && (
              <CommonPagination
                currentPage={currentPage}
                paginationLength={categoryList?.pagination}
                currentFunction={currentFunction}
              />
            )}
          </div>
        </Tab>
        <Tab eventKey="subcategory" title="Sub Categories">
          <DataTableFilter
            filterType={"By Sub Category"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={
              selfInfo?.user_type === "admin" ||
              selfInfo?.permissions?.includes("add-category")
            }
            ButtonSet={{
              buttontitle: "Add New Sub Category",
              function: AddSubCategoryFnct,
            }}
          />
          <div
            className="tableContent"
            style={{
              height:
                window.screen.width > 540
                  ? "calc(100vh - 115px)"
                  : "calc(100vh - 143px)",
            }}
          >
            <Scrollbars
              style={{
                height:
                  subcategoryList?.pagination?.total_records > 10
                    ? window.screen.width < 767
                      ? "calc(100% - 10px)"
                      : "calc(100% - 7.5rem)"
                    : "calc(100% - 0px)",
              }}
              className="ScrollbarsWrapper"
              renderView={(props) => <div {...props} className="view" />}
            >
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Sub Category Name</th>
                      <th>Category Name</th>

                      <th>Created At</th>
                      {(selfInfo?.user_type === "admin" ||
                        selfInfo?.permissions?.includes("update-category")) && (
                        <th>Active</th>
                      )}

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subcategoryList &&
                      subcategoryList?.list?.map((user, index) => {
                        return (
                          <tr key={index}>
                            <td>{user?.name}</td>
                            <td>{getParentCategoryById(user?.category_id)}</td>

                            <td>{moment(user.created_at).format("LL")}</td>
                            {(selfInfo?.user_type === "admin" ||
                              selfInfo?.permissions?.includes(
                                "update-category"
                              )) && (
                              <td>
                                <CommonToggle
                                  valueToggle={user?.is_active}
                                  setValueToggle={() =>
                                    AllowSubCategoryGet(
                                      user,
                                      "is_active",
                                      user?.is_active
                                    )
                                  }
                                  name={""}
                                />
                              </td>
                            )}
                            <td className="respoactionSupplier">
                              {(selfInfo?.user_type === "admin" ||
                                selfInfo?.permissions?.includes(
                                  "update-category"
                                )) && (
                                <button
                                  type="button"
                                  className="btn actiontblebtn edit-btn"
                                  onClick={() => EditSubCategory(user)}
                                >
                                  <i className="bi bi-pencil-square"></i>
                                </button>
                              )}
                              {(selfInfo?.user_type === "admin" ||
                                selfInfo?.permissions?.includes(
                                  "delete-category"
                                )) && (
                                <button
                                  type="button"
                                  className="btn actiontblebtn delete-btn"
                                  onClick={() => DeleteSubCategoryFnct(user)}
                                >
                                  <i className="bi bi-trash3"></i>
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    {subcategoryList && subcategoryList?.list.length === 0 && (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={8}>
                          Sub Category Not Found !
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Scrollbars>
            {/* pagination */}
            {parseInt(subcategoryList?.pagination?.total_records) > 10 && (
              <CommonPagination
                currentPage={currentPage}
                paginationLength={subcategoryList?.pagination}
                currentFunction={currentFunction}
              />
            )}
          </div>
        </Tab>
      </Tabs>

      {showModal?.open && (
        <CommonModel
          setUserSearch={setUserSearch}
          setCurrentPage={setCurrentPage}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </section>
  );
};

export default CategoryManagement;
