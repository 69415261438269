/* eslint-disable */
import React, { useState } from "react";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import ExportIcon from "../../assets/img/export_icon.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const DataTableFilter = (props) => {
  const { filterType, buttonvisible, userSearch, ButtonSet, setUserSearch, userType, userState, userCategory, tooltip, userLimit, setUserLimit, ClearSearchFiled, SelectUserStateFnct, SelectUserTypeFnct, SelectUserCategoryFnct } = props;
  const [ResponsiveSearchBox, setResponsiveSearchBox] = useState(false);

  return (<div className="card-header-New">
    {window.screen.width > 700 ? (<React.Fragment>
      {userSearch !== undefined && (
        <>
          <div className="searchBoxwithbtn w-25 ps-3">
            <div className="text-right">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip
                    id="button-tooltip-2"
                    style={{ position: "fixed" }}
                  >
                    {tooltip ? tooltip : "Search By Name"}
                  </Tooltip>
                }
              >
                <i hidden
                  className="bi bi-info-circle-fill position-absolute"
                  style={{ top: "-1.6rem" }}
                ></i>
              </OverlayTrigger>
            </div>
            {userSearch === "" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-search left-21px"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                <path d="M21 21l-6 -6"></path>
              </svg>
            ) : (
              <i
                className="bi bi-x-circle-fill searchclear"
                onClick={() => ClearSearchFiled()}
              ></i>
            )}
            <input
              type="text"
              className="form-control rounded"
              value={userSearch}
              onKeyDown={(e) => EmptySpaceFieldValid(e)}
              onChange={(e) => setUserSearch(e.target.value)}
              placeholder={`Search ${filterType} ...`}
            />
          </div>
        </>
      )}
    </React.Fragment>) : (<React.Fragment>
      <div className="responsiveSearchBox">
        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setResponsiveSearchBox(!ResponsiveSearchBox)} className="icon icon-tabler left-21px icon-tabler-search icon-tabler-search-respo" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
          <path d="M21 21l-6 -6"></path>
        </svg>
        {ResponsiveSearchBox && (<div className="responsiSearcFiled">
          <div className="searchBoxwithbtn">
            <input type="text" className="form-control rounded ps-3" value={userSearch} onKeyDown={(e) => EmptySpaceFieldValid(e)} onChange={(e) => setUserSearch(e.target.value)} placeholder={`Search ${filterType} ...`} />
            {userSearch === "" ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search left-21px" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
              <path d="M21 21l-6 -6"></path>
            </svg>) : (<i className="bi bi-x-circle-fill searchclear" onClick={() => ClearSearchFiled()}></i>)}
          </div>
        </div>)}
      </div>
    </React.Fragment>)}
    <div className="row card-header-right w-auto ">
      <div className="d-flex align-items-center">
        {userCategory !== undefined && (
          <div className="d-flex align-items-center position-relative col-auto">
            {userCategory === "" || userCategory === "All" &&
              <i className="bi bi-flag position-absolute ms-2"></i>}
            <select
              className={`form-control activestatusselet rounded ${userCategory === "All" ? "text-center" : "text-start"}`}
              value={userCategory}
              onChange={(e) => SelectUserCategoryFnct(e)}
              id="user-type"
            >
              <option value="" disabled selected>Select Status</option>
              <option value="all">All</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
        )}
        {console.log("userCategory", userCategory)}



        {userState !== undefined && (
          <div className="col-auto">

            {/* <i className="bi bi-credit-card-2-back-fill position-absolute"></i> */}
            {userCategory === "All" &&
              <i className="bi bi-flag position-absolute ms-2"></i>}
            <select
              className="form-control activestatusselet w-auto text-center rounded"
              value={userState}
              onChange={(e) => SelectUserStateFnct(e)}
              id="user-type"
            >

<option value="" disabled selected>Select Status</option>
              <option value="all">All</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>)}

        {userType !== undefined && (
          <div className="col-auto">

            {/* <i className="bi bi-credit-card-2-back-fill position-absolute"></i> */}

            <select
              className="form-control activestatusselet w-auto rounded"
              value={userType}
              onChange={(e) => SelectUserTypeFnct(e)}
              id="user-type"
            >

<option value="" disabled selected>Select Status</option>
              <option value="all">All</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>)}
        {/* {userLimit && */}
          <div className="col-auto">
            <select
              className="form-control userLimitselect w-auto  text-start rounded"
              value={userLimit}
              onChange={(e) => setUserLimit(parseInt(e.target.value))}
            >
              <option value="" selected disabled>Records</option>
              <option value="10">
                10
              </option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
              <option value="60">60</option>
              <option value="70">70</option>
              <option value="80">80</option>
              <option value="90">90</option>
              <option value="100">100</option>
            </select>
          </div>
        {/* } */}

        {buttonvisible && (
          <button
            className="btn head_cmn_btn add-button-data-table me-3 rounded "
            onClick={ButtonSet?.function}
          >
            {/* <i className="bi bi-plus"></i> */} + {" "}
            {ButtonSet?.buttontitle}
          </button>
        )}
      </div>
    </div>
  </div>)
}

export default DataTableFilter;