import React from "react";

const InputField = ({
  value,
  maxLength,
  minLength,
  label,
  className,
  name,
  placeholder,
  autoFocus,
  type,
  onChange,
  onKeyDown,
  onKeyUp,
  readOnly,
  errormessage,
  onClick,
  showpassword,
  showOldpassword,
  showConfirmpassword,
  required,
}) => (
  <div
    className={
      errormessage !== "" ? "form-group error-form-group" : "form-group"
    }
  >
    {console.log("showConfirmpassword",showConfirmpassword)}
    {label && (
      <label htmlFor="input-field">
        {label.replace(/<(.*)>/g, "")} {/* Escape any HTML tags in label */}
        {required ? <span className="common-blue">*</span> : null}
      </label>
    )}

    <div className="formInput">
      {name === "Password" && (
        <input type="password" style={{ display: "none" }} />
      )}
      {name === "Address" ||
      name === "ReviewMessage" ||
      name === "Description" ||
      name === "DescriptionFrench" ||
      name === "DescriptionEnglish" ||
      name === "SupportReply" ||
      name === "Message" ? (
        <textarea
          type={type}
          value={value}
          name={name}
          className={className}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
      ) : (
        <input
          type={type}
          value={value}
          name={name}
          className={className}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          autoComplete="off"
          readOnly={readOnly}
          required={required}
          maxLength={maxLength}
          minLength={minLength}
          autoFocus={autoFocus}
          title={placeholder}
        />
      )}
      {name === "Password" && (
        <button className="eyeshowbtn" onClick={onClick}>
          {showpassword ? (
            <i className="bi bi-eye"></i>
          ) : (
            <i className="bi bi-eye-slash"></i>
          )}
        </button>
      )}
      {name === "Old_password" && (
        <button className="eyeshowbtn" onClick={onClick}>
          {showOldpassword ? (
            <i className="bi bi-eye"></i>
          ) : (
            <i className="bi bi-eye-slash"></i>
          )}
        </button>
      )}
      {name === "Confirm_password" && (
        <button className="eyeshowbtn" onClick={onClick}>
          {showConfirmpassword ? (
            <i className="bi bi-eye"></i>
          ) : (
            <i className="bi bi-eye-slash"></i>
          )}
        </button>
      )}
    </div>
    {errormessage !== "" && (
      <div className="errormendatorr">
        <i className="bi bi-x-circle-fill"></i>
        {errormessage}
      </div>
    )}
  </div>
);

export default InputField;
