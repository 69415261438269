import React from 'react'

const GradientDiv = (props) => {
    return (
        <div className=" py-2 px-0" style={{ width: "128px", height: "117px" }}>
            <div className="col-md-12">
                <h6 className='w-max-content'>{props?.alt}</h6>
            </div>
            <div className={`position-relative p-2 rounded ${props?.className}`}>
                <div className="d-flex align-items-center flex-column py-3 bg-white position-relative h-100px h-100 rounded">
                    {props?.subTitle && <h6 className='text-center text-black fw-700 mt-1'>{props?.subTitle}</h6>}

                    {props?.src && <img height={"45px"} width={"40px"} src={props?.src} alt={props?.alt} className={`${props?.imgClass}`}/>}
                    <h5 className={`my-2  ${props?.cntClassName} fw-800`}>{props?.text}</h5>
                </div>
            </div>
        </div>
    )
}

export default GradientDiv