import React from "react";
import { Modal, Button } from "react-bootstrap";
import checkIcon from "../../assets/img/checkIcon.png";
import HurrayCheckIcon from "../../assets/icons/HurrayCheckIcon.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import copyIcon from "../../assets/icons/copyIcon.svg";
import { toast } from "react-toastify";
import { setShowSuccessModal } from "../../redux/actions/adminActions";
export function SuccessModal() {
  const navigate = useNavigate();

  return (
    <Modal centered show>
      <Modal.Body className="text-center p-5">
        <div className="check-success-icon">
          <img src={checkIcon} alt="checkIcon" />
        </div>
        <Modal.Title className="common-green">Successfully</Modal.Title>
        <p className="secondary-text mb-4 text-black fs-5">
          Your Password has been reset
        </p>
        <Button className="btn w-100 py-3" onClick={() => navigate("/")}>
          Continue
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export function VendorSuccessModal(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = props?.data?.data?.response?.data;
  console.log("data", data);
  const copyToClipboard = () => {
    console.log("copyToClipboard");
    // Check if the data and password exist
    if (data && data?.password) {
      // Create a temporary textarea element to copy the text
      const textarea = document.createElement("textarea");
      textarea.value = data.password;
      document.body.appendChild(textarea);
      textarea.select();
      // Copy the text to the clipboard
      document.execCommand("copy");
      // Remove the textarea element
      document.body.removeChild(textarea);
      // Optionally, notify the user that the text has been copied
      toast.success("Password copied to clipboard!");
    }
  };
  const redirectHandler = () => {
    dispatch(
      setShowSuccessModal({ type: "vendorSuccess", response: "", show: false })
    );
    navigate("/vendor");
  };
  return (
    <Modal centered show>
      <Modal.Body className="text-center p-5">
        <h3 className="text-pink">Congratulations.!</h3>
        <div className="check-success-icon">
          <img
            src={data?.logo?.thumbnail_url}
            alt={props?.checkIcon}
            className="rounded-circle"
            height={"150px"}
            width={"150px"}
          />
        </div>
        <Modal.Title className="common-green">
          {data?.business_name}
        </Modal.Title>
        <p className="secondary-text mb-4 text-black fs-6">Store Created</p>
        <div className="d-flex justify-content-space-around gap-2 mb-4">
          <div className="grey-gradient px-2 py-3  w-100 rounded py-1">
            <div className="d-flex flex-column px-2">
              <small className="text-light-purpule">Vendor Code</small>
              <p className="mb-0">{data?.vendor_code}</p>
            </div>
          </div>
          <div className="grey-gradient px-2 py-3 w-100 rounded py-1 position-relative" onClick={copyToClipboard}>
            <div className="text-end z-100" >
              <img src={copyIcon} alt="copyIcon" />
            </div>
            <div
              className="d-flex flex-column px-2"
              style={{ marginTop: "-1.5rem" }}
            >
              <i className="bi bi-copy position-absolute top-0 end-0"></i>
              <small className="text-light-purpule">Password</small>
              <p className="mb-0">{data?.password}</p>
            </div>
          </div>
        </div>
        <Button className="btn w-100 py-3" onClick={redirectHandler}>
          Back To Vendors
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export function PaymentSuccessModal(props) {
  const navigate = useNavigate();
  return (
    <Modal centered show>
      <Modal.Body className="text-center p-5">
        <div className="check-success-icon">
          <img
            src={HurrayCheckIcon}
            alt="HurrayCheckIcon"
            className="rounded-circle"
          />
        </div>
        <h3 className="text-primary">Successfully</h3>
        <p className="mb-0">
          <span className="secondary-text">Payment Transaction id </span>
          <span className="fw-800">#5654WQ</span>
        </p>
        <p className="mb-3">
          <span className="secondary-text">Coin Transaction id </span>
          <span className="fw-800">#5654WQ</span>
        </p>
        <div className="d-flex justify-content-between flex-column pt-4 border-top-dashed gap-2 mb-4">
          <div className="d-flex justify-content-between pb-3 border-bottom align-items-center">
            <small className="fs-6 secondary-text">Offer Amount</small>{" "}
            <span className="fs-6">59.3 AED</span>
          </div>
          <div className="d-flex justify-content-between pb-3 border-bottom align-items-center">
            <small className="fs-6 secondary-text">Coins Redeem</small>{" "}
            <span className="fs-6 delete-text">50 ( For -5 AED)</span>
          </div>
          <div className="d-flex justify-content-between pb-3 border-bottom align-items-center">
            <small className="fs-6 secondary-text">Paid Amount</small>{" "}
            <span className="fs-6">59.3 AED</span>
          </div>
        </div>
        <Button className="btn w-100 py-2" onClick={() => navigate("/vendor")}>
          Okay
        </Button>
        <h6 className="text-parrot mt-2">Offer sent to Admin for approval.</h6>
      </Modal.Body>
    </Modal>
  );
}
