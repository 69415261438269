// Import Axios
import axios from 'axios';

// Define the function to make the GET request
export const GetRequestCall = async (url, payload, accessToken) => {
  console.log("accessToken",accessToken)
  try {
    // Make the GET request with Axios
    const response = await axios.get(url, {
      params: payload,
      headers: {
        Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
      },
    });

    // Access the response data
    const data = response.data;

    // Do something with the data, for example, log it
    console.log(data);

    return data; // You can return the data if needed
  } catch (error) {
    // Handle errors
    console.error('Error fetching data:', error);
    throw error; // You can throw the error or handle it accordingly
  }
};
