import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars-2";
import { WebSocketContext } from "../../App";
import { extractIdFromUrl } from "../Shared/constant";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CheckValid from "../Common/CheckValid";
import InputField from "../Common/InputField";
import AvatarGet from "../Common/AvatarGet";
import { Spinner } from "react-bootstrap";
import UploadFileAPI, { DeleteFileAPI } from "../../Api/UploadFileAPI";
import imageSelect from "../../assets/img/imageSelect.png";
import { SuccessModal } from "../Common/SuccessModal";
import wsSend_request from "../../Api/ws/ws_request";
import { toast } from "react-toastify";
import { LoaderStartMain, setConfirmModal } from "../../redux/actions/adminActions";
const Profile = () => {
  const Dispatch = useDispatch()
  const { selfInfo,  accessToken } = useSelector(
    (state) => state.adminReducers
  );
  const { websocket } = useContext(WebSocketContext);

  const [inputValue, setInputValue] = useState({
    UserName: selfInfo?.username,
    Lname: "",
    Address: "",
    Old_password: "",
    Password: "",
    Confirm_password: "",
  });

  const showModal = false;
  const [showpassword, setshowpassword] = useState(false);
  const [showOldpassword, setshowOldpassword] = useState(false);
  const [showConfirmpassword, setshowConfirmpassword] = useState(false);
  const [ChangePasswordState, setChangePasswordState] = useState(false);
console.log("setChangePasswordState",setChangePasswordState)
  const [errorUserName, setErrorUserName] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [errorOldPassword, setErrorOldPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const [UploadFileLoader, setUploadFileLoader] = useState(false);
  const [AvatarImage, setAvatarImage] = useState(selfInfo?.avatar);
  const [AvatarImageId, setAvatarImageId] = useState("");
  console.log("AvatarImageId",AvatarImageId)
  const [changeImageClicked, setChangeImageClicked] = useState(false);
  const fileInputRef = useRef(null);
  const PasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line
  useEffect(() => {
    if (ChangePasswordState === true) {
      const MainWrapsId = document.getElementById("chnagepasswordblog");
      MainWrapsId.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [ChangePasswordState]);

  useEffect(() => {
    if (selfInfo) {
      setInputValue(i => ({
        ...i,
        UserName: selfInfo?.username,
        Lname: selfInfo?.last_name,
        Address: selfInfo?.address === null ? "" : selfInfo?.address,
      }));
    }
  }, [selfInfo]); // inputValue is not included in the dependency array
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const ShowHidePassword = () => {
    setshowpassword(!showpassword);
  };

  const ShowOldHidePassword = () => {
    setshowOldpassword(!showOldpassword);
  };

  const ShowConfirmHidePassword = () => {
    setshowConfirmpassword(!showConfirmpassword);
  };
  const removeMediaAPIHandler = async () => {
    Dispatch(LoaderStartMain(true));
    const response = await DeleteFileAPI(
      accessToken,
      extractIdFromUrl(AvatarImage?.url?.file_url || AvatarImage?.file_url)
    );
    console.log("response", response);
    if (response?.status === 200) {
      setAvatarImage("");
      setAvatarImageId("");
      toast.success(response?.file);
    } else {
      toast.error(response?.file || response?.msg);
    }
      Dispatch(LoaderStartMain(false));
  }
  // remove image uploaded
  const RemoveUploadImage = async () => {
    Dispatch(setConfirmModal({
      show:"MEDIA",
      callBack: (e) => removeMediaAPIHandler(e)
    }))
  };

  const UploadImage = async (e) => {
    Dispatch(LoaderStartMain(true));

    console.log("UploadImage Inside");
    setUploadFileLoader(true);
    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("avatar", files, files.name);
    formData.append("is_admin", selfInfo?.user_type === "admin" ? 1 : 0);
    const fileresponse = await UploadFileAPI(accessToken, formData);
    console.log("fileresponse", fileresponse);
    if (fileresponse) {
      setUploadFileLoader(false);
      setAvatarImage(fileresponse[0]);
      setAvatarImageId(fileresponse[0]?.id);
    }
    Dispatch(LoaderStartMain(false));

  };

  const handleImageChangeClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      setChangeImageClicked(true);
    }
  };
  console.log("AvatarImage", AvatarImage?.url?.thumbnail_url);

  const EditProfilePassword = () => {
    if (
      inputValue?.Old_password !== "" &&
      inputValue?.Password !== "" &&
      inputValue?.Password === inputValue?.Confirm_password
    ) {
      if (
        PasswordRegex.test(inputValue?.Old_password) === true &&
        PasswordRegex.test(inputValue?.Password) === true
      ) {
        if (inputValue?.Old_password !== inputValue?.Password) {
          let param = {
            transmit: "single",
            url: "user_profile_update",
            request: {
              old_password: inputValue?.Old_password,
              new_password: inputValue?.Password,
              username: inputValue?.UserName,
              avatar: AvatarImage?.name,
              user_type: "admin",
            },
          };
          wsSend_request(websocket, param);
          let requestPara = {
            transmit: "single",
            url: "user_get",
            request: {
              user_type: selfInfo?.user_type,
            },
          };
          wsSend_request(websocket, requestPara);
          setInputValue({
            ...inputValue,
            Old_password: "",
            Password: "",
          });
          // setTimeout(() => Navigate(DASHBOARD_URL), 200);
        } else {
          if (inputValue?.Old_password === inputValue?.Password) {
            setErrorPassword("Old Password and new password cannot be same.");
          } else {
            setErrorPassword("");
          }
          if (inputValue?.Password !== inputValue?.Confirm_password) {
            setErrorConfirmPassword("Password does not match");
          }
        }
      } else {
        CheckValid(inputValue?.Old_password, {
          type: "oldpassword",
          error: setErrorOldPassword,
        });
        CheckValid(inputValue?.Password, {
          type: "newpassword",
          error: setErrorPassword,
        });
        if (inputValue?.Password !== inputValue?.Confirm_password) {
          setErrorConfirmPassword("Password does not match");
        }
      }
    } else {
      CheckValid(inputValue?.Old_password, {
        type: "oldpassword",
        error: setErrorOldPassword,
      });
      CheckValid(inputValue?.Password, {
        type: "newpassword",
        error: setErrorPassword,
      });
      if (inputValue?.Password !== inputValue?.Confirm_password) {
        setErrorConfirmPassword("Password does not match");
      }
    }
  };

  const SavaChangesHandler = () => {
    if (inputValue?.UserName !== "") {
      let param = {
        transmit: "single",
        url: "user_profile_update",
        request: {
          username: inputValue?.UserName,
          avatar: AvatarImage?.name,
          user_type: "admin",
        },
      };
      wsSend_request(websocket, param);
      let requestPara = {
        transmit: "single",
        url: "user_get",
        request: {
          user_type: selfInfo?.user_type,
        },
      };
      wsSend_request(websocket, requestPara);
      setInputValue({
        ...inputValue,
        Old_password: "",
        Password: "",
        UserName: "",
      });
    } else {
      CheckValid(inputValue?.UserName, {
        type: "UserName",
        error: setErrorUserName,
      });
    }
  };
  return (
    <>
      {showModal ? (
        <SuccessModal />
      ) : (
        <section className="dashboard_wrapper">
          <Scrollbars
            style={{ height: "calc(100% - 53px)" }}
            className="ScrollbarsWrapper"
            renderView={(props) => <div {...props} className="view" />}
          >
            <div className="form-sections border rounded p-3 w-100">
              <div className="row p-3">
                <div
                  className="col-md-6"
                  style={{ borderRight: "2px solid #DEE3EA" }}
                >
                  {/* Profile Image Section */}
                  <h6 className="headecenter secondary-text">Profile Image</h6>
                  <div className="col-md-4">
                    {/* Avatar */}
                    <div className="form-group fileUploadingform">
                      <div
                        className="d-flex align-items-center justify-content-center mt-3"
                        onClick={handleImageChangeClick}
                      >
                        <img src={imageSelect} alt="" />
                        <span className="common-blue ms-2">
                          Change Profile Image
                        </span>
                      </div>
                      {/* Uploaded Image */}
                      {(   AvatarImage?.url?.file_url ||
                              AvatarImage?.file_url) && (
                        <div className="uploadedImagewrps mt-3">
                          <AvatarGet
                            type="product"
                            url={
                              AvatarImage?.url?.file_url ||
                              AvatarImage?.file_url
                            }
                          />
                          <div
                            className="closeIocn"
                            onClick={RemoveUploadImage}
                          >
                            <i className="bi bi-x-circle-fill"></i>
                          </div>
                        </div>
                      )}

                      {/* File Input */}
                      <input
                        type="file"
                        ref={fileInputRef}
                        className="form-control visually-hidden"
                        onChange={UploadImage}
                        disabled={UploadFileLoader}
                        accept=".png, .jpg, .jpeg"
                        style={{ visibility: "hidden" }}
                      />
                      {/* Loading Spinner */}
                      {changeImageClicked && AvatarImage === "" && (
                        <>
                          <input
                            type="file"
                            className="form-control visually-hidden"
                            onChange={UploadImage}
                            disabled={UploadFileLoader}
                            accept=".png, .jpg, .jpeg"
                          />
                          {UploadFileLoader && (
                            <div className="filUploading">
                              <Spinner animation="border" role="status">
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </Spinner>
                              Please wait, your file is uploading.
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {/* Profile Information */}
                  <InputField
                    type="text"
                    value={inputValue.UserName}
                    className={
                      errorUserName !== ""
                        ? "form-control error-form"
                        : "form-control"
                    }
                    placeholder="Enter Name"
                    label="Admin Name"
                    name="UserName"
                    onChange={handleChange}
                    onKeyUp={(e) =>
                      CheckValid(e.target.value, {
                        type: "UserName",
                        error: setErrorUserName,
                      })
                    }
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorUserName}
                  />
                  {/* Email */}
                  <div className="form-group">
                    <label>Email</label>
                    <div className="inputText">
                      {selfInfo?.email === null ? "-" : selfInfo?.email}
                    </div>
                  </div>
                  {/* Save Button */}
                  <div className="col-md-12">
                    <div
                      className="form-group-btn"
                      style={{ textAlign: "right" }}
                    >
                      <button
                        type="button"
                        className="btn addbtncmn w-100 py-3"
                        onClick={SavaChangesHandler}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 ms-5">
                  {/* Change Password Section */}
                  {/* Old Password */}
                  <InputField
                    type={showOldpassword ? "text" : "password"}
                    value={inputValue.Old_password}
                    className={
                      errorOldPassword !== ""
                        ? "form-control error-form"
                        : "form-control"
                    }
                    placeholder="Enter current password"
                    label="Current Password"
                    name="Old_password"
                    onChange={handleChange}
                    onKeyUp={(e) =>
                      CheckValid(inputValue.Old_password, {
                        type: "oldpassword",
                        error: setErrorOldPassword,
                      })
                    }
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorOldPassword}
                    onClick={ShowOldHidePassword}
                    showOldpassword={showOldpassword}
                  />
                  {/* New Password */}
                  <InputField
                    type={showpassword ? "text" : "password"}
                    value={inputValue.Password}
                    className={
                      errorPassword !== ""
                        ? "form-control error-form"
                        : "form-control"
                    }
                    placeholder="Enter New password"
                    label="New Password"
                    name="Password"
                    onChange={handleChange}
                    onKeyUp={(e) =>
                      CheckValid(e.target.value, {
                        type: "newpassword",
                        error: setErrorPassword,
                      })
                    }
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorPassword}
                    onClick={ShowHidePassword}
                    showpassword={showpassword}
                  />
                  {/* Confirm Password */}
                  <InputField
                    type={showConfirmpassword ? "text" : "password"}
                    value={inputValue.Confirm_password}
                    className={
                      errorConfirmPassword !== ""
                        ? "form-control error-form"
                        : "form-control"
                    }
                    placeholder="Enter confirm password"
                    label="Confirm Password"
                    name="Confirm_password"
                    onChange={handleChange}
                    onKeyUp={(e) =>
                      CheckValid(inputValue.Confirm_password, {
                        type: "oldpassword",
                        error: setErrorConfirmPassword,
                      })
                    }
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorConfirmPassword}
                    onClick={ShowConfirmHidePassword}
                    showConfirmpassword={showConfirmpassword}
                  />
                  {/* Submit Button */}
                  <div className="form-group-btn">
                    <button
                      type="button"
                      className="btn addbtncmn w-100 py-3"
                      onClick={EditProfilePassword}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </section>
      )}
    </>
  );
};

export default Profile;
