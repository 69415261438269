import { FORGOT_PASSWORD_API_URL, SET_NEW_PASSWORD_API_URL } from "../components/Shared/constant";

const ForgotPasswordAPI = async (payload) => {
    const responce = window.axios.post(FORGOT_PASSWORD_API_URL, payload, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }}).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default ForgotPasswordAPI;


export const SetPasswordAPI = async (payload) => {
  const responce = window.axios.post(SET_NEW_PASSWORD_API_URL, payload, {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }}).then(function (result) {
      return {
        ...result.data,
        status:200
      };
  }).catch(function (result) {
      return {
        ...result?.response?.data,
        status:result?.response?.status
      }
  });
  return responce;
};
  