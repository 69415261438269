import React, { useState } from "react";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { Image } from "react-bootstrap";
import ResetPasswordImage from "../../assets/img/reset_password.png";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN_URL } from "../Shared/constant";
import CreatePasswordAPI from "../../Api/CreatePasswordAPI";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
    const createPasswordId = useParams()?.roomId;
    const Navigate = useNavigate();
    const [ inputValue, setInputValue ] = useState({ Password: "" });
    const { Password } = inputValue;
    const [ showpassword, setshowpassword ] = useState(false);

    // Regex
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line

    // error message
    const [ errorPassword, setErrorPassword ] = useState("");

    // show password
    const ShowHidePassword = () => {
        setshowpassword(!showpassword);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // login function
    const ResetPasswordfunct = async () => {
        if(inputValue?.Password !== "") {
            if(PasswordRegex.test(inputValue?.Password) === true) {
                const jsonData = JSON.stringify({ password: inputValue?.Password });
                const response = await CreatePasswordAPI(jsonData, createPasswordId);
                if(response?.status === 200){
                    toast.success(response.msg);
                    setTimeout(() => Navigate(LOGIN_URL), 200);
                    clearForm();
                } else {
                    clearForm();
                    toast.error(response.error);
                }
            } else {
                CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
            }
        } else {
            CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
        }
    };

    // clear form
    const clearForm = () => {
        setInputValue({...inputValue, Password: ""});
        setErrorPassword("");
    };

    return(<section className="login_wrapper">
    <div className="login_wrap_slider">
        <Image src={ResetPasswordImage} alt="login image" />
        <h4>Reset Password</h4>
        <p>Enter a new password</p>
    </div>
    <div className="login_form_wraps">
        <div className="loginTEXTS">
            {/* <h1>E Find</h1> */}
            <h4>Reset Password ?</h4>
        </div>
        <InputField
            type={showpassword ? "text" : "password"}
            value={Password}
            className={errorPassword !== "" ? "form-control error-form" : "form-control"}
            placeholder="Enter password"
            label="Password"
            name="Password"
            onChange={handleChange}
            onKeyUp={(e) => CheckValid(e.target.value, {type: 'password', error: setErrorPassword})}
            onKeyDown={EmptySpaceFieldValid}
            errormessage={errorPassword}
            onClick={ShowHidePassword}
            showpassword={showpassword}
            required={true}
        />
        <div className="form-group-btn">
            <button className="btn loginCmn_btn" onClick={() => ResetPasswordfunct()}>Ok</button>
            <Link to={LOGIN_URL} className="btn forgot_password_link"><i className="bi bi-arrow-left"></i> Back</Link>
        </div>
    </div>
</section>)
};

export default ResetPassword;