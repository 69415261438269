/* eslint-disable */
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callGetFCMToken, onMessageListener } from "./InitialFirebase";
import { setStoreFirebaseData } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";

const PageLoad = () => {
  const { websocket } = useContext(WebSocketContext);
  const dispatch = useDispatch();
  const { firebaseData, selfInfo } = useSelector(
    (state) => state.adminReducers
  );
  const handleSendNotification = async (fcmToken) => {
    let param = {
      transmit: "single",
      url: "notification_subscription",
      request: {
        topic: selfInfo?.id,
        token: fcmToken,
        type : "subscribe"
      },
    };
    wsSend_request(websocket, param);
  };
  console.log("pageload firebaseData", firebaseData);
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
          navigator.serviceWorker.register('/firebase-messaging-sw.js')
          .then((registration) => {
              console.log('Service Worker registration successful with scope: ', registration.scope);
          }).catch((err) => {
              console.log('Service Worker registration failed: ', err);
          });
      });
  }  
  }, []);

  useEffect(() => {
    if (selfInfo?.user_type === 'admin') {
      if ('Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window) {
        if (Notification.permission !== 'granted') {
          requestNotificationPermission();
        } else {
          const interval = setInterval(() => {
            if (!firebaseData) {
              callToken();
            } else {
              clearInterval(interval); // Clear the interval once firebaseData has a value
            }
          }, 3000); // Call callToken every 3 seconds
          // Clean up interval on component unmount
          return () => clearInterval(interval);
        }
      }
    }
  }, [firebaseData, selfInfo, websocket]); // Include dependencies here

  useEffect(() => {
    onMessageListener();
  });

  const callToken = async () => {
    const data = await callGetFCMToken();
    if (data) {
      const response = await handleSendNotification(data);
      console.log("pageload send-notification", response);
      if (!response?.error) {
        dispatch(setStoreFirebaseData(data));
      }
    }
  };
  const requestNotificationPermission = () => {
    if (
      "Notification" in window &&
      "serviceWorker" in navigator &&
      "PushManager" in window
    ) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          callToken();
        } else {
          console.warn("Notification permission denied.");
        }
      });
    }
  };
  return <div>{/* Your component content here */}</div>;
};

export default PageLoad;
