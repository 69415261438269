/* eslint-disable */
import React, { useState, useContext, useEffect, useRef } from "react";
import {
  VENDOR_OFFER_MANAGEMENT_URL,
  ageOptions,
  bytesToKB,
  countPlainTextOccurrences,
  customComponents,
  extractFilename,
  extractIdFromUrl,
  formatDates,
  isEndDateTimeGreaterThanStartDateTime,
  maxDescLength,
  maxSize,
  minDescLength,
  parseDateTime,
  vendorCountryOptions,
} from "../Shared/constant";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import UploadFileAPI, { DeleteFileAPI } from "../../Api/UploadFileAPI";
import CommonModel from "../Common/CommonModel";
import AvatarGet from "../Common/AvatarGet";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import CommonLoader from "../Common/CommonLoader";
import "react-toastify/dist/ReactToastify.css";
import {
  LoaderStartMain,
  getBrandsWiseModelList,
  setConfirmModal,
} from "../../redux/actions/adminActions";
import { Tab, Tabs } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import "react-clock/dist/Clock.css";
import DatePicker from "react-datepicker";
import TimePicker from "../Common/TimePicker/TimePicker.jsx";
import { toast } from "react-toastify";
import uploadIcon from "../../assets/icons/uploadIcon.svg";
import closeIcon from "../../assets/img/closeIcon.png";
import { FilteredMap, InitialMap } from "../Common/Map";
import menIcon from "../../assets/img/menIcon.png";
import womenIcon from "../../assets/img/womenIcon.png";
import InputRange from "react-input-range";
import { PaymentSuccessModal } from "../Common/SuccessModal";
import PaymentModal from "../Common/PaymentModal";

const VendorAddEditOffer = () => {
  const Navigate = useNavigate();
  const Dispatch = useDispatch();
  const [key, setKey] = useState("info");
  const [tabChange, setTabchange] = useState(false);
  const [infoFormFill, setinfoFormFill] = useState(false);
  const params = useParams();
  const Location = useLocation()?.pathname?.split("/")[1];
  const { websocket } = useContext(WebSocketContext);
  const [inputValue, setInputValue] = useState({
    offer_title: "",
  });
  const { offer_title } = inputValue;
  const [editorState, setQueEditorState] = useState(EditorState.createEmpty());
  const [StoreDesc, setStoreDesc] = useState("");
  const [charCount, setCharCount] = useState(0);
  console.log("charCount", charCount);
  const [Category, setCategory] = useState("");

  const {
    viewProduct,
    accessToken,
    device_id,
    loaderMain,
    ClearFormSet,
    selfInfo,
    paymentModalData,
    detailcategoryAllList,
    subcategoryList,
    liveMapUserList,
  } = useSelector((state) => state.adminReducers);
  console.log("paymentModalData", paymentModalData);
  const [showSubmit, setShowSubmit] = useState(false);

  const [UploadFileLoader, setUploadFileLoader] = useState(false);
  const [AvatarImage, setAvatarImage] = useState("");
  const [AvatarImageId, setAvatarImageId] = useState("");
  console.log("AvatarImage", AvatarImage, "AvatarImageId", AvatarImageId);
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  const [fileData, setFileData] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const categoryOptions = detailcategoryAllList
    ?.filter((elm) => elm?.is_active === true)
    ?.map((elm) => ({
      value: elm?.id,
      label: elm?.name,
      sub_categories: elm?.sub_categories,
    }));
  const subcategoryOptions = subcategoryList?.list
    .filter((elm) => elm?.is_active === true)
    .map((elm) => ({ value: elm?.id, label: elm?.name }));

  const handleCategoryChange = (selectedOptions) => {
    console.log("selectedOptions", selectedOptions);
    setSelectedCategories(selectedOptions);
    const tagsToAdd = [];
    selectedOptions.forEach((option) => {
      if (option.sub_categories && option.sub_categories.length) {
        option.sub_categories.forEach((subCategory) => {
          tagsToAdd.push({
            label: subCategory.name,
            value: subCategory.id,
          });
        });
      }
    });
    setSelectedTags((prev) => {
      // Use a Set to store unique tag values
      const tagSet = new Set(prev.map((tag) => tag.value));
      // Iterate over the tagsToAdd array and add each tag to the newTags array if it's not already present
      tagsToAdd.forEach((tag) => {
        if (!tagSet.has(tag.value)) {
          prev.push(tag);
          tagSet.add(tag.value);
        }
      });
      // Return the new state array
      return prev;
    });
  };
  const commaSeparatedCategories = selectedCategories
    .map((category) => category.value)
    .join(",");

  const handleTagsChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };
  const commaSeparatedTags = selectedTags.map((Tags) => Tags.value).join(",");

  // error field business_contact_number
  const [erroroffer_title, setErroroffer_title] = useState("");
  const [errorStartTimeAndDate, seterrorStartTimeAndDate] = useState("");
  const [errorEndTimeAndDate, seterrorEndTimeAndDate] = useState("");
  const [errorAvatarImage, seterrorAvatarImage] = useState("");
  const [errorCategory, seterrorCategory] = useState("");
  const [errorStoreDesc, setErrorStoreDesc] = useState("");

  const fileInputRef = useRef(null);

  const [error, setError] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  // get product details
  useEffect(() => {
    if (Location === "vendor-edit-offer") {
      if (params?.roomId) {
        let param = {
          transmit: "single",
          url: "offer_get",
          request: {
            offer_id: params?.roomId,
            user_type: selfInfo?.user_type,
          },
        };
        wsSend_request(websocket, param);
      }
    }
  }, [params?.roomId, Location, websocket]);

  // get product list
  useEffect(() => {
    let param = {
      transmit: "single",
      url: "category_detailed_listing",
      request: {
        limit: 5000,
        page: 1,
      },
    };
    wsSend_request(websocket, param);
  }, [websocket]);
  useEffect(() => {
    let param = {
      transmit: "single",
      url: "subcategory_listing",
      request: {
        filter: null,
        limit: 5000,
        page: 1,
        search: null,
        user_type: selfInfo?.user_type || "admin",
      },
    };
    wsSend_request(websocket, param);
  }, [websocket]);

  useEffect(() => {
    if (Location === "vendor-edit-offer" && viewProduct) {
      console.log("viewProduct", viewProduct);
      setInputValue({
        ...inputValue,
        offer_title: viewProduct?.offer_title,
        country: viewProduct?.country,
        city: viewProduct?.city,

        address: viewProduct?.address,
        location: viewProduct?.location,
        state_code: viewProduct?.state,
      });

      if (viewProduct?.offer_users) {
        const [startAge, endAge] = viewProduct?.offer_users?.age_range
          .split("-")
          .map((age) => parseInt(age));
        setStartAge({
          value: startAge,
          label: `${startAge} - Age`,
        });
        setEndAge({
          value: endAge,
          label: `${endAge} - Age`,
        });
      }
      if (viewProduct?.offer_users?.gender?.includes("Male")) {
        setMenSelected(true);
      }
      if (viewProduct?.offer_users?.gender?.includes("Female")) {
        setWomenSelected(true);
      }
      if (viewProduct?.offer_start_time) {
        const { date, time } = parseDateTime(viewProduct?.offer_start_time);
        setStartDate(date);
        setStartTime(time);
      }
      if (viewProduct?.offer_end_time) {
        const { date, time } = parseDateTime(viewProduct?.offer_end_time);
        setEndDate(date);
        setEndTime(time);
      }
      if (viewProduct?.offer_description) {
        setErrorStoreDesc("");
        const defaultStoreDescriptionContent = viewProduct.offer_description;
        const storeDescriptionContentState = ContentState.createFromBlockArray(
          convertFromHTML(defaultStoreDescriptionContent)
        );
        const storeDescriptionEditorState = EditorState.createWithContent(
          storeDescriptionContentState
        );
        setQueEditorState(storeDescriptionEditorState);
        const characterCount = countPlainTextOccurrences(
          viewProduct?.offer_description
        );
        setCharCount(characterCount);
      }
      console.log("viewProduct", viewProduct);

      if (viewProduct && viewProduct?.interest) {
        // Split the categories string into an array
        const productCategories = viewProduct?.interest.split(",");

        // Filter categoryList to find objects whose name matches the product categories
        const filteredCategories = detailcategoryAllList?.filter((category) =>
          productCategories.includes(category.name)
        );

        // Map the filtered categories to the required structure and update the state
        const mappedCategories = filteredCategories.map((category) => ({
          value: category.id,
          label: category.name,
        }));

        setSelectedCategories(mappedCategories);
      }
      if (viewProduct && viewProduct?.sub_interest) {
        // Split the categories string into an array
        const productCategories = viewProduct?.sub_interest.split(",");

        // Filter categoryList to find objects whose name matches the product categories
        const filteredCategories = subcategoryList?.list?.filter((category) =>
          productCategories.includes(category.name)
        );

        // Map the filtered categories to the required structure and update the state
        const mappedCategories = filteredCategories.map((category) => ({
          value: category.id,
          label: category.name,
        }));

        setSelectedTags(mappedCategories);
      }
      console.log("viewProduct", viewProduct);
      if (viewProduct?.logo?.file_url) {
        setAvatarImage({
          url: {
            file_url: viewProduct?.logo?.file_url,
          },
          size: viewProduct?.logo?.size,
        });
        setFileData([]);
        if (viewProduct?.logo?.original_name) {
          setFileData((prevFileData) => {
            prevFileData.push(viewProduct?.logo);
            return prevFileData;
          });
        }
      }
      console.log("fileData", fileData, "AvatarImage", AvatarImage);
    }
  }, [viewProduct, websocket]);
  const isEditorEmpty = () => {
    const contentState = editorState.getCurrentContent();
    const hasText = contentState.hasText();
    return !hasText;
  };
  useEffect(() => {
    if (isEditorEmpty() && tabChange) {
      console.log("setErrorDesc");
      setErrorStoreDesc("Please enter description");
    } else {
      console.log("setErrorDesc");

      setErrorStoreDesc("");
    }

    if (startDate && startTime) {
      seterrorStartTimeAndDate("");
    }
    if (endDate && endTime) {
      seterrorEndTimeAndDate("");
    }
  }, [
    inputValue?.address,
    StoreDesc?.length,
    fileData,
    AvatarImage,
    startDate,
    startTime,
    endTime,
    endDate,
  ]);
  const onEditorChange = (newEditorState) => {
    // if (charCount < maxDescLength) {
      setQueEditorState(newEditorState);
      const contentState = newEditorState.getCurrentContent();
      const plainText = contentState.getPlainText("");
      const characterCount = plainText.length;
      setCharCount(characterCount);
      const contentAsRaw = convertToRaw(contentState);
      const htmlContent = draftToHtml(contentAsRaw);
      const styledHtmlContent = `<div style="font-family: Manrope;">${htmlContent}</div>`;

      setStoreDesc(styledHtmlContent);

      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        question: styledHtmlContent,
      }));
    // }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const UploadLogo = async (e) => {
    Dispatch(LoaderStartMain(true));

    console.log("UploadLogo called");
    setUploadFileLoader(true);
    const files = e.target.files[0];
    if (files && files.size > maxSize) {
      toast.error("File size exceeds 30MB limit.");
      e.target.value = null; // Clear the input field
      Dispatch(LoaderStartMain(false));

      return;
    }
    const formData = new FormData();
    formData.append("avatar", files, files.name);

    if (params?.roomId) {
      formData?.append("to", params?.roomId);
    } else {
      formData?.append("to", inputValue?.owner_email_id);
    }
    formData.append("is_admin", selfInfo?.user_type === "admin" ? "1" : "0");
    const fileresponse = await UploadFileAPI(accessToken, formData);
    console.log("UploadLogo fileresponse", fileresponse, "formData", formData);
    if (fileresponse) {
      setUploadFileLoader(false);
      setAvatarImage(fileresponse[0]);
      setFileData(fileresponse[0]);
      setAvatarImageId(fileresponse[0]?.id);
    }
    Dispatch(LoaderStartMain(false));
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const fileInput = fileInputRef.current;
    console.log("handleFileChange", e, fileInput);
    if (fileInput.files.length > 0) {
      UploadLogo(e);
    }
  };

  const checkIsValid = () => {
  
    if (
      inputValue?.offer_title &&
      charCount > minDescLength &&
      charCount < maxDescLength &&
      startDate &&
      startTime &&
      endDate &&
      endDate &&
      AvatarImage &&
      errorEndTimeAndDate === "" &&
      ((startDate &&
        endDate &&
        startDate?.getTime() === endDate?.getTime() &&
        startTime >= endTime) ||
        true)
    ) {
      return true;
    } else {
      if (selectedCategories?.length == 0 && showSubmit) {
        console.log("selectedCategories", selectedCategories);

        seterrorCategory("Please select category");
      } else {
        seterrorCategory("");
      }
      CheckValid(inputValue?.offer_title, {
        type: "Title",
        error: setErroroffer_title,
      });
      if (charCount < minDescLength || isEditorEmpty()) {
        console.log("setErrorDesc");

        setErrorStoreDesc(
          `Please enter at least ${minDescLength} character description`
        );
      } else {
        console.log("setErrorDesc");

        setErrorStoreDesc("");
      }
      if (charCount > maxDescLength && charCount > minDescLength) {
        setErrorStoreDesc(
          `Please enter maximum ${maxDescLength} character description`
        );
      }
      if (Location === "vendor-edit-offer" && !viewProduct?.offer_description) {
        console.log("setErrorDesc");

        setErrorStoreDesc("Please enter description");
      }
      CheckValid(startDate, {
        type: "startDate",
        error: seterrorStartTimeAndDate,
      });
      CheckValid(endDate, {
        type: "endDate",
        error: seterrorEndTimeAndDate,
      });
      CheckValid(startTime, {
        type: "startDate",
        error: seterrorStartTimeAndDate,
      });
      CheckValid(endTime, {
        type: "endDate",
        error: seterrorEndTimeAndDate,
      });
      CheckValid(AvatarImage, {
        type: "Avatar",
        error: seterrorAvatarImage,
      });
      if (!liveMapUserList?.list?.counts?.total_users && key === "media") {
        toast.error("Please select offer filter");
      }
      if (
        !isEndDateTimeGreaterThanStartDateTime(
          startDate,
          startTime,
          endDate,
          endTime
        )
      ) {
        seterrorEndTimeAndDate(
          "Please select offer end time greater than start time"
        );
      }

      return false;
    }
  };
  const handleFormClick = (e) => {
    e.stopPropagation();
    // fileInputRef.current.click();
  };
  const removeMediaAPIHandler = async () => {
    Dispatch(LoaderStartMain(true));
    const response = await DeleteFileAPI(
      accessToken,
      extractIdFromUrl(AvatarImage?.url?.file_url)
    );
    console.log("response", response);
    if (response?.status === 200) {
      setAvatarImage("");
      setAvatarImageId("");
      toast.success(response?.file);
    } else {
      toast.error(response?.file || response?.msg);
    }
    Dispatch(LoaderStartMain(false));
  };
  // remove image uploaded
  const RemoveUploadImage = async () => {
    Dispatch(
      setConfirmModal({
        show: "MEDIA",
        callBack: (e) => removeMediaAPIHandler(e),
      })
    );
  };
  console.log(
    "liveofferuserid",
    liveMapUserList?.list?.list?.length
      ? liveMapUserList?.list?.list?.map((item) => item?.id).join(",")
      : ""
  );
  const CreateOffer = () => {
    if (selectedCategories?.length == 0 && Location !== "vendor-edit-offer") {
      toast.error("Please apply offer filter");
      return;
    }

    const isValid = checkIsValid();
    console.log("isValid", isValid);
    if (
      !liveMapUserList?.list?.counts?.total_users &&
      Location !== "vendor-edit-offer"
    ) {
      toast.error("Please apply offer filter");
      return;
    }
    if (
      isValid &&
      (liveMapUserList?.list?.counts?.total_users ||
        Location === "vendor-edit-offer")
    ) {
      if (Location === "vendor-edit-offer") {
        let param;

        param = {
          transmit: "single",
          url: "offer_update",
          request: {
            offer_title: inputValue?.offer_title,
            offer_start_time: formatDates(startDate, startTime),
            offer_end_time: formatDates(endDate, endTime),
            offer_interests: selectedCategories
              ?.map((item) => item.value)
              .join(","),
            offer_description: StoreDesc || viewProduct?.offer_description,
            logo: AvatarImage?.name,
            offer_id: params?.roomId,
            user_type: selfInfo?.user_type,
          },
        };
        console.log("param", param);
        wsSend_request(websocket, param);
        Navigate(VENDOR_OFFER_MANAGEMENT_URL);
      } else {
        let paramRequest;

        paramRequest = {
          created_by: selfInfo?.user_type,
          vendor_code: selfInfo?.vendor_code,
          vendor_id: selfInfo?.id,
          offer_title: inputValue?.offer_title,
          offer_start_time: formatDates(startDate, startTime),
          offer_end_time: formatDates(endDate, endTime),
          offer_interests: selectedCategories
            ?.map((item) => item.value)
            .join(","),
          notification_ids: liveMapUserList?.list?.list?.length
            ? liveMapUserList?.list?.list?.map((item) => item?.id).join(",")
            : "",
          offer_description: StoreDesc || viewProduct?.offer_description,
          logo: fileData[0]?.name || AvatarImage?.name,
          offer_mode: "payment",
          user_count: liveMapUserList?.list?.counts?.total_users,
          user_type: selfInfo?.user_type,
        };

        const paramfilter = Object.entries(paramRequest).filter((elm) => {
          if (elm[1] !== "") {
            return elm;
          }
        });
        console.log("paramfilter", paramfilter, "paramRequest", paramRequest);
        let param = {
          transmit: "single",
          url: "offer_add",
          request: Object.fromEntries(paramfilter),
        };
        wsSend_request(websocket, param);

        // Navigate(VENDOR_OFFER_MANAGEMENT_URL);
      }
    }
  };
  const resetAllFilledData = () => {
    setInputValue({
      offer_title: "",
      owner_contact_person: "",
      business_email_id: "",
      owner_email_id: "",
      owner_contact_number: "",
      business_contact_number: "",
      business_contact_person: "",
      address: "",
      location: "",
    });
    setAvatarImage("");
    setAvatarImageId("");
    setFileData([]);
    setStoreData({
      Monday: {
        Monday_Active: false,
        open_time: "",
        close_time: "",
      },
      Tuesday: {
        Tuesday_Active: false,
        open_time: "",
        close_time: "",
      },
      Wednesday: {
        Wednesday_Active: false,
        open_time: "",
        close_time: "",
      },
      Thursday: {
        Thursday_Active: false,
        open_time: "",
        close_time: "",
      },
      Friday: {
        Friday_Active: false,
        open_time: "",
        close_time: "",
      },
      Saturday: {
        Saturday_Active: false,
        open_time: "",
        close_time: "",
      },
      Sunday: {
        Sunday_Active: false,
        open_time: "",
        close_time: "",
      },
    });
    setSelectedState(null);
    setErrorCountry(null);
    setKey("info");
  };

  const removeCategory = (categoryToRemove) => {
    console.log("categoryToRemove", categoryToRemove);
    const updatedCategories = selectedCategories.filter(
      (category) => category.value !== categoryToRemove.value
    );
    setSelectedCategories(updatedCategories);

    // Check if categoryToRemove has sub_categories
    if (categoryToRemove?.sub_categories) {
      // Create a Set of sub_category ids for efficient lookup
      const subCategoryIds = new Set(
        categoryToRemove.sub_categories.map((subCat) => subCat.id)
      );
      // Remove tags whose id matches with any of the sub_category ids
      setSelectedTags((prevTags) =>
        prevTags.filter((tag) => !subCategoryIds.has(tag.value))
      );
    }
  };
  const removeTags = (TagsToRemove) => {
    const updatedTags = selectedTags.filter(
      (Tags) => Tags.value !== TagsToRemove.value
    );
    setSelectedTags(updatedTags);
  };
  const BaicInfoHandler = (key) => {
    setTabchange(true);
    if (checkIsValid()) {
      setKey(key);
      setinfoFormFill(true);
    } else {
      console.log("check valid function goes here");
      setinfoFormFill(false);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    console.log("handleStartTimeChange", time);
    if (time && time.hour && time.minute) {
      // Construct the time string from hour and minute
      const timeString = `${time.hour}:${time.minute}`;
      setStartTime(timeString);
    }
  };

  const handleEndTimeChange = (time) => {
    if (time && time.hour && time.minute) {
      // Construct the time string from hour and minute
      const timeString = `${time.hour}:${time.minute}`;
      setEndTime(timeString);
    }
  };
  const [menSelected, setMenSelected] = useState(false);
  const [womenSelected, setWomenSelected] = useState(false);
  const [rangeValue, setRangeValue] = useState(10); // Initial value
  const formatLabel = (value) => `${value} km`;
  const [startAge, setStartAge] = useState(null);
  const [endAge, setEndAge] = useState(null);
  console.log("startAge", startAge, "endAge", endAge);
  const handleStartAgeChange = (selectedOption) => {
    setStartAge(selectedOption);
  };

  const handleEndAgeChange = (selectedOption) => {
    setEndAge(selectedOption);
  };
  const ApplyFilterHandler = () => {
    console.log("startAge", startAge, endAge);
    if ((startAge && !endAge) || (!startAge && endAge)) {
      toast.error("Please select valid ages");
      return;
    }
    if (
      startAge?.value !== undefined &&
      endAge?.value !== undefined &&
      (endAge.value <= 0 || startAge.value > endAge.value)
    ) {
      toast.error(
        "Start age cannot be greater than end age, and end age must be greater than 0"
      );
      return;
    }

    if (selectedCategories?.length == 0) {
      console.log("selectedCategories", selectedCategories);
      seterrorCategory("Please select category");
      return;
    } else {
      seterrorCategory("");
    }
    setShowSubmit(true);
    let paramRequest = {
      limit: 5000,
      page: 1,
      distance: rangeValue == "0" ? "" : rangeValue,
      gender:
        menSelected && womenSelected
          ? ""
          : menSelected
          ? "Male"
          : womenSelected
          ? "Female"
          : "",
      interest: selectedCategories?.map((item) => item.value).join(","),
      age_range:
        startAge?.value > 0 && endAge?.value > 0
          ? `${startAge?.value}-${endAge?.value}`
          : "",
      vendor_id: selfInfo?.id,
    };
    const paramfilter = Object.entries(paramRequest).filter((elm) => {
      if (elm[1] !== "") {
        return elm;
      }
    });
    let param = {
      transmit: "single",
      url: "user_live_map",
      request: Object.fromEntries(paramfilter),
    };
    console.log("param", param);
    wsSend_request(websocket, param);
    toast.success("Filter applied");
  };

  useEffect(() => {
    setTimeout(() => {
      if (Location === "vendor-add-offer") {
        const productCategories = selfInfo?.interest.split(",");

        // Filter categoryList to find objects whose name matches the product categories
        const filteredCategories = detailcategoryAllList?.filter((category) =>
          productCategories.includes(category.name)
        );

        // Map the filtered categories to the required structure and update the state
        const mappedCategories = filteredCategories?.map((category) => ({
          value: category?.id,
          label: category?.name,
          sub_categories: category?.sub_categories,
        }));

        if (mappedCategories?.length) {
          const newTags = [];
          mappedCategories.forEach((item) => {
            // Create array of objects with value and label properties
            const newArray = item.sub_categories.map((elm) => ({
              value: elm?.id,
              label: elm?.name,
            }));

            // Add each item from newArray to newTags
            newTags.push(...newArray);
            console.log("newArray", newArray); // Output the created array (optional)
          });

          // Update selectedTags state with newTags
          setSelectedTags((prevTags) => [...prevTags, ...newTags]);
        }
        setSelectedCategories(mappedCategories);
      }
    }, 2000);
  }, [Location, websocket]);
  useEffect(() => {
    if (selectedCategories?.length > 0) {
      seterrorCategory("");
    }
  }, [selectedCategories?.length]);
  return (
    <>
      {paymentModalData?.show && paymentModalData?.data?.type === "offer" && (
        <PaymentModal />
      )}
      <div className="breadcrumb">
        <Link className="text-black" to={VENDOR_OFFER_MANAGEMENT_URL}>
          Offer
        </Link>
        <span> > </span>
        <span>
          <Link>
            {" "}
            {Location === "vendor-edit-offer" ? "Edit Offer" : "Add New Offer"}
          </Link>
        </span>
      </div>
      <section className="dashboard_wrapper_form border p-3">
        <div className="scrollform row">
          <Scrollbars
            style={{ height: "calc(100vh - 150px)" }}
            className="ScrollbarsWrapper"
            renderView={(props) => <div {...props} className="view" />}
          >
            {Location === "vendor-add-offer" && (
              <div className="position-relative mb-2">
                <div className="position-absolute end-0 me-5 mt-1">
                  <div className="d-flex gap-2">
                    <button
                      className="common-blue-bg btn pt-1"
                      onClick={() =>
                        key === "media"
                          ? CreateOffer()
                          : BaicInfoHandler("media")
                      }
                    >
                      {key === "media" ? "Submit" : "Save And Continue"}
                    </button>
                  </div>
                </div>
              </div>
            )}

            <Tabs
              id="vendor-offer-tabbed-add-edit"
              activeKey={key}
              onSelect={(k) => BaicInfoHandler(k)}
              className="mb-3"
            >
              <Tab
                eventKey="info"
                title={
                  <div className={`${infoFormFill ? "common-blue" : ""}`}>
                    <i
                      className={`bi bi-1-circle-fill ${
                        infoFormFill ? "common-blue" : ""
                      }`}
                    ></i>{" "}
                    <span
                      onClick={() => {
                        setKey("info");
                      }}
                    >
                      {" "}
                      Offer Description{" "}
                    </span>
                  </div>
                }
              >
                <div className="scrollformCustom">
                  <div className="row" style={{ margin: "0 auto" }}>
                    <div className="col-md-4" hidden>
                      <div className="form-group fileUploadingform">
                        <label>Product Image</label>
                        {AvatarImage === "" || AvatarImage === null ? (
                          <>
                            <input
                              type="file"
                              className="form-control"
                              onChange={(e) => UploadImage(e)}
                              disabled={UploadFileLoader}
                              accept=".png, .jpg, .jpeg"
                            />
                            {UploadFileLoader && (
                              <div className="filUploading">
                                <Spinner animation="border" role="status">
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </Spinner>
                                Please wait, your file is uploading.
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="uploadedImagewrps">
                            <AvatarGet type="product" url={AvatarImage} />
                            <div
                              className="closeIocn"
                              onClick={() => RemoveUploadImage()}
                            >
                              <i className="bi bi-x-circle-fill"></i>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-9">
                      <InputField
                        type="text"
                        value={offer_title}
                        className={
                          erroroffer_title !== ""
                            ? "form-control error-form"
                            : "form-control"
                        }
                        placeholder="Enter offer title"
                        label="Offer Title <span class='redmednstar'>*</span>"
                        name="offer_title"
                        onChange={handleChange}
                        onKeyUp={(e) =>
                          CheckValid(e.target.value, {
                            type: "offer_title",
                            error: setErroroffer_title,
                          })
                        }
                        onKeyDown={EmptySpaceFieldValid}
                        errormessage={erroroffer_title}
                        required={true}
                        maxLength={255}
                        minLength={1}
                      />
                    </div>

                    <div className="col-md-9 row">
                      <div className="col-md-12">
                        <label
                          htmlFor="toolbarClassName"
                          className="mb-2 fs-14px fw-600"
                        >
                          Offer Description{" "}
                          <span className="common-blue">*</span>
                        </label>
                        <Editor
                          editorState={editorState}
                          onEditorStateChange={onEditorChange}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          wrapperStyle={{
                            width: "100%",
                            border: "1px solid #ccc",
                            backgroundColor: "white",
                            padding: "10px",
                            borderRadius: "4px",
                          }}
                          editorStyle={{
                            minHeight: "150px",
                            border: "1px solid #ccc",
                            padding: "10px",
                            borderRadius: "4px",
                            fontFamily: "sans-serif",
                          }}
                          toolbar={{
                            options: [
                              "inline",
                              "blockType",
                              "fontSize",
                              "list",
                              "textAlign",
                              "colorPicker",
                              "link",
                              "emoji",
                              "history",
                            ],
                            inline: {
                              inDropdown: false,
                              options: [
                                "bold",
                                "italic",
                                "underline",
                                "strikethrough",
                                "superscript",
                                "subscript",
                              ],
                            },
                            list: {
                              inDropdown: false,
                              options: ["unordered", "ordered"],
                            },
                            textAlign: {
                              inDropdown: true,
                            },
                            link: { inDropdown: false },
                            history: { inDropdown: false },
                          }}
                        />
                        {errorStoreDesc && (
                          <div className="errormendatorr">
                            <i className="bi bi-x-circle-fill"></i>
                            {errorStoreDesc}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="col-md-12 d-flex flex-column p-2">
                        <div className="col-md-9 row border p-2">
                          <div className="d-flex gap-1 flex-column col-md-12 p-2">
                            <label className="fs-14px">
                              Starting On <span className="common-blue">*</span>
                            </label>
                            <div className="row flex-wrap gap-1 col-md-12">
                              <div className="col-md-4">
                                <DatePicker
                                  selected={startDate}
                                  onChange={handleStartDateChange}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="Select a date"
                                  minDate={new Date()}
                                />
                              </div>
                              <div className="col-md-3" id="starting_on">
                                <TimePicker
                                  name="startTime"
                                  onFocusChange={console.log}
                                  minuteStep={1}
                                  onTimeChange={(newTime) =>
                                    handleStartTimeChange(newTime)
                                  }
                                  placeholder={startTime}
                                  time={startTime}
                                  timeFormat="HH:MM"
                                  theme="material"
                                  timeMode="24"
                                />
                              </div>
                              {errorStartTimeAndDate && (
                                <div className="errormendatorr">
                                  <i className="bi bi-x-circle-fill"></i>
                                  {errorStartTimeAndDate}
                                </div>
                              )}
                            </div>
                            <label className="fs-14px">
                              Expires On <span className="common-blue">*</span>
                            </label>
                            <div className="row gap-1 col-md-12 ">
                              <div className="col-md-4">
                                <DatePicker
                                  selected={endDate}
                                  onChange={handleEndDateChange}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="Select a date"
                                  minDate={
                                    startDate ? new Date(startDate) : new Date()
                                  }
                                />
                              </div>
                              <div className="col-md-3" id="starting_on">
                                <TimePicker
                                  name="endTime"
                                  minuteStep={1}
                                  onTimeChange={(newTime) =>
                                    handleEndTimeChange(newTime)
                                  }
                                  placeholder={endTime}
                                  time={endTime}
                                  timeFormat="HH:MM"
                                  theme="material"
                                  timeMode="24"
                                />
                              </div>
                            </div>
                            {errorEndTimeAndDate && (
                              <div className="errormendatorr">
                                <i className="bi bi-x-circle-fill"></i>
                                {errorEndTimeAndDate}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="col-md-12 my-3">
                        <h6>
                          Upload Offer Image{" "}
                          <span className="common-blue">*</span>
                        </h6>
                      </div>
                      <div className="col-md-9 d-flex gap-1">
                        <div className="col-md-12 row">
                          <form
                            className="file-upload-form d-flex col-md-4"
                            id="add-edit-event"
                            onClick={handleFormClick}
                          >
                            <label
                              htmlFor="file"
                              className="file-upload-label"
                              id="customer-padding-for-label"
                            >
                              <div className="file-upload-design">
                                <img src={uploadIcon} alt="" />
                                <p>
                                  <a className="browse-button px-1 py-0 text-primary">
                                    Click to upload
                                  </a>
                                  or drag and drop
                                </p>
                                <p className="secondary-text">
                                  Max. File Size: 30MB
                                </p>
                              </div>
                              <input
                                ref={fileInputRef}
                                id="file"
                                type="file"
                                style={{ visibility: "hidden" }}
                                className="form-control"
                                onChange={(e) => handleFileChange(e)}
                                name="logo" // Added the name attribute
                                disabled={UploadFileLoader}
                                accept=".png, .jpg, .jpeg"
                              />
                            </label>
                          </form>

                          {AvatarImage && (
                            <div className="col-md-3 border br-1rem d-flex p-2 flex-column position-relative">
                              <img
                                src={closeIcon}
                                alt=""
                                className="position-absolute top--5 end--7 w-1-5rem cursor-pointer"
                                onClick={RemoveUploadImage}
                              />
                              <div className="flex-grow-1">
                                <img
                                  className="w-100 img-fluid" // Use "img-fluid" class to make the image responsive
                                  src={AvatarImage?.url?.file_url}
                                  alt={AvatarImage?.url?.file_url}
                                  style={{ maxHeight: "200px" }}
                                />
                              </div>
                              <div className="d-flex flex-column gap-0 me-2">
                                <i className="mb-0 text-truncate">
                                  {AvatarImage?.original_name}
                                </i>
                                <p className="secondary-text">
                                  {bytesToKB(AvatarImage?.size)}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {!AvatarImage && errorAvatarImage && (
                        <div className="errormendatorr">
                          <i className="bi bi-x-circle-fill"></i>
                          {errorAvatarImage}
                        </div>
                      )}
                    </div>
                    <div className="col-md-12 mt-4 mb-5">
                      {Location === "vendor-edit-offer" && (
                        <button
                          className="bg-primary  px-3 py-3 btn w-50 text-white"
                          onClick={CreateOffer}
                        >
                          {Location !== "vendor-edit-offer"
                            ? "Proceed to payment"
                            : "Update"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Tab>
              {Location === "vendor-add-offer" && (
                <Tab
                  eventKey="media"
                  title={
                    <div>
                      <i
                        className={`bi bi-2-circle-fill ${
                          infoFormFill && key === "media"
                            ? "common-blue"
                            : "text-secondary"
                        }`}
                      ></i>{" "}
                      Filter & Payment
                    </div>
                  }
                >
                  <div className="scrollformCustom">
                    <div className="row" style={{ margin: "0 auto" }}>
                      <div className="col-md-12 row mt-3">
                        <div className="col-md-4 border-right">
                          <div className="col-md-12">
                            <div className="d-flex flex-column">
                              <h6>
                                Categories
                                <span className="common-blue"> *</span>
                              </h6>
                              <div className="filedaddwraps justify-content-start mb-2 pe-3">
                                <Select
                                  value={selectedCategories}
                                  onChange={handleCategoryChange}
                                  options={categoryOptions}
                                  isSearchable
                                  isMulti
                                  placeholder="Select Categories"
                                  components={customComponents}
                                />
                              </div>
                              {errorCategory && (
                                <div className="errormendatorr">
                                  <i className="bi bi-x-circle-fill"></i>
                                  {errorCategory}
                                </div>
                              )}

                              {selectedCategories.map((category, index) => (
                                <div
                                  key={index}
                                  className="selected-category ms-2 mx-1"
                                >
                                  <i
                                    className="bi bi-dash-circle text-red"
                                    onClick={() => removeCategory(category)}
                                  ></i>
                                  <span className="ms-2">
                                    {" "}
                                    {category.label}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                          {/* <div id="payment-success-modal">
      <PaymentSuccessModal/>
      </div> */}

                          <div className="col-md-12 pe-3 mt-3">
                            <div className="d-flex flex-column">
                              <h6>Tags</h6>
                              <div className="filedaddwraps justify-content-start mb-2">
                                <Select
                                  value={selectedTags}
                                  onChange={handleTagsChange}
                                  options={subcategoryOptions}
                                  isSearchable
                                  isMulti
                                  placeholder="Select Tags"
                                  components={customComponents}
                                />
                              </div>
                              {selectedTags.map((Tag, index) => (
                                <div key={index} className="selected-Tag ms-2">
                                  <i
                                    className="bi bi-dash-circle text-red"
                                    onClick={() => removeTags(Tag)}
                                  ></i>
                                  <span className="ms-2"> {Tag.label}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="col-md-12 mt-2">
                            <div className="col-md-12">
                              <div className="col-md-12 d-flex gap-5 p-2">
                                <div
                                  className="d-flex align-items-center border br-1rem px-2 py-1 gap-2"
                                  onClick={(e) => {
                                    setMenSelected((prev) => !prev);
                                  }}
                                >
                                  <img
                                    src={menIcon}
                                    alt="menIcon"
                                    className="h-1-5rem w-1-5rem"
                                  />{" "}
                                  <span
                                    className={`${
                                      menSelected
                                        ? "text-primary"
                                        : "text-black"
                                    }`}
                                  >
                                    Men
                                  </span>
                                  <i
                                    className={`bi bi-check-circle-fill fs-1-3rem ${
                                      menSelected
                                        ? "text-primary"
                                        : "text-black"
                                    }`}
                                  ></i>
                                </div>
                                <div
                                  className="d-flex align-items-center border br-1rem px-2 py-1 gap-2"
                                  onClick={(e) => {
                                    setWomenSelected((prev) => !prev);
                                  }}
                                >
                                  <img
                                    src={womenIcon}
                                    alt="womenIcon"
                                    className="h-1-5rem w-1-5rem"
                                  />{" "}
                                  <span
                                    className={`${
                                      womenSelected
                                        ? "text-primary"
                                        : "text-black"
                                    }`}
                                  >
                                    Women
                                  </span>
                                  <i
                                    className={`bi bi-check-circle-fill fs-1-3rem ${
                                      womenSelected
                                        ? "text-primary"
                                        : "text-black"
                                    }`}
                                  ></i>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="px-2">
                                  <label htmlFor="rangeInput">Distance</label>
                                  <div className="mb-2 mt-3 px-3 py-2">
                                    <InputRange
                                      maxValue={20}
                                      minValue={0}
                                      value={rangeValue}
                                      onChange={(newValue) =>
                                        setRangeValue(newValue)
                                      }
                                      onChangeComplete={(newValue) =>
                                        console.log(newValue)
                                      }
                                      formatLabel={formatLabel}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 d-flex flex-column p-2">
                                <label className="fs-14px mb-1 mt-2">
                                  Select Age Range
                                </label>
                                <div className="d-flex gap-3">
                                  <div className="col-md-4">
                                    <Select
                                      value={startAge}
                                      onChange={handleStartAgeChange}
                                      options={ageOptions}
                                      isSearchable
                                      placeholder="Start"
                                    />
                                  </div>
                                  <label>To</label>
                                  <div className="col-md-4">
                                    <Select
                                      value={endAge}
                                      onChange={handleEndAgeChange}
                                      options={ageOptions}
                                      isSearchable
                                      placeholder="End"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 px-1 mb-2 py-1">
                                <button
                                  className="bg-primary px-3 py-3 btn w-100 text-white"
                                  onClick={ApplyFilterHandler}
                                >
                                  Apply Filter
                                </button>
                              </div>

                              <div className="col-md-12 px-1 mb-5 py-1">
                                {liveMapUserList?.list?.counts &&
                                  Location !== "vendor-edit-offer" && (
                                    <div className="col-md-12 mb-3 mt-2">
                                      <div className="col-md-12 d-flex align-items-center justify-content-center bg-highlight-pink px-4 py-2 rounded ">
                                        <div className="d-flex justify-content-center col-md-4 gap-2 align-items-center">
                                          <small className="fs-6">
                                            Active Users-
                                          </small>{" "}
                                          <span className="text-fuchsia">
                                            {liveMapUserList?.list?.counts
                                              ?.active_users || "-"}
                                          </span>{" "}
                                          <span className="vertical-line"></span>
                                        </div>
                                        <div className="d-flex justify-content-center col-md-4 gap-2 align-items-center">
                                          <small className="fs-6">
                                            Total User-
                                          </small>{" "}
                                          <span className="text-fuchsia">
                                            {" "}
                                            {liveMapUserList?.list?.counts
                                              ?.total_users || "-"}
                                          </span>{" "}
                                          <span className="vertical-line"></span>
                                        </div>
                                        <div className="d-flex justify-content-center col-md-4 gap-2 align-items-center">
                                          <small className="fs-6">
                                            Payable Amount-
                                          </small>{" "}
                                          <span className="text-fuchsia">
                                            {liveMapUserList?.list?.counts
                                              ?.total_users * 10}
                                          </span>{" "}
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                <button
                                  className="bg-pink px-3 py-3 btn w-100 text-white"
                                  onClick={CreateOffer}
                                >
                                  {Location !== "vendor-edit-offer"
                                    ? "Proceed to payment"
                                    : "Update"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8 position-relative">
                          {showSubmit && (
                            <h6 className="position-absolute start-3 top-1 w-25 bg-none total-user-count-heading">
                              total users{" "}
                              {liveMapUserList?.list?.counts?.total_users}
                            </h6>
                          )}
                          {showSubmit && liveMapUserList?.list?.list?.length ? (
                            <>
                              <FilteredMap
                                data={
                                  liveMapUserList?.list?.list
                                    ? liveMapUserList?.list
                                    : []
                                }
                              />
                            </>
                          ) : (
                            <>
                              <InitialMap />{" "}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              )}
            </Tabs>
          </Scrollbars>
        </div>
        {/* loader start here */}
        {loaderMain && <CommonLoader />}
      </section>
      {showModal?.open && (
        <CommonModel
          fromUrl={"product"}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};

export default VendorAddEditOffer;
