import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge, NavDropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  ADMIN_SETTING_MANAGEMENT_URL,
  VIEW_PROFILE_URL,
  VIEW_VENDOR_PROFILE_URL,
} from "./constant";
import AvatarGet from "../Common/AvatarGet";
import { useSelector } from "react-redux";
import SidebarToggle from "../../assets/img/toogle_btn.png";
import CommonModel from "../Common/CommonModel";

const Header = (props) => {
  const { setShowSidebar, showSidebar } = props;
  const Location = useLocation().pathname?.split("/")[1].replaceAll("-", " ");
  const Navigate = useNavigate();
  const {
    selfInfo,
    addToCartLists,
    faqList,
    supportList,
    notificationList,
    userList,
    paymentList,
    categoryList,
    vendorList,
    eventList,
    offerList,
    vendorEventList,
    vendorOfferList,
    viewProduct,
    vendorPaymentList,
    vendorViewTCList,
    approvalDataList,
  } = useSelector((state) => state.adminReducers);
  console.log(
    "first",
    userList,
    paymentList,
    categoryList,
    vendorList,
    eventList,
    offerList
  );
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });

  // logout function
  const LogoutFnct = () => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Are you sure?",
      subtitle: "Logout",
      description: `Are you sure want to log out?</h5>`,
      modalType: "not form",
      button: "Log Out",
    });
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="head_section">
      {window.screen.width < 901 && (
        <button
          className="toogle_bar_btn"
          onClick={() => setShowSidebar(!showSidebar)}
        >
          <img src={SidebarToggle} alt="toggle" />
        </button>
      )}
      <h4>
        {Location === "faq" || Location === "edit faq" ? (
          <div className="d-flex flex-column">
            <h6>FAQ's</h6>
            <small className="secondary-text">
              Total Questions {faqList?.pagination?.total_records || "0"}
            </small>
          </div>
        ) : Location === "support" ? (
          <div className="d-flex flex-column">
            <h6>Support</h6>
            <small className="secondary-text">
              Total Support {supportList?.pagination?.total_records || "0"}
            </small>
          </div>
        ) : Location === "dashboard" ? (
          <div className="d-flex flex-column">
            <h5>Dashboard</h5>
            <small className="text-96">Welcome back to E-Find Admin!</small>
          </div>
        ) : Location === "notification" ? (
          <div className="d-flex flex-column">
            <h6>Notification</h6>
            <small className="secondary-text">
              Total Notification {notificationList?.pagination?.total_records || "0"}
            </small>
          </div>
        ) : Location === "approval" ? (
          <div className="d-flex flex-column">
            <h6>Approval</h6>
            <small className="secondary-text">
              Total Request {approvalDataList?.pagination?.total_records || "0"}
            </small>
          </div>
        ) : Location === "user" ? (
          <div className="d-flex flex-column">
            <h6>Users</h6>
            <small className="secondary-text">
              Total Users {userList?.pagination?.total_records || "0"}
            </small>
          </div>
        ) : Location === "payment" ? (
          <div className="d-flex flex-column">
            <h6>Payments Overview</h6>
            <small className="secondary-text">
              Total Payments {paymentList?.pagination?.total_records || "0"}
            </small>
          </div>
        ) : Location === "category" ? (
          <div className="d-flex flex-column">
            <h6>Categories</h6>
            <small className="secondary-text">
              Total Categories {categoryList?.pagination?.total_records || "0"}
            </small>
          </div>
        ) : Location === "vendor" ? (
          <div className="d-flex flex-column">
            <h6>Vendors</h6>
            <small className="secondary-text">
              Total Vendor {vendorList?.pagination?.total_records || "0"}
            </small>
          </div>
        ) : Location === "view vendor" ? (
          <div className="d-flex flex-column">
            <h6>{viewProduct?.business_name}</h6>
          </div>
        ) : Location === "offer" ? (
          <div className="d-flex flex-column">
            <h6>Offers</h6>
            <small className="secondary-text">
              Total Offers {offerList?.pagination?.total_records || "0"}
            </small>
          </div>
        ) : Location === "view-offer" ? (
          <div className="d-flex flex-column">
            <h6>{viewProduct?.name}</h6>
          </div>
        ) : Location === "view-event" ? (
          <div className="d-flex flex-column">
            <h6>{viewProduct?.name}</h6>
          </div>
        ) : Location === "event" ? (
          <div className="d-flex flex-column">
            <h6>Events</h6>
            <small className="secondary-text">
              Total Events {eventList?.pagination?.total_records || "0"}
            </small>
          </div>
        ) : Location === "vendor event" ? (
          <div className="d-flex flex-column">
            <h6>Events</h6>
            <small className="secondary-text">
              Total Events {vendorEventList?.pagination?.total_records || "0"}
            </small>
          </div>
        ) : Location === "vendor offer" ? (
          <div className="d-flex flex-column">
            <h6>Offers</h6>
            <small className="secondary-text">
              Total Offers {vendorOfferList?.pagination?.total_records || "0"}
            </small>
          </div>
        ) : Location === "vendor add offer" ? (
          <div className="d-flex flex-column">
            <h5>Offers</h5>
          </div>
        ) : Location === "vendor coins" ? (
          <div className="d-flex flex-column">
            <h6>Coin Transactions</h6>
            <small className="secondary-text">
              Total Transactions {vendorViewTCList?.pagination?.total_records || "0"}
            </small>
          </div>
        ) : Location === "vendor support" ? (
          <div className="d-flex flex-column">
            <h6>Support</h6>
            <small className="secondary-text" hidden>
              Total Support {supportList?.pagination?.total_records || "0"}
            </small>
          </div>
        ) : Location === "vendor payment" ? (
          <div className="d-flex flex-column">
            <h6>Payments Overview</h6>
            <small className="secondary-text">
              Total Payments {vendorPaymentList?.pagination?.total_records || "0"}
            </small>
          </div>
        ) : Location === "vendor dashboad" ? (
          <div className="d-flex flex-column">
            <h5 className="fw-600">Hello {selfInfo?.username} 👋</h5>
            <small className="secondary-text">
              Let's Check Your Store Today.
            </small>
          </div>
        ) : (
          <h6 className="fw-600 fs-18px"> {Location}</h6>
        )}
      </h4>
      <div className="dropdownwraps">
        {selfInfo?.user_type === "admin" && (
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={
              <Tooltip id="button-tooltip-2" style={{ position: "fixed" }}>
                View Settings
              </Tooltip>
            }
          >
            <button
              type="button"
              className="btn border me-2 px-3 py-2 position-relative"
              onClick={() => Navigate(ADMIN_SETTING_MANAGEMENT_URL)}
            >
              {/* <span className="setting-count">3</span> */}
              <i className="bi bi-gear"></i>
              {addToCartLists?.length > 0 && (
                <Badge bg="secondary">{addToCartLists?.length}</Badge>
              )}
            </button>
          </OverlayTrigger>
        )}
        <OverlayTrigger
          placement="bottom"
          trigger={["hover", "focus"]}
          overlay={
            <Tooltip
              id="button-tooltip-2"
              style={{ position: "fixed", display: "none" }}
            ></Tooltip>
          }
        >
          <div className="user_DETAILS me-2">
            <div className="d-flex gap-1">
              <div className="avatar-profile me-1 mt-1">
                <AvatarGet
                  type={"product"}
                  url={
                    selfInfo?.avatar?.thumbnail_url || selfInfo?.logo?.file_url
                  }
                />
              </div>
              {window.screen.width > 540 && (
                <React.Fragment>
                  <div className="d-flex flex-column mt-1">
                    <h6 className="text-truncate mb-0 fs-12px fw-700">
                      {selfInfo?.user_type === "admin"
                        ? selfInfo?.username || selfInfo.email
                        : selfInfo?.owner_contact_person ||
                          selfInfo?.business_name}
                    </h6>
                    <small className="text-truncate secondary-text">
                      {selfInfo?.user_type || "Admin"}
                    </small>
                  </div>
                </React.Fragment>
              )}
            </div>

            <NavDropdown
              title=""
              className="border-0 btn txt-black fw-6 "
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                className="d-flex aic"
                onClick={() => {
                  handleDropdownToggle();
                  Navigate(
                    selfInfo?.user_type === "admin"
                      ? VIEW_PROFILE_URL
                      : VIEW_VENDOR_PROFILE_URL
                  );
                }}
              >
                {" "}
                <i className="me-2 bi bi-person-circle"></i> My Profile
              </NavDropdown.Item>
              <NavDropdown.Item
                className="d-flex aic"
                onClick={() => {
                  LogoutFnct();
                }}
              >
                <i className="bi bi-box-arrow-right me-2"></i>Logout
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </OverlayTrigger>
        {/* <OverlayTrigger
                placement="bottom"
                trigger={['hover', 'focus']}
                overlay={<Tooltip id="button-tooltip-2" style={{ position: "fixed" }}>Logout</Tooltip>}
            >
                <button type="button" className="btn logoutbtn" onClick={() => LogoutFnct()}>
                    <i className="bi bi-box-arrow-right"></i>
                </button>
            </OverlayTrigger> */}
      </div>
      {showModal?.open && (
        <CommonModel showModal={showModal} setShowModal={setShowModal} />
      )}
    </div>
  );
};

export default Header;
