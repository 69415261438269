/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import vendorAI from "../../assets/icons/vendorAI.svg";
import binIcon from "../../assets/icons/binIcon.svg";

const CommonewModel = (props) => {
  const { websocket } = useContext(WebSocketContext);
  const { showModalNew, setShowModalNew, setCurrentPage } = props;
  const { device_id, selfInfo } = useSelector((state) => state.adminReducers);
  console.log("showModalNew", showModalNew);
  // close modal
  const handleClose = () => {
    setShowModalNew({ ...showModalNew, open: false });
  };

  const CommonFunction = async () => {
    if (showModalNew?.subtitle === "Active Product") {
      let param = {
        transmit: "single",
        url: "vendor_update",
        request: {
          vendor_id: showModalNew?.Data?.id,
          is_active: showModalNew?.Data?.is_active ? 0 : 1,
          user_type : selfInfo?.user_type
        },
      };
      wsSend_request(websocket, param);
      CommonListUpdate("vendor_admin_listing");
      handleClose();
    }
    
    else if (showModalNew?.subtitle === "Active User") {
        let param = {
          transmit: "single",
          url: "user_update",
          request: {
            user_id: showModalNew?.Data?.id,
            is_active: showModalNew?.Data?.is_active ? "0" : "1",
            user_type:selfInfo?.user_type
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("user_listing");
        handleClose();
      }
      else if (showModalNew?.subtitle === "Delete User") {
        let param = {
          transmit: "single",
          url: "user_delete",
          request: {
            user_id: showModalNew?.Data?.id,
          },
        };
        wsSend_request(websocket, param);
        CommonListUpdate("user_listing");
        handleClose();
      }
      else if (showModalNew?.subtitle === "Delete Vendor Review By Admin") {
        let param = {
          transmit: "single",
          url: "review_delete",
          request: {
            review_id: showModalNew?.Data?.id,
            user_type: selfInfo?.user_type
          },
        };
        wsSend_request(websocket, param);
        reviewListUpdate("review_listing");
        handleClose();
      }
    
    else if (showModalNew?.subtitle === "Active Event") {
      let param = {
        transmit: "single",
        url: "event_update",
        request: {
          event_id: showModalNew?.Data?.id,
          is_active: showModalNew?.Data?.is_active ? 0 : 1,
          user_type: selfInfo?.user_type
        },
      };
      wsSend_request(websocket, param);
      CommonListUpdate("event_listing");
      handleClose();
    } else if (showModalNew?.subtitle === "Active Offer") {
      let param = {
        transmit: "single",
        url: "offer_update",
        request: {
          offer_id: showModalNew?.Data?.id,
          is_active: showModalNew?.Data?.is_active ? 0 : 1,
          user_type: selfInfo?.user_type
        },
      };
      wsSend_request(websocket, param);
      CommonListUpdate("offer_listing");
      handleClose();
    } else if (showModalNew?.subtitle === "Delete Product") {
      let param = {
        transmit: "single",
        url: "delete_product",
        request: {
          product_id: showModalNew?.Data?.id,
          DeviceId: device_id,
        },
      };
      wsSend_request(websocket, param);
      CommonListUpdate("vendor_admin_listing");
      handleClose();
    } else if (showModalNew?.subtitle === "Delete FAQ") {
      let param = {
        transmit: "single",
        url: "faq_delete",
        request: {
          faq_id: showModalNew?.Data?.id,
        },
      };
      wsSend_request(websocket, param);
      CommonListUpdate("faq_listing");
      handleClose();
    }
  };

  // When editing API calling, at that time list API calling.
  const CommonListUpdate = (url) => {
    if (url) {
      let param = {
        transmit: "single",
        url: url,
        request: {
          filter: "all",
          limit: 10,
          page: sessionStorage.getItem('currentPage') || 1,
          search: "",
          user_type: selfInfo?.user_type,
        },
      };
      wsSend_request(websocket, param);
      setCurrentPage(1);
    }
  };
  const reviewListUpdate = (url) => {
    if (url) {
      let param = {
        transmit: "single",
        url: url,
        request: {
          filter: "all",
          limit: 10,
          page: 1,
          search: "",
          user_type: selfInfo?.user_type,
          vendor_id: showModalNew?.Data?.vendor_id,

        },
      };
      wsSend_request(websocket, param);
      setCurrentPage(1);
    }
  };

  return (
    <Modal
      className={
        showModalNew?.title === "Create Supplier"
          ? "commonModal addSupplierModel"
          : "commonModal"
      }
      centered
      show={showModalNew?.open}
      onHide={handleClose}
    >
      {showModalNew?.title && (
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              width: showModalNew?.title === "Are you sure?" ? "100%" : "",
            }}
          >
            {showModalNew?.title}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        {showModalNew?.modalType === "form" ? (
          <></>
        ) : (
          <>
            <div className="notformsection">
              {showModalNew?.icon === "vendorAI" && (
                <div className="img-container d-center mt-5">
                  <img src={vendorAI} alt="" />
                </div>
              )}
               {showModalNew?.icon === "binIcon" && (
                <div className="img-container d-center mt-5">
                  <img src={binIcon} alt="" />
                </div>
              )}
              {showModalNew?.subtitle === "Logout" && (
                <i className="bi logoutIconbtn"></i>
              )}
              <p
                className="fs-20px px-5 py-2 fw-600"
                dangerouslySetInnerHTML={{ __html: showModalNew?.description }}
              ></p>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center gap-3 align-items-center w-100">
        <Button
          variant="secondary"
          className="no-btn no-text w-33 py-2"
          onClick={handleClose}
        >
          {showModalNew?.closeText || "Close"}
        </Button>
        <Button
          variant="primary"
          className="addbtncmn w-33 py-2"
          onClick={CommonFunction}
        >
          {showModalNew?.button}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CommonewModel;
