/* eslint-disable */
import { ActionTypes } from "../actions/adminActions";

const initailData = {
  is_login: "no",
  user_id: "",
  device_id: "",
  selfInfo: {},
  accessToken: "",
  permissionList: [],
  dashboardCount: {},
  dashboardPaymentRevenue: {},
  dashboardTransactionRevenue: {},
  dashboardUserRevenue: {},

  userList: [],
  userListIdWise: {},
  approvalRRList: {
    list: [],
    pagination: {},
  },
  approvalOfferList: {
    list: [],
    pagination: {},
  },
  approvalEventList: {
    list: [],
    pagination: {},
  },
  approvalPhotoList: {
    list: [],
    pagination: {},
  },
  approvalDataList: {
    list: [],
    pagination: {},
  },

  DashboardMostSuccessOfferList: {
    list: [],
    pagination: {},
  },
  DashboardMostSuccessEventList: {
    list: [],
    pagination: {},
  },
  DashboardReferralList: {
    list: [],
    pagination: {},
  },
  DashboardMostLikedList: {
    list: [],
    pagination: {},
  },
  DashboardMostWhishList: {
    list: [],
    pagination: {},
  },
  DashboardCoinEarningList: {
    list: [],
    pagination: {},
  },

  brandList: {
    list: [],
    pagination: {},
  },
  modelList: {
    list: [],
    pagination: {},
  },
  brandmodelsList: {
    list: [],
    pagination: {},
  },
  supplierList: {
    list: [],
    pagination: {},
  },
  usercategoryList: {
    list: [],
    pagination: {},
  },
  supplierListall: [],
  supplierdetails: {},
  vendorList: {
    list: [],
    pagination: {},
  },
  vendorListAll: [],
  offerList: {
    list: [],
    pagination: {},
  },
  offerListAll: [],
  eventList: {
    list: [],
    pagination: {},
  },
  eventListAll: [],
  vendorEventList: {
    list: [],
    pagination: {},
  },
  vendorOfferList: {
    list: [],
    pagination: {},
  },
  liveMapUserList: {
    list: [],
    pagination: {},
  },
  vendorOfferListAll: [],
  brandlistall: [],
  modellistall: [],
  selectedBrandWiseModel: [],
  viewProduct: {},
  addToCartLists: [],
  loaderMain: false,
  ClearCaches_setww: "",
  supportList: {
    list: [],
    pagination: {},
  },
  vendorSupportList: {
    list: [],
    pagination: {},
  },

  categoryList: {
    list: [],
    pagination: {},
  },
  categoryAllList: [],
  subcategoryList: {
    list: [],
    pagination: {},
  },
  vendorOfferResponseList: {
    list: [],
    pagination: {},
  },
  vendorViewGalleryList: {
    list: [],
    pagination: {},
  },
  vendorViewReviewList: {
    list: [],
    pagination: {},
  },
  vendorViewTCList: {
    list: [],
    pagination: {},
  },
  VendorStoreGallery: {
    list: [],
    pagination: {},
  },
  ViewPagesResponseList: {
    list: [],
    pagination: {},
  },
  vendorViewEventResponseList: {
    list: [],
    pagination: {},
  },
  vendorEventResponseList: {
    list: [],
    pagination: {},
  },
  settingCoinEarningList: {
    list: [],
    pagination: {},
  },
  settingBadgeList: {
    list: [],
    pagination: {},
  },
  settingMCRList: {
    list: [],
    pagination: {},
  },
  faqList: {
    list: [],
    pagination: {},
  },
  notificationList: {
    list: [],
    pagination: {},
  },
  paymentList: {
    list: [],
    pagination: {},
  },
  vendorPaymentList: {
    list: [],
    pagination: {},
  },
  vendorCTList: {
    list: [],
    pagination: {},
  },

  subcategoryAllList: [],
  detailcategoryAllList: [],
  ClearFormSet: {
    url: "",
    action: false,
  },
  settinglists: {
    list: [],
    pagination: {},
  },
  settingsObjectIdWise: {},
  SelectCurrentBrand: {},
  SelectCurrentBrandModel: {},
  SelectCurrentCategory: {},
  SelectCurrentSupplier: {},
  SelectedCurrentBrandID: "",
  faqListAll: [],
  successModalData: "",
  paymentModalData: "",
  confirmModal: "",
  firebaseData:"",
};

export const adminReducers = (state = initailData, action) => {
  switch (action.type) {
    case ActionTypes.SET_STORE_FIREBASE_DATA:
      return {
          ...state,
          firebaseData: action.payload,
      };
    case ActionTypes.IS_LOGIN:
      return {
        ...state,
        is_login: action.payload,
      };
    case ActionTypes.GET_USER_ID:
      return {
        ...state,
        user_id: action.payload,
      };
    case ActionTypes.GET_DEVICE_ID:
      return {
        ...state,
        device_id: action.payload,
      };
    case ActionTypes.SELF_DETAILS:
      return {
        ...state,
        selfInfo: action.payload,
      };
    case ActionTypes.CLEAR_FORM_SET_FUCT:
      return {
        ...state,
        ClearFormSet: {
          url: action?.payload?.url,
          action: action?.payload?.action,
        },
      };
    case ActionTypes.EDIT_SELF_DETAILS:
      return {
        ...state,
        selfInfo: action.payload,
      };
    case ActionTypes.GET_ACCESSTOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case ActionTypes.LOADER_MAIN_START:
      return {
        ...state,
        loaderMain: action.payload,
      };
    case ActionTypes.GET_USER_LIST:
      console.log("GET_USER_LIST", action.payload);
      return {
        ...state,
        userList: action.payload?.data,
      };
    case ActionTypes.GET_DASHBOARD_DETAILS:
      return {
        ...state,
        dashboardCount: action.payload,
      };
    case ActionTypes.GET_DASHBOARD_PR_DETAILS:
      console.log("GET_DASHBOARD_PR_DETAILS", action.payload);
      return {
        ...state,
        dashboardPaymentRevenue: {
          ...state.dashboardPaymentRevenue,
          [action.payload.data.particular === "event" ? "event" : "offer"]:
            action.payload.data,
        },
      };
    case ActionTypes.GET_DASHBOARD_TR_DETAILS:
      console.log("GET_DASHBOARD_TR_DETAILS", action.payload);

      return {
        ...state,
        dashboardTransactionRevenue: action.payload,
      };
    case ActionTypes.GET_DASHBOARD_UR_DETAILS:
      return {
        ...state,
        dashboardUserRevenue: action.payload,
      };
    case ActionTypes.GET_USER_LIST_ID_WISE:
      const ObjectUserListId = {};
      action.payload.map((elm) => {
        ObjectUserListId[elm?.id] = elm;
      });

      return {
        ...state,
        userListIdWise: ObjectUserListId,
      };
    case ActionTypes.ADD_USER_LIST:
      const OldUserlist = [...state.userList];
      OldUserlist.unshift(action.payload);
      return {
        ...state,
        userList: OldUserlist,
      };
    case ActionTypes.EDIT_USER_LIST:
      const OldUserlistx = [...state.userList];
      const UpdateUserList = OldUserlistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            first_name: action.payload.first_name,
            last_name: action.payload.last_name,
            email: action.payload.email,
            password: action.payload.password,
            phone_code: action.payload.phone_code,
            phone: action.payload.phone,
            address: action.payload.address,
            permissions: action.payload.permissions,
            is_admin: action.payload.is_admin,
            is_staff: action.payload.is_staff,
            is_active: action.payload.is_active,
          };
        } else return elm;
      });
      return {
        ...state,
        userList: UpdateUserList,
      };
    case ActionTypes.DELETE_USER:
      const OldUserListz = [...state.userList];
      const NewUpdatedUserList = [];
      OldUserListz &&
        OldUserListz.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedUserList.push(elm);
          }
        });
      return {
        ...state,
        userList: NewUpdatedUserList,
      };
    case ActionTypes.GET_BRAND_LIST:
      return {
        ...state,
        brandList: {
          list:
            Object.keys(action.payload).length === 0
              ? []
              : action?.payload?.data?.data,
          pagination:
            Object.keys(action.payload).length === 0
              ? []
              : action?.payload?.data?.pagination,
        },
      };
    case ActionTypes.GET_ALL_PRODUCT_LIST:
      return {
        ...state,
        vendorListAll:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };
    case ActionTypes.GET_ALL_EVENT_LIST:
      return {
        ...state,
        eventListAll:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };
    case ActionTypes.GET_ALL_OFFER_LIST:
      return {
        ...state,
        offerListAll:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };
    case ActionTypes.GET_ALL_LIVE_MAP_USER_LIST:
      return {
        ...state,
        liveMapUserList: {
          list:
            Object.keys(action.payload).length === 0
              ? []
              : action.payload.data.data,
          pagination:
            Object.keys(action.payload).length === 0
              ? []
              : action.payload.data.pagination,
        },
      };

    case ActionTypes.GET_ALL_VENDOR_OFFER_LIST:
      return {
        ...state,
        vendorOfferListAll:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };
    case ActionTypes.GET_ALL_BRANDS_LIST:
      return {
        ...state,
        brandlistall:
          Object.keys(action.payload).length === 0
            ? []
            : action?.payload?.data?.data,
      };
    case ActionTypes.GET_ALL_MODEL_LIST:
      return {
        ...state,
        modellistall:
          Object.keys(action.payload).length === 0
            ? []
            : action.payload?.data?.data,
      };
    case ActionTypes.SELECT_BRAND_GET_MODEL_LIST:
      return {
        ...state,
        selectedBrandWiseModel:
          Object.keys(action.payload).length === 0
            ? []
            : action.payload.data.data,
      };
    case ActionTypes.ADD_NEW_BRAND:
      const OldBrandlist =
        state.brandList.list === undefined ? [] : [...state.brandList.list];
      const OldAllBrandList =
        state.brandlistall === undefined ? [] : [...state.brandlistall];
      OldBrandlist.unshift(action.payload.data);
      OldAllBrandList.unshift(action.payload.data);
      return {
        ...state,
        brandList: {
          list: OldBrandlist,
        },
        brandlistall: OldAllBrandList,
      };
    case ActionTypes.SELECT_CURRENT_ADDED_BRAND:
      return {
        ...state,
        SelectCurrentBrand: action.payload,
      };
    case ActionTypes.SELECT_CURRENT_ADDED_BRAND_MODEL:
      return {
        ...state,
        SelectCurrentBrandModel: action.payload,
      };
    case ActionTypes.SELECT_CURRENT_ADDED_CATEGORY:
      return {
        ...state,
        SelectCurrentCategory: action.payload,
      };
    case ActionTypes.SELECT_CURRENT_ADDED_SUPPLIER:
      return {
        ...state,
        SelectCurrentSupplier: action.payload,
      };
    case ActionTypes.GET_SELECTED_CURRENT_BRAND_IDS:
      return {
        ...state,
        SelectedCurrentBrandID: action.payload,
      };
    case ActionTypes.EDIT_BRAND:
      const OldBrandlistx = [...state.brandList.list];
      const UpdateBrandList = OldBrandlistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
            is_active: action.payload.is_active,
          };
        } else return elm;
      });
      return {
        ...state,
        brandList: {
          list: UpdateBrandList,
        },
      };
    case ActionTypes.DELETE_BRAND:
      const OldBrandListz = [...state.brandList.list];
      const NewUpdatedBrandList = [];
      OldBrandListz &&
        OldBrandListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedBrandList.push(elm);
          }
        });
      return {
        ...state,
        brandList: {
          list: NewUpdatedBrandList,
        },
      };
    case ActionTypes.GET_BRAND_MODELS_LIST:
      return {
        ...state,
        brandmodelsList: {
          list:
            action.payload.data.data === undefined
              ? []
              : action.payload.data.data,
          pagination:
            action.payload.pagination === undefined
              ? []
              : action.payload.pagination,
        },
      };
    case ActionTypes.GET_MODEL_LIST:
      return {
        ...state,
        modelList: {
          list:
            Object.keys(action.payload).length === 0
              ? []
              : action.payload.data.data,
          pagination:
            Object.keys(action.payload).length === 0
              ? []
              : action.payload.data.pagination,
        },
      };
    case ActionTypes.ADD_NEW_MODEL:
      const OldModellist =
        state.modelList.list === undefined ? [] : [...state.modelList.list];
      OldModellist.unshift(action.payload.data);
      const OldSelectBrandWiseModel =
        state.selectedBrandWiseModel == undefined
          ? []
          : [...state.selectedBrandWiseModel];
      OldSelectBrandWiseModel.unshift(action.payload.data);
      return {
        ...state,
        modelList: {
          list: OldModellist,
        },
        selectedBrandWiseModel: OldSelectBrandWiseModel,
      };
    case ActionTypes.EDIT_MODEL:
      const OldModellistx = [...state.modelList.list];
      const UpdateModelList = OldModellistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
            brand_id: action.payload.brand_id,
            is_active: action.payload.is_active,
            added_by: action.payload.added_by,
          };
        } else return elm;
      });
      return {
        ...state,
        modelList: {
          list: UpdateModelList,
        },
      };
    case ActionTypes.DELETE_MODEL:
      const OldModelListz = [...state.modelList.list];
      const NewUpdatedModelList = [];
      OldModelListz &&
        OldModelListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedModelList.push(elm);
          }
        });
      return {
        ...state,
        modelList: {
          list: NewUpdatedModelList,
        },
      };
    case ActionTypes.GET_PERMISSION_LIST:
      return {
        ...state,
        permissionList: action.payload,
      };
    case ActionTypes.GET_ALL_SUPPLIERS_LIST:
      return {
        ...state,
        supplierListall:
          Object.keys(action.payload).length === 0
            ? []
            : action.payload?.data?.data,
      };
    case ActionTypes.GET_SUPPLIER_LIST:
      return {
        ...state,
        supplierList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.ADD_SUPPLIER_LIST:
      const OldSupplierlist = [...state.supplierList.list];
      OldSupplierlist.unshift(action.payload.data);
      const OldSupplierlistAll =
        state.supplierListall === undefined ? [] : [...state.supplierListall];
      OldSupplierlistAll.unshift(action.payload.data);
      return {
        ...state,
        supplierList: {
          list: OldSupplierlist,
        },
        supplierListall: OldSupplierlistAll,
      };
    case ActionTypes.UPDATE_SUPPLIER_LIST:
      const OldSupplierlistx = [...state.supplierList.list];
      const UpdateSupplierList = OldSupplierlistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            title: action.payload.title,
            website: action.payload.website,
            is_active: action.payload.is_active,
            name: action.payload.name,
            email: action.payload.email,
            phone_code: action.payload.phone_code,
            phone: action.payload.phone,
            company: action.payload.company,
            address: action.payload.address,
            vat_registration_no: action.payload.vat_registration_no,
            registration_no: action.payload.registration_no,
            contacts: action.payload.contacts,
          };
        } else return elm;
      });
      return {
        ...state,
        supplierList: {
          list: UpdateSupplierList,
        },
      };
    case ActionTypes.DELETE_SUPPLIER:
      const OldSupplierListz = [...state.supplierList.list];
      const NewUpdatedSupplierList = [];
      OldSupplierListz &&
        OldSupplierListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedSupplierList.push(elm);
          }
        });
      return {
        ...state,
        supplierList: {
          list: NewUpdatedSupplierList,
        },
      };
    case ActionTypes.GET_SUPPLIER_DETAILS:
      return {
        ...state,
        supplierdetails: action.payload,
      };
    case ActionTypes.GET_PRODUCT_LIST:
      return {
        ...state,
        vendorList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_EVENT_LIST:
      return {
        ...state,
        eventList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_OFFER_LIST:
      return {
        ...state,
        offerList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };

    case ActionTypes.GET_VENDOR_EVENT_LIST:
      console.log("GET_VENDOR_EVENT_LIST", action.payload);
      return {
        ...state,
        vendorEventList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_VENDOR_OFFER_LIST:
      return {
        ...state,
        vendorOfferList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };

    case ActionTypes.ADD_EVENT_LIST:
      const OldEventlist = [...state.eventList.list];
      OldEventlist.unshift(action.payload);
      return {
        ...state,
        eventList: {
          list: OldEventlist,
        },
      };
    case ActionTypes.ADD_OFFER_LIST:
      const OldOfferlist = [...state.offerList.list];
      OldOfferlist.unshift(action.payload);
      return {
        ...state,
        offerList: {
          list: OldOfferlist,
        },
      };

    case ActionTypes.ADD_VENDOR_EVENT_LIST:
      const OldVendorEventlist = [...state.vendorEventList.list];
      OldVendorEventlist.unshift(action.payload);
      return {
        ...state,
        vendorEventList: {
          list: OldVendorEventlist,
        },
      };
    case ActionTypes.ADD_VENDOR_OFFER_LIST:
      const OldVendorOfferlist = [...state.vendorOfferList.list];
      OldVendorOfferlist.unshift(action.payload);
      return {
        ...state,
        offerList: {
          list: OldVendorOfferlist,
        },
      };

    case ActionTypes.ADD_PRODUCT_LIST:
      const OldProductlist = [...state.supplierList.list];
      OldProductlist.unshift(action.payload);
      return {
        ...state,
        vendorList: {
          list: OldProductlist,
        },
      };
    case ActionTypes.UPDATE_PRODUCT_LIST:
      const OldProductlistx = [...state.vendorList.list];
      const UpdateProductList = OldProductlistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
          };
        } else return elm;
      });
      return {
        ...state,
        vendorList: {
          list: UpdateProductList,
        },
      };
    case ActionTypes.UPDATE_EVENT_LIST:
      const OldEventlistx = [...state.eventList.list];
      const UpdateEventList = OldEventlistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
          };
        } else return elm;
      });
      return {
        ...state,
        eventList: {
          list: UpdateEventList,
        },
      };
    case ActionTypes.UPDATE_OFFER_LIST:
      const OldOfferlistx = [...state.offerList.list];
      const UpdateOfferList = OldOfferlistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
          };
        } else return elm;
      });
      return {
        ...state,
        offerList: {
          list: UpdateOfferList,
        },
      };

    case ActionTypes.UPDATE_VENDOR_EVENT_LIST:
      const OldVendorEventlistx = [...state.vendorEventList.list];
      const UpdateVendorEventList = OldVendorEventlistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
          };
        } else return elm;
      });
      return {
        ...state,
        vendorEventList: {
          list: UpdateVendorEventList,
        },
      };
    case ActionTypes.UPDATE_VENDOR_OFFER_LIST:
      const OldVendorOfferlistx = [...state.offerList.list];
      const UpdateVendorOfferList = OldVendorOfferlistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
          };
        } else return elm;
      });
      return {
        ...state,
        offerList: {
          list: UpdateVendorOfferList,
        },
      };

    case ActionTypes.DELETE_PRODUCT:
      const OldProductListz = [...state.vendorList.list];
      const NewUpdatedProductList = [];
      OldProductListz &&
        OldProductListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedProductList.push(elm);
          }
        });
      return {
        ...state,
        vendorList: {
          list: NewUpdatedProductList,
        },
      };
    case ActionTypes.DELETE_EVENT:
      const OldEventListz = [...state.eventList.list];
      const NewUpdatedEventList = [];
      OldEventListz &&
        OldEventListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedEventList.push(elm);
          }
        });
      return {
        ...state,
        eventList: {
          list: NewUpdatedEventList,
        },
      };
    case ActionTypes.DELETE_OFFER:
      const OldOfferListz = [...state.offerList.list];
      const NewUpdatedOfferList = [];
      OldOfferListz &&
        OldOfferListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedOfferList.push(elm);
          }
        });
      return {
        ...state,
        offerList: {
          list: NewUpdatedOfferList,
        },
      };

    case ActionTypes.DELETE_VENDOR_EVENT:
      const OldVendorEventListz = [...state.vendorEventList.list];
      const NewUpdatedVendorEventList = [];
      OldVendorEventListz &&
        OldVendorEventListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedVendorEventList.push(elm);
          }
        });
      return {
        ...state,
        vendorEventList: {
          list: NewUpdatedVendorEventList,
        },
      };
    case ActionTypes.DELETE_VENDOR_OFFER:
      const OldVendorOfferListz = [...state.vendorOfferList.list];
      const NewUpdatedVendorOfferList = [];
      OldVendorOfferListz &&
        OldVendorOfferListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedVendorOfferList.push(elm);
          }
        });
      return {
        ...state,
        vendorOfferList: {
          list: NewUpdatedVendorOfferList,
        },
      };

    case ActionTypes.VIEW_PRODUCT_DETAILS:
      return {
        ...state,
        viewProduct: action.payload,
      };

    case ActionTypes.GET_SUPPORT_LIST:
      return {
        ...state,
        supportList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_VENDOR_SUPPORT_LIST:
      return {
        ...state,
        vendorSupportList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };

    case ActionTypes.APPROVAL_DATA_LIST:
      return {
        ...state,
        approvalDataList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };

    case ActionTypes.DASHBOARD_MOST_SUCCESS_OFFER_LIST:
      return {
        ...state,
        DashboardMostSuccessOfferList: {
          list: action.payload?.data === undefined ? [] : action.payload?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };

    case ActionTypes.DASHBOARD_MOST_SUCCESS_EVENT_LIST:
      return {
        ...state,
        DashboardMostSuccessEventList: {
          list: action.payload?.data === undefined ? [] : action.payload?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };

    case ActionTypes.DASHBOARD_REFERRAL_LIST:
      return {
        ...state,
        DashboardReferralList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
      case ActionTypes.DASHBOARD_MOST_LIKED_LIST:
        if (!action || !action.payload) {
          return state; // Return the current state if action or payload is undefined
        }
        return {
          ...state,
          DashboardMostLikedList: {
            list: action.payload.data || [],
            pagination: action.payload.data.pagination || [],
          },
        };
      

    case ActionTypes.DASHBOARD_COIN_EARNING_LIST:
      console.log("DASHBOARD_COIN_EARNING_LIST", action.payload);
      return {
        ...state,
        DashboardCoinEarningList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };

    case ActionTypes.DASHBOARD_MOST_WHISHLIST_LIST:
      console.log("DASHBOARD_MOST_WHISHLIST_LIST", action.payload);

      return {
        ...state,
        DashboardMostWhishList: {
          list: action.payload?.data === undefined ? [] : action.payload?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };

    case ActionTypes.GET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_SUB_CATEGORY_LIST:
      return {
        ...state,
        subcategoryList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_VENDOR_OFFER_RESPONSE_LIST:
      return {
        ...state,
        vendorOfferResponseList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_VENDOR_VIEW_GALLERY_LIST:
      console.log("GET_VENDOR_VIEW_GALLERY_LIST", action.payload);
      return {
        ...state,
        vendorViewGalleryList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_VENDOR_VIEW_REVIEW_LIST:
      return {
        ...state,
        vendorViewReviewList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_VENDOR_VIEW_TC_LIST:
      return {
        ...state,
        vendorViewTCList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_VENDOR_STORE_GALLERY_LIST:
      return {
        ...state,
        VendorStoreGallery: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_VENDOR_VIEW_OFFER_RESPONSE_LIST:
      console.log("GET_VENDOR_VIEW_OFFER_RESPONSE_LIST", action.payload?.data);
      return {
        ...state,
        ViewPagesResponseList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_VENDOR_VIEW_EVENT_RESPONSE_LIST:
      return {
        ...state,
        vendorViewEventResponseList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_VENDOR_EVENT_RESPONSE_LIST:
      return {
        ...state,
        vendorEventResponseList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_SETTING_COIN_EARNING_LIST:
      return {
        ...state,
        settingCoinEarningList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_SETTING_BADGE_LIST:
      console.log("GET_SETTING_BADGE_LIST", action?.payload);
      return {
        ...state,
        settingBadgeList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_SETTING_MCR_LIST:
      return {
        ...state,
        settingMCRList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_ALL_USER_CATEGORY_LIST:
      return {
        ...state,
        usercategoryList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_ALL_CATEGORY_LIST:
      return {
        ...state,
        categoryAllList:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };
      case ActionTypes.GET_ALL_DETAIL_CATEGORY_LIST:
        console.log("GET_ALL_DETAIL_CATEGORY_LIST",action.payload)
        return {
          ...state,
          detailcategoryAllList:
            Object.keys(action.payload).length === 0 ||
            action.payload.data.data === undefined
              ? []
              : action.payload.data.data,
        };
    case ActionTypes.GET_ALL_SUB_CATEGORY_LIST:
      return {
        ...state,
        subcategoryAllList:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };

    case ActionTypes.GET_ALL_VENDOR_OFFER_RESPONSE_LIST:
      return {
        ...state,
        vendorOfferResponseList:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };
    case ActionTypes.GET_ALL_VENDOR_EVENT_RESPONSE_LIST:
      return {
        ...state,
        vendorEventResponseList:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };
    case ActionTypes.SUCCESS_MODAL_DATA:
      console.log("SUCCESS_MODAL_DATA", action?.payload);
      return {
        ...state,
        successModalData: {
          show: action?.payload?.show,
          data: action?.payload?.data,
        },
      };
    case ActionTypes.PAYMENT_MODAL_DATA:
      console.log("PAYMENT_MODAL_DATA", action?.payload);
      return {
        ...state,
        paymentModalData: {
          show: action?.payload?.show,
          data: action?.payload?.data,
        },
      };
      case ActionTypes.CONFIRM_MODAL:
        console.log("CONFIRM_MODAL", action?.payload);
        return {
          ...state,
          confirmModal:action?.payload
        };

    case ActionTypes.ADD_CATEGORY_LIST:
      const OldCategorylist = [...state.categoryList.list];
      OldCategorylist.unshift(action.payload);
      return {
        ...state,
        categoryList: {
          list: OldCategorylist,
        },
      };
    case ActionTypes.ADD_SUB_CATEGORY_LIST:
      const OldSubCategorylist = [...state.categoryList.list];
      OldSubCategorylist.unshift(action.payload);
      return {
        ...state,
        subcategoryList: {
          list: OldSubCategorylist,
        },
      };
    case ActionTypes.ADD_SETTING_COIN_EARNING_LIST:
      const OldSettingCoinEarninglist = [...state.settingCoinEarningList.list];
      OldSettingCoinEarninglist.unshift(action.payload);
      return {
        ...state,
        settingCoinEarningList: {
          list: OldSettingCoinEarninglist,
        },
      };
    case ActionTypes.ADD_SETTING_BADGE_LIST:
      const OldSettingBadgelist = [...state.settingBadgeList.list];
      OldSettingBadgelist.unshift(action.payload);
      return {
        ...state,
        settingBadgeList: {
          list: OldSettingBadgelist,
        },
      };
    case ActionTypes.ADD_SETTING_MCR_LIST:
      const OldSettingMCRlist = [...state.settingMCRList.list];
      OldSettingMCRlist.unshift(action.payload);
      return {
        ...state,
        settingMCRList: {
          list: OldSettingMCRlist,
        },
      };
    case ActionTypes.UPDATE_CATEGORY_LIST:
      const OldCategorylistx = [...state.categoryList.list];
      const UpdateCategoryList = OldCategorylistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
            is_active: action.payload.is_active,
          };
        } else return elm;
      });
      return {
        ...state,
        categoryList: {
          list: UpdateCategoryList,
        },
      };
    case ActionTypes.UPDATE_SUB_CATEGORY_LIST:
      const OldSubCategorylistx = [...state.subcategoryList.list];
      const UpdateSubCategoryList = OldSubCategorylistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
            is_active: action.payload.is_active,
          };
        } else return elm;
      });
      return {
        ...state,
        subcategoryList: {
          list: UpdateSubCategoryList,
        },
      };
    case ActionTypes.UPDATE_SETTING_COIN_EARNING_LIST:
      const OldSettingCoinEarninglistx = [...state.settingCoinEarningList.list];
      const UpdateSettingCoinEarningList = OldSettingCoinEarninglistx.map(
        (elm) => {
          if (elm?.id === action.payload.id) {
            return {
              ...elm,
              name: action.payload.name,
              is_active: action.payload.is_active,
            };
          } else return elm;
        }
      );
      return {
        ...state,
        settingCoinEarningList: {
          list: UpdateSettingCoinEarningList,
        },
      };
    case ActionTypes.UPDATE_SETTING_BADGE_LIST:
      const OldSettingBadgelistx = [...state.settingBadgeList.list];
      const UpdateBadgeList = OldSettingBadgelistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
            is_active: action.payload.is_active,
          };
        } else return elm;
      });
      return {
        ...state,
        settingBadgeList: {
          list: UpdateBadgeList,
        },
      };
    case ActionTypes.UPDATE_SETTING_MCR_LIST:
      const OldSettingMCRlistx = [...state.settingMCRList.list];
      const UpdateSettingMCRList = OldSettingMCRlistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
            is_active: action.payload.is_active,
          };
        } else return elm;
      });
      return {
        ...state,
        settingMCRList: {
          list: UpdateSettingMCRList,
        },
      };
    case ActionTypes.DELETE_CATEGORY_LIST:
      const OldCategoryListz = [...state.vendorList.list];
      const NewUpdatedCategoryList = [];
      OldCategoryListz &&
        OldCategoryListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedCategoryList.push(elm);
          }
        });
      return {
        ...state,
        categoryList: {
          list: NewUpdatedCategoryList,
        },
      };
    case ActionTypes.DELETE_SUB_CATEGORY_LIST:
      const OldSubCategoryListz = [...state.vendorList.list];
      const NewUpdatedSubCategoryList = [];
      OldSubCategoryListz &&
        OldSubCategoryListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedSubCategoryList.push(elm);
          }
        });
      return {
        ...state,
        subcategoryList: {
          list: NewUpdatedSubCategoryList,
        },
      };
    case ActionTypes.DELETE_SETTING_COIN_EARNING_LIST:
      const OldSettingCoinEarningListz = [...state.settingCoinEarningList.list];
      const NewUpdatedSettingCoinEarningList = [];
      OldSettingCoinEarningListz &&
        OldSettingCoinEarningListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedSettingCoinEarningList.push(elm);
          }
        });
      return {
        ...state,
        settingCoinEarningList: {
          list: NewUpdatedSettingCoinEarningList,
        },
      };
    case ActionTypes.DELETE_SETTING_BADGE_LIST:
      const OldSettingBadgeListz = [...state.settingBadgeList.list];
      const NewUpdatedSettingBadgeList = [];
      OldSettingBadgeListz &&
        OldSettingBadgeListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedSettingBadgeList.push(elm);
          }
        });
      return {
        ...state,
        settingBadgeList: {
          list: NewUpdatedSettingBadgeList,
        },
      };
    case ActionTypes.DELETE_SETTING_MCR_LIST:
      const OldSettingMCRListz = [...state.settingMCRList.list];
      const NewUpdatedSettingMCRList = [];
      OldSettingMCRListz &&
        OldSettingMCRListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedSettingMCRList.push(elm);
          }
        });
      return {
        ...state,
        settingMCRList: {
          list: NewUpdatedSettingMCRList,
        },
      };
    case ActionTypes.GET_SETTINGS_LIST:
      const ObjectSettingsListId = {};
      action.payload?.data?.data.map((elm) => {
        ObjectSettingsListId[elm?.slug] = elm;
      });

      return {
        ...state,
        settinglists: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
        settingsObjectIdWise: ObjectSettingsListId,
      };
    case ActionTypes.ADD_SETTINGS_LIST:
      const OldSettinglist = [...state.settinglists.list];
      OldSettinglist.unshift(action.payload);
      return {
        ...state,
        settinglists: {
          list: OldSettinglist,
        },
      };
    case ActionTypes.UPDATE_CATEGORY_LIST:
      const OldSettinglistx = [...state.settinglists.list];
      const UpdateSettingList = OldSettinglistx.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
          };
        } else return elm;
      });
      return {
        ...state,
        settinglists: {
          list: UpdateSettingList,
        },
      };
    case ActionTypes.DELETE_SETTINGS_LIST:
      const OldsettinglistsListz = [...state.settinglists.list];
      const NewUpdatedsettinglistsList = [];
      OldsettinglistsListz &&
        OldsettinglistsListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedsettinglistsList.push(elm);
          }
        });
      return {
        ...state,
        settinglists: {
          list: NewUpdatedsettinglistsList,
        },
      };
    case ActionTypes.ADD_TO_CART:
      const OldAddToCartlist = [...state.addToCartLists];
      OldAddToCartlist.unshift(action.payload);
      return {
        ...state,
        addToCartLists: OldAddToCartlist,
      };
    case ActionTypes.ADD_CART_MULTIPLE_QUANTITY:
      const OldAddToCartlists = [...state.addToCartLists];
      const UpdateProdCratlist = OldAddToCartlists.map((elm) => {
        if (elm?.id === action?.payload?.product.id) {
          const AmountAdd =
            parseFloat(action?.payload?.product.data.unit_cost_price) +
            parseFloat(action?.payload?.mainCost);
          return {
            ...elm,
            data: {
              ...elm.data,
              quantity:
                parseInt(action?.payload?.product.data.quantity) +
                parseInt(action?.payload?.mainQuantity),
              unit_cost_price: AmountAdd.toFixed(1),
            },
          };
        } else return elm;
      });
      return {
        ...state,
        addToCartLists: UpdateProdCratlist,
      };
    case ActionTypes.REMOVE_CART_MULTIPLE_QUANTITY:
      const OldAddToCartlistsZ = [...state.addToCartLists];
      const UpdateProdCratlistS = OldAddToCartlistsZ.map((elm) => {
        if (elm?.id === action?.payload?.product.id) {
          const AmountRemove =
            parseFloat(action?.payload?.product.data.unit_cost_price) -
            parseFloat(action?.payload?.mainCost);
          return {
            ...elm,
            data: {
              ...elm.data,
              quantity:
                parseInt(action?.payload?.product.data.quantity) -
                parseInt(action?.payload?.mainQuantity),
              unit_cost_price: AmountRemove.toFixed(1),
            },
          };
        } else return elm;
      });
      return {
        ...state,
        addToCartLists: UpdateProdCratlistS,
      };
    case ActionTypes.REMOVE_ADD_TO_CART:
      const OldCartListz = [...state.addToCartLists];
      const NewUpdatedCartList = [];
      OldCartListz &&
        OldCartListz?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewUpdatedCartList.push(elm);
          }
        });
      return {
        ...state,
        addToCartLists: NewUpdatedCartList,
      };
    case ActionTypes.ADMIN_LOGOUT:
      return {
        ...initailData,
      };
    case ActionTypes.GET_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_PAYMENT_LIST:
      return {
        ...state,
        paymentList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_VENDOR_PAYMENT_LIST:
      console.log("vendorPaymentList", action.payload);
      return {
        ...state,
        vendorPaymentList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_CT_LIST:
      return {
        ...state,
        vendorCTList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.GET_ALL_FAQ_LIST:
      return {
        ...state,
        faqListAll:
          Object.keys(action.payload).length === 0 ||
          action.payload.data.data === undefined
            ? []
            : action.payload.data.data,
      };
    case ActionTypes.GET_FAQ_LIST:
      return {
        ...state,
        faqList: {
          list:
            action.payload?.data?.data === undefined
              ? []
              : action.payload?.data?.data,
          pagination:
            action.payload.data?.pagination === undefined
              ? []
              : action.payload.data?.pagination,
        },
      };
    case ActionTypes.ADD_FAQ_LIST:
      const OldFAQlist = [...state.faqList.list];
      OldFAQlist.unshift(action.payload);
      return {
        ...state,
        documentList: {
          list: OldFAQlist,
        },
      };
    case ActionTypes.UPDATE_FAQ_LIST:
      const oldFAQ = [...state.faqList.list];
      const UpdatedFAQ = oldFAQ.map((elm) => {
        if (elm?.id === action.payload.id) {
          return {
            ...elm,
            name: action.payload.name,
          };
        } else return elm;
      });
      return {
        ...state,
        documentList: {
          list: UpdatedFAQ,
        },
      };
    case ActionTypes.DELETE_FAQ:
      const oldFAQBfore = [...state.faqList.list];
      const NewFaqFiltereddelete = [];
      oldFAQBfore &&
        oldFAQBfore?.filter((elm) => {
          if (elm?.id !== action.payload.id) {
            return NewFaqFiltereddelete.push(elm);
          }
        });
      return {
        ...state,
        faqList: {
          list: NewFaqFiltereddelete,
        },
      };
    default:
      return state;
  }
};
