/* eslint-disable */
import React, { useState, useContext, useEffect, useRef } from "react";
import {
  API_URL,
  GOOGLE_MAP_API_KEY,
  VENDR_EVENT_MANAGEMENT_URL,
  ageOptions,
  bytesToKB,
  countPlainTextOccurrences,
  customComponents,
  dubaiStatesOptions,
  extractIdFromUrl,
  formatDates,
  getStateWiseAmount,
  isEndDateTimeGreaterThanStartDateTime,
  isVideoUrl,
  maxDescLength,
  maxSize,
  minDescLength,
  parseDateTime,
  vendorCountryOptions,
} from "../Shared/constant";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import UploadFileAPI, { DeleteFileAPI } from "../../Api/UploadFileAPI";
import CommonModel from "../Common/CommonModel";
import AvatarGet from "../Common/AvatarGet";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import CommonLoader from "../Common/CommonLoader";
import "react-toastify/dist/ReactToastify.css";
import {
  LoaderStartMain,
  getBrandsWiseModelList,
  setConfirmModal,
  setShowSuccessModal,
} from "../../redux/actions/adminActions";
import { Tab, Tabs } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import "react-clock/dist/Clock.css";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import uploadIcon from "../../assets/icons/uploadIcon.svg";
import closeIcon from "../../assets/img/closeIcon.png";
import menIcon from "../../assets/img/menIcon.png";
import womenIcon from "../../assets/img/womenIcon.png";
import InputRange from "react-input-range";
import TimePicker from "../Common/TimePicker/TimePicker.jsx";
import { AddThumbnailModal } from "../VendorMangement/AddThumbnailModal.jsx";
import MediaViewer from "../Common/MediaViewer.jsx";
import { GetRequestCall } from "../Common/GetRequestCall.jsx";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";

const VendorAddEditEvent = () => {
  const {
    viewProduct,
    accessToken,
    device_id,
    loaderMain,
    ClearFormSet,
    selfInfo,
    paymentModalData,
    detailcategoryAllList,
    subcategoryList,
    liveMapUserList,
  } = useSelector((state) => state.adminReducers);
  console.log("detailcategoryAllList", detailcategoryAllList, subcategoryList);
  const Navigate = useNavigate();
  const Dispatch = useDispatch();
  const [key, setKey] = useState("info");
  const [infoFormFill, setinfoFormFill] = useState(false);
  const params = useParams();
  const Location = useLocation()?.pathname?.split("/")[1];
  const { websocket } = useContext(WebSocketContext);
  const [inputValue, setInputValue] = useState({
    event_title: "",
    card_holder_name: "",
    card_number: "",
    CVV: "",
    address: "",
  });
  const { event_title, card_holder_name, card_number, CVV } = inputValue;

  const [selectedDate, setSelectedDate] = useState(null);
  const [errorcard_holder_name, setErrorcard_holder_name] = useState("");
  const [errorcard_number, setErrorcard_number] = useState("");
  const [errorCVV, setErrorCVV] = useState("");
  console.log("selectedDate", selectedDate);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedDubaiState, setSelectedDubaiState] = useState({
    value: Location === "vendor-edit-event" ? viewProduct?.state : "",
    label: Location === "vendor-edit-event" ? viewProduct?.state : "",
  });

  useEffect(() => {
    if (inputValue?.state) {
      setSelectedDubaiState({
        value: inputValue?.state_code,
        label: inputValue?.state,
      });
    }
  }, [inputValue?.state]);

  console.log("selectedOption", selectedOption);
  const [coinLimit, setCoinLimit] = useState({
    percentage: "",
    ratio: {
      dma_coins: "",
      value: "",
    },
  });
  const TotalUser = parseFloat(
    paymentModalData?.data?.response?.data?.user_count ||
      liveMapUserList?.list?.counts?.total_users
  );
  const TotalAmount =
    getStateWiseAmount(
      selectedDubaiState?.value || inputValue?.state_code || inputValue?.state
    ) || parseFloat(TotalUser * 10);
  const coinLimitValue = Math.min(
    selfInfo?.coins ? selfInfo?.coins : 0,
    TotalAmount / (parseFloat(coinLimit?.percentage) / 10)
  );

  // Calculate one coin value
  const oneCoinValue =
    parseFloat(coinLimit?.ratio?.value) /
    parseFloat(coinLimit?.ratio?.dma_coins);

  // Calculate RedeemTotal
  const RedeemTotal = parseFloat(
    parseFloat(selectedOption?.value || 0) * oneCoinValue
  );

  // Calculate GrandTotal
  const GrandTotal = parseFloat(TotalAmount - RedeemTotal);
  console.log(
    "TotalAmount",
    TotalAmount,
    "coinLimitValue",
    coinLimitValue,
    "oneCoinValue",
    oneCoinValue,
    "RedeemTotal",
    RedeemTotal,
    "GrandTotal",
    GrandTotal,
    "coinLimit",
    coinLimit
  );
  console.log("paymentModalData", paymentModalData);
  const options = Array.from({ length: coinLimitValue }, (_, index) => ({
    value: index + 1,
    label: `${index + 1}`,
  }));
  const coinHandler = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  console.log(
    " paymentModalData?.data?.response?.data?.type,",
    paymentModalData?.data?.response?.data?.id
  );
  const handleClose = () => {
    if (!liveMapUserList?.list?.counts?.total_users) {
      toast.error("Please apply offer filter");
      return;
    }
    let param = {
      transmit: "single",
      url: "vendor_payment",
      request: {
        action: "approve",
        particular_id: paymentModalData?.data?.response?.data?.id,
        particular_type: paymentModalData?.data?.type,
        total_amount: GrandTotal,
      },
    };
    console.log("param", param);
    wsSend_request(websocket, param);
    Dispatch(
      setShowSuccessModal({
        type: "",
        response: "",
        show: false,
      })
    );
    Navigate(VENDR_EVENT_MANAGEMENT_URL);
  };
  const percentageHandler = async () => {
    try {
      const url = `${API_URL}admin/setting/percentage-redemption/null/V2U/view`;
      const response = await GetRequestCall(url, {}, accessToken);

      // Do something with the response, for example, log it
      console.log("response", response, "url", url);
      if (response?.data) {
        setCoinLimit(response?.data);
      }

      // Handle the response as needed
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      // Handle the error as needed
    }
  };
  useEffect(() => {
    percentageHandler();
  }, []);

  const [editorState, setQueEditorState] = useState(EditorState.createEmpty());
  const [StoreDesc, setStoreDesc] = useState("");

  const [Category, setCategory] = useState("");

  const [UploadFileLoader, setUploadFileLoader] = useState(false);
  const [AvatarImage, setAvatarImage] = useState("");
  const [AvatarImageId, setAvatarImageId] = useState("");
  console.log("AvatarImage", AvatarImage, "AvatarImageId", AvatarImageId);
  const [showModal, setShowModal] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {},
  });
  const [selectedLocation, setSelectedLocation] = useState({
    lat: "",
    lng: "",
  });

  const [fileData, setFileData] = useState([]);
  console.log("fileData", fileData);
  const handleDubaiStateChange = (selectedOption) => {
    setSelectedDubaiState(selectedOption);
  };
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const categoryOptions = detailcategoryAllList
    ?.filter((elm) => elm?.is_active === true)
    ?.map((elm) => ({
      value: elm?.id,
      label: elm?.name,
      sub_categories: elm?.sub_categories,
    }));
  const subcategoryOptions = subcategoryList?.list
    .filter((elm) => elm?.is_active === true)
    .map((elm) => ({
      value: elm?.id,
      label: elm?.name,
      sub_categories: elm?.sub_categories,
    }));

  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
    const tagsToAdd = [];
    selectedOptions.forEach((option) => {
      if (option.sub_categories && option.sub_categories.length) {
        option.sub_categories.forEach((subCategory) => {
          tagsToAdd.push({
            label: subCategory.name,
            value: subCategory.id,
          });
        });
      }
    });
    setSelectedTags((prev) => {
      // Use a Set to store unique tag values
      const tagSet = new Set(prev.map((tag) => tag.value));
      // Iterate over the tagsToAdd array and add each tag to the newTags array if it's not already present
      tagsToAdd.forEach((tag) => {
        if (!tagSet.has(tag.value)) {
          prev.push(tag);
          tagSet.add(tag.value);
        }
      });
      // Return the new state array
      return prev;
    });
  };
  const commaSeparatedCategories = selectedCategories
    .map((category) => category.value)
    .join(",");

  const handleTagsChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };
  const commaSeparatedTags = selectedTags.map((Tags) => Tags.value).join(",");

  // error field business_contact_number
  const [errorevent_title, setErrorevent_title] = useState("");
  const [errorStartTimeAndDate, seterrorStartTimeAndDate] = useState("");
  const [errorEndTimeAndDate, seterrorEndTimeAndDate] = useState("");
  const [errorAvatarImage, seterrorAvatarImage] = useState("");
  const [errorowner_email_id, setErrorowner_email_id] = useState("");
  const [errorEventAddress, setErrorEventAddress] = useState("");
  const [errorEventStart, setErrorEventStart] = useState("");
  const [errorEventEnd, setErrorEventEnd] = useState("");
  const [errorState, setErrorState] = useState("");
  console.log("errorState", errorState);
  const [errorCategory, seterrorCategory] = useState("");
  const [errorMedia, setErrorMedia] = useState("");
  const [errorStoreDesc, setErrorStoreDesc] = useState("");
  const [charCount, setCharCount] = useState(0);
  console.log("errorStoreDesc", errorStoreDesc);
  // get product details
  useEffect(() => {
    if (Location === "vendor-edit-event") {
      if (params?.roomId) {
        let param = {
          transmit: "single",
          url: "event_get",
          request: {
            event_id: params?.roomId,
            user_type: selfInfo?.user_type,
          },
        };
        wsSend_request(websocket, param);
      }
    }
  }, [params?.roomId, Location, websocket]);

  // get product list
  useEffect(() => {
    let param = {
      transmit: "single",
      url: "category_detailed_listing",
      request: {
        limit: 5000,
        page: 1,
      },
    };
    wsSend_request(websocket, param);
  }, [websocket]);
  useEffect(() => {
    let param = {
      transmit: "single",
      url: "subcategory_listing",
      request: {
        filter: null,
        limit: 5000,
        page: 1,
        search: null,
        user_type: selfInfo?.user_type || "admin",
      },
    };
    wsSend_request(websocket, param);
  }, [websocket]);
  const [showVideoModal, setShowVideoModal] = useState({
    show: false,
    to: "",
    id: "",
    parent_id: "",
  });
  useEffect(() => {
    if (Location === "vendor-edit-event" && viewProduct) {
      setInputValue({
        ...inputValue,
        event_title: viewProduct?.event_title,
        country: viewProduct?.country,
        city: viewProduct?.city,

        address: viewProduct?.address,
        location: viewProduct?.location,
        state_code: viewProduct?.state,
        state: viewProduct?.state,
      });

      setSelectedLocation({
        lat: viewProduct?.latitude,
        lng: viewProduct?.longitude,
      });
      if (viewProduct?.event_users) {
        const [startAge, endAge] = viewProduct?.event_users.age_range
          .split("-")
          .map((age) => parseInt(age));
        setStartAge({
          value: startAge,
          label: `${startAge} - Age`,
        });
        setEndAge({
          value: endAge,
          label: `${endAge} - Age`,
        });
      }
      if (viewProduct?.event_users?.gender?.includes("Male")) {
        setMenSelected(true);
      }
      if (viewProduct?.event_users?.gender?.includes("Female")) {
        setWomenSelected(true);
      }
      if (viewProduct?.event_description) {
        setErrorStoreDesc("");
        const defaultStoreDescriptionContent = viewProduct.event_description;
        const storeDescriptionContentState = ContentState.createFromBlockArray(
          convertFromHTML(defaultStoreDescriptionContent)
        );
        const storeDescriptionEditorState = EditorState.createWithContent(
          storeDescriptionContentState
        );
        setQueEditorState(storeDescriptionEditorState);
        const characterCount = countPlainTextOccurrences(
          viewProduct?.event_description
        );
        setCharCount(characterCount);
      }

      if (viewProduct && viewProduct?.interest) {
        // Split the categories string into an array
        const productCategories = viewProduct?.interest.split(",");

        // Filter categoryList to find objects whose name matches the product categories
        const filteredCategories = detailcategoryAllList?.filter((category) =>
          productCategories.includes(category.name)
        );

        // Map the filtered categories to the required structure and update the state
        const mappedCategories = filteredCategories.map((category) => ({
          value: category.id,
          label: category.name,
        }));

        setSelectedCategories(mappedCategories);
      }

      console.log("viewProduct", viewProduct);
      if (viewProduct?.files?.length) {
        const updatedFileData = [];

        viewProduct.files.forEach((item) => {
          if (item?.url?.file_url) {
            updatedFileData.push(item);
          }
        });

        setFileData(updatedFileData);
        setAvatarImage(updatedFileData[0]);
      } else {
        setAvatarImage("");
        setFileData([]);
      }

      if (viewProduct?.event_start_time) {
        const { date, time } = parseDateTime(viewProduct?.event_start_time);
        setStartDate(date);
        setStartTime(time);
      }
      if (viewProduct?.event_end_time) {
        const { date, time } = parseDateTime(viewProduct?.event_end_time);
        setEndDate(date);
        setEndTime(time);
      }
    }
  }, [viewProduct, websocket]);
  const onEditorChange = (newEditorState) => {
    // if (charCount < maxDescLength) {
    setQueEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const plainText = contentState.getPlainText("");
    const characterCount = plainText.length;
    setCharCount(characterCount);
    const contentAsRaw = convertToRaw(contentState);
    const htmlContent = draftToHtml(contentAsRaw);
    const styledHtmlContent = `<div style="font-family: Manrope;">${htmlContent}</div>`;

    setStoreDesc(styledHtmlContent);

    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      question: styledHtmlContent,
    }));
    // }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const UploadImage = async (e) => {
    Dispatch(LoaderStartMain(true));

    console.log("UploadImage called");
    setUploadFileLoader(true);
    const files = e.target.files[0];
    if (files && files.size > maxSize) {
      toast.error("File size exceeds 30MB limit.");
      e.target.value = null; // Clear the input field
      Dispatch(LoaderStartMain(false));

      return;
    }
    const formData = new FormData();
    formData.append("avatar", files, files.name);

    formData?.append("to", selfInfo?.id);
    formData.append("is_admin", selfInfo?.user_type === "admin" ? "1" : "0");
    const fileresponse = await UploadFileAPI(accessToken, formData);
    console.log("UploadImage fileresponse", fileresponse, "formData", formData);
    if (fileresponse) {
      setFileData((prevFileData) => [...prevFileData, fileresponse[0]]);
      setFileData((prevFileData) =>
        prevFileData.map((file, index) => ({ ...file, sequence: index + 1 }))
      );
      if (files.type.startsWith("image")) {
        // Handle image upload
        console.log("Selected file is an image:", files);
        // Your image upload logic here
      }
      // Check if the selected file is a video
      else if (files.type.startsWith("video")) {
        // Handle video upload
        console.log("Selected file is a video:", files);
        setShowVideoModal((prev) => ({
          ...prev,
          show: true,
          to: formData?.to,
          id: params?.roomId || inputValue?.owner_email_id,
          parent_id: fileresponse[0]?.id,
        }));
      }
    }
    Dispatch(LoaderStartMain(false));
  };

  const handleFileChange = (e) => {
    const fileInput = fileInputRef.current;
    UploadImage(e);
  };

  const checkIsValid = () => {
    if (
      inputValue?.event_title &&
      startDate &&
      startTime &&
      endDate &&
      endDate &&
      inputValue?.address &&
      errorEventAddress === "" &&
      selectedDubaiState?.value &&
      errorState === "" &&
      charCount > minDescLength &&
      charCount < maxDescLength &&
      fileData?.length > 0 &&
      errorEventEnd === "" &&
      isEndDateTimeGreaterThanStartDateTime(
        startDate,
        startTime,
        endDate,
        endTime
      )
    ) {
      return true;
    } else {
      if (!selectedDubaiState?.value) {
        setErrorState("Please select state");
      } else {
        setErrorState("");
      }
      if (!endDate || !endTime) {
        setErrorEventEnd("Please select event end date and time");
      } else {
        setErrorEventEnd("");
      }
      if (!startDate || !startTime) {
        console.log("Please select event start date and time");
        setErrorEventStart("Please select event start date and time");
      } else {
        setErrorEventStart("");
      }
      CheckValid(inputValue?.address, {
        type: "Address",
        error: setErrorEventAddress,
      });
      if (selectedCategories?.length == 0) {
        seterrorCategory("Please select category");
      } else {
        seterrorCategory("");
      }
      if (fileData?.length == 0) {
        setErrorMedia("Please upload event media");
      } else {
        setErrorMedia("");
      }
      CheckValid(inputValue?.event_title, {
        type: "Title",
        error: setErrorevent_title,
      });
      if (charCount < minDescLength) {
        setErrorStoreDesc(
          `Please enter at least ${minDescLength} character description`
        );
      } else {
        setErrorStoreDesc("");
      }
      if (charCount > maxDescLength && charCount > minDescLength) {
        setErrorStoreDesc(
          `Please enter maximum ${maxDescLength} character description`
        );
      }
      CheckValid(startDate, {
        type: "startDate",
        error: seterrorStartTimeAndDate,
      });
      CheckValid(endDate, {
        type: "endDate",
        error: seterrorEndTimeAndDate,
      });
      CheckValid(startTime, {
        type: "startDate",
        error: seterrorStartTimeAndDate,
      });
      CheckValid(endTime, {
        type: "endDate",
        error: seterrorEndTimeAndDate,
      });
      CheckValid(AvatarImage, {
        type: "Avatar",
        error: seterrorAvatarImage,
      });
      if (
        startDate &&
        endDate &&
        startDate?.getTime() === endDate?.getTime() &&
        startTime >= endTime
      ) {
        setErrorEventEnd(
          "Please select event end time greater than start time"
        );
      } else if (!startDate || !endDate || !startTime || !endTime) {
        // Handle the case where any of the time values are missing
        setErrorEventEnd("Please enter all required time values for the event");
      }
      if (
        !isEndDateTimeGreaterThanStartDateTime(
          startDate,
          startTime,
          endDate,
          endTime
        )
      ) {
        setErrorEventEnd(
          "Please select an offer end time greater than the start time"
        );
      }

      return false;
    }
  };
  const handleFormClick = () => {
    // fileInputRef.current.click();
  };
  const removeMediaAPIHandler = async (nameToRemove) => {
    Dispatch(LoaderStartMain(true));
    const response = await DeleteFileAPI(
      accessToken,
      extractIdFromUrl(nameToRemove)
    );
    console.log("response", response);
    if (response?.status === 200) {
      setFileData((prevFileData) =>
        prevFileData.filter((item) => item?.name !== nameToRemove)
      );
      toast.success(response?.file);
    } else {
      toast.error(response?.file || response?.msg);
    }
    Dispatch(LoaderStartMain(false));
  };
  // remove image uploaded
  const handleRemoveImage = async (nameToRemove) => {
    Dispatch(
      setConfirmModal({
        show: "MEDIA",
        callBack: () => removeMediaAPIHandler(nameToRemove),
      })
    );
  };
  const [showPaymentUI, setShowPaymentUI] = useState(false);
  const createEvent = () => {
    if (
      !liveMapUserList?.list?.counts?.total_users &&
      Location !== "vendor-edit-event"
    ) {
      toast.error("Please apply event filter");
      return;
    }
    setSubmitClick(true);
    setShowPaymentUI(true);

    const isValid = checkIsValid();
    console.log("isValid", isValid);
    if (isValid) {
      if (Location === "vendor-edit-event") {
        let param;

        param = {
          transmit: "single",
          url: "event_update",
          request: {
            event_title: inputValue?.event_title,
            event_start_time: formatDates(startDate, startTime),
            event_end_time: formatDates(endDate, endTime),
            interest: selectedCategories?.map((item) => item.value).join(","),
            event_description: StoreDesc || viewProduct?.event_description,

            files: fileData?.map(({ sequence, name }) => ({
              sequence,
              name,
            })),
            location: `${selectedLocation?.lat},${selectedLocation?.lng}`,
            state: selectedDubaiState?.value || inputValue?.state_code,
            country: inputValue?.country,
            address: inputValue?.address,
            user_type: selfInfo?.user_type,
            event_id: params?.roomId,
          },
        };

        wsSend_request(websocket, param);
        Navigate(VENDR_EVENT_MANAGEMENT_URL);
      } else {
        let paramRequest;

        paramRequest = {
          created_by: selfInfo?.user_type,
          vendor_code: selfInfo?.vendor_code,
          vendor_id: selfInfo?.id,
          event_title: inputValue?.event_title,
          event_start_time: formatDates(startDate, startTime),
          event_end_time: formatDates(endDate, endTime),
          interest: selectedCategories?.map((item) => item.value).join(","),
          event_description: StoreDesc || viewProduct?.event_description,
          event_users: {
            age_range: `${startAge?.value}-${endAge?.value}`,
            gender:
              menSelected && womenSelected
                ? "men,women"
                : menSelected
                ? "men"
                : womenSelected
                ? "women"
                : "",
          },
          files: fileData?.map(({ sequence, name }) => ({ sequence, name })),
          event_mode: "payment",
          location: `${selectedLocation?.lat},${selectedLocation?.lng}`,
          state: inputValue?.state_code || selectedDubaiState?.value,
          country: inputValue?.country,
          address: inputValue?.address,
          user_type: selfInfo?.user_type,
          // user_count: liveMapUserList?.list?.counts?.total_users,
        };

        const paramfilter = Object.entries(paramRequest).filter((elm) => {
          if (elm[1] !== "") {
            return elm;
          }
        });
        console.log("paramfilter", paramfilter, "paramRequest", paramRequest);
        let param = {
          transmit: "single",
          url: "event_add",
          request: Object.fromEntries(paramfilter),
        };
        wsSend_request(websocket, param);
        // Navigate(VENDR_EVENT_MANAGEMENT_URL);
      }
    }
  };
  const resetAllFilledData = () => {
    setInputValue({
      event_title: "",
      owner_contact_person: "",
      business_email_id: "",
      owner_email_id: "",
      owner_contact_number: "",
      business_contact_number: "",
      business_contact_person: "",
      address: "",
      location: "",
    });
    setAvatarImage("");
    setAvatarImageId("");
    setFileData([]);
    setStoreData({
      Monday: {
        Monday_Active: false,
        open_time: "",
        close_time: "",
      },
      Tuesday: {
        Tuesday_Active: false,
        open_time: "",
        close_time: "",
      },
      Wednesday: {
        Wednesday_Active: false,
        open_time: "",
        close_time: "",
      },
      Thursday: {
        Thursday_Active: false,
        open_time: "",
        close_time: "",
      },
      Friday: {
        Friday_Active: false,
        open_time: "",
        close_time: "",
      },
      Saturday: {
        Saturday_Active: false,
        open_time: "",
        close_time: "",
      },
      Sunday: {
        Sunday_Active: false,
        open_time: "",
        close_time: "",
      },
    });
    setSelectedDubaiState(null);
    setErrorCountry(null);
    setKey("info");
  };
  const AddCategoryFnct = () => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Add Category",
      subtitle: "Add Category",
      description: ``,
      modalType: "form",
      button: "Yes",
    });
  };
  // add new category
  const AddSubCategoryFnct = () => {
    setShowModal({
      ...showModal,
      open: !showModal?.open,
      title: "Add Sub Category",
      subtitle: "Add Sub Category",
      description: ``,
      modalType: "form",
      button: "Save",
    });
  };
  const removeCategory = (categoryToRemove) => {
    console.log("categoryToRemove", categoryToRemove);
    const updatedCategories = selectedCategories.filter(
      (category) => category.value !== categoryToRemove.value
    );
    setSelectedCategories(updatedCategories);

    // Check if categoryToRemove has sub_categories
    if (categoryToRemove?.sub_categories) {
      // Create a Set of sub_category ids for efficient lookup
      const subCategoryIds = new Set(
        categoryToRemove.sub_categories.map((subCat) => subCat.id)
      );
      // Remove tags whose id matches with any of the sub_category ids
      setSelectedTags((prevTags) =>
        prevTags.filter((tag) => !subCategoryIds.has(tag.value))
      );
    }
  };
  const removeTags = (TagsToRemove) => {
    const updatedTags = selectedTags.filter(
      (Tags) => Tags.value !== TagsToRemove.value
    );
    setSelectedTags(updatedTags);
  };
  const BaicInfoHandler = (key) => {
    if (checkIsValid()) {
      setKey(key);
      setinfoFormFill(true);
    } else {
      console.log("check valid function goes here");
      setinfoFormFill(false);
    }
  };

  const fileInputRef = useRef(null);

  const [error, setError] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handleStartTimeChange = (time) => {
    console.log("handleStartTimeChange", time);
    if (time && time.hour && time.minute) {
      // Construct the time string from hour and minute
      const timeString = `${time.hour}:${time.minute}`;
      setStartTime(timeString);
    }
  };

  const handleEndTimeChange = (time) => {
    if (time && time.hour && time.minute) {
      // Construct the time string from hour and minute
      const timeString = `${time.hour}:${time.minute}`;
      setEndTime(timeString);
    }
  };
  const [menSelected, setMenSelected] = useState(false);
  const [womenSelected, setWomenSelected] = useState(false);
  const [rangeValue, setRangeValue] = useState(10); // Initial value
  const formatLabel = (value) => `${value} km`;
  const [startAge, setStartAge] = useState(null);
  const [endAge, setEndAge] = useState(null);
  console.log("startAge", startAge, "endAge", endAge);
  const handleStartAgeChange = (selectedOption) => {
    setStartAge(selectedOption);
  };
  const [showSubmit, setShowSubmit] = useState(false);
  const [showEventForm, setShowEventForm] = useState(false);

  const handleEndAgeChange = (selectedOption) => {
    setEndAge(selectedOption);
  };
  const ApplyFilterHandler = () => {
    if ((startAge && !endAge) || (!startAge && endAge)) {
      toast.error("Please select valid ages");
      return;
    }
    if (
      startAge?.value !== undefined &&
      endAge?.value !== undefined &&
      (endAge.value <= 0 || startAge.value > endAge.value)
    ) {
      toast.error(
        "Start age cannot be greater than end age, and end age must be greater than 0"
      );
      return;
    }

    if (selectedCategories?.length == 0) {
      seterrorCategory("Please select category");
      return;
    } else {
      seterrorCategory("");
    }
    if (selfInfo?.id) {
      setShowSubmit(true);
      let paramRequest = {
        limit: 5000,
        page: 1,
        distance: selfInfo?.id ? (rangeValue == "0" ? "" : rangeValue) : "",
        gender:
          menSelected && womenSelected
            ? ""
            : menSelected
            ? "Male"
            : womenSelected
            ? "Female"
            : "",
        interest: selectedCategories?.map((item) => item.value).join(","),
        age_range:
          startAge?.value > 0 && endAge?.value > 0
            ? `${startAge?.value}-${endAge?.value}`
            : "",
        state: selfInfo?.state,
        vendor_id: selfInfo?.id,
      };
      const paramfilter = Object.entries(paramRequest).filter((elm) => {
        if (elm[1] !== "") {
          return elm;
        }
      });
      let param = {
        transmit: "single",
        url: "user_live_map",
        request: Object.fromEntries(paramfilter),
      };
      console.log("param", param);
      wsSend_request(websocket, param);
      toast.success("Filter applied");
    } else {
      toast.error("Please select specified vendor or state");
    }
  };

  let inputRef = useRef();
  const libraries = ["places"];
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: libraries,
  });

  console.log("selectedLocation", selectedLocation, "inputValue", inputValue);
  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      console.log("handlePlaceChanged", place);

      // Extracting desired values from the place object
      const address = place.formatted_address;
      const addressComponents = place.address_components || [];
      let city = "";
      let postalCode = "";
      let state = "";
      let stateCode = "";
      let country = "";
      let countryCode = "";

      // Extracting city, postal code, state, country from address components
      addressComponents.forEach((component) => {
        if (component.types.includes("locality")) {
          city = component.long_name;
        } else if (component.types.includes("postal_code")) {
          postalCode = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          state = component.long_name;
          stateCode = component.short_name;
        } else if (component.types.includes("country")) {
          country = component.long_name;
          countryCode = component.short_name;
        }
      });

      // Update state with the retrieved data
      setInputValue((previnputValue) => ({
        ...previnputValue,
        address: address,
        city: city,
        postal_code: postalCode,
        location: address,
        state: state,
        state_code: stateCode,
        country: country,
        country_code: countryCode,
      }));

      // Set selected location
      setSelectedLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };
  const [submitClick, setSubmitClick] = useState(false);
  console.log("Inputvalue?.address", inputValue?.address);
  useEffect(() => {
    if (fileData?.length == 0) {
      setErrorMedia("Please upload event media");
    } else {
      setErrorMedia("");
    }
    if (selectedCategories?.length == 0 && infoFormFill) {
      seterrorCategory("Please select category");
    } else {
      seterrorCategory("");
    }
    if (inputValue?.event_title?.length && infoFormFill) {
      setErrorevent_title("");
    }
    if (inputValue?.address && infoFormFill) {
      setErrorEventAddress("");
    }
    if (startDate && startTime && infoFormFill) {
      setErrorEventStart("");
    }
    if (endDate && endTime && infoFormFill) {
      setErrorEventEnd("");
    }

    if ((StoreDesc?.length == 0 || StoreDesc?.length == 49) && infoFormFill) {
      setErrorStoreDesc("Please enter description");
    } else {
      setErrorStoreDesc("");
    }
    if (fileData?.length == 0 && infoFormFill) {
      setErrorMedia("Please upload media");
    } else {
      setErrorMedia("");
    }
    if (inputValue?.address === "" && infoFormFill) {
      CheckValid(inputValue?.address, {
        type: "Address",
        error: setErrorEventAddress,
      });
    }
    if (viewProduct?.store_description && infoFormFill) {
      setErrorStoreDesc("");
    }
    if ((!endDate || !endTime) && infoFormFill) {
      setErrorEventEnd("Please select event end date and time");
    } else {
      setErrorEventEnd("");
    }
    if ((!startDate || !startTime) && infoFormFill) {
      console.log("Please select event start date and time", infoFormFill);

      setErrorEventStart("Please select event start date and time");
    } else {
      setErrorEventStart("");
    }
    if (!selectedDubaiState?.value && infoFormFill) {
      setErrorState("Please select state");
    } else {
      setErrorState("");
    }
  }, [
    inputValue?.event_title,
    selectedCategories,
    menSelected,
    womenSelected,
    startDate,
    startTime,
    endDate,
    endTime,
    inputValue?.address,
    StoreDesc?.length,
    startAge,
    endAge,
    viewProduct,
    fileData,
    selectedDubaiState?.value,
  ]);
  useEffect(() => {
    if (inputValue?.state) {
      setSelectedDubaiState({
        value: inputValue?.state_code,
        label: inputValue?.state,
      });
    }
  }, [inputValue?.state, inputValue?.country]);

  useEffect(() => {
    setTimeout(() => {
      if (Location === "vendor-add-event") {
        const productCategories = selfInfo?.interest.split(",");

        // Filter categoryList to find objects whose name matches the product categories
        const filteredCategories = detailcategoryAllList?.filter((category) =>
          productCategories.includes(category.name)
        );

        // Map the filtered categories to the required structure and update the state
        const mappedCategories = filteredCategories?.map((category) => ({
          value: category?.id,
          label: category?.name,
          sub_categories: category?.sub_categories,
        }));

        if (mappedCategories?.length) {
          const newTags = [];
          mappedCategories.forEach((item) => {
            // Create array of objects with value and label properties
            const newArray = item.sub_categories.map((elm) => ({
              value: elm?.id,
              label: elm?.name,
            }));

            // Add each item from newArray to newTags
            newTags.push(...newArray);
            console.log("newArray", newArray); // Output the created array (optional)
          });

          // Update selectedTags state with newTags
          setSelectedTags((prevTags) => [...prevTags, ...newTags]);
        }
        setSelectedCategories(mappedCategories);
      }
    }, 2000);
  }, [Location]);

  return (
    <>
      {showVideoModal?.show === true ? (
        <AddThumbnailModal
          data={showVideoModal}
          setShowVideoModal={setShowVideoModal}
          setFileData={setFileData}
          fileData={fileData}
        />
      ) : (
        <>
          <div className="breadcrumb">
            <Link className="text-black" to={VENDR_EVENT_MANAGEMENT_URL}>
              Event
            </Link>
            <span> > </span>
            <span>
              <Link>
                {" "}
                {Location === "edit-vendor-event"
                  ? "Edit Event"
                  : "Add New Event"}
              </Link>
            </span>
          </div>
          <section className="dashboard_wrapper_form border p-3">
            <div className="scrollform row">
              <Scrollbars
                style={{ height: "calc(100vh - 150px)" }}
                className="ScrollbarsWrapper"
                renderView={(props) => <div {...props} className="view" />}
              >
                <div className="position-relative mb-2" hidden>
                  <div className="position-absolute end-0 me-5 mt-1">
                    <div className="d-flex gap-2">
                      <button
                        className="common-blue-bg btn pt-1"
                        onClick={() =>
                          key === "media"
                            ? createEvent()
                            : BaicInfoHandler("media")
                        }
                      >
                        {key === "media" ? "Submit" : "Save And Continue"}
                      </button>
                    </div>
                  </div>
                </div>

                <Tabs
                  id="vendor-event-tabbed-add-edit"
                  activeKey={key}
                  onSelect={(k) => BaicInfoHandler(k)}
                  className="mb-3"
                >
                  <Tab
                    eventKey="info"
                    title={
                      <div className={`${infoFormFill ? "common-blue" : ""}`}>
                        <i
                          className={`bi bi-1-circle-fill ${
                            infoFormFill ? "common-blue" : ""
                          }`}
                        ></i>{" "}
                        <span
                          onClick={() => {
                            setKey("info");
                          }}
                        >
                          {" "}
                          Event Description{" "}
                        </span>
                      </div>
                    }
                  >
                    <div className="scrollformCustom">
                      <div className="row" style={{ margin: "0 auto" }}>
                        <div className="col-md-4" hidden>
                          <div className="form-group fileUploadingform">
                            <label>Product Image</label>
                            {AvatarImage === "" || AvatarImage === null ? (
                              <>
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={(e) => UploadImage(e)}
                                  disabled={UploadFileLoader}
                                  accept=".png, .jpg, .jpeg"
                                />
                                {UploadFileLoader && (
                                  <div className="filUploading">
                                    <Spinner animation="border" role="status">
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </Spinner>
                                    Please wait, your file is uploading.
                                  </div>
                                )}
                              </>
                            ) : (
                              <div className="uploadedImagewrps">
                                <AvatarGet type="product" url={AvatarImage} />
                                <div
                                  className="closeIocn"
                                  onClick={() => RemoveUploadImage()}
                                >
                                  <i className="bi bi-x-circle-fill"></i>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-9">
                          <InputField
                            type="text"
                            value={event_title}
                            className={
                              errorevent_title !== ""
                                ? "form-control error-form"
                                : "form-control"
                            }
                            placeholder="Enter event title"
                            label="Event Title <span class='redmednstar'>*</span>"
                            name="event_title"
                            onChange={handleChange}
                            onKeyUp={(e) =>
                              CheckValid(e.target.value, {
                                type: "Title",
                                error: setErrorevent_title,
                              })
                            }
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorevent_title}
                            required={true}
                            maxLength={255}
                            minLength={1}
                          />
                        </div>
                        <div className="col-md-9 mb-2">
                          <label className="mb-2 fs-14px">
                            Select State <span className="common-blue">*</span>
                          </label>
                          <Select
                            value={selectedDubaiState}
                            onChange={handleDubaiStateChange}
                            options={dubaiStatesOptions}
                            isSearchable
                            placeholder="Select State"
                          />
                          {errorState !== "" && (
                            <div className="errormendatorr">
                              <i className="bi bi-x-circle-fill"></i>
                              {errorState}
                            </div>
                          )}
                        </div>

                        <div className="col-md-9 mb-2">
                          <label htmlFor="address" className="common-label">
                            Event Address <span className="common-blue">*</span>
                          </label>
                          {isLoaded && (
                            <StandaloneSearchBox
                              onLoad={(ref) => (inputRef.current = ref)}
                              onPlacesChanged={handlePlaceChanged}
                            >
                              <div className="">
                                <input
                                  type="text"
                                  placeholder={inputValue?.address}
                                  className="form-control form-input text-truncate pl-5"
                                />
                                {errorEventAddress && (
                                  <div className="errormendatorr">
                                    <i className="bi bi-x-circle-fill"></i>
                                    {errorEventAddress}
                                  </div>
                                )}
                                {console.log(
                                  "inputref",
                                  inputRef?.current?.getPlaces()
                                )}
                              </div>
                            </StandaloneSearchBox>
                          )}
                        </div>

                        <div className="col-md-9 row">
                          <div className="col-md-12">
                            <label
                              htmlFor="toolbarClassName"
                              className="mb-2 fs-14px fw-400"
                            >
                              Event Description{" "}
                              <span className="common-blue">*</span>
                            </label>
                            <Editor
                              editorState={editorState}
                              onEditorStateChange={onEditorChange}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              wrapperStyle={{
                                width: "100%",
                                border: "1px solid #ccc",
                                backgroundColor: "white",
                                padding: "10px",
                                borderRadius: "4px",
                              }}
                              editorStyle={{
                                minHeight: "150px",
                                border: "1px solid #ccc",
                                padding: "10px",
                                borderRadius: "4px",
                                fontFamily: "sans-serif",
                              }}
                              toolbar={{
                                options: [
                                  "inline",
                                  "blockType",
                                  "fontSize",
                                  "list",
                                  "textAlign",
                                  "colorPicker",
                                  "link",
                                  "emoji",
                                  "history",
                                ],
                                inline: {
                                  inDropdown: false,
                                  options: [
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "superscript",
                                    "subscript",
                                  ],
                                },
                                list: {
                                  inDropdown: false,
                                  options: ["unordered", "ordered"],
                                },
                                textAlign: {
                                  inDropdown: true,
                                },
                                link: { inDropdown: false },
                                history: { inDropdown: false },
                              }}
                            />

                            {errorStoreDesc && (
                              <div className="errormendatorr">
                                <i className="bi bi-x-circle-fill"></i>
                                {errorStoreDesc}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mt-3">
                          <div className="col-md-12 d-flex flex-column p-2">
                            <div className="col-md-9 row border p-2">
                              <div className="d-flex gap-1 flex-column col-md-12 p-2">
                                <label className="fs-14px">
                                  Starting On{" "}
                                  <span className="common-blue">*</span>
                                </label>
                                <div className="row flex-wrap gap-1 col-md-12">
                                  <div className="col-md-4">
                                    <DatePicker
                                      selected={startDate}
                                      onChange={handleStartDateChange}
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText="Select a date"
                                      minDate={new Date()}
                                    />
                                  </div>
                                  <div className="col-md-3" id="starting_on">
                                    <TimePicker
                                      name="startTime"
                                      onFocusChange={console.log}
                                      minuteStep={1}
                                      onTimeChange={(newTime) =>
                                        handleStartTimeChange(newTime)
                                      }
                                      placeholder={startTime}
                                      time={startTime}
                                      timeFormat="HH:MM"
                                      theme="material"
                                      timeMode="24"
                                    />
                                  </div>
                                  {errorEventStart && (
                                    <div className="errormendatorr">
                                      <i className="bi bi-x-circle-fill"></i>
                                      {errorEventStart}
                                    </div>
                                  )}
                                </div>
                                <label className="fs-14px">
                                  Expires On{" "}
                                  <span className="common-blue">*</span>
                                </label>
                                <div className="row gap-1 col-md-12 ">
                                  <div className="col-md-4">
                                    <DatePicker
                                      selected={endDate}
                                      onChange={handleEndDateChange}
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText="Select a date"
                                      minDate={
                                        startDate
                                          ? new Date(startDate)
                                          : new Date()
                                      }
                                    />
                                  </div>
                                  <div className="col-md-3" id="starting_on">
                                    <TimePicker
                                      name="endTime"
                                      minuteStep={1}
                                      onTimeChange={(newTime) =>
                                        handleEndTimeChange(newTime)
                                      }
                                      placeholder={endTime}
                                      time={endTime}
                                      timeFormat="HH:MM"
                                      theme="material"
                                      timeMode="24"
                                    />
                                  </div>
                                </div>
                                {errorEventEnd && (
                                  <div className="errormendatorr">
                                    <i className="bi bi-x-circle-fill"></i>
                                    {errorEventEnd}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="col-md-12" hidden>
                            <h6 className="col-md-12 my-2">
                              Upload Event Media{" "}
                              <span className="common-blue">*</span>
                            </h6>
                            <div className="col-md-12 row">
                              <div className="col-md-4 mt-1">
                                <form
                                  className="file-upload-form"
                                  id="add-edit-event"
                                >
                                  <label
                                    htmlFor="file"
                                    className="file-upload-label"
                                  >
                                    <div className="file-upload-design">
                                      <img src={uploadIcon} alt="" />
                                      <p>
                                        <a className="browse-button px-1 py-0 text-primary">
                                          Click to upload
                                        </a>
                                        or drag and drop
                                      </p>
                                      <p className="secondary-text">
                                        Max. File Size: 30MB
                                      </p>
                                    </div>
                                    <input
                                      id="file"
                                      type="file"
                                      onChange={UploadImage}
                                      accept=".jpg, .jpeg, .png, .mp4, .avi"
                                    />
                                  </label>
                                </form>
                                {errorMedia && (
                                  <div className="errormendatorr">
                                    <i className="bi bi-x-circle-fill"></i>
                                    {errorMedia}
                                  </div>
                                )}
                              </div>
                              <div className="col-md-8">
                                <div className="col-md-12 row">
                                  {fileData?.length > 0 &&
                                    fileData.map((item, index) => (
                                      <div
                                        key={index}
                                        className="col-md-4 m-0 position-relative"
                                        id="add-edit-event-media"
                                      >
                                        <div
                                          className="d-flex  justify-content-end"
                                          style={{
                                            width: "170px",
                                            height: "auto",
                                          }}
                                        >
                                          <img
                                            src={closeIcon}
                                            alt="Close"
                                            className="bi bi-x rounded-circle mt-3 mr-2 w-1-5rem h-1-5rem"
                                            style={{
                                              cursor: "pointer",
                                              zIndex: 12,
                                              marginBottom: "-10px",
                                            }}
                                            onClick={() =>
                                              handleRemoveImage(item?.name)
                                            } // Assuming you have a function to remove the image
                                          />
                                        </div>

                                        <MediaViewer
                                          media={item?.url?.file_url}
                                          minHeight={"120px"}
                                          maxHeight={"120px"}
                                          minWidth={"150px"}
                                          maxWidth={"150px"}
                                          thumbnail={
                                            item?.url?.gif_url ||
                                            item?.url?.thumbnail_url ||
                                            item?.url?.file_url
                                          }
                                        />
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 overflow-hidden">
                            <div className="col-md-12 my-3">
                              <h6>
                                Upload Event Image{" "}
                                <span className="common-blue">*</span>
                              </h6>
                            </div>
                            <div className="col-md-12 d-flex gap-1 mb-5">
                              <div className="col-md-12 row">
                                <form
                                  className="file-upload-form col-md-4"
                                  id="add-edit-event"
                                >
                                  <label
                                    htmlFor="file"
                                    className="file-upload-label"
                                  >
                                    <div className="file-upload-design">
                                      <img src={uploadIcon} alt="" />
                                      <p>
                                        <a className="browse-button px-1 py-0 text-primary">
                                          Click to upload
                                        </a>
                                        or drag and drop
                                      </p>
                                      <p className="secondary-text">
                                        Max. File Size: 30MB
                                      </p>
                                    </div>
                                    <input
                                      id="file"
                                      type="file"
                                      onChange={UploadImage}
                                      accept=".jpg, .jpeg, .png, .mp4, .avi"
                                    />
                                  </label>
                                </form>

                                {fileData?.length > 0 &&
                                  fileData?.map((item, index) => (
                                    <div
                                      className="col-md-2 border my-3 mx-3 br-1rem d-flex p-2 flex-column position-relative"
                                      key={index}
                                    >
                                      <img
                                        src={closeIcon}
                                        alt=""
                                        className="position-absolute top--5 end--7 w-1-5rem"
                                        onClick={() =>
                                          handleRemoveImage(item?.name)
                                        }
                                      />
                                      <div className="flex-grow-1">
                                        <MediaViewer
                                          media={item?.url?.file_url}
                                          minHeight={"120px"}
                                          maxHeight={"120px"}
                                          minWidth={"150px"}
                                          maxWidth={"150px"}
                                          thumbnail={
                                            item?.url?.gif_url ||
                                            item?.url?.thumbnail_url ||
                                            item?.url?.file_url
                                          }
                                        />
                                      </div>
                                      <div className="d-flex flex-column gap-0 me-2">
                                        <i className="mb-0 text-truncate">
                                          {item?.original_name}
                                        </i>
                                        <p className="secondary-text">
                                          {bytesToKB(item?.size)}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                            {errorMedia && (
                              <div
                                className="errormendatorr"
                                style={{ marginTop: "-3rem" }}
                              >
                                <i className="bi bi-x-circle-fill"></i>
                                {errorMedia}
                              </div>
                            )}
                          </div>
                          {Location === "vendor-edit-event" && (
                            <div className="col-md-12 pe-4 my-3 py-3">
                              <button
                                className="bg-primary px-3 py-3 btn w-50 text-white"
                                onClick={createEvent}
                              >
                                {" "}
                                Update
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Tab>
                  {Location === "vendor-add-event" && (
                    <Tab
                      eventKey="media"
                      title={
                        <div>
                          <i
                            className={`bi bi-2-circle-fill ${
                              infoFormFill && key === "media"
                                ? "common-blue"
                                : "text-secondary"
                            }`}
                          ></i>{" "}
                          Filter & Payment
                        </div>
                      }
                    >
                      <div className="scrollformCustom">
                        <div className="row" style={{ margin: "0 auto" }}>
                          <div className="col-md-12 mt-3 row">
                            <div className="col-md-6 border-right">
                              <div className="col-md-12">
                                <div className="d-flex flex-column">
                                  <h6>Categories</h6>
                                  <div className="filedaddwraps justify-content-start mb-2 pe-3">
                                    <Select
                                      value={selectedCategories}
                                      onChange={handleCategoryChange}
                                      options={categoryOptions}
                                      isSearchable
                                      isMulti
                                      placeholder="Select Categories"
                                      components={customComponents}
                                    />
                                  </div>
                                  {errorCategory && (
                                    <div className="errormendatorr">
                                      <i className="bi bi-x-circle-fill"></i>
                                      {errorCategory}
                                    </div>
                                  )}

                                  {selectedCategories.map((category, index) => (
                                    <div
                                      key={index}
                                      className="selected-category ms-2"
                                    >
                                      <i
                                        className="bi bi-dash-circle text-red"
                                        onClick={() => removeCategory(category)}
                                      ></i>
                                      <span className="ms-2">
                                        {" "}
                                        {category.label}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="col-md-12 pe-3">
                                <div className="d-flex flex-column">
                                  <h6>Tags</h6>
                                  <div className="filedaddwraps justify-content-start mb-2">
                                    <Select
                                      value={selectedTags}
                                      onChange={handleTagsChange}
                                      options={subcategoryOptions}
                                      isSearchable
                                      isMulti
                                      placeholder="Select Tags"
                                      components={customComponents}
                                    />
                                  </div>
                                  {selectedTags.map((Tag, index) => (
                                    <div
                                      key={index}
                                      className="selected-Tag ms-2"
                                    >
                                      <i
                                        className="bi bi-dash-circle text-red"
                                        onClick={() => removeTags(Tag)}
                                      ></i>
                                      <span className="ms-2"> {Tag.label}</span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="col-md-12">
                                  <div className="col-md-12 d-flex gap-5  py-1">
                                    <div
                                      className="d-flex align-items-center gap-2 border br-1rem px-2 py-1"
                                      onClick={(e) => {
                                        setMenSelected((prev) => !prev);
                                      }}
                                    >
                                      <img
                                        src={menIcon}
                                        alt="menIcon"
                                        className="h-1-5rem w-1-5rem"
                                      />{" "}
                                      <span
                                        className={`${
                                          menSelected
                                            ? "text-primary"
                                            : "text-black"
                                        }`}
                                      >
                                        Men
                                      </span>
                                      <i
                                        className={`bi bi-check-circle-fill fs-1-3rem ${
                                          menSelected
                                            ? "text-primary"
                                            : "text-black"
                                        }`}
                                      ></i>
                                    </div>
                                    <div
                                      className="d-flex align-items-center border br-1rem px-2 py-1 gap-2"
                                      onClick={(e) => {
                                        setWomenSelected((prev) => !prev);
                                      }}
                                    >
                                      <img
                                        src={womenIcon}
                                        alt="womenIcon"
                                        className="h-1-5rem w-1-5rem"
                                      />{" "}
                                      <span
                                        className={`${
                                          womenSelected
                                            ? "text-primary"
                                            : "text-black"
                                        }`}
                                      >
                                        Women
                                      </span>
                                      <i
                                        className={`bi bi-check-circle-fill fs-1-3rem ${
                                          womenSelected
                                            ? "text-primary"
                                            : "text-black"
                                        }`}
                                      ></i>
                                    </div>
                                  </div>

                                  <div className="col-md-12 d-flex flex-column">
                                    <label className="fs-14px mb-1 mt-2">
                                      Select Age Range
                                    </label>
                                    <div className="d-flex gap-3">
                                      <div className="col-md-4">
                                        <Select
                                          value={startAge}
                                          onChange={handleStartAgeChange}
                                          options={ageOptions}
                                          isSearchable
                                          placeholder="Start"
                                        />
                                      </div>
                                      <label>To</label>
                                      <div className="col-md-4">
                                        <Select
                                          value={endAge}
                                          onChange={handleEndAgeChange}
                                          options={ageOptions}
                                          isSearchable
                                          placeholder="End"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 pe-4  py-3">
                                    <button
                                      className="bg-primary px-3 py-3 btn w-100 text-white"
                                      onClick={ApplyFilterHandler}
                                    >
                                      Apply Filter
                                    </button>
                                  </div>

                                  <div className="col-md-12 pe-4 mb-3 py-3">
                                    <button
                                      className="bg-pink px-3 py-3 btn w-100 text-white"
                                      onClick={createEvent}
                                    >
                                      {Location !== "vendor-edit-event"
                                        ? "Proceed to payment"
                                        : "Update"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {showPaymentUI &&
                              liveMapUserList?.list?.counts?.total_users &&
                              submitClick &&
                              Location !== "vendor-edit-event" && (
                                <div className="col-md-6 position-relative">
                                  <div className="row" id="payment-modal">
                                    {liveMapUserList?.list?.counts && (
                                      <div className="col-md-12 mb-3 mt-2">
                                        <div className="col-md-12 d-flex align-items-center justify-content-center bg-highlight-pink px-4 py-2 rounded ">
                                          <div className="d-flex justify-content-center col-md-4 gap-2 align-items-center">
                                            <small className="fs-6">
                                              Active Users-
                                            </small>{" "}
                                            <span className="text-fuchsia">
                                              {liveMapUserList?.list?.counts
                                                ?.active_users || "-"}
                                            </span>{" "}
                                            <span className="vertical-line"></span>
                                          </div>
                                          <div className="d-flex justify-content-center col-md-4 gap-2 align-items-center">
                                            <small className="fs-6">
                                              Total User-
                                            </small>{" "}
                                            <span className="text-fuchsia">
                                              {" "}
                                              {liveMapUserList?.list?.counts
                                                ?.total_users || "-"}
                                            </span>{" "}
                                            <span className="vertical-line"></span>
                                          </div>
                                          <div className="d-flex justify-content-center col-md-4 gap-2 align-items-center">
                                            <small className="fs-6">
                                              Payable Amount-
                                            </small>{" "}
                                            <span className="text-fuchsia">
                                              150
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    <div className="col-md-12">
                                      <div className="row" id="payment-modal">
                                        <div className="col-md-12">
                                          <div
                                            className="bg-blue w-100 "
                                            hidden
                                          >
                                            <p className="info px-2 py-1">
                                              <svg
                                                width="25"
                                                height="25"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <g clip-path="url(#clip0_2187_37685)">
                                                  <path
                                                    d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM9.6875 5.625C9.87292 5.625 10.0542 5.67998 10.2084 5.783C10.3625 5.88601 10.4827 6.03243 10.5536 6.20373C10.6246 6.37504 10.6432 6.56354 10.607 6.7454C10.5708 6.92725 10.4815 7.0943 10.3504 7.22541C10.2193 7.35652 10.0523 7.44581 9.8704 7.48199C9.68854 7.51816 9.50004 7.49959 9.32874 7.42864C9.15743 7.35768 9.01101 7.23752 8.908 7.08335C8.80499 6.92918 8.75 6.74792 8.75 6.5625C8.75 6.31386 8.84878 6.0754 9.02459 5.89959C9.20041 5.72377 9.43886 5.625 9.6875 5.625ZM10.625 14.375C10.2935 14.375 9.97554 14.2433 9.74112 14.0089C9.5067 13.7745 9.375 13.4565 9.375 13.125V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75C9.70652 8.75 10.0245 8.8817 10.2589 9.11612C10.4933 9.35054 10.625 9.66848 10.625 10V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z"
                                                    fill="#407BFF"
                                                  />
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_2187_37685">
                                                    <rect
                                                      width="20"
                                                      height="20"
                                                      fill="white"
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                              <span className="common-blue">
                                                Each user corresponds to a value
                                                of 10 AED.
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-md-12">
                                          <InputField
                                            type="text"
                                            value={card_holder_name}
                                            className={
                                              errorcard_holder_name !== ""
                                                ? "form-control error-form"
                                                : "form-control"
                                            }
                                            placeholder="Ex John Doe"
                                            label="Card holder name <span class='redmednstar'>*</span>"
                                            name="card_holder_name"
                                            onChange={handleChange}
                                            onKeyUp={(e) =>
                                              CheckValid(e.target.value, {
                                                type: "card_holder_name",
                                                error: setErrorcard_holder_name,
                                              })
                                            }
                                            onKeyDown={EmptySpaceFieldValid}
                                            errormessage={errorcard_holder_name}
                                            required={true}
                                            autoFocus={true}
                                          />
                                        </div>
                                        <div className="col-md-12">
                                          <InputField
                                            type="text"
                                            value={card_number}
                                            className={
                                              errorcard_number !== ""
                                                ? "form-control error-form"
                                                : "form-control"
                                            }
                                            placeholder="Enter card number"
                                            label="Card Number <span class='redmednstar'>*</span>"
                                            name="card_number"
                                            onChange={handleChange}
                                            onKeyUp={(e) =>
                                              CheckValid(e.target.value, {
                                                type: "card_number",
                                                error: setErrorcard_number,
                                              })
                                            }
                                            onKeyDown={EmptySpaceFieldValid}
                                            errormessage={errorcard_number}
                                            required={true}
                                          />
                                        </div>
                                        <div className="col-md-12 d-flex">
                                          <div className="col-md-6">
                                            <label
                                              htmlFor=""
                                              className="fs-14px mb-1 fw-400"
                                            >
                                              Expiry Date{" "}
                                              <span className="common-blue">
                                                *
                                              </span>
                                            </label>
                                            <DatePicker
                                              selected={selectedDate}
                                              onChange={(date) =>
                                                setSelectedDate(date)
                                              }
                                              showMonthYearPicker
                                              dateFormat="MM/yyyy"
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <InputField
                                              type="text"
                                              value={CVV}
                                              className={
                                                errorCVV !== ""
                                                  ? "form-control error-form"
                                                  : "form-control"
                                              }
                                              placeholder="CVV"
                                              label="Security Code <span class='redmednstar'>*</span>"
                                              name="CVV"
                                              onChange={handleChange}
                                              onKeyUp={(e) =>
                                                CheckValid(e.target.value, {
                                                  type: "CVV",
                                                  error: setErrorCVV,
                                                })
                                              }
                                              onKeyDown={EmptySpaceFieldValid}
                                              errormessage={errorCVV}
                                              required={true}
                                              maxLength={3}
                                              minLength={3}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-12">
                                          <label htmlFor="">Coins Redeem</label>
                                          <Select
                                            value={selectedOption}
                                            onChange={coinHandler}
                                            options={options}
                                            isSearchable
                                            placeholder="Select a number..."
                                          />
                                        </div>
                                        <div className="col-md-12 my-2">
                                          <div className="col-md-12 d-flex justify-content-between align-items-center">
                                            <p className="secondary-text">
                                              Offer Amount
                                            </p>
                                            <p>{TotalAmount}</p>
                                          </div>
                                          <div className="col-md-12 d-flex justify-content-between align-items-center border-bottom-dotted">
                                            <p className="secondary-text">
                                              Coins Redeem
                                            </p>
                                            <p className="delete-text">
                                              {selectedOption?.value} ( For -{" "}
                                              {RedeemTotal.toFixed(2)} AED)
                                            </p>
                                          </div>
                                          <div className="col-md-12 d-flex my-2 justify-content-between align-items-center border-bottom-dotted">
                                            <p className="fw-700">
                                              Grand Total
                                            </p>
                                            <p className="fw-800">
                                              {GrandTotal}{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <button
                                        className="btn bg-primary text-white fw-500 w-100 py-3 mb-5"
                                        onClick={handleClose}
                                      >
                                        Pay {GrandTotal} AED
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </Tab>
                  )}
                </Tabs>
              </Scrollbars>
            </div>
            {/* loader start here */}
            {loaderMain && <CommonLoader />}
          </section>
          {showModal?.open && (
            <CommonModel
              fromUrl={"product"}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}
        </>
      )}
    </>
  );
};

export default VendorAddEditEvent;
