import React, { useState } from "react";
import ReactPlayer from "react-player";
import playIcon from "../../assets/img/playIcon.png";
import { isVideoUrl } from "../Shared/constant";

const MediaViewerCustom = ({
  media,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  thumbnail,
  showFull,
}) => {
  const [showFullSize, setShowFullSize] = useState(showFull || false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const toggleFullSize = () => {
    setShowFullSize((prev) => !prev);
  };

  const handleVideoClick = () => {
    setIsVideoPlaying(true);
  };

  const renderMedia = () => {
    if (thumbnail && thumbnail.length > 20) {
      return (
        <div
          onClick={toggleFullSize}
          style={{
            cursor: "pointer",
            position: "relative",
            width: "100%",
            height: "100%",
            zIndex: "1201",
          }}
        >
          <img
            src={thumbnail}
            alt="video Thumbnail"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "8px",
              maxHeight: maxHeight,
              maxWidth: maxWidth,
              minWidth: minWidth,
              minHeight: minHeight,
              zIndex: "1201",
            }}
          />
          <img
            src={playIcon}
            alt="Play Icon"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "30px",
              height: "30px",
              zIndex: "1201",
            }}
            id="playIcon"
          />
        </div>
      );
    } else if (media && media.length > 20) {
      return (
        <div
          onClick={handleVideoClick}
          style={{
            cursor: "pointer",
            position: "relative",
            width: "100%",
            height: "100%",
            zIndex: "1201",
          }}
        >
          { isVideoUrl(media) ? (
            <ReactPlayer
              url={media}
              playing={isVideoPlaying}
              controls
              width="100%"
              height="100%"
            />
          ) : (
            <img
              src={media}
              alt="media"
              width={showFullSize ? "60%" : "60%"}
              height={showFullSize ? "80%" : "90%"}
            />
          )}
        </div>
      );
    } else {
      return null; // Return null if neither thumbnail nor media is provided or they are invalid
    }
  };

  return <div id="MediaViewerCustom">{renderMedia()}</div>;
};

export default MediaViewerCustom;
