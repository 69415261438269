/* eslint-disable */
import { useEffect, useState, useRef, useCallback } from "react";
import { ws_response } from "./ws/ws_response";

const useWebSocket = (url, functions, Dispatch, accessToken) => {
  const [connected, setConnected] = useState(false);
  const socketRef = useRef(null);
  const reconnectIntervalRef = useRef(null);

  const connectWebSocket = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.close();
    }

    const newSocket = new WebSocket(url);
    console.log("url==========>", url, accessToken);
    functions(newSocket);
    socketRef.current = newSocket;

    newSocket.onopen = () => {
      console.log("WebSocket connection opened");
      setConnected(true);
      clearInterval(reconnectIntervalRef.current); // Clear the reconnection interval on successful connection
    };

    newSocket.onmessage = (evt) => {
      console.log("inside onmessage", evt);
      Dispatch(ws_response({ evt, ws: newSocket }));
    };

    newSocket.onclose = (event) => {
      console.log("inside onclose", event);
      setConnected(false);
      scheduleReconnect(); // Schedule reconnection
    };

    newSocket.onerror = (error) => {
      console.log("WebSocket error", error);
      setConnected(false);
      scheduleReconnect(); // Schedule reconnection on error
    };
  }, [url, accessToken, functions, Dispatch]);

  const scheduleReconnect = useCallback(() => {
    clearInterval(reconnectIntervalRef.current);
    reconnectIntervalRef.current = setInterval(() => {
      console.log("Attempting to reconnect...");
      connectWebSocket();
    }, 5000); // Try to reconnect every 5 seconds
  }, [connectWebSocket]);

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.close(); // Close connection on cleanup
      }
      clearInterval(reconnectIntervalRef.current); // Clear interval on cleanup
    };
  }, [connectWebSocket]);

  return connected;
};

export default useWebSocket;
