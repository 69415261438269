/* eslint-disable */
import React, { useState } from "react";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { Image } from "react-bootstrap";
import LoginImage from "../../assets/img/LogINBG.png";
import LogoShort from "../../assets/img/logoIcon.png";

import { Link } from "react-router-dom";
import {
  FORGOT_PASSWORD_URL,
  DASHBOARD_URL,
  LOGIN_API_URL,
  VENDOR_DASHBOARD_URL,
} from "../Shared/constant";
import { useNavigate } from "react-router-dom";
import LoginAPI from "../../Api/LoginAPI";
import { useDispatch } from "react-redux";
import {
  getSelfDetails,
  getUserId,
  getAccessToken,
  checkLogin,
  getDeviceId,
} from "../../redux/actions/adminActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BtnLoader from "../Common/BtnLoader";

const DeleteAccount = () => {
  const Navigate = useNavigate();
  const Dispatch = useDispatch();
  const [inputValue, setInputValue] = useState({
    Email: "",
    Password: "",
    remember: false,
  });
  const { Email, Password, remember } = inputValue;
  const [showpassword, setshowpassword] = useState(false);
  const [loaderStart, setloaderStart] = useState(false);
  console.log("inputValue", inputValue);
  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  // Regex
  const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
  const PasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line

  // error message
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  // show password
  const ShowHidePassword = () => {
    setshowpassword(!showpassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrorEmail("");
    setErrorPassword("");
  };

  // login function
  const Login = async () => {
    if (inputValue?.Email !== "" && inputValue?.Password !== "") {
      if (
        EmailRegex.test(inputValue?.Email) === true &&
        PasswordRegex.test(inputValue?.Password) === true
      ) {
        setloaderStart(true);
        const jsonData = JSON.stringify({
          email: inputValue?.Email,
          password: inputValue?.Password,
          device_type: "web",
        });
        toast.success("User Account Deleted Successfully");
            setloaderStart(false);
            setInputValue({
                Email: "",
                Password: "",
                remember: false,
              })

        // const response = await LoginAPI(jsonData);
        // console.log("response", response?.data?.user?.user_type);
        // if (response?.status === 200) {
        //   setTimeout(() => {
        //     toast.success(response.msg);

        //     Dispatch(getSelfDetails(response?.data?.user));
        //     Dispatch(getUserId(response?.data?.user?.id));
        //     Dispatch(getAccessToken(response?.data?.access_token));
        //     Dispatch(getDeviceId(response?.data?.device_id));
        // localStorage.setItem("device_id",response?.data?.device_id)
        //     Dispatch(checkLogin("yes"));
        //     clearForm();
        //     setloaderStart(false);

        //     if (inputValue?.remember == true) {
        //       localStorage.setItem("remember", "true");
        //     } else {
        //       localStorage.setItem("remember", "false");
        //     }
        //     Navigate(
        //       response?.data?.user?.user_type === "admin"
        //         ? DASHBOARD_URL
        //         : VENDOR_DASHBOARD_URL
        //     );
        //   }, 100);
        // } else {
        //   setloaderStart(false);
        //   toast.error(response.error);
        // }
      } else {
        CheckValid(inputValue?.Email, { type: "email", error: setErrorEmail });
        CheckValid(inputValue?.Password, {
          type: "password",
          error: setErrorPassword,
        });
      }
    } else {
      CheckValid(inputValue?.Email, { type: "email", error: setErrorEmail });
      CheckValid(inputValue?.Password, {
        type: "password",
        error: setErrorPassword,
      });
    }
  };

  // clear form
  const clearForm = () => {
    setInputValue({ ...inputValue, Email: "", Password: "" });
    setErrorEmail("");
    setErrorPassword("");
  };

  return (
    <section className="login_wrapper d-flex w-100">
      <div className="login_form_wraps mx-auto">
        <img
          src={LogoShort}
          alt="login image"
          style={{ height: "2.5rem", width: "2.5rem" }}
          className="text-left mb-3"
        />

        <div className="loginTEXTS">
          {/* <h1>E Find</h1> */}
          <h4>Account Delete</h4>
          <p className="text-left mt-3 mb-0">
          Enter your email and password to delete your account.
          </p>
        </div>
        <InputField
          type="text"
          value={Email}
          className={
            errorEmail !== "" ? "form-control error-form" : "form-control"
          }
          placeholder="Enter Email Address"
          label="Email Address"
          name="Email"
          onChange={handleChange}
          onKeyUp={(e) =>
            CheckValid(e.target.value, { type: "email", error: setErrorEmail })
          }
          onKeyDown={EmptySpaceFieldValid}
          errormessage={errorEmail}
          required={true}
        />
        <InputField
          type={showpassword ? "text" : "password"}
          value={Password}
          className={
            errorPassword !== "" ? "form-control error-form" : "form-control"
          }
          placeholder="Enter Password"
          label="Password"
          name="Password"
          onChange={handleChange}
          onKeyUp={(e) =>
            CheckValid(e.target.value, {
              type: "password",
              error: setErrorPassword,
            })
          }
          onKeyDown={EmptySpaceFieldValid}
          errormessage={errorPassword}
          onClick={ShowHidePassword}
          showpassword={showpassword}
          required={true}
        />
        <div className="form-group-btn ">
          <button
            className="btn loginCmn_btn w-100"
            disabled={loaderStart}
            onClick={() => Login()}
          >
            Delete
          </button>
        </div>
      </div>
    </section>
  );
};

export default DeleteAccount;
