import React from 'react';
import { Modal } from 'react-bootstrap';
import MediaViewerCustom from './MediaViewerCustom';

export default function MediaModal(props) {
  const { media, thumbnail, maxHeight, maxWidth, minHeight, minWidth } = props?.data;
console.log(" media, thumbnail, maxHeight, maxWidth, minHeight, minWidth", media, thumbnail, maxHeight, maxWidth, minHeight, minWidth)
  const handleClose = () => {
    props?.setShowMediaModal({
      show: false
    });
  };

  return (
    <Modal centered show onHide={handleClose} style={{ zIndex: '1200' }}>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>

      <Modal.Body className='text-center p-5'>
        <MediaViewerCustom
          media={media}
          thumbnail={thumbnail}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          minHeight={minHeight}
          minWidth={minWidth}
        />
      </Modal.Body>
    </Modal>
  );
}
