import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setConfirmModal } from '../../redux/actions/adminActions';
import { Modal } from 'react-bootstrap';

export default function MediaConfirmModal() {
    const dispatch = useDispatch();
    const {
        confirmModal
      } = useSelector((state) => state.adminReducers);

    const handleCloseModal = async () => {
        if (confirmModal?.callBack) {
            await confirmModal?.callBack();
        }
        CloseData();
    };

    const CloseData = () => {
        dispatch(setConfirmModal({ show: '' }));
    };

    if (confirmModal?.show !== 'MEDIA') {
        return null;
    }

    return (
        <Modal show={true} onHide={CloseData} animation={false} centered>
            <Modal.Body className="text-center p-4">
                <h6 className="logout-modal-subtitle">Are you sure you want to delete this media ?</h6>
                <div className="d-flex align-items-center p-2">
                    <button className="me-2 w-100 btn mt-2 bg-secondary text-white" size="sm" variant="dark" onClick={CloseData}>No</button>
                    <button className="ms-2 w-100 btn mt-2 bg-danger text-white" size="sm" variant="primary" onClick={handleCloseModal}>Yes</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

MediaConfirmModal.propTypes = {
    handleClose: PropTypes.func,
    show: PropTypes.bool,
};
