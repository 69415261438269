/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import CommonToggle from "../Common/CommonToggle";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { ADD_SUPPLIER_MANAGEMENT_URL, VIEW_SUPPLIER_MANAGEMENT_URL, EDIT_SUPPLIER_MANAGEMENT_URL } from "../Shared/constant";
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonPagination from "../Common/CommonPagination";
import CommonModel from "../Common/CommonModel";

const Supplier = () => {
    const { websocket } = useContext(WebSocketContext);
    const { selfInfo, supplierList } = useSelector((state) => state.adminReducers);
    const Navigate = useNavigate();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // add new vendor
    const AddSupplierFnct = () => {
        Navigate(ADD_SUPPLIER_MANAGEMENT_URL);
    };

    // get supplier list
    useEffect(() => {
        let param = { 
            "transmit": "single", 
            "url": "supplier_list",
            "request" : { 
                "filter" : userType, 
                "limit" : userLimit, 
                "page" : currentPage, 
                "search" : userSearch.trim() 
            }
        };
        wsSend_request(websocket, param);
    }, [ userSearch, userType, userLimit, currentPage ]);

    // delete supplier
    const DeleteSupplierFnct = (data_) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Delete Supplier",
            subtitle: "Delete Supplier",
            description: `Do you really want to delete <h5>${data_?.name}</h5>`,
            modalType: "not form",
            button: "Yes",
            Data: data_
        });
    };

    // active supplier
    const AllowSupplierGet = (data_, type, booleancheck) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: "Are you sure?",
            subtitle: "Active Supplier",
            description: `Do you really want to ${booleancheck ? "remove " : ""} ${type.split("_")[1]} to <h5>${data_?.name}</h5>`,
            modalType: "not form",
            button: "Yes",
            Data: data_
        });
    }; 

    return(<section className="dashboard_wrapper">
        <DataTableFilter 
            filterType={"Supplier (company, name, email, registration number)"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user_type === "admin" || (selfInfo?.permissions?.includes("add-supplier")))}
            ButtonSet={{
                buttontitle: "Add Supplier",
                function: AddSupplierFnct
            }}
        />
        <div className="tableContent" style={{ height: window.screen.width > 540 ? "calc(100vh - 155px)" : "calc(100vh - 143px)" }}>
            <Scrollbars 
                style={{ height: supplierList?.pagination?.total_records > 10 ? (window.screen.width < 767 ? "calc(100% - 10px)" : "calc(100% - 38px)") : "calc(100% - 0px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Title</th>
                                <th>Company Name</th>
                                <th>Phone</th>
                                {(selfInfo?.user_type === "admin" || (selfInfo?.permissions?.includes("update-supplier"))) && (<th>Active</th>)}
                                <th>Created At</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {supplierList && supplierList?.list?.map((user, index) => {
                                return(<tr key={index}>
                                        <td>{user?.name === null ? "-" : user?.name}</td>
                                        <td>{(user?.title === "" || user?.title === null) ? "-" : user?.title}</td>
                                        <td>{(user?.company === "" || user?.company === null) ? "-" : user?.company}</td>
                                        <td>{(user?.phone === null || user?.phone === "") ? "-" : "+"+user?.phone_code +"-"+ user?.phone}</td>
                                        {(selfInfo?.user_type === "admin" || (selfInfo?.permissions?.includes("update-supplier"))) && (<td>
                                            <CommonToggle 
                                                valueToggle={user?.is_active}
                                                setValueToggle={() => AllowSupplierGet(user, "is_active", user?.is_active)}
                                                name={""}
                                            />
                                        </td>)}
                                        <td>{moment(user.created_at).format('LL')}</td>
                                        <td className="respoactionSupplier">
                                            <button type="button" className="btn actiontblebtn" onClick={() => Navigate(`${VIEW_SUPPLIER_MANAGEMENT_URL}/${user?.id}`)}>
                                                <i className="bi bi-eye"></i>
                                            </button>
                                            {(selfInfo?.user_type === "admin" || (selfInfo?.permissions?.includes("update-supplier"))) && (<button type="button" className="btn actiontblebtn" onClick={() => Navigate(`${EDIT_SUPPLIER_MANAGEMENT_URL}/${user?.id}`)}>
                                                <i className="bi bi-pencil-square"></i>
                                            </button>)}
                                            {(selfInfo?.user_type === "admin" || (selfInfo?.permissions?.includes("delete-supplier"))) && (<button type="button" className="btn actiontblebtn" onClick={() => DeleteSupplierFnct(user)}>
                                                <i className="bi bi-trash3" style={{ color: "red" }}></i>
                                            </button>)}
                                        </td>
                                    </tr>)})}
                            {supplierList && supplierList?.list.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Supplier Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
            {/* pagination */}
            {supplierList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={supplierList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModal?.open && (<CommonModel setUserSearch={setUserSearch} setCurrentPage={setCurrentPage}  showModal={showModal} setShowModal={setShowModal} />)}
</section>)
}

export default Supplier;